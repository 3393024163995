import React from "react";

const SendIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="telegram-logo 1" clip-path="url(#clip0_2296_13820)">
        <path
          id="Vector"
          d="M8.25012 12.7108L16.6799 20.1289C16.7774 20.2148 16.8956 20.2738 17.0228 20.3002C17.15 20.3266 17.2819 20.3196 17.4055 20.2797C17.5292 20.2398 17.6403 20.1685 17.7281 20.0728C17.8159 19.977 17.8774 19.8601 17.9064 19.7334L21.4327 4.346C21.4632 4.21296 21.4568 4.07412 21.4142 3.94445C21.3716 3.81477 21.2944 3.69918 21.191 3.61012C21.0876 3.52106 20.9618 3.46192 20.8272 3.43906C20.6927 3.4162 20.5544 3.4305 20.4274 3.4804L3.12517 10.2777C2.97438 10.3369 2.84683 10.4434 2.76159 10.5812C2.67636 10.719 2.63803 10.8806 2.65235 11.042C2.66666 11.2034 2.73285 11.3558 2.841 11.4764C2.94915 11.5971 3.09346 11.6794 3.25233 11.7112L8.25012 12.7108Z"
          stroke="#F8FAF8"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_2"
          d="M8.25977 12.6444L21.0203 3.42847"
          stroke="#F8FAF8"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_3"
          d="M12.46 16.3494L9.53033 19.2791C9.42544 19.384 9.2918 19.4554 9.14632 19.4843C9.00083 19.5133 8.85003 19.4984 8.71299 19.4416C8.57594 19.3849 8.45881 19.2888 8.3764 19.1654C8.29399 19.0421 8.25 18.8971 8.25 18.7487V12.6445"
          stroke="#F8FAF8"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2296_13820">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SendIcon;
