import React, { useEffect, useContext } from "react";

// Components
import Loader from "../../../components/ui/loader/Loader";
import RankStats from "./components/rankStats/RankStats";
import RankAnalytics from "./components/rankAnalytics/RankAnalytics";
import SimpleTitle from "./../../../components/advanced/simpleTitle/SimpleTitle";
import RankHistory from "./components/rankHistory/RankHistory";
import RankGraph from "./components/rankGraph/RankGraph";

// Libraries
import { Button, Container } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";
import { useNavigate } from "react-router-dom";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Data
import config from "../../../data/config";

// Styles
import Style from "./RankOverview.module.css";
import classNames from "../../../utils/classNames";

const RankOverview = () => {
  // Hooks
  const navigate = useNavigate();

  // Contexts
  const { paths } = useContext(RoutesContext);

  useEffect(() => {
    document.title = `${config.WEBSITE_NAME} | Rank Overview`;
    //eslint-disable-next-line
  }, []);

  return (
    <PermissionRoute
      loader={<Loader />}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        // dispatcher
        return true;
      }}
    >
      <Container>
        <section className={classNames(Style.section, Style.headers)}>
          <SimpleTitle
            title="Rank Overview"
            subtitle={
              "With Rank Overview, you get real-time analytics that show you exactly where you stand. Understand your current status, and what you need to level up. It's all designed to help you make smarter decisions and grow efficiently."
            }
          />
          <Button
            className={Style.wikiBtn}
            onClick={() => {
              navigate(paths.main.wiki);
            }}
          >
            Visit Wiki
          </Button>
        </section>
        <section className={Style.section}>
          <RankStats />
        </section>
        <section className={Style.section}>
          <RankHistory />
        </section>
        <section className={Style.section}>
          <RankAnalytics />
        </section>
        <section className={Style.section}>
          <RankGraph />
        </section>
      </Container>
    </PermissionRoute>
  );
};

export default RankOverview;
