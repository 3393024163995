import React, { useContext, useEffect } from "react";

// Components
import Loader from "../../../components/ui/loader/Loader";
// import SimpleTitle from "../../../components/advanced/simpleTitle/SimpleTitle";
// import Terms from "./components/terms/Terms";

// Libraries
import { PermissionRoute } from "@hybris-software/use-auth";
import { useNavigate } from "react-router-dom";
import { Button } from "@hybris-software/ui-kit";
// import useQuery from "@hybris-software/use-query";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Data
// import endpoints from "../../../data/endpoints";

// Styles
import Style from "./LegalView.module.css";
import config from "../../../data/config";

const LegalView = () => {
  // Hooks
  const navigate = useNavigate();

  // Contexts
  const { paths } = useContext(RoutesContext);

  // States
  // const [linkTerms, setLinkTerms] = useState("");

  // const termsDataApi = useQuery({
  //   url: endpoints.auth.TERMS,
  //   method: "GET",
  //   executeImmediately: true,
  // });

  useEffect(() => {
    window.open(config?.LEGAL_TERMS_URL, "_blank", "noopener noreferrer");
    //eslint-disable-next-line
  }, []);

  return (
    <PermissionRoute
      loader={<Loader />}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        navigate(paths.auth.login);
      }}
      // permissionController={(response) => {
      //   setLinkTerms(response?.data?.termsAndCondition);
      //   return true;
      // }}
    >
      {/* <Container>
        <SimpleTitle title="Legal" />
        <section className={Style.section}>
          <Terms linkTerms={linkTerms} data={termsDataApi?.response?.data} />
        </section>
      </Container> */}
      <div className={Style.window}>
        <div className={Style.box}>
          <h4>Legal Terms</h4>
          <p>
            If the legal terms did not open automatically, click on the button below
          </p>
          <Button
            onClick={() => {
              window.open(
                config?.LEGAL_TERMS_URL,
                "_blank",
                "noopener noreferrer"
              );
            }}
          >
            Go to Legal Terms
          </Button>
        </div>
      </div>
    </PermissionRoute>
  );
};

export default LegalView;
