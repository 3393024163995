import React, { useRef } from "react";

// Libraries
import { Outlet } from "react-router-dom";
import { MagicModal } from "@hybris-software/ui-kit";

// Images
import Lights from "../../assets/images/lights.png";

// Styles
import Style from "./AuthLayout.module.css";

const AuthLayout = () => {
  // Refs
  const errorRef = useRef(null);
  return (
    <>
      <MagicModal
        overlayStyle={{ background: "var(--overlay-modal)" }}
        ref={errorRef}
        destroyBodyOnClose={false}
        closeIcon={null}
      />

      <img
        rel="preload"
        as="image"
        src={Lights}
        className={Style.ellipseOne}
        alt=""
      />
      <div className={Style.blur} />

      <div className={Style.layout}>
        <Outlet
          context={{
            errorRef,
          }}
        />
      </div>
    </>
  );
};

export default AuthLayout;
