import React from "react";

const Activities = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
    >
      <path
        d="M9.23993 4.33893C10.0547 2.3867 12.7785 2.3867 13.5933 4.33893L14.1608 5.69873C14.4945 6.49823 15.227 7.05247 16.0767 7.14825L17.6604 7.32677C19.6604 7.55223 20.4864 10.0509 19.0258 11.4568L17.6811 12.7511C17.0899 13.3201 16.8287 14.1582 16.9898 14.9691L17.3144 16.6039C17.7254 18.6733 15.4968 20.2357 13.7378 19.1114L12.6757 18.4326C11.9061 17.9407 10.9271 17.9407 10.1575 18.4326L9.09543 19.1114C7.33646 20.2357 5.10786 18.6733 5.5188 16.6039L5.84345 14.9691C6.0045 14.1582 5.74327 13.3201 5.15208 12.7511L3.80745 11.4568C2.34679 10.0509 3.17284 7.55223 5.17283 7.32677L6.75651 7.14825C7.60617 7.05247 8.33875 6.49822 8.67242 5.69873L9.23993 4.33893Z"
        stroke="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Activities;
