import React, { useRef, useState, useEffect } from "react";

// Components
import Card from "../card/Card";
import SimpleStyledCard from "../simpleStyledCard/SimpleStyledCard";

// Libraries
import { TableClient } from "@hybris-software/use-table";
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";

// Icons
import LineCloseIcon from "../../../assets/svgComponents/LineCloseIcon";
import LineCheckIcon from "../../../assets/svgComponents/LineCheckIcon";

// Data
import endpoints from "../../../data/endpoints";
import { customTableStyles, rewardList } from "../../../data/constants";

// Styles
import Style from "./PoolsTable.module.css";

const PoolsTable = () => {
  // Functions
  const getCurrentQuarter = () => {
    const month = new Date().getMonth() + 1; // getMonth() is zero-based in javascript
    return Math.ceil(month / 3);
  };
  const currentQuarter = getCurrentQuarter();

  // Data
  const filterList = [
    {
      name: "ALL",
      value: "all",
      isDisabled: false,
    },
    {
      name: "Q1",
      value: "Q1",
      isDisabled: currentQuarter < 1,
    },
    {
      name: "Q2",
      value: "Q2",
      isDisabled: currentQuarter < 2,
    },
    {
      name: "Q3",
      value: "Q3",
      isDisabled: currentQuarter < 3,
    },
    {
      name: "Q4",
      value: "Q4",
      isDisabled: currentQuarter < 4,
    },
  ];
  const quartersMapping = {
    Q1: ["January", "February", "March"],
    Q2: ["April", "May", "June"],
    Q3: ["July", "August", "September"],
    Q4: ["October", "November", "December"],
    ALL: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  };

  // Hooks
  const ref = useRef(null);
  const [timeFilter, setTimeFilter] = useState(filterList[0]);

  // Queries
  const poolStatsAPI = useQuery({
    url: endpoints.pools.POOLS + "?filter=" + timeFilter.value,
    method: "GET",
    executeImmediately: false,
    onSuccess: (response) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  const createMonthColumns = (currentFilter) => {
    const months = quartersMapping[currentFilter] || quartersMapping.ALL; // Default to ALL if no match
    return months.map((month) => ({
      Header: month,
      field: month.toLowerCase(),
      orderField: month.toLowerCase(),
      sortable: false,
      noAction: true,
      accessor: (row) => {
        const monthStats = row.monthStats[month];
        if (monthStats === null) {
          return "-";
        } else {
          return monthStats ? <LineCheckIcon /> : <LineCloseIcon />;
        }
      },
    }));
  };

  const createRewardColumn = () => {
    return poolStatsAPI?.response?.data?.poolRewardsGenerated
      ? [
          {
            Header: "Reward",
            field: "Reward",
            orderField: "Reward",
            sortable: false,
            noAction: true,
            accessor: (row) => {
              return (
                <div className={Style.StatusWrapper}>
                  <span>{rewardList?.clfi_w}: {row?.rewards?.clfiW}</span>
                  <span>{rewardList?.clfi_r}: {row?.rewards?.clfiR}</span>
                </div>
              );
            },
          },
        ]
      : [];
  };

  const columns = [
    {
      Header: "#",
      field: "id",
      orderField: "id",
      sortable: false,
      noAction: true,
      accessor: (row, rowIndex) => {
        return rowIndex + 1;
      },
    },
    {
      Header: "Pool Name",
      field: "name",
      orderField: "name",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return row.name ?? "-";
      },
    },
    ...createMonthColumns(timeFilter.value),
    ...createRewardColumn(),
  ];

  useEffect(() => {
    poolStatsAPI.executeQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFilter]);

  return (
    <>
      <SimpleStyledCard className={Style?.titleCard}>
        <div className={Style.titleContainer}>
          <h5>Pools Stats</h5>
          <div className={Style.filterWrapper}>
            <div className={Style.filterTitle}>Filter</div>
            <PointsButtonGroup
              initialSelected={timeFilter}
              onSelectedChange={(selectedFilter) =>
                setTimeFilter(selectedFilter)
              }
              filterList={filterList}
            />
          </div>
        </div>
      </SimpleStyledCard>
      <Card apiHook={poolStatsAPI} className={Style?.poolCard}>
        <TableClient
          height={410}
          defaultPageSize={20}
          pageSizes={[1, 2, 3, 5, 10, 100]}
          enableAllowedActions={false}
          ref={ref}
          columns={[...columns]}
          rawData={poolStatsAPI?.response?.data?.data}
          Styles={customTableStyles}
          paginationClassName={Style.pagination}
          enablePageSizeSelect={false}
          enableSearch={false}
          enableSearchFieldSelect={false}
          enableSelectableRows={false}
          enableSettings={false}
          enableRowsSelectedBadge={false}
          enableSearchBadges={false}
        />
      </Card>
    </>
  );
};

// Button component
const PointButton = ({ label, isSelected, onButtonClick, isDisabled }) => {
  const hoverClass = !isSelected && isDisabled ? Style.hoverable : "";
  return (
    <button
      className={`${
        isSelected ? Style.selectedButton : Style.button
      } ${hoverClass}`}
      onClick={onButtonClick}
      disabled={isDisabled}
    >
      {label}
    </button>
  );
};

// ButtonGroup component
const PointsButtonGroup = ({
  initialSelected,
  onSelectedChange,
  filterList,
}) => {
  const [selected, setSelected] = useState(initialSelected.value);

  const handleButtonClick = (button) => {
    setSelected(button.value);
    onSelectedChange(button);
  };

  return (
    <div className={Style.buttonGroup}>
      {filterList.map((button) => (
        <PointButton
          key={button?.value}
          label={button?.name}
          isSelected={selected === button?.value}
          onButtonClick={() => handleButtonClick(button)}
          isDisabled={button?.isDisabled}
        />
      ))}
    </div>
  );
};

export default PoolsTable;
