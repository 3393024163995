import React from "react";

const EventIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
    >
      <path
        d="M7.06946 9.38554L8.03462 10.3456L9.96495 8.42546M12.3779 9.86559H15.7559M7.06946 14.186L8.03462 15.1461L9.96495 13.2259M12.3779 14.666H15.7559M3.3899 14.6003C2.95337 12.7491 2.95337 10.8225 3.3899 8.97126C3.99436 6.40792 6.00642 4.40645 8.58331 3.80517C10.4443 3.37093 12.3811 3.37093 14.2421 3.80517C16.819 4.40645 18.831 6.40792 19.4355 8.97126C19.872 10.8225 19.872 12.7491 19.4355 14.6003C18.831 17.1636 16.819 19.1651 14.2421 19.7664C12.3811 20.2006 10.4443 20.2006 8.58331 19.7664C6.00642 19.1651 3.99436 17.1636 3.3899 14.6003Z"
        stroke="#CECECE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EventIcon;
