import React, { useContext, useRef } from "react";

// Components
import DateFormatter from "../../../../../components/advanced/dateFormatter/DateFormatter";
import SimpleStyledCard from "../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";

// Libraries
import Table from "@hybris-software/use-table";

// Context
import GeneralInfoContext from "../../../../../contexts/GeneralInfoContext";

// Data
import endpoints from "../../../../../data/endpoints";
import { customTableStyles } from "../../../../../data/constants";

// Styles
import Style from "./PastReferral.module.css";

const PastReferral = () => {
  // Contexts
  const [generalInfo] = useContext(GeneralInfoContext);

  const columns = [
    {
      Header: "User ID",
      field: "id",
      orderField: "id",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return row.id;
      },
    },
    {
      Header: "Username's",
      field: "username",
      orderField: "username",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return "@" + row.username;
      },
    },
    {
      Header: "Rank",
      field: "rank",
      orderField: "rank",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return generalInfo?.rankInfo?.find((item) => item.id === row.rank)
          ?.name;
      },
    },
    {
      Header: "Date",
      field: "dateJoined",
      orderField: "date_joined",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return <DateFormatter date={row.dateJoined} />;
      },
    },
  ];

  const ref = useRef(null);
  const endPoint = endpoints.referrals.PAST_REFERRALS;

  return (
    <SimpleStyledCard style={{ overflow: "unset" }}>
      <h5>Past Referral</h5>

      <Table
        ref={ref}
        height="410"
        enableAllowedActions={false}
        columns={columns}
        endPoint={endPoint}
        enableSelectableRows={false}
        enableSearch={false}
        enableSearchFieldSelect={false}
        enablePageSizeSelect={true}
        defaultPageSize={50}
        extraFilters={{}}
        Styles={customTableStyles}
        searchBadgesClassName={Style.searchBadges}
        paginationButtonClassName={Style.paginationButton}
        paginationRecordsClassName={Style.paginationRecords}
        toPageInputBaseClassName={Style.toPageInput}
        enableSettings={false}
        enableRefreshBtn={false}
        enableHiddenColumnBadge={false}
      />
    </SimpleStyledCard>
  );
};

export default PastReferral;
