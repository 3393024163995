import React from "react";

// Components
import WalletBalance from "../walletBalance/WalletBalance";
import Card from "../../../../../../../components/advanced/card/Card";

// Icons
import E from "../../../../../../../assets/svg/E.svg";

// Styles
import Style from "./XgiWallet.module.css";
import Theme from "../../../../../../../assets/css/theme.module.css";

const XgiWallet = ({walletReportAPI}) => {
  

  return (
    <Card
      minHeight={"180px"}
      apiHook={walletReportAPI}
      className={Style.walletCard}
    >
      <div className={Theme.walletCardTitle}>
        XGI Wallet
      </div>
      <div>
        <WalletBalance
          icon={E}
          balance={walletReportAPI?.response?.data?.xgiBalance}
          unit={"XGI"}
        />
      </div>
    </Card>
  );
};

export default XgiWallet;
