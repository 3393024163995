import React, { useContext }  from "react";

// Libraries
import { Link } from "react-router-dom";

// Contexts
import { RoutesContext } from "../../../../../contexts/RoutesContext";

// Styles
import Style from "./Header.module.css";

const Header = () => {
  // Contexts
  const { paths } = useContext(RoutesContext);

  return (
    <div className={Style.header}>
      <div className={Style.title}>
        <Link to={paths?.main?.community?.community}><p className={Style.parent}>Community</p></Link>
        <p className={Style.currentPage}>
          Growth Analytics
        </p>
      </div>
    </div>
  );
};

export default Header;
