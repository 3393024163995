import React from 'react'

// Data
import config from '../../data/config'

// Images
import Lights from '../../assets/images/lights.png'

// Styles
import Style from './NotFound.module.css'

const NotFound = () => {
    return (
        <div>
            <div className={Style.bg} />
            <img src={Lights} className={Style.ellipseOne} alt="" />
            <div className={Style.blur} />

            <div className={Style.center}>
                <img rel='preload' as="image" src={config.LOGO_WHITE} alt="" />
                404
                <div className={Style.info}>Page not Found</div>
            </div>
        </div>
    )
}

export default NotFound