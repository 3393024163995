import React from "react";

// Styles
import Style from "./SimpleTitle.module.css";

const SimpleTitle = ({ title, subtitle }) => {
  return (
    <div className={Style.header}>
      <h4>{title}</h4>
      {subtitle && <p>{subtitle}</p>}
    </div>
  );
};

export default SimpleTitle;
