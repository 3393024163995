import React, { useState } from "react";

// Libraries

import { Handle, Position, useReactFlow } from "reactflow";
import useQuery from "@hybris-software/use-query";

// Images
import placeholder from "../../../../../assets/images/placeholder.png";

// Utils
import generateCardData from "../../../../../utils/generateCardData";
import { getColorTagByPercentage } from "./../../../../../utils/getColorBtPercentage";
import getOrdinal from "./../../../../../utils/getOrdinal";

// Data
import endpoints from "../../../../../data/endpoints";
import config from "../../../../../data/config";

// style
import Style from "./MemberCard.module.css";

const MemberCard = ({
  data,
  id,
  triggerNodeExpansion,
  setDownlineFetched,
  deleteNode,
}) => {
  // Consts
  const page = data.page || 1;

  // States
  const [isHovered, setIsHovered] = useState(false);

  // Hooks

  const { addNodes, addEdges } = useReactFlow();

  //Queries
  const queryId = data.isLoadMore ? id.split("-")[1] : id;
  const firstLineAPI = useQuery({
    url: endpoints.community.FIRST_lINE(queryId, page),
    method: "GET",
    executeImmediately: data.isRoot === true,
    onSuccess: (response) => {
      const maxPage = response.data.totalPages;

      if (!data.isLoadMore) {
        setDownlineFetched(id, data);

        response.data.results.forEach((el) => {
          const newNodeId = el.id;

          addNodes(generateCardData(el));

          const colorTag = getColorTagByPercentage(
            data?.rankInfinityPercentage
          );
          addEdges({
            id: `ed${newNodeId}->${id}`,
            source: id,
            target: newNodeId,
            type: "step",
            style: { stroke: colorTag, strokeWidth: "6px" },
          });
        });

        if (page < maxPage) {
          const loadMoreId = `loadmore-${id}-${page}`;
          addNodes(
            generateCardData(
              {
                id: loadMoreId,
                isLoadMore: true,
                page: page + 1,
                totalRewardsInLine: response.data.results[0].totalRewardsInLine,
              },
              false
            )
          );
          addEdges({
            id: `ed${loadMoreId}->${id}`,
            source: id,
            target: loadMoreId,
            type: "step",
            style: { stroke: "#515151" },
            animated: true, //dashed
          });
        }
      } else {
        deleteNode(id, data);

        const parentId = id.split("-")[1];

        response.data.results.forEach((el) => {
          const newNodeId = el.id;

          addNodes(generateCardData(el));
          const colorTag = getColorTagByPercentage(
            data?.rankInfinityPercentage
          );
          addEdges({
            id: `ed${newNodeId}->${parentId}`,
            source: parentId,
            target: newNodeId,
            type: "step",
            style: { stroke: colorTag, strokeWidth: "6px" },
          });
        });

        if (page < maxPage) {
          const newLoadMoreId = `loadmore-${parentId}-${page}`;
          addNodes(
            generateCardData(
              {
                id: newLoadMoreId,
                isLoadMore: true,
                page: page + 1,
                totalRewardsInLine: response.data.results[0].totalRewardsInLine,
              },
              false
            )
          );
          addEdges({
            id: `ed${newLoadMoreId}->${parentId}`,
            source: parentId,
            target: newLoadMoreId,
            type: "step",
            style: { stroke: "#515151" },
            animated: true, //dashed
          });
        }
      }
    },
    clientOptions: {
      timeout: 120000,
    },
  });

  // Functions
  const loadMore = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    firstLineAPI.executeQuery();
  };

  const handleClick = () => {
    if (firstLineAPI.isLoading) {
      return;
    }

    if (!data.isRoot && !firstLineAPI.isSuccess && !data.downlineFetched) {
      firstLineAPI.executeQuery();
    } else {
      triggerNodeExpansion(id, data);
    }
  };
  if (!data.isLoadMore) {
    return data.infinityActive || data.isRoot ? (
      <>
        <div className={Style.cardWrapper}>
          <Handle type="target" position={Position.Top} />

          <div className={Style.cardBody}>
            <div
              className={Style.avatar}
              style={{
                backgroundImage: `url(${
                  data.profilePicture ? data.profilePicture : placeholder
                })`,
              }}
            ></div>
            <div className={Style.cardInfo}>
              {data.isFirstInLayer && (
                <div className={Style.lineWrapper}>
                  <p className={Style.lineLevel}>
                    {getOrdinal(data.depth)} Line
                  </p>
                  <p className={Style.lineDetails}>
                    Rewards:
                    <span> {data.totalRewardsInLine} {config.EURK_UNIT} </span>
                  </p>
                </div>
              )}
              <span className={Style.usernameStyle}>@{data.username}</span>
              <span className={Style.rankStyle}>{data.rank} Rank</span>
              <div className={Style.separator}></div>
              <div className={Style.infinityValue}>
                Infinity Bonus: <span> {data.rankInfinityPercentage}%</span>
              </div>
            </div>
          </div>
          <div
            className={Style.handleButton}
            onClick={handleClick}
            style={{
              visibility:
                data.expandable || data.downlineUsersCount > 0
                  ? "visible"
                  : "hidden",
            }}
          >
            <Handle
              type="source"
              isConnectable={false}
              position={Position.Bottom}
              style={{ display: data.isLeaf ? "none" : "" }}
              className={
                firstLineAPI.isLoading
                  ? Style.rotating
                  : !data.expanded
                  ? Style.customHandleExpand
                  : Style.customHandleCollapsed
              }
            />
          </div>
        </div>
      </>
    ) : (
      <>
        <div className={Style.circleWrapper}>
          <Handle
            type="target"
            position={Position.Top}
            style={{ visibility: "hidden" }}
          />

          <div className={Style.circleCardBody}>
            <div
              className={Style.circleCenter}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            ></div>
            <div
              className={`${Style.hoverContent} ${
                isHovered ? Style.visible : ""
              }`}
            >
              <div className={Style.cardInfo}>
                <span className={Style.usernameStyle}>@{data.username}</span>
                <p className={Style.infinityValue}>
                  Infinity Bonus: <span> {data.rankInfinityPercentage}%</span>
                </p>
              </div>
            </div>
            {data.isFirstInLayer && (
              <div className={Style.lineWrapperHidden}>
                <p className={Style.lineLevel}>{getOrdinal(data.depth)} Line</p>
                <p className={Style.lineDetails}>
                  Rewards:
                  <span> {data.totalRewardsInLine} {config.EURK_UNIT} </span>
                </p>
              </div>
            )}
          </div>

          <div
            className={Style.handleButton}
            onClick={handleClick}
            style={{
              visibility:
                data.expandable || data.downlineUsersCount > 0
                  ? "visible"
                  : "hidden",
            }}
          >
            <Handle
              type="source"
              isConnectable={false}
              position={Position.Bottom}
              className={
                firstLineAPI.isLoading
                  ? Style.rotating
                  : !data.expanded
                  ? Style.customHandleExpand
                  : Style.customHandleCollapsed
              }
            />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className={Style.cardWrapper}>
        <Handle
          type="target"
          position={Position.Top}
          className={Style.emptyHandle}
        />
        <div className={Style.cardBodyLoadMore} onClick={loadMore}>
          <span className={Style.usernameStyle}>
            {firstLineAPI.isLoading ? "Loading ..." : "Load More "}
          </span>

          <Handle
            type="source"
            isConnectable={false}
            position={Position.Bottom}
            className={Style.emptyHandle}
          />
          {data.isFirstInLayer && (
            <div className={Style.lineWrapperLoadMore}>
              <p className={Style.lineLevel}> {getOrdinal(data.depth)} Line</p>
              <p className={Style.lineDetails}>
                Rewards: <span> {data.totalRewardsInLine} {config.EURK_UNIT} </span>
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default MemberCard;
