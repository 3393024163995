import React from "react";

// Components
import SimpleStyledCard from "../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";

// Styles
import Style from "./Summary.module.css";

const Summary = () => {
  return (
    <SimpleStyledCard minHeight={"150px"}>
      <div className={Style.title}>Infinity Bonus</div>
      <div className={Style.subTitle}>
        Infinity bonus is rewarded for Infinity points from your active legs,
        based on your monthly commisionable rank. To be eligible for the same,
        you have to be active and qualified.
      </div>
    </SimpleStyledCard>
  );
};

export default Summary;
