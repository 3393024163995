import React, { useContext } from "react";

// Components
import Loader from "../../../components/ui/loader/Loader";

// Libraries
import useQuery from "@hybris-software/use-query";
import { Button } from "@hybris-software/ui-kit";
import { useNavigate } from "react-router-dom";
import { PermissionRoute } from "@hybris-software/use-auth";

// Data
import endpoints from "../../../data/endpoints";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Styles
import Style from "./WikiView.module.css";

const WikiView = () => {
  // Hooks
  const navigate = useNavigate();

  // Contexts
  const { paths } = useContext(RoutesContext);

  const wikiAPI = useQuery({
    url: endpoints.wiki.WIKI,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      window.open(response?.data?.url, "_blank", "noopener noreferrer");
    },
    onError: (error) => {},
  });

  return (
    <PermissionRoute
      loader={<Loader />}
      forLoggedUser={true}
      firstApiLoading={wikiAPI.isLoading}
      unAuthorizedAction={() => {
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        // dispatcher
        return true;
      }}
    >
      <div className={Style.window}>
        <div className={Style.box}>
          <h4>Wiki</h4>
          <p>
            If the wiki did not open automatically, click on the button below
          </p>
          <Button
            onClick={() => {
              window.open(
                wikiAPI?.response?.data?.url,
                "_blank",
                "noopener noreferrer"
              );
            }}
          >
            Go to Wiki
          </Button>
        </div>
      </div>
    </PermissionRoute>
  );
};

export default WikiView;
