import React, { useEffect, useState, useContext } from "react";

// Components
import Loader from "../../../components/ui/loader/Loader";
import Steps from "./components/steps/Steps";
import Card from "../../../components/advanced/card/Card";
import CompleteKyc from "./components/completeKyc/CompleteKyc";
import WatchVideo from "./components/watchVideo/WatchVideo";
import SimpleTitle from "../../../components/advanced/simpleTitle/SimpleTitle";

// Libraries
import { Container } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";

import { useNavigate } from "react-router-dom";
import useQuery from "@hybris-software/use-query";
// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";
import GeneralInfoContext from "../../../contexts/GeneralInfoContext";

// Data
import { accountType, steps } from "../../../data/constants";
import config from "../../../data/config";

// Utils
import { addSlashIfNeeded } from "../../../utils/utilityFunctions";

// Video
import ComingSoon from "../../../assets/images/metaverse.mp4";

// Styles
import Style from "./FirstStepView.module.css";

const FirstStepView = () => {
  // Contexts
  const { paths } = useContext(RoutesContext);
  const [generalInfo] = useContext(GeneralInfoContext);
  // Data
  const firstStepsArray = [
    {
      step: steps.COMPLETE_DATA,
      title: "Complete Your data",
      order: 1,
    },
    {
      step: steps.BUY_LICENSEE,
      title: "Buy License",
      order: 2,
    },
    {
      step: steps.COMPLETE_KYC,
      title: `Complete Your ${
        generalInfo?.userInfo?.accountType === accountType.PERSONAL
          ? "KYC"
          : "KYB"
      }`,
      order: 3,
    },
    {
      step: steps.WATCH_ECOSYSTEM_VIDEO,
      title: "Watch Ecosystem Video",
      order: 4,
    },
  ];

  // States
  const [activeStep, setActiveState] = useState();
  const [updatedSteps, setUpdatedSteps] = useState(firstStepsArray);

  // Hooks
  const navigate = useNavigate();

  // Queries
  const firstStepAPI = useQuery({
    url: generalInfo?.endpoints?.FIRST_STEP,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      updatedSteps?.map((item, index) => {
        return setUpdatedSteps((prev) => {
          return prev.map((item, index) => {
            return { ...item, done: response?.data?.[item?.step] };
          });
        });
      });
      incompleteList = updatedSteps
        ?.filter((item) => !item.done)
        ?.sort((a, b) => a.order - b.order);

      setActiveState(
        incompleteList?.length > 0
          ? incompleteList[0]?.step
          : updatedSteps[0]?.step
      );
    },
    clientOptions: {
      timeout: 120000,
    },
  });
  // Variables
  let incompleteList;
  const isFirstStep = updatedSteps[0]?.step === activeStep;

  const watchVideoEcosystem = {
    title: "Watch Our Ecosystem Video",
    firstSubtitle: `To grasp the ecosystem of 2access and XERA, watching this video is essential`,
    secondSubtitle: `It offers a comprehensive understanding of their structures, operations, and interactions within the landscape.`,
    video: ComingSoon,
    firstStepAPI: firstStepAPI,
    type: "ecosystem",
    step: steps.WATCH_ECOSYSTEM_VIDEO,
  };

  useEffect(() => {
    document.title = `${generalInfo.config.WEBSITE_NAME} | First Step`;
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setActiveState(
      updatedSteps
        ?.filter((item) => !item.done)
        ?.sort((a, b) => a.order - b.order)?.[0]?.step
    );
    if (updatedSteps?.filter((item) => !item.done).length === 0) {
      setActiveState(steps.WATCH_ECOSYSTEM_VIDEO);
    }
    //eslint-disable-next-line
  }, [firstStepAPI.isSuccess]);

  //functions
  const handlePreviousStep = () => {
    const currentStepIndex = updatedSteps.findIndex(
      (step) => step.step === activeStep
    );
    if (currentStepIndex > 0) {
      setActiveState(updatedSteps[currentStepIndex - 1].step);
    }
  };

  return (
    <PermissionRoute
      loader={<Loader />}
      firstApiLoading={firstStepAPI.isLoading}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        return true;
      }}
    >
      <Container>
        <div className={Style.view}>
          <SimpleTitle title="Welcome To The Dashboard - Your First Step" />

          <Card apiHook={firstStepAPI} className={Style.card}>
            <div className={`${Style.containerWithButton} ${Style.stepsGrid}`}>
              <Steps
                firstStepAPI={firstStepAPI}
                setActiveState={setActiveState}
                firstStepsArray={updatedSteps}
                incompleteList={incompleteList}
              />

              {activeStep && firstStepAPI?.isSuccess && (
                <>
                  {activeStep === steps.COMPLETE_DATA && (
                    <CompleteKyc
                      section={"Complete Your Data"}
                      firstSubtitle={`Input Your Complete Information in ${config.WEBSITE_2ACCESS}`}
                      secondSubtitle={`Filling in your full data in ${config.WEBSITE_2ACCESS} ensures a secure and tailored experience for you. It's essential for transaction verification, security measures, and to access all features without limitations.`}
                      link={
                        firstStepAPI?.response?.data?.[steps.COMPLETE_DATA]
                          ? ""
                          : `${
                              addSlashIfNeeded(config.BLOCKCHAINID_BASE_URL) +
                              "profile"
                            }`
                      }
                      linkText={`Go to ${config.WEBSITE_2ACCESS}`}
                      onPrevious={!isFirstStep ? handlePreviousStep : null}
                    />
                  )}
                  {activeStep === steps.COMPLETE_KYC && (
                    <CompleteKyc
                      section={`Complete Your ${
                        generalInfo?.userInfo?.accountType ===
                        accountType.PERSONAL
                          ? "KYC"
                          : "KYB"
                      }`}
                      firstSubtitle={`After making your first purchase, head over to ${
                        config.WEBSITE_2ACCESS
                      } and complete your 
                     ${
                       generalInfo?.userInfo?.accountType ===
                       accountType.PERSONAL
                         ? "Know Your Customer (KYC)"
                         : "Know Your Business (KYB)"
                     } process.`}
                      secondSubtitle={`This is a vital step for account verification and to ensure a safe, compliant experience on the platform.`}
                      link={
                        firstStepAPI?.response?.data?.[steps.COMPLETE_KYC]
                          ? ""
                          : `${
                              addSlashIfNeeded(config.BLOCKCHAINID_BASE_URL) +
                              "kyc"
                            }`
                      }
                      linkText={
                        generalInfo?.userInfo?.accountType ===
                        accountType.PERSONAL
                          ? "KYC Page"
                          : "KYB Page"
                      }
                      onPrevious={!isFirstStep ? handlePreviousStep : null}
                    />
                  )}
                  {activeStep === steps.WATCH_ECOSYSTEM_VIDEO && (
                    <WatchVideo
                      {...watchVideoEcosystem}
                      onPrevious={!isFirstStep ? handlePreviousStep : null}
                    />
                  )}
                  {activeStep === steps.BUY_LICENSEE && (
                    <CompleteKyc
                      section={"Purchase Your First License"}
                      firstSubtitle={`To get started, navigate to the ${config.WEBSITE_LFI} page and acquire your initial license.`}
                      secondSubtitle=" This is your gateway to accessing all the opportunities XERA has to offer."
                      link={
                        firstStepAPI?.response?.data?.[steps.BUY_LICENSEE]
                          ? ""
                          : `${addSlashIfNeeded(config.LFI_BASE_URL)}`
                      }
                      linkText={`Go To ${config.WEBSITE_LFI}`}
                      onPrevious={!isFirstStep ? handlePreviousStep : null}
                    />
                  )}
                </>
              )}
            </div>
          </Card>
        </div>
      </Container>
    </PermissionRoute>
  );
};

export default FirstStepView;
