import React from "react";

// Components
import Card from "../../../../../components/advanced/card/Card";
import HalfDoughnutChart from "../../../../../components/advanced/halfDoughnutChart/HalfDoughnutChart";
import SimpleStyledCard from "../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";

// Libraries
import useQuery from "@hybris-software/use-query/";

// Icons
import CP from "../../../../../assets/svg/CP.svg";

// Data
import endpoints from "../../../../../data/endpoints";

// Utils
import { formatNumberEuropean } from "../../../../../utils/utilityFunctions";

// Styles
import Style from "./RankBonus.module.css";

const RankBonus = () => {
  // Queries
  const turnoverBonusApi = useQuery({
    url: endpoints.rankBonus.TURNOVER_BONUS,
    method: "GET",
    executeImmediately: true,
    clientOptions: {
      timeout: 120000,
    },
  });


  return (
    <Card
      apiHook={turnoverBonusApi}
      minHeight={285}
      className={Style.emptyCard}
    >
      <SimpleStyledCard>
        <div className={Style.userCardGrid}>
          {/* First Line Count */}
          <div className={Style.cardChildren}>
            <div className={Style.singleUsersCard}>
              <p>First Line Count</p>
              <div className={Style.amountWrapper}>
                <div className={Style.amountTop}>
                  {turnoverBonusApi?.response?.data?.firstLineCount}
                  <span> USERS</span>
                </div>
              </div>
            </div>

            {turnoverBonusApi?.response?.data?.targetFirstLineCount && (
              <div className={Style.usersTarget}>
                Target: {turnoverBonusApi?.response?.data?.targetFirstLineCount}
                <span> USERS</span>
              </div>
            )}
            <div className={Style.chart}>
              <HalfDoughnutChart
                percentageStart={
                  turnoverBonusApi?.response?.data?.firstLineCountProgress
                }
                size={140}
                showStartAndEnd={false}
                showPercentage
              />
            </div>
          </div>

          <div className={Style.verticalSeparator}></div>

          {/* First Line Turnover */}
          <div className={Style.cardChildren}>
            <div className={Style.singleUsersCard}>
              <p>First Line Turnover</p>
              <div className={Style.amountWrapper}>
                <div className={Style.amountTop}>
                  <img src={CP} alt="" width={25} />
                  {formatNumberEuropean(
                    turnoverBonusApi?.response?.data?.firstLineTurnover
                  )}
                  <span>CP</span>
                </div>
              </div>
            </div>
            {turnoverBonusApi?.response?.data?.targetFirstLineTurnover && (
              <div className={Style.usersTarget}>
                Target:{" "}
                {formatNumberEuropean(
                  turnoverBonusApi?.response?.data?.targetFirstLineTurnover
                )}
                <span> CP</span>
              </div>
            )}
            <div className={Style.chart}>
              <HalfDoughnutChart
                percentageStart={
                  turnoverBonusApi?.response?.data?.firstLineTurnoverProgress
                }
                size={140}
                showStartAndEnd={false}
                showPercentage
              />
            </div>
          </div>
          <div className={Style.verticalSeparator}></div>
          {/* Personal Turnover */}
          <div className={Style.cardChildren}>
            <div className={Style.singleUsersCard}>
              <p>Personal Turnover</p>
              <div className={Style.amountWrapper}>
                <div className={Style.amountTop}>
                  <img src={CP} alt="" width={25} />
                  {formatNumberEuropean(
                    turnoverBonusApi?.response?.data?.personalTurnover
                  )}
                  <span>CP</span>
                </div>
              </div>
            </div>
            {turnoverBonusApi?.response?.data?.targetPersonalTurnover && (
              <div className={Style.usersTarget}>
                Target:{" "}
                {formatNumberEuropean(
                  turnoverBonusApi?.response?.data?.targetPersonalTurnover
                )}
                <span> CP</span>
              </div>
            )}
            <div className={Style.chart}>
              <HalfDoughnutChart
                percentageStart={
                  turnoverBonusApi?.response?.data?.personalTurnoverProgress
                }
                size={140}
                showStartAndEnd={false}
                showPercentage
              />
            </div>
          </div>
          <div className={Style.verticalSeparator}></div>
          {/* Total Turnover */}
          <div className={Style.cardChildren}>
            <div className={Style.singleUsersCard}>
              <p>Total Turnover</p>
              <div className={Style.amountWrapper}>
                <div className={Style.amountTop}>
                  <img src={CP} alt="" width={25} />
                  {formatNumberEuropean(
                    turnoverBonusApi?.response?.data?.totalTurnover
                  )}
                  <span>CP</span>
                </div>
              </div>
            </div>
            {turnoverBonusApi?.response?.data?.targetTotalTurnover && (
              <div className={Style.usersTarget}>
                Target:{" "}
                {formatNumberEuropean(
                  turnoverBonusApi?.response?.data?.targetTotalTurnover
                )}
                <span> CP</span>
              </div>
            )}
            <div className={Style.chart}>
              <HalfDoughnutChart
                percentageStart={
                  turnoverBonusApi?.response?.data?.totalTurnoverProgress
                }
                size={140}
                showStartAndEnd={false}
                showPercentage
              />
            </div>
          </div>
        </div>
      </SimpleStyledCard>

    </Card>
  );
};

export default RankBonus;
