import React from "react";

// Libraries
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

// Utilities
import classNames from "../../../utils/classNames";

// Styles
import Style from "./RangeDatePicker.module.css";

const RangeDatePicker = ({
  setRangeDate,
  rangeDate,
  minDate,
  maxDate,
  className,
}) => {
  return (
    <div className={Style.filterDate}>
      <div className={Style.customDate} style={{ position: "relative" }}>
        <DateRangePicker
          //   clearIcon={null}
          className={classNames(Style.dateRangePicker, className)}
          calendarClassName={Style.test}
          onChange={setRangeDate}
          rangeDivider=""
          value={rangeDate}
          minDate={minDate}
          maxDate={maxDate}
          format="d M y"
        />
      </div>
    </div>
  );
};

export default RangeDatePicker;
