import React, { useState, useContext, useEffect, useRef } from "react";

// Components
import Card from "../../../../../components/advanced/card/Card";

//Libraries
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

// Context
import GeneralInfoContext from "../../../../../contexts/GeneralInfoContext";
import { usePrivacyContext } from "../../../../../contexts/PrivacyContext";

// Data
import endpoints from "../../../../../data/endpoints";

// Images
import CoinPlaceholder from "../../../../../assets/images/dummy-rank.png";
import usersRankIcon from "../../../../../assets/svg/usersIcon.svg";

// Icons
import { PiArrowLeft, PiArrowRight } from "react-icons/pi";

// Styles
import Style from "./SliderRanks.module.css";

function opacity(active, i) {
  if (active === i) return 1;
  if (i >= active + 2) return 0.15;
  if (i >= active - 2) return 0.15;
  return 0;
}

const SliderRanks = () => {
  // Contexts
  const { privacy } = usePrivacyContext();
  // States
  const [active, setActive] = useState(2);
  const [generalInfo] = useContext(GeneralInfoContext);
  const [ranks, setRanks] = useState([]);

  //refs
  const swiperRef = useRef();

  // Queries
  const communityRanksAPI = useQuery({
    url: endpoints.community.COMMUNITY_RANKS,
    method: "GET",
    executeImmediately: true,
    clientOptions: {
      timeout: 120000,
    },
  });

  useEffect(() => {
    setRanks(generalInfo?.rankInfo?.sort((a, b) => a.id - b.id));
    // eslint-disable-next-line
  }, []);

  return (
    <Card apiHook={communityRanksAPI} className={Style.cardSlider}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h5 className={Style.title}>Ranks In Your Community</h5>

        <div className={Style.pagination}>
          <div
            style={{
              opacity: swiperRef.current?.isBeginning ? 0.5 : 1,
              cursor: swiperRef.current?.isBeginning && "not-allowed",
            }}
            className={Style.prev}
            onClick={() => {
              swiperRef.current?.slidePrev();
            }}
          >
            <PiArrowLeft />
          </div>

          <div
            style={{
              opacity: swiperRef.current?.isEnd ? 0.5 : 1,
              cursor: swiperRef.current?.isEnd && "not-allowed",
            }}
            className={Style.next}
            onClick={() => {
              swiperRef.current?.slideNext();
            }}
          >
            <PiArrowRight />
          </div>
        </div>
      </div>
      <Swiper
        ref={swiperRef}
        modules={[Navigation]}
        longSwipes={false}
        spaceBetween={16}
        slidesPerView={5}
        centeredSlides={true}
        initialSlide={2} // The index of the initial active slide
        onSlideChange={(swiper) => setActive(swiper.activeIndex)}
        simulateTouch={false}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        className={Style.slider}
        breakpoints={{
          500: { slidesPerView: 5 },
          430: { slidesPerView: 4 },
          322: { slidesPerView: 3 },
          0: { slidesPerView: 2 },
        }}
      >
        {ranks?.map((rank, i) => (
          <SwiperSlide key={rank.id}>
            <div
              className={active === i ? Style.rankOnFocus : Style.rank}
              style={{
                opacity: opacity(active, i),
                transform:
                  active === i
                    ? "translateY(-25px)"
                    : active + 2 === i || active - 2 === i
                    ? "translateY(+25px)"
                    : "translateY(0)",
                height:
                  active === i
                    ? "calc(290px + 25px)"
                    : active + 2 === i || active - 2 === i
                    ? "calc(290px - 25px)"
                    : "290px",
              }}
            >
              <div className={Style.rankImageContainer}>
                <img
                  src={rank.iconUrl ?? CoinPlaceholder}
                  alt={rank.name}
                  className={Style?.rankImage}
                />
                <div style={{ marginTop: 12 }} />
                <div className={Style.users}>Rank {rank.id}</div>
                <div className={Style.rankName}>{rank.name}</div>
              </div>
              <div className={Style.users} style={{ marginTop: 8 }}>
                <img src={usersRankIcon} alt="" />
                <span>
                  {" "}
                  {privacy
                    ? "***"
                    : communityRanksAPI?.response?.data.find(
                        (item) => item.id === rank.id
                      )?.count ?? 0}
                </span>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Card>
  );
};

export default SliderRanks;
