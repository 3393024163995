import React, { useContext } from "react";

// Components
import Card from "../../../../../components/advanced/card/Card";
import SimpleStyledCard from "./../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";
import getOrdinal from "../../../../../utils/getOrdinal";

// Libraries
import useQuery from "@hybris-software/use-query";

// Icons
import VP from "../../../../../assets/svg/VP.svg";
import CP from "../../../../../assets/svg/CP.svg";
import IP from "../../../../../assets/svg/IP.svg";
import DummyRank from "../../../../../assets/images/dummy-rank.png";

// Contexts
import { usePrivacyContext } from "../../../../../contexts/PrivacyContext";
import GeneralInfoContext from "../../../../../contexts/GeneralInfoContext";

// Data
import endpoints from "../../../../../data/endpoints";

// Styles
import Style from "./RecapCard.module.css";

const RecapCard = () => {
  const { privacy } = usePrivacyContext();
  // Contexts
  const [generalInfo] = useContext(GeneralInfoContext);

  // Quires
  const communityStatsAPI = useQuery({
    url: endpoints.community.COMMUNITY_STATS,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  // Data
  const data = [
    {
      label: "Total Community Size",
      amount: privacy ? "***" : communityStatsAPI?.response?.data?.userCount,
      fullNumber: privacy
        ? "***"
        : communityStatsAPI?.response?.data?.userCount,

      unit: communityStatsAPI?.response?.data?.userCount > 1 ? "Users" : "User",
      percentage: privacy
        ? "***"
        : (communityStatsAPI?.response?.data?.userCountIncreased >= 0
            ? "+"
            : "-") +
          communityStatsAPI?.response?.data?.userCountIncreased +
          " %",
      color:
        communityStatsAPI?.response?.data?.userCountIncreased > 0
          ? "var( --text-completed)"
          : communityStatsAPI?.response?.data?.userCountIncreased < 0
          ? "var( --error)"
          : "var( --warning-dark)",
    },
    {
      label: "Your First Line",
      amount: privacy
        ? "***"
        : communityStatsAPI?.response?.data?.firstLineUserCount,
      fullNumber: privacy
        ? "***"
        : communityStatsAPI?.response?.data?.firstLineUserCount,

      unit:
        communityStatsAPI?.response?.data?.firstLineUserCount > 1
          ? "Users"
          : "User",
      percentage: privacy
        ? "***"
        : (communityStatsAPI?.response?.data?.firstLineUserCountIncreased >= 0
            ? "+"
            : "-") +
          communityStatsAPI?.response?.data?.firstLineUserCountIncreased +
          " %",
      color:
        communityStatsAPI?.response?.data?.firstLineUserCountIncreased > 0
          ? "var( --text-completed)"
          : communityStatsAPI?.response?.data?.firstLineUserCountIncreased < 0
          ? "var( --error)"
          : "var( --warning-dark)",
    },
    {
      label: <span>{getOrdinal(1)} Line Active Users</span>,

      amount: privacy
        ? "***"
        : communityStatsAPI?.response?.data?.firstLineActiveUserCount,
      unit: "Users",
      percentage: "",
    },
    {
      label: "No. of Active Users",
      amount: privacy
        ? "***"
        : communityStatsAPI?.response?.data?.teamActiveUserCount,
      fullNumber: privacy
        ? "***"
        : communityStatsAPI?.response?.data?.teamActiveUserCount,
      unit: "Users",
      percentage: privacy
        ? "***"
        : (communityStatsAPI?.response?.data?.teamActiveUserCountIncreased >= 0
            ? "+"
            : "-") +
          communityStatsAPI?.response?.data?.teamActiveUserCountIncreased +
          " %",
      color:
        communityStatsAPI?.response?.data?.teamActiveUserCountIncreased > 0
          ? "var( --text-completed)"
          : communityStatsAPI?.response?.data?.teamActiveUserCountIncreased < 0
          ? "var( --error)"
          : "var( --warning-dark)",
    },
    {
      label: "Depth",
      amount: privacy
        ? "***"
        : getOrdinal(communityStatsAPI?.response?.data?.maxDepth),
      fullNumber: "",
      unit: "",
      percentage: "",
      color: "",
    },
    {
      label: "Highest Rank",
      amount: communityStatsAPI?.response?.data?.highestRank ?? 0,
      fullNumber: "",
      unit: "",
      percentage: "",
      color: "",
    },
  ];

  return (
    <Card
      minHeight={386}
      apiHook={communityStatsAPI}
      className={Style.generalCard}
    >
      <div className={Style.container}>
        {data.map((item, index) => {
          return (
            <SimpleStyledCard className={Style.addBackground} key={index}>
              {item.label === "Highest Rank" || item.label === "Depth" ? (
                <div className={Style.bodyWrapperSmall}>
                  <span className={Style.labelBig}>{item.label}</span>
                  {item.label === "Highest Rank" ? (
                    <div
                      className={Style.amountImg}
                      onTouchStart={(e) => {
                        e.currentTarget.classList.toggle(Style.showFullNumber);
                      }}
                    >
                      <img
                        style={{
                          width: "44px",
                          height: "44px",
                          filter: privacy && "blur(4px)",
                        }}
                        src={
                          generalInfo?.rankInfo[
                            generalInfo?.rankInfo.length - item.amount - 1
                          ]?.iconUrl
                        }
                        alt=""
                      />
                      <span className={Style.toolTipFullNumber}>
                        {privacy
                          ? "*****"
                          : generalInfo?.rankInfo[
                              generalInfo?.rankInfo.length - item.amount - 1
                            ]?.name}
                      </span>
                    </div>
                  ) : (
                    <span
                      style={{ marginBottom: "0" }}
                      className={
                        item.label === "Depth"
                          ? Style.depthWrapper
                          : Style.amount
                      }
                    >
                      {item.amount}
                    </span>
                  )}
                </div>
              ) : (
                <>
                  <div className={Style.label}>{item.label}</div>
                  <div className={Style.bodyWrapper}>
                    {item.unit === "VP" ? (
                      <img src={VP} alt="" />
                    ) : item.unit === "CP" ? (
                      <img src={CP} alt="" />
                    ) : item.unit === "IP" ? (
                      <img src={IP} alt="" />
                    ) : item.unit === "rank" ? (
                      <img
                        style={{ width: "44px", height: "44px" }}
                        src={DummyRank}
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                    <div
                      className={Style.amount}
                      onTouchStart={(e) => {
                        e.currentTarget.classList.toggle(Style.showFullNumber);
                      }}
                    >
                      {privacy ? item.amount : item.amount}
                      <span>{item.unit}</span>
                      <span className={Style.toolTipFullNumber}>
                        {item.fullNumber} {item.unit}
                      </span>
                    </div>
                  </div>
                  {item.percentage && (
                    <div
                      className={Style.percentage}
                      style={{ color: item.color }}
                    >
                      {item.percentage}
                      <span>From Last Week</span>
                    </div>
                  )}
                </>
              )}
            </SimpleStyledCard>
          );
        })}
      </div>
    </Card>
  );
};

export default RecapCard;
