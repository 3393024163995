import React, { useRef, useState, useEffect } from "react";

// Components
import SimpleStyledCard from "../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";
import DateFormatter from "../../../../../components/advanced/dateFormatter/DateFormatter";
import RangeDatePicker from "../../../../../components/advanced/rangeDatePicker/RangeDatePicker";
import StatusResponse from "../../../../../components/advanced/statusResponse/StatusResponse";
import ExportModal from "../../../../../components/advanced/exportModal/ExportModal";

// Libraries
import Table from "@hybris-software/use-table";
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";
import { useOutletContext } from "react-router-dom";
import { Button } from "@hybris-software/ui-kit";

// Data
import endpoints from "../../../../../data/endpoints";
import { customTableStyles } from "../../../../../data/constants";

//Utilities
import { formatNumberEuropean } from "../../../../../utils/utilityFunctions";

// Icon
import successIcon from "../../../../../assets/icons/response-Icons/success.png";
import DownloadIcon from "../../../../../assets/svgComponents/DownloadIcon";

// Styles
import Style from "./BonusesHistory.module.css";
import Theme from "../../../../../assets/css/theme.module.css";

const BonusesHistory = () => {
  // Refs
  const ref = useRef(null);

  // Variables
  const initialFilters = {
    date: {
      gteDatePickerValue: null,
      label: null,
      lteDatePickerValue: null,
    },
  };

  const columns = [
    {
      Header: "#",
      field: "id",
      orderField: "id",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return row.id;
      },
    },
    {
      Header: "Date",
      field: "date",
      orderField: "date",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return <DateFormatter date={row.date} />;
      },
    },
    {
      Header: "Time",
      field: "date",
      orderField: "date",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return new Date(row?.date).toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
      },
    },
    {
      Header: "Bonus",
      field: "bonus",
      orderField: "bonus",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return formatNumberEuropean(row.bonus);
      },
    },

    {
      Header: "Amount",
      field: "personalTurnoverAchieved",
      orderField: "turnover_achieved",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return formatNumberEuropean(row.personalTurnoverAchieved);
      },
    },
  ];

  const endPoint = endpoints.rankBonus.BONUS_HISTORY;

  // Status
  const [extraFilters, setExtraFilters] = useState({});
  const [currentFilter, setCurrentFilter] = useState(initialFilters);

  // Context
  const { generalModalRef, generalErrorsResolver } = useOutletContext();

  // Queries
  const exportBonusHistoryAPI = useQuery({
    url: endpoints.exports.TURNOVER_BONUS_HISTORY,
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      generalModalRef.current.updateBody(
        <StatusResponse
          title="Success"
          description={<ExportModal generalModalRef={generalModalRef} />}
          icon={successIcon}
        >
          <Button onClick={() => generalModalRef.current.destroy()}>
            Close
          </Button>
        </StatusResponse>,
        { modalStyle: { maxWidth: "452px" } }
      );
    },
    onError: (error) => {
      generalErrorsResolver(error);
    },
    clientOptions: {
      timeout: 120000,
    },
  });

  useEffect(() => {
    setExtraFilters({
      date__gte: currentFilter?.date?.gteDatePickerValue
        ? currentFilter?.date?.gteDatePickerValue.toISOString()
        : null,
      date__lte: currentFilter?.date?.lteDatePickerValue
        ? currentFilter?.date?.lteDatePickerValue.toISOString()
        : null,
    });

    // eslint-disable-next-line
  }, [currentFilter]);

  return (
    <SimpleStyledCard className={Style.card}>
      <div className={Style.header}>
        <h5>History</h5>

        <div className={Style.action}>
          <div className={Style.datePickerContainer}>
            <RangeDatePicker
              className={Style.datePicker}
              setRangeDate={(value) => {
                setCurrentFilter({
                  date: {
                    gteDatePickerValue: value ? value[0] : null,
                    lteDatePickerValue: value ? value[1] : null,
                    label: value
                      ? `From ${new Date(
                          value[0]
                        ).toLocaleDateString()} to ${new Date(
                          value[1]
                        ).toLocaleDateString()}`
                      : "",
                  },
                });
              }}
              rangeDate={[
                currentFilter.date.gteDatePickerValue,
                currentFilter.date.lteDatePickerValue,
              ]}
            />
          </div>
          <Button
            onClick={() => exportBonusHistoryAPI.executeQuery()}
            className={Theme.downloadBtn}
            isLoading={exportBonusHistoryAPI.isLoading}
          >
            <div>Download XLSX Report</div>
            <DownloadIcon />
          </Button>
        </div>
      </div>
      <Table
        ref={ref}
        height="410"
        enableAllowedActions={false}
        columns={columns}
        endPoint={endPoint}
        enableSelectableRows={false}
        enableSearch={false}
        enableSearchFieldSelect={false}
        enablePageSizeSelect={true}
        defaultPageSize={5}
        extraFilters={extraFilters}
        Styles={customTableStyles}
        searchBadgesClassName={Style.searchBadges}
        paginationButtonClassName={Style.paginationButton}
        paginationRecordsClassName={Style.paginationRecords}
        toPageInputBaseClassName={Style.toPageInput}
        enableSettings={false}
        enableRefreshBtn={false}
        enableHiddenColumnBadge={false}
      />
    </SimpleStyledCard>
  );
};

export default BonusesHistory;
