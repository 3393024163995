import React from "react";

// Icons
import ModalCloseIcon from "../../../../../assets/svgComponents/ModalCloseIcon";

// Components
import PointsButtonGroup from "../../../../../components/advanced/pointsButtonGroup/PointsButtonGroup";
import Card from "../../../../../components/advanced/card/Card";

// Libraries
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";

// Utils
import getOrdinal from "../../../../../utils/getOrdinal";

// Data
import { unitsList } from "../../../../../data/constants";
import endpoints from "../../../../../data/endpoints";

// Styles
import Style from "./CommunityPointsModal.module.css";

const CommunityPointsModal = ({ generalModalRef, userId }) => {
  //state
  const [selectedValue, setSelectedValue] = React.useState(unitsList.VP.value);

  // Quires
  const rewardsPointsAPI = useQuery({
    url: endpoints.dashboard.REWARDS_POINTS(userId, selectedValue),
    method: "GET",
    executeImmediately: true,
    clientOptions: {
      timeout: 120000,
    },
  });

  //consts
  const communityPointsData = [
    {
      type: unitsList.VP.label,
      attributes: {
        "Personal Volume": rewardsPointsAPI?.response?.data?.personalTotal,
        Team: rewardsPointsAPI?.response?.data?.teamTotal,
        "1st Line": rewardsPointsAPI?.response?.data?.firstLineTotal,
      },
    },
    {
      type: unitsList.CP.label,
      attributes: {
        "Personal Volume": rewardsPointsAPI?.response?.data?.personalTotal,
        Team: rewardsPointsAPI?.response?.data?.teamTotal,
        "1st Line": rewardsPointsAPI?.response?.data?.firstLineTotal,
      },
    },
    {
      type: unitsList.IP.label,
      attributes: {
        "Personal Volume": rewardsPointsAPI?.response?.data?.personalTotal,
        Team: rewardsPointsAPI?.response?.data?.teamTotal,
        "1st Line": rewardsPointsAPI?.response?.data?.firstLineTotal,
      },
    },
  ];

  //use memo for dynamic data
  //this finds the corresponding type in the array/object
  const selectedData = communityPointsData.find(
    (data) => data.type.toLowerCase() === selectedValue.toLowerCase()
  );

  return (
    <div className={Style.modalContent}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h5 className={Style.modalTitle}>Community Points</h5>
        <div
          className={Style.closeContainer}
          onClick={() => {
            generalModalRef.current.destroy();
          }}
        >
          <ModalCloseIcon />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          minWidth: "150px",
          marginBottom: 12,
        }}
      >
        <PointsButtonGroup
          initialSelected={selectedValue}
          onSelectedChange={(value) => setSelectedValue(value)}
        />
      </div>
      <Card
        minHeight={"150px"}
        apiHook={rewardsPointsAPI}
        className={Style.modalContentWithoutClose}
      >
        {selectedData && rewardsPointsAPI?.isSuccess && (
          <div className={Style.otherOptions}>
            <div className={Style.otherOptionsHeading}>
              {Object.entries(selectedData.attributes).map(
                ([key, value], index, array) => (
                  <React.Fragment key={key}>
                    <div className={Style.otherOptionsTitle}>
                      {key === "1st Line" ? (
                        <span>{getOrdinal(1)} Line :</span>
                      ) : (
                        <span>{key}</span>
                      )}
                      <div>
                        <span style={{ fontWeight: "600" }}>{value} </span>
                        <span>{selectedData.type}</span>
                      </div>
                    </div>
                    {index !== array.length - 1 && (
                      <div className={Style.divider} />
                    )}
                  </React.Fragment>
                )
              )}
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

export default CommunityPointsModal;
