import React from 'react'

const CommunityIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
            <g clipPath="url(#clip0_311_3272)">
                <path d="M3.28125 18.8993C3.28125 16.8971 4.7361 15.1917 6.71329 14.8761L6.89138 14.8477C8.47461 14.595 10.0879 14.595 11.6711 14.8477L11.8492 14.8761C13.8264 15.1917 15.2812 16.8971 15.2812 18.8993C15.2812 19.7648 14.5797 20.4663 13.7143 20.4663H4.84822C3.98281 20.4663 3.28125 19.7648 3.28125 18.8993Z" stroke="none" strokeWidth="1.5" />
                <path d="M12.7812 7.96631C12.7812 9.89931 11.2142 11.4663 9.28125 11.4663C7.34825 11.4663 5.78125 9.89931 5.78125 7.96631C5.78125 6.03331 7.34825 4.46631 9.28125 4.46631C11.2142 4.46631 12.7812 6.03331 12.7812 7.96631Z" stroke="none" strokeWidth="1.5" />
                <path d="M15.2812 11.4663C17.2142 11.4663 18.7812 9.89931 18.7812 7.96631C18.7812 6.03331 17.2142 4.46631 15.2812 4.46631M17.6711 20.4663H19.7143C20.5797 20.4663 21.2812 19.7648 21.2812 18.8993C21.2812 16.8971 19.8264 15.1917 17.8492 14.8761V14.8761C17.7307 14.8572 17.6106 14.8477 17.4906 14.8477C17.1708 14.8477 17.0715 14.8477 16.5224 14.8477" stroke="none" strokeWidth="1.5" strokeLinecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_311_3272">
                    <rect width="22.2672" height="22.1501" fill="white" transform="translate(0.28125 0.466309)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default CommunityIcon