import React, { useRef, useState } from "react";

// Libraries
import { Button } from "@hybris-software/ui-kit";

// Images
import ErrorIcon from "../../../assets/icons/response-Icons/rejected.png";

// Data
import config from "../../../data/config";

// Style
import Style from "./ErrorModal.module.css";

const download = (filename, text) => {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

const ErrorModal = ({ onButtonClick = () => {}, error, modalRef }) => {
  const testRef = useRef(null);
  const [showHtml, setShowHtml] = useState(false);

  return (
    <>
      <div className={Style.error}>
        <img className={Style.icon} src={ErrorIcon} alt="" />
        <h4>Something went wrong</h4>
        <p>
          Please try again later. If the error persists contact the support.
        </p>

        <div className={Style.button}>
          <Button
            onClick={() => {
              onButtonClick();
              modalRef.current.destroy();
            }}
          >
            Close
          </Button>
        </div>

        {config.DEBUG_MODE && (
          <div className={Style.dataInfo}>
            <div className={Style.debugMode}>
              <div className={Style.label}>**Debug mode only**</div>
              <div className={Style.message}>
                Error Message: {error.message}
              </div>

              <div className={Style.showHtml} ref={testRef}></div>

              {showHtml ? (
                <div
                  className={Style.show}
                  onClick={() => {
                    setShowHtml(false);
                    testRef.current.innerHTML = "";
                  }}
                >
                  Hide Error
                </div>
              ) : (
                <div
                  className={Style.show}
                  onClick={() => {
                    setShowHtml(true);
                    testRef.current.innerHTML = error.response.data;
                  }}
                >
                  Show Error in HTML
                </div>
              )}
              <Button
                className={Style.download}
                onClick={() => {
                  download("error.html", error.response.data);
                }}
              >
                Download error
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ErrorModal;
