import React, { useEffect, useContext } from "react";

// Components
import Loader from "../../../components/ui/loader/Loader";
import GradientHeaderV2 from "../../../components/advanced/gradientHeaderV2/GradientHeaderV2";
import BonusDetails from "./components/BonusDetails";

// Libraries
import { Container } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";
import { useNavigate } from "react-router-dom";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Data
import config from "../../../data/config";

// Styles
import Style from "./HouseBonusView.module.css";

const HouseBonusView = () => {
  // Hooks
  const navigate = useNavigate();

  // Contexts
  const { paths } = useContext(RoutesContext);

  useEffect(() => {
    document.title = `${config.WEBSITE_NAME} | House Bonus`;
    //eslint-disable-next-line
  }, []);

  return (
    <PermissionRoute
      loader={<Loader />}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        // navigate
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        // dispatcher
        return true;
      }}
    >
      <Container>
        <section className={Style.headerSection}>
          <GradientHeaderV2
            subtitle="Discover the mechanics behind availing a house in Dubai on XERA!"
            title={"Want to Know How To Avail House Bonus?"}
            linkText={"Visit Our Wiki for More Details"}
          />
        </section>
        <section className={Style.section}>
          <BonusDetails />
        </section>
      </Container>
    </PermissionRoute>
  );
};

export default HouseBonusView;
