import React, { useContext } from "react";

// Library
import { Button } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";

// Components
import WalletBalance from "../walletBalance/WalletBalance";
import Card from "../../../../../../../components/advanced/card/Card";

// Context
import { RoutesContext } from "../../../../../../../contexts/RoutesContext";

// Data
import config from "../../../../../../../data/config";
import endpoints from "../../../../../../../data/endpoints";

// Utils
import classNames from "../../../../../../../utils/classNames";

// Icons
import E from "../../../../../../../assets/svg/E.svg";
import LinkArrowIcon from "../../../../../../../assets/svgComponents/LinkArrowIcon";
import SuccessIcon from "../../../../../../../assets/icons/response-Icons/success.png";
import BillIcon from "../../../../../../../assets/icons/response-Icons/bell.png";

// Styles
import Style from "./DebtWallet.module.css";
import Theme from "../../../../../../../assets/css/theme.module.css";
import { useOutletContext } from "react-router-dom";
import StatusResponse from "../../../../../../../components/advanced/statusResponse/StatusResponse";
import errorCodes from "../../../../../../../data/errorCodes";
import { AutoDebitStatus } from "../../../../../../../data/constants";

const DebtWallet = ({ walletReportAPI }) => {
  // Contexts
  const { paths } = useContext(RoutesContext);
  const { generalModalRef, generalErrorsResolver } = useOutletContext();

  // Queries
  const debtConfigAPI = useQuery({
    url: endpoints.wallets.DEBTS_CONFIG,
    method: "GET",
    executeImmediately: true,
  });

  const requestDebtHaltAPI = useQuery({
    url: endpoints.wallets.DEBTS_HALT_REQUEST,
    method: "POST",
    executeImmediately: false,
    onSuccess: () => {
      generalModalRef.current.updateBody(
        <StatusResponse
          icon={SuccessIcon}
          title="Success"
          description={`The request has been submitted to admin and will be halted after approval.`}
        >
          <Button onClick={() => generalModalRef.current.destroy()}>
            Close
          </Button>
        </StatusResponse>,
        {
          onModalDestroy: () => {
            debtConfigAPI?.executeQuery();
          },
        }
      );
    },
    onError: (error) => {
      if (+error.response.status === 400) {
        generalModalRef.current.updateBody(
          <StatusResponse
            icon={BillIcon}
            title={errorCodes?.[error?.response?.data?.code]?.title || "Failed"}
            description={error.response?.data?.message}
          >
            <Button onClick={() => generalModalRef.current.destroy()}>
              Close
            </Button>
          </StatusResponse>,
          {
            onModalDestroy: () => {
              debtConfigAPI?.executeQuery();
            },
          }
        );
      } else {
        generalErrorsResolver(error);
      }
    },
  });

  const cancelDebtHaltAPI = useQuery({
    url: endpoints.wallets.DEBTS_HALT_CANCEL,
    method: "POST",
    executeImmediately: false,
    onSuccess: () => {
      generalModalRef.current.updateBody(
        <StatusResponse
          icon={SuccessIcon}
          title="Success"
          description={`The request has been cancelled.`}
        >
          <Button onClick={() => generalModalRef.current.destroy()}>
            Close
          </Button>
        </StatusResponse>,
        {
          onModalDestroy: () => {
            debtConfigAPI?.executeQuery();
          },
        }
      );
    },
    onError: (error) => {
      if (+error.response.status === 400) {
        generalModalRef.current.updateBody(
          <StatusResponse
            icon={BillIcon}
            title={errorCodes?.[error?.response?.data?.code]?.title || "Failed"}
            description={error.response?.data?.message}
          >
            <Button onClick={() => generalModalRef.current.destroy()}>
              Close
            </Button>
          </StatusResponse>,
          {
            onModalDestroy: () => {
              debtConfigAPI?.executeQuery();
            },
          }
        );
      } else {
        generalErrorsResolver(error);
      }
    },
  });

  const reEnableDebtHaltAPI = useQuery({
    url: endpoints.wallets.DEBTS_HALT_RE_ENABLE,
    method: "POST",
    executeImmediately: false,
    onSuccess: () => {
      generalModalRef.current.updateBody(
        <StatusResponse
          icon={SuccessIcon}
          title="Success"
          description={`The request has been re enabled.`}
        >
          <Button onClick={() => generalModalRef.current.destroy()}>
            Close
          </Button>
        </StatusResponse>,
        {
          onModalDestroy: () => {
            debtConfigAPI?.executeQuery();
          },
        }
      );
    },
    onError: (error) => {
      if (+error.response.status === 400) {
        generalModalRef.current.updateBody(
          <StatusResponse
            icon={BillIcon}
            title={errorCodes?.[error?.response?.data?.code]?.title || "Failed"}
            description={error.response?.data?.message}
          >
            <Button onClick={() => generalModalRef.current.destroy()}>
              Close
            </Button>
          </StatusResponse>,
          {
            onModalDestroy: () => {
              debtConfigAPI?.executeQuery();
            },
          }
        );
      } else {
        generalErrorsResolver(error);
      }
    },
  });

  return (
    <Card
      minHeight={"180px"}
      apiHook={debtConfigAPI}
      className={Style.walletCard}
    >
      <div className={classNames(Style.cardHeader, Theme.walletCardTitle)}>
        <div>Debt Wallet</div>
        {!debtConfigAPI?.response?.data?.hideHaltBtn && (
          <div>
            <Button
              isLoading={
                cancelDebtHaltAPI?.isLoading ||
                requestDebtHaltAPI?.isLoading ||
                reEnableDebtHaltAPI?.isLoading
              }
              disabled={debtConfigAPI?.response?.data?.disableDebtHaltBtn}
              className={Style.button}
              onClick={() => {
                switch (debtConfigAPI?.response?.data?.haltDebtStatus) {
                  case AutoDebitStatus?.PENDING?.value:
                    requestDebtHaltAPI?.executeQuery();
                    break;
                  case AutoDebitStatus?.REQUESTED?.value:
                    cancelDebtHaltAPI?.executeQuery();
                    break;
                  case AutoDebitStatus?.APPROVED?.value:
                    reEnableDebtHaltAPI?.executeQuery();
                    break;

                  default:
                    requestDebtHaltAPI?.executeQuery();
                    break;
                }
              }}
            >
              {debtConfigAPI?.response?.data?.haltDebtStatus ===
              AutoDebitStatus?.REQUESTED?.value ? (
                <>Cancel Halt Request</>
              ) : debtConfigAPI?.response?.data?.haltDebtStatus ===
                AutoDebitStatus?.APPROVED?.value ? (
                <>Re Enable Auto Debit</>
              ) : (
                <>Halt Auto Debit</>
              )}
            </Button>
          </div>
        )}
      </div>
      <div>
        <WalletBalance
          icon={E}
          balance={walletReportAPI?.response?.data?.debtAmount}
          unit={config.EURK_UNIT}
        />
      </div>
      <div className={Style.action}>
        <div
          className={Style.infoBoxAction}
          onClick={() => {
            window.open(paths.main.debtWallets, "_blank");
          }}
        >
          <div className={Style.infoBoxActionTitle}>View Details</div>
          <LinkArrowIcon />
        </div>
      </div>
    </Card>
  );
};

export default DebtWallet;
