import React, { useContext } from "react";

// Components
import Card from "../../../../../components/advanced/card/Card";

// Libraries
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";
import { Link } from "react-router-dom";

// Contexts
import { RoutesContext } from "../../../../../contexts/RoutesContext";

// Data
import endpoints from "../../../../../data/endpoints";

// Icons
import userInfoAvatar from "../../../../../assets/images/placeholder.png";
import reportBuilderKYCDateIcon from "../../../../../assets/svg/reportBuilderKYCDateIcon.svg";
import reportBuilderMemberSince from "../../../../../assets/svg/reportBuilderMemberSince.svg";
import reportBuilderEmail from "../../../../../assets/svg/reportBuilderEmail.svg";
import reportBuilderMobile from "../../../../../assets/svg/reportBuilderMobile.svg";
import arrowRight from "../../../../../assets/svg/arrow-right.svg";

// Style
import Style from "./UserInfo.module.css";

const UserInfo = ({ id, setUserInfo }) => {
  const { paths } = useContext(RoutesContext);

  // Queries
  const builderReportUserAPI = useQuery({
    url: `${endpoints.builderReport.USERS}${id}/info/`,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      setUserInfo(response?.data);
    },
    onError: (error) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  //functions
  const formatDateUtc = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    const time = formattedDate.split(" ");
    console.log(time);
    return `${time[1].replace(",", "").padStart(2, "0")}-${time[0]}-${time[2]}`;
  };

  //add or change from here
  const userInfoFields = [
    {
      apiKey: "kycApprovedDate",
      title: "KYC Date:",
      icon: reportBuilderKYCDateIcon,
      formatFunction: formatDateUtc,
    },
    {
      apiKey: "dateJoined",
      title: "Member Since:",
      icon: reportBuilderMemberSince,
      formatFunction: formatDateUtc,
    },
    { apiKey: "phone", title: "Number:", icon: reportBuilderMobile },
    { apiKey: "email", title: "Email:", icon: reportBuilderEmail },
  ];

  return (
    <Card
      className={Style.card}
      minHeight="145px"
      apiHook={builderReportUserAPI}
    >
      <div className={Style.userInfo}>
        <div className={Style.leftSection}>
          <div
            className={Style.avatar}
            style={{
              backgroundImage: `url(${
                builderReportUserAPI?.response?.data.profilePicture ??
                userInfoAvatar
              })`,
            }}
          />
          <div>
            <div className={Style.username}>
              <h5>@{builderReportUserAPI?.response?.data.username ?? "-"}</h5>
            </div>
            <div className={Style.additionalInfo}>
              {userInfoFields.map(
                ({ apiKey, title, icon, formatFunction }, index, array) => (
                  <React.Fragment key={apiKey}>
                    <UserInfoItem
                      icon={icon}
                      title={title}
                      content={
                        builderReportUserAPI?.response?.data[apiKey]
                          ? formatFunction
                            ? formatFunction(
                                builderReportUserAPI?.response?.data[apiKey]
                              )
                            : builderReportUserAPI?.response?.data[apiKey]
                          : "-"
                      }
                    />
                    {index !== array.length - 1 && (
                      <div className={Style?.verticalDivider} />
                    )}
                  </React.Fragment>
                )
              )}
            </div>
          </div>
        </div>
        <div>
          <div className={Style.rankRuleContainer}>
            <Link className={Style.rankRule} to={paths.main.wiki}>
              View Rank Rules
            </Link>
            <img src={arrowRight} alt="arrow-right" />
          </div>
        </div>
      </div>
    </Card>
  );
};

const UserInfoItem = ({ icon, title, content }) => (
  <div className={Style.additionalInfoItem}>
    <img src={icon} alt={title} width={16} height={16} />
    <div className={Style.userTextWrapper}>
      <div className={Style.additionalInfoTitle}>{title}</div>
      <div className={Style.additionalInfoContent}>{content}</div>
    </div>
  </div>
);

export default UserInfo;
