import React from 'react'

const CommissionIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 23 23" fill="none">
            <path d="M15.1972 15.1972C18.0128 14.5988 20.125 12.0981 20.125 9.10417C20.125 5.66389 17.3361 2.875 13.8958 2.875C10.9019 2.875 8.40123 4.98723 7.80281 7.80281M15.1972 15.1972C15.2864 14.7775 15.3333 14.3421 15.3333 13.8958C15.3333 10.4556 12.5444 7.66667 9.10417 7.66667C8.65786 7.66667 8.22252 7.7136 7.80281 7.80281M15.1972 15.1972C14.5988 18.0128 12.0981 20.125 9.10417 20.125C5.66389 20.125 2.875 17.3361 2.875 13.8958C2.875 10.9019 4.98723 8.40123 7.80281 7.80281M8.76534 11.8388L7.04715 13.557C6.86003 13.7441 6.86003 14.0475 7.04716 14.2347L8.76535 15.9528C8.95247 16.14 9.25586 16.14 9.44299 15.9528L11.1612 14.2347C11.3483 14.0475 11.3483 13.7441 11.1612 13.557L9.44299 11.8388C9.25586 11.6517 8.95247 11.6517 8.76534 11.8388Z" stroke="none" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
    )
}

export default CommissionIcon