import React, { useState, useContext, useEffect } from "react";

// Components
import HamburgerMenu from "../../advanced/hamburgerMenu/HamburgerMenu";
import Tooltip from "../../advanced/tooltip/Tooltip";

// Libraries
import { Container } from "@hybris-software/ui-kit";

// Context
import GeneralInfoContext from "../../../contexts/GeneralInfoContext";

// Data
import config from "../../../data/config";

// Images
import placeholderImage from "../../../assets/images/placeholder.png";

// Icons
import { BiChevronDown } from "react-icons/bi";
import { MdLogout } from "react-icons/md";
import { CgWebsite } from "react-icons/cg";

// Styles
import Style from "./Header.module.css";

const Header = ({ open, setOpen, logoutModalRef }) => {
  //  States
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [currentUtcDate, setCurrentUtcDate] = useState(new Date());

  // Context
  const [generalInfo] = useContext(GeneralInfoContext);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentUtcDate(new Date());
    }, 1000); // Update every second

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const options = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
    timeZone: "UTC",
  };

  const formattedDate = currentUtcDate.toLocaleTimeString("en-US", options);

  return (
    <Container>
      <div className={Style.header}>
        <div className={Style.finder}>
          <img src={config.LOGO_WHITE} alt="" />
        </div>

        <div className={Style.information}>
          <div className={Style.time}>
            <span className={Style.label}>Server time:</span>{" "}
            <div className={Style.dataFormat}>{formattedDate}</div> UTC
          </div>
          <div
            className={Style.avatar}
            onClick={() => setDropdownOpen((old) => !old)}
          >
            <div
              className={Style.picture}
              style={{
                backgroundImage: `url(${
                  generalInfo?.userInfo?.profilePicture ?? placeholderImage
                })`,
              }}
            />
            <div
              className={dropdownOpen ? Style.menuOpen : Style.menu}
              onMouseLeave={() => setDropdownOpen(false)}
            >
              <div className={Style.content}>
                <div className={Style.title}>
                  Welcome! <br />
                  <span>@{generalInfo?.userInfo?.username} </span>
                </div>
                <div className={Style.bid}>
                  <div className={Style.id}>
                    ID: {generalInfo?.userInfo?.id}
                  </div>
                  <Tooltip valueToCopy={generalInfo?.userInfo?.id} />
                </div>
                <div
                  className={Style.item}
                  onClick={() => {
                    window.open(
                      `https://app.2access.io/`,
                      "_blank",
                      "noopener noreferrer"
                    );
                  }}
                >
                  <CgWebsite />
                  Go to {config.WEBSITE_2ACCESS}
                </div>
                <div
                  className={Style.item}
                  onClick={() => {
                    logoutModalRef.current.open();
                  }}
                >
                  <MdLogout />
                  Logout
                </div>
              </div>
            </div>
            <div>
              <div className={!dropdownOpen ? Style.action : Style.actionOpen}>
                <div className={Style.username}>
                  @{generalInfo?.userInfo?.username}
                </div>
                <BiChevronDown />
              </div>
            </div>
          </div>
          <HamburgerMenu open={open} setOpen={setOpen} />
        </div>
      </div>
    </Container>
  );
};

export default Header;
