import React, { useState, useEffect, useRef } from "react";

// Components
import PercentageSelector from "../percentageSelector/PercentageSelector";
import StatusResponse from "../statusResponse/StatusResponse";

// Libraries
import useForm from "@hybris-software/use-ful-form";
import { InputField, Button } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";
import OtpInput from "react18-input-otp";

// Utilities
import {
  addSlashIfNeeded,
  formatNumberEuropean,
  truncateNumber,
} from "../../../utils/utilityFunctions";

// Data
import endpoints from "../../../data/endpoints";
import config from "../../../data/config";

// Icons
import ModalCloseIcon from "../../../assets/svgComponents/ModalCloseIcon";
import SuccessIcon from "../../../assets/icons/response-Icons/success.png";
import BillIcon from "../../../assets/icons/response-Icons/bell.png";

// Styles
import Style from "./SendModal.module.css";
import Theme from "../../../assets/css/theme.module.css";

const SendModal = ({
  balance,
  generalModalRef,
  generalErrorsResolver,
  token,
  refreshAPI,
}) => {
  // Variables
  const errorCodes = {
    FEATURE_DISABLED: {
      message:
        "Temporarily, the function is disabled. We are in the process of adding new features and soon all functions will be available again.",
    },
    OTP_VERIFICATION_FAILED: {
      message: "Error while checking OTP, please try again later.",
    },
    TOTP_NOT_ENABLED: {
      message: (
        <div>
          Google authenticator not enabled on {config.WEBSITE_2ACCESS}. please
          change your OTP method in
          <span
            onClick={() => {
              window.open(
                addSlashIfNeeded(config.BLOCKCHAINID_BASE_URL),
                "_blank"
              );
            }}
            className={Style.link}
          >
            {config.WEBSITE_2ACCESS}
          </span>
          from Email OTP to Google Authenticator OTP{" "}
        </div>
      ),
    },
    INSUFFICIENT_BALANCE: {
      message:
        "It seems that you don't not have enough balance in your wallet.",
    },
    INVALID_OTP: {
      message: "Invalid OTP",
    },
    LFI_USER_NOT_FOUND: {
      message: (
        <div>
          It seems that you never logged inside {config.WEBSITE_LFI} before,
          please make sure that you have an account in{" "}
          <span
            onClick={() => {
              window.open(addSlashIfNeeded(config.LFI_BASE_URL), "_blank");
            }}
            className={Style.link}
          >
            {config.WEBSITE_LFI} platform
          </span>
        </div>
      ),
    },
    KYC_NOT_APPROVED: {
      message: (
        <div>
          Your KYC has to be approved to continue with this action,{" "}
          <span
            onClick={() => {
              window.open(
                addSlashIfNeeded(config.BLOCKCHAINID_BASE_URL) + "kyc",
                "_blank"
              );
            }}
            className={Style.link}
          >
            start your KYC.
          </span>
        </div>
      ),
    },
  };
  // States
  const [percentage, setPercentage] = useState(0);

  // Refs
  const amountDivRef = useRef(null);

  // Forms
  const form = useForm({
    inputs: {
      amount: {
        required: true,
        errorOnEveryChange: true,
        formatter: (value) => {
          if (!value) return "";
          if (value[0] === ".") value = "0" + value;
          let tmpValue = value;
          if (tmpValue.split(".")[1]?.length > 8) {
            tmpValue = tmpValue.slice(0, -1);
          }
          tmpValue = tmpValue
            .replace(/[^0-9.]/g, "")
            .replace(/(\..*)\./g, "$1");
          if (parseFloat(tmpValue) > parseFloat(balance))
            return truncateNumber(balance, 8);
          else return tmpValue;
        },
        validator: (value) => {
          if (parseFloat(value) > parseFloat(balance)) {
            return [false, "Insufficient balance"];
          } else {
            return [true, ""];
          }
        },
      },
      token: {
        value: token?.value,
      },
      otp: {
        required: true,
        validator: (value) => {
          if (value?.length === 6) {
            return [true, null];
          }
          return [false, null];
        },
      },
    },
  });

  // Queries
  const withdrawAPI = useQuery({
    url: endpoints.wallets.WITHDRAW,
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      generalModalRef.current.updateBody(
        <StatusResponse
          icon={SuccessIcon}
          title="Success"
          description={`Successful operation`}
        >
          <Button onClick={() => generalModalRef.current.destroy()}>
            Close
          </Button>
        </StatusResponse>,
        {
          onModalDestroy: () => {
            if (refreshAPI) {
              refreshAPI.executeQuery();
            }
          },
        }
      );
    },
    onError: (error) => {
      if (error?.response?.status === 422) {
        form.fetchQueryErrors(error?.response?.data);
      } else if (
        error?.response?.status === 400 &&
        error?.response?.data?.["code"] === "INVALID_OTP"
      ) {
        return;
      } else if (error.response.status === 400) {
        generalModalRef.current.updateBody(
          <StatusResponse
            icon={BillIcon}
            title={"Failed !"}
            description={
              errorCodes[error?.response?.data?.["code"]]?.message ??
              "Something went wrong"
            }
          >
            <Button onClick={() => generalModalRef.current.destroy()}>
              Close
            </Button>
          </StatusResponse>
        );
      } else {
        generalErrorsResolver(error);
      }
    },
    clientOptions: {
      timeout: 120000,
    },
  });

  // Functions
  function calculatePercentage() {
    if (true) {
      form.setFieldValue("amount", (percentage * balance).toString());
    }
  }

  useEffect(() => {
    calculatePercentage();
    // eslint-disable-next-line
  }, [percentage]);

  useEffect(() => {
    calculatePercentage();
    // eslint-disable-next-line
  }, []);

  const otpErrorMessage = withdrawAPI.isError &&
    withdrawAPI.error.response.status === 400 &&
    withdrawAPI.error.response.data["code"] === "INVALID_OTP" && {
      googleAuthOtp: (
        <>
          {(withdrawAPI.error.response.data["code"] === "INVALID_OTP" && (
            <>Invalid Google Authenticator OTP Code</>
          )) ||
            ""}
        </>
      ),
    };

  useEffect(() => {
    amountDivRef.current.focus();
  }, []);

  return (
    <div className={Style.modalContent}>
      <div className={Style.modalTitle}>
        Please confirm you want to move your {token.label} balance to{" "}
        {config.WEBSITE_LFI}!
        <div
          className={Style.closeContainer}
          onClick={() => {
            generalModalRef.current.destroy();
          }}
        >
          <ModalCloseIcon />
        </div>
      </div>
      <div className={Style.divider}></div>

      <div className={Style.amountContainer}>
        <div className={Style.amount}>
          <InputField
            tabIndex="0"
            ref={amountDivRef}
            className={Style.inputNumberAmount}
            label="Enter the amount"
            placeholder={"Insert Amount"}
            {...form.getInputProps("amount")}
          />
          <div className={Style.balance}>
            Balance: {formatNumberEuropean(balance)} {token.label}
          </div>
        </div>
        <PercentageSelector
          percentage={percentage}
          setPercentage={setPercentage}
        />
      </div>
      <div className={Style.modalOTPTitle}>
        Insert the OTP Code from Your Google Authentication App for Your{" "}
        {config.WEBSITE_2ACCESS} Account
      </div>
      <div className={Style.modalOTP}>
        <OtpInput
          {...form.getInputProps("otp")}
          onChange={(otp) => {
            form.getInputProps("otp").setValue(otp);
          }}
          numInputs={6}
          isInputNum
          containerStyle={Theme.otpContainer}
          inputStyle={Theme.otpInputStyle}
          focusStyle={Theme.otpFocusStyle}
          errorStyle={Theme.otpErrorStyle}
          shouldAutoFocus={true}
        />
        <div className={Style.otpErrorMessage}>
          {otpErrorMessage.googleAuthOtp}
        </div>
      </div>
      {/* 
      <div className={Style.instruction}>
        <h6 className={Style.instructionTitle}>Instruction</h6>
        <p className={Style.instructionText}>{description}</p>
      </div> */}
      <div className={Style.sendActions}>
        <div
          className={Style.cancelBtn}
          onClick={() => {
            generalModalRef.current.destroy();
          }}
        >
          <div> Cancel</div>
        </div>
        <Button
          className={Style.proceed}
          isLoading={withdrawAPI.isLoading}
          disabled={
            !form.isValid() ||
            withdrawAPI.isLoading ||
            parseFloat(form.getInputProps("amount").value) === 0
          }
          onClick={() => {
            withdrawAPI.executeQuery(form.getApiBody());
          }}
        >
          Proceed
        </Button>
      </div>
    </div>
  );
};

export default SendModal;
