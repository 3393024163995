import { useContext } from "react";

// Components
import StatusResponse from "../statusResponse/StatusResponse";

//Context
import { RoutesContext } from "../../../contexts/RoutesContext";

// Icon
import bell from "../../../assets/icons/response-Icons/bell.png";

const UnauthorizedModal = () => {
  const { paths } = useContext(RoutesContext);

  return (
    <StatusResponse
      icon={bell}
      title="Your session has expired"
      description="Place login again."
      cta={{
        isRight: false,
        text: "Login again",
        to: paths.auth.login,
      }}
    />
  );
};

export default UnauthorizedModal;
