import React, { useEffect, useState } from "react";

// Styles
import Style from "./CountdownCircle.module.css";

const CountdownCircle = ({
  deadline = "2023-12-31T23:59:59.000Z",
  marginInline = "unset",
}) => {
  const calculateTimeLeft = (finishData) => {
    const difference = +new Date(finishData) - +new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
      if (timeLeft.days < 10) {
        timeLeft.days = "0" + timeLeft.days;
      }
      if (timeLeft.hours < 10) {
        timeLeft.hours = "0" + timeLeft.hours;
      }
      if (timeLeft.minutes < 10) {
        timeLeft.minutes = "0" + timeLeft.minutes;
      }
      if (timeLeft.seconds < 10) {
        timeLeft.seconds = "0" + timeLeft.seconds;
      }
    } else {
      timeLeft = {
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00",
      };
    }
    return timeLeft;
  };

  const [data, setData] = useState(calculateTimeLeft(deadline));
  const percentage = 100 - (data?.days / 30) * 100;

  useEffect(() => {
    const timer = setTimeout(() => {
      setData(calculateTimeLeft(deadline));
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <div
      className={Style.timer}
      style={{
        background: `linear-gradient(#454545, #050505) content-box no-repeat, conic-gradient(var(--text-selected-number) ${
          100 - percentage
        }%, 0, #525252) border-box`,
        marginInline: marginInline,
      }}
    >
      <div className={Style.numbers}>
        <div>
          {data?.days}
          <span> Days</span>
        </div>
        {data?.hours}:{data?.minutes}:{data?.seconds}
        <div className={Style.left}>LEFT</div>
      </div>
    </div>
  );
};

export default CountdownCircle;
