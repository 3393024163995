import React, { useState } from "react";

// Components
import NotFound from "./components/notFound/NotFound";

// Libraries
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Data
import { routeComponents, paths, sidebarRoutes } from "./data/routes";

// Contexts
import { RoutesContext } from "./contexts/RoutesContext";
import GeneralInfoContext from "./contexts/GeneralInfoContext";

function App() {
  const [generalInfo, setGeneralInfo] = useState({});
  return (
    <BrowserRouter>
      <GeneralInfoContext.Provider value={[generalInfo, setGeneralInfo]}>
        <RoutesContext.Provider value={{ paths, sidebarRoutes }}>
          <Routes>
            {routeComponents}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </RoutesContext.Provider>
      </GeneralInfoContext.Provider>
    </BrowserRouter>
  );
}

export default App;
