import React from 'react'

const ReferralIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 23 24" fill="none">
            <path d="M8.16797 15.3897C8.16797 14.482 8.82543 13.7089 9.71894 13.5659C10.8427 13.386 11.9878 13.386 13.1116 13.5659C14.0051 13.7089 14.6626 14.482 14.6626 15.3897V15.5906C14.6626 16.1214 14.2336 16.5517 13.7044 16.5517H9.1261C8.59694 16.5517 8.16797 16.1214 8.16797 15.5906V15.3897Z" stroke="none" strokeWidth="1.5" />
            <path d="M13.3095 9.22264C13.3095 10.272 12.4614 11.1228 11.4153 11.1228C10.3691 11.1228 9.52101 10.272 9.52101 9.22264C9.52101 8.17323 10.3691 7.32251 11.4153 7.32251C12.4614 7.32251 13.3095 8.17323 13.3095 9.22264Z" stroke="none" strokeWidth="1.5" />
            <path d="M19.7649 7.32253C19.7649 5.28368 17.6879 3.63086 15.1259 3.63086M3.06445 15.6288C3.06445 18.1774 4.72601 20.2434 6.77565 20.2434M6.77565 3.63086C4.72601 3.63086 3.06445 5.28368 3.06445 7.32253M15.1259 20.2434C17.6879 20.2434 19.7649 18.1774 19.7649 15.6288" stroke="none" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
    )
}

export default ReferralIcon