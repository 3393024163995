import React, { useContext } from "react";

// Components
import SimpleStyledCard from "../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";
import DateFormatter from "../../../../../components/advanced/dateFormatter/DateFormatter";

// Contexts
import GeneralInfoContext from "../../../../../contexts/GeneralInfoContext";

// Styles
import Style from "./RankDifference.module.css";

const RankDifference = ({ response }) => {
  const [generalInfo] = useContext(GeneralInfoContext);

  const currentRank = generalInfo?.rankInfo?.filter(
    (rank) => rank?.id === response?.rank
  )?.[0];

  const commissionableRank = generalInfo?.rankInfo?.filter(
    (rank) => rank?.id === response?.commissionableRank
  )?.[0];

  return (
    <SimpleStyledCard className={Style.box}>
      <div className={Style.shapeOne} />
      <div className={Style.shapeTwo} />
      <LevelRank
        generalInfo={generalInfo}
        label="Your Current Rank"
        response={response}
        currentRank={currentRank}
      />
      <div className={Style.border} />
      <LevelRank
        generalInfo={generalInfo}
        label="Your Infinity Rank"
        date={false}
        response={response}
        currentRank={commissionableRank}
      />
    </SimpleStyledCard>
  );
};

const LevelRank = ({
  generalInfo,
  label,
  date = true,
  response,
  currentRank,
}) => {
  return (
    <div className={Style.levelCard}>
      <div className={Style.text}>
        <div className={Style.level}>{label}</div>
        <h5 className={Style.rank}>{currentRank?.name}</h5>
        {date ? (
          <div className={Style.rankDate}>
            Since <DateFormatter date={response?.rankSice} />
          </div>
        ) : (
          <div className={Style.nodate}></div>
        )}
      </div>

      <div className={Style.coin}>
        <img src={currentRank?.iconUrl} alt="" />
        <div className={Style.opacityIcon}>
          <img src={currentRank?.iconUrl} alt="" />
        </div>
        <div className={Style.opacityIconLeft}>
          <img src={currentRank?.iconUrl} alt="" />
        </div>
      </div>
    </div>
  );
};

export default RankDifference;
