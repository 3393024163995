import React from "react";

// Libraries
import { useNavigate } from "react-router-dom";
import { useContext } from "react";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Style
import Style from "./ExportModal.module.css";

const ExportModal = ({ generalModalRef }) => {
  // Contexts
  const { paths } = useContext(RoutesContext);

  // Hooks
  const navigate = useNavigate();

  return (
    <>
      <div>You request for export is done.</div>
      <div>
        You will be able to download the file from{" "}
        <div
          onClick={() => {
            navigate(paths.main.export);
            generalModalRef.current.destroy();
          }}
          className={Style.link}
        >
          export page
        </div>{" "}
        when its ready.
      </div>
    </>
  );
};

export default ExportModal;
