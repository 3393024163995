import React, { useEffect, useState, useRef, useContext } from "react";

// Components
import Card from "../../../../../components/advanced/card/Card";

// Libraries
import { useWindowSize } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";
import { useNavigate } from "react-router-dom";

// Images
import CompletedIcon from "../../../../../assets/images/completed-icon.png";
import Prev from "../../../../../assets/images/selectedLayerPrev.png";
import Next from "../../../../../assets/images/selectedLayerNext.png";

// Icons
import { PiArrowUp } from "react-icons/pi";

// Contexts
import GeneralInfoContext from "../../../../../contexts/GeneralInfoContext";
import { RoutesContext } from "../../../../../contexts/RoutesContext";

// Data
import endpoints from "../../../../../data/endpoints";

// Styles
import Style from "./RankHistory.module.css";
import DateFormatter from "../../../../../components/advanced/dateFormatter/DateFormatter";

const RankHistory = () => {
  const navigate = useNavigate();
  const [generalInfo] = useContext(GeneralInfoContext);
  const { paths } = useContext(RoutesContext);
  const currentRank = generalInfo?.userInfo?.currentRank;

  const cardRef = useRef(null);
  const { width } = useWindowSize();

  const [ranks, setRanks] = useState([]);
  const [active, setActive] = useState(currentRank);
  const [cardWidth, setCardWidth] = useState(0);

  // Queries
  const rankReportAPI = useQuery({
    url: endpoints.rankOverView.RANK_REPORT,
    method: "GET",
    executeImmediately: true,
    clientOptions: {
      timeout: 120000,
    },
  });

  useEffect(() => {
    setRanks(generalInfo?.rankInfo?.sort((a, b) => a.id - b.id));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCardWidth(cardRef?.current?.offsetWidth);
    // eslint-disable-next-line
  }, [width]);

  return (
    <Card
      minHeight={"464px"}
      className={Style.container}
      apiHook={rankReportAPI}
    >
      <div className={Style.header}>
        <h5>Career Rank History</h5>
        <div className={Style.pagination}>
          <div
            className={Style.prev}
            style={
              active === 0
                ? { opacity: 0.5, cursor: "not-allowed" }
                : { opacity: 1 }
            }
            onClick={() => {
              if (active > 0) setActive(active - 1);
            }}
          >
            <PiArrowUp />
          </div>
          <div
            className={Style.next}
            style={
              active === ranks?.length - 1
                ? { opacity: 0.5, cursor: "not-allowed" }
                : { opacity: 1 }
            }
            onClick={() => {
              if (active < ranks?.length - 1) setActive(active + 1);
            }}
          >
            <PiArrowUp />
          </div>
        </div>
      </div>

      <div
        ref={cardRef}
        className={Style.card}
        style={{
          transform: `translateX(${active * -272}px)`,
          marginLeft: width > 575 ? cardWidth / 2 - 136 : 0,
        }}
      >
        {ranks?.map((item, i) => {
          return (
            <div
              key={i}
              className={active === i ? Style.selected : Style.hidden}
            >
              <img
                draggable={false}
                className={Style.selectedLayer}
                src={i > currentRank ? Next : Prev}
                alt=""
              />

              <img
                draggable={false}
                className={active === i ? Style.coinActive : Style.coin}
                src={item.iconUrl}
                alt=""
              />

              <div
                className={i > currentRank ? Style.boxTextNext : Style.boxText}
              >
                <div className={Style.rankNumber}>Rank {i}</div>
                <div className={Style.name}>{item.name}</div>

                {i <= currentRank && <div className={Style.divider} />}

                {i < currentRank && (
                  <div className={Style.passed}>
                    <div className={Style.inner}>
                      Completed
                      <img
                        draggable={false}
                        className={Style.completed}
                        src={CompletedIcon}
                        width={20}
                        height={20}
                        alt=""
                      />
                    </div>
                  </div>
                )}

                {currentRank === i && (
                  <>
                    <div className={Style.date}>
                      Member since:{" "}
                      <DateFormatter
                        date={rankReportAPI?.response?.data?.rankUpdatedAt}
                      />
                    </div>
                    <div className={Style.currently}>Currently</div>
                    <div className={Style.ellipse} />
                    <div className={Style.ellipseTwo} />
                  </>
                )}

                {i > currentRank && (
                  <div
                    className={Style.rankRules}
                    onClick={() => {
                      navigate(paths?.main?.wiki);
                    }}
                  >
                    View WIKI to Unlock
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default RankHistory;
