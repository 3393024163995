import React, { useRef, useState, useEffect } from "react";

// Components
import SimpleStyledCard from "../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";
import RangeDatePicker from "../../../../../components/advanced/rangeDatePicker/RangeDatePicker";
import StatusResponse from "../../../../../components/advanced/statusResponse/StatusResponse";
import DateFormatter from "../../../../../components/advanced/dateFormatter/DateFormatter";
import ExportModal from "../../../../../components/advanced/exportModal/ExportModal";

// Libraries
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";
import { Button } from "@hybris-software/ui-kit";
import { useOutletContext } from "react-router";
import Table from "@hybris-software/use-table";

// Utilities
import { formatNumberEuropean } from "../../../../../utils/utilityFunctions";

// Data
import endpoints from "../../../../../data/endpoints";
import config from "../../../../../data/config";
import { customTableStyles } from "../../../../../data/constants";

// Icon
import successIcon from "../../../../../assets/icons/response-Icons/success.png";
import DownloadIcon from "../../../../../assets/svgComponents/DownloadIcon";
import CP from "../../../../../assets/svg/CP.svg";
import userAvatar from "../../../../../assets/images/strongLegPic.png";

// Styles
import Style from "./BonusHistory.module.css";
import Theme from "../../../../../assets/css/theme.module.css";

const BonusHistory = () => {
  // Variables
  const initialFilters = {
    date: {
      gteDatePickerValue: null,
      label: null,
      lteDatePickerValue: null,
    },
  };

  const columns = [
    {
      Header: "#",
      field: "id",
      orderField: "id",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return row.id;
      },
    },
    {
      Header: "Purchase User ",
      field: "purchasedUserId",
      orderField: "purchased_user_id",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return (
          <div className={Style.userName}>
            <img
              alt="user"
              width={48}
              height={48}
              src={row.purchasedUserProfilePicture ?? userAvatar}
            />
            <div>@{row.purchasedUserUsername}</div>
          </div>
        );
      },
    },
    {
      Header: "Purchased CP",
      field: "purchasedCp",
      orderField: "purchased_cp",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return (
          <div className={Style.CpContainer}>
            <img width={18} height={18} src={CP} alt="CP" />
            {formatNumberEuropean(row.purchasedCp)}{" "}
            <span style={{ opacity: ".7" }}>CP</span>
          </div>
        );
      },
    },

    {
      Header: `${config.EURK_C_UNIT} Amount`,
      field: "eurficAmount",
      orderField: "eurfic_amount",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return (
          <div>
            {formatNumberEuropean(row.eurficAmount)}{" "}
            <span style={{ opacity: ".7" }}>EurK - C</span>
          </div>
        );
      },
    },
    {
      Header: `${config.EURK_W_UNIT} Amount`,
      field: "eurfiwAmount",
      orderField: "eurfiw_amount",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return (
          <div>
            {formatNumberEuropean(row.eurfiwAmount)}{" "}
            <span style={{ opacity: ".7" }}>EurK - W</span>
          </div>
        );
      },
    },
    {
      Header: "% Bonus",
      field: "bonusPercentage",
      orderField: "bonusPercentage",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return row.bonusPercentage + "%";
      },
    },
    {
      Header: "Date",
      field: "date",
      orderField: "date",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return <DateFormatter date={row.date} />;
      },
    },
  ];

  const ref = useRef(null);
  const endPoint = endpoints.directBonus.HISTORY;

  // Status
  const [extraFilters, setExtraFilters] = useState({});
  const [currentFilter, setCurrentFilter] = useState(initialFilters);

  // Context
  const { generalModalRef, generalErrorsResolver } = useOutletContext();

  // Queries
  const exportBonusHistoryAPI = useQuery({
    url: endpoints.exports.DIRECT_BONUS_HISTORY,
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      generalModalRef.current.updateBody(
        <StatusResponse
          title="Success"
          description={<ExportModal generalModalRef={generalModalRef} />}
          icon={successIcon}
        >
          <Button onClick={() => generalModalRef.current.destroy()}>
            Close
          </Button>
        </StatusResponse>,
        { modalStyle: { maxWidth: "452px" } }
      );
    },
    onError: (error) => {
      generalErrorsResolver(error);
    },
    clientOptions: {
      timeout: 120000,
    },
  });

  useEffect(() => {
    setExtraFilters({
      date__gte: currentFilter?.date?.gteDatePickerValue
        ? currentFilter?.date?.gteDatePickerValue.toISOString()
        : null,
      date__lte: currentFilter?.date?.lteDatePickerValue
        ? currentFilter?.date?.lteDatePickerValue.toISOString()
        : null,
    });

    // eslint-disable-next-line
  }, [currentFilter]);

  return (
    <SimpleStyledCard className={Style.bonusHistory}>
      <div className={Style.header}>
        <h5>Bonus History</h5>

        <div className={Style.action}>
          <div className={Style.datePickerContainer}>
            <RangeDatePicker
              className={Style.datePicker}
              maxDate={new Date()}
              setRangeDate={(value) => {
                setCurrentFilter({
                  date: {
                    gteDatePickerValue: value ? value[0] : null,
                    lteDatePickerValue: value ? value[1] : null,
                    label: value
                      ? `From ${new Date(
                          value[0]
                        ).toLocaleDateString()} to ${new Date(
                          value[1]
                        ).toLocaleDateString()}`
                      : "",
                  },
                });
              }}
              rangeDate={[
                currentFilter.date.gteDatePickerValue,
                currentFilter.date.lteDatePickerValue,
              ]}
            />
          </div>
          <Button
            onClick={() => exportBonusHistoryAPI.executeQuery()}
            buttonClassName={Theme.downloadBtn}
            isLoading={exportBonusHistoryAPI.isLoading}
          >
            <div>Download XLSX Report</div>
            <DownloadIcon />
          </Button>
        </div>
      </div>
      <Table
        ref={ref}
        height="410"
        enableAllowedActions={false}
        columns={columns}
        endPoint={endPoint}
        enableSelectableRows={false}
        enableSearch={false}
        enableSearchFieldSelect={false}
        enablePageSizeSelect={true}
        defaultPageSize={50}
        extraFilters={extraFilters}
        Styles={customTableStyles}
        searchBadgesClassName={Style.searchBadges}
        paginationButtonClassName={Style.paginationButton}
        paginationRecordsClassName={Style.paginationRecords}
        toPageInputBaseClassName={Style.toPageInput}
        enableSettings={false}
        enableRefreshBtn={false}
        enableHiddenColumnBadge={false}
      />
    </SimpleStyledCard>
  );
};

export default BonusHistory;
