import React from "react";

// Utils
import { formatNumberEuropean } from "../../../../../../../utils/utilityFunctions";

// Icons
import LinkArrowIcon from "../../../../../../../assets/svgComponents/LinkArrowIcon";

// Styles
import Style from "./BalanceDetails.module.css";

const BalanceDetails = ({ icon, balance, unit, action, actionLabel }) => {
  return (
      <div className={Style.balanceContainer}>
        <div className={Style.balanceWrapper}>
          <div className={Style.imageContainer}>
            <img src={icon} alt="Icon" />
           
          </div>
          <div className={Style.balance}>{formatNumberEuropean(balance)}</div>
          <span className={Style.unit}>{unit}</span>
        </div>
    
        <div
          className={Style.infoBoxAction}
          onClick={() => {
            action();
          }}
        >
          <div className={Style.infoBoxActionTitle}>{actionLabel}</div>
          <LinkArrowIcon />
        </div>
      </div>
  );
};

export default BalanceDetails;
