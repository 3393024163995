import React from "react";

import moment from "moment";

// Styles
// import Style from "./DateFormatter.module.css";

const DateFormatter = ({ date, format = "YYYY-MM-DD" }) => {
  return <>{moment(date).format(format)}</>;
};

export default DateFormatter;
