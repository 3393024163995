import React from "react";

// Libraries
import { Button } from "@hybris-software/ui-kit";

//Styles
import Style from "./CompleteKyc.module.css";

const CompleteKyc = ({
  section = "",
  firstSubtitle = "",
  secondSubtitle = "",
  link = "",
  linkText = "Submit",
  onPrevious,
}) => {
  return (
    <>
      <div className={Style.animation}>
        <div className={Style.title}>
          <h5>{section}</h5>
        </div>
        <div className={Style.childContainer}>
          <div className={Style.subTitle}>
            <p>{firstSubtitle}</p>
            <p>{secondSubtitle}</p>
          </div>
          <div
            className={
              onPrevious ? Style.buttonStyle : Style.ButtonStyleOnPrevious
            }
          >
            {onPrevious && (
              <Button className={Style.leftButton} onClick={onPrevious}>
                Previous Step
              </Button>
            )}
            {link && (
              <Button
                className={Style.rightButton}
                onClick={() => {
                  window.open(link, "_blank");
                }}
              >
                {linkText}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CompleteKyc;
