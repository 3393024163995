import React, { useState, useEffect, useContext } from "react";

// Components
import Loader from "../../../components/ui/loader/Loader";
import Header from "./components/header/Header";
import RecapCard from "./components/recapCard/RecapCard";
import SliderRanks from "./components/sliderRanks/SliderRanks";
import TreeMembers from "./components/treeMembers/TreeMembers";
import WeakLegStats from "./components/weakLegStats/WeakLegStats";
import StrongLeg from "./components/StrongLeg/StrongLeg";

// Libraries
import { Container } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";
import { useNavigate } from "react-router-dom";

// Data
import config from "../../../data/config";

// Contexts
import { PrivacyProvider } from "../../../contexts/PrivacyContext";
import { RoutesContext } from "../../../contexts/RoutesContext";

// Styles
import Style from "./CommunityView.module.css";

const CommunityView = () => {
  // Hooks
  const navigate = useNavigate();
  const [privacy, setPrivacy] = useState(false);

  // Contexts
  const { paths } = useContext(RoutesContext);

  useEffect(() => {
    document.title = `${config.WEBSITE_NAME} | Community`;
    //eslint-disable-next-line
  }, []);

  return (
    <PermissionRoute
      loader={<Loader />}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        // navigate
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        // dispatcher
        return true;
      }}
    >
      <PrivacyProvider>
        <Container>
          <Header privacy={privacy} setPrivacy={setPrivacy} />
          <div className={Style.grid}>
            <RecapCard />
            <SliderRanks />
          </div>

          {/* <ReferralLinkBanner /> */}
          <TreeMembers />
          <div className={Style.legStats}>
            <StrongLeg />
            <WeakLegStats />
          </div>
        </Container>
      </PrivacyProvider>
    </PermissionRoute>
  );
};

export default CommunityView;
