import React from 'react'

const RankOverviewIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 23 23" fill="none">
            <path d="M13.457 14.9986C14.1317 14.8461 14.7528 14.5649 15.2913 14.1829L16.3554 18.3424C16.6748 19.5912 15.3319 20.6291 14.1358 20.0577L12.3425 19.201C11.7572 18.9214 11.0698 18.9214 10.4845 19.201L8.69125 20.0577C7.49516 20.6291 6.15219 19.5912 6.47167 18.3424L7.53573 14.1829C8.07422 14.5649 8.69534 14.8461 9.37007 14.9986M13.457 14.9986C12.1129 15.3023 10.7141 15.3023 9.37007 14.9986M13.457 14.9986C15.3181 14.578 16.7712 13.178 17.2078 11.385C17.523 10.0901 17.523 8.7425 17.2078 7.4476C16.7712 5.65459 15.3181 4.25459 13.457 3.83401C12.1129 3.53027 10.7141 3.53027 9.37007 3.83401C7.50898 4.25459 6.05582 5.65459 5.61927 7.4476C5.30399 8.7425 5.30399 10.0901 5.61927 11.385C6.05582 13.178 7.50898 14.578 9.37007 14.9986" stroke="red" strokeWidth="1.5" />
        </svg>
    )
}

export default RankOverviewIcon