import React, { useContext, useEffect, useRef, useState } from "react";

//Libraries
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Pagination } from "swiper/modules";

// Components
import Card from "../../../../../components/advanced/card/Card";

// Context
import { RoutesContext } from "../../../../../contexts/RoutesContext";
import { usePrivacyContext } from "../../../../../contexts/PrivacyContext";

// Data
import endpoints from "../../../../../data/endpoints";
import { unitsList } from "../../../../../data/constants";

// Utils
import getOrdinal from "../../../../../utils/getOrdinal";

// Icons
import { PiArrowUp } from "react-icons/pi";
import ExportIcon from "../../../../../assets/svgComponents/ExportIcon";
import userInfoAvatar from "../../../../../assets/images/placeholder.png";

// Images
import bluredPicture from "../../../../../assets/images/bluredImage.png";

// Styles
import Style from "./WeakLegStats.module.css";
import PointsButtonGroup from "../../../../../components/advanced/pointsButtonGroup/PointsButtonGroup";

const WeakLegStats = () => {
  //state
  const [selectedValue, setSelectedValue] = React.useState(unitsList.VP.value);

  // Hooks
  const swiperRef = useRef(null);
  const [range, setRange] = useState(4);

  // Contexts
  const { privacy } = usePrivacyContext();

  // Functions
  function fixSlidesPerView(slides) {
    if (slides === length || length > slides) {
      return slides;
    } else {
      return length;
    }
  }

  const incrementRange = () => {
    swiperRef.current?.slideNext();
  };

  const decrementRange = () => {
    swiperRef.current?.slidePrev();
  };

  // Queries
  const rewardsPointsAPI = useQuery({
    url: endpoints.community.COMMUNITY_USER_DETAILS,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  const newData = rewardsPointsAPI?.response?.data?.userDetails?.length > 0 && [
    ...rewardsPointsAPI?.response?.data?.userDetails,
    ...rewardsPointsAPI?.response?.data?.userDetails,
  ];

  const length = rewardsPointsAPI?.response?.data?.userDetails?.length;

  const weakLegStatsAPI = useQuery({
    url: endpoints.community.COMMUNITY_WEAK_LEG_REWARDS(
      `${newData[range - 4]?.id}`
    ),
    method: "GET",
    executeImmediately: false,
    onSuccess: (response) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  // Data
  const renderUsername = () => {
    const usernameToDisplay = privacy ? "*****" : newData[range - 4]?.username;
    return usernameToDisplay;
  };

  // Swiper Data
  const swiperConfig = {
    centeredSlides: false,
    slidesPerView: "auto",
    spaceBetween: 5,
    onSlidePrevTransitionEnd: () => setRange((prevRange) => prevRange - 1),
    onSlideNextTransitionEnd: () => setRange((prevRange) => prevRange + 1),
    allowTouchMove: false,
    loop: true,
    breakpoints: {
      0: {
        slidesPerView: fixSlidesPerView(2),
      },
      300: {
        slidesPerView: fixSlidesPerView(3),
      },
      370: {
        slidesPerView: fixSlidesPerView(4),
      },
      430: {
        slidesPerView: fixSlidesPerView(5),
      },
      530: {
        slidesPerView: fixSlidesPerView(6),
      },
      620: {
        slidesPerView: fixSlidesPerView(7),
      },
      991: {
        slidesPerView: fixSlidesPerView(4),
      },
      1350: {
        slidesPerView: fixSlidesPerView(5),
      },
      1500: {
        slidesPerView: fixSlidesPerView(6),
      },
      1700: {
        slidesPerView: fixSlidesPerView(7),
      },
    },
    modules: [Pagination],
    onBeforeInit: (swiper) => (swiperRef.current = swiper),
    className: Style.swiperContainer,
    range: range,
  };

  useEffect(() => {
    if (newData.length > 0) {
      weakLegStatsAPI.executeQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range, rewardsPointsAPI?.isSuccess]);

  // Functions
  function getLabelByValue(value) {
    for (let key in unitsList) {
      if (unitsList[key].value === value) {
        return unitsList[key].label;
      }
    }
    return null; // Return null if value is not found
  }

  const totalData = {
    [unitsList.VP.value]: {
      totalTeam: rewardsPointsAPI?.response?.data?.totalTeamVp,
      totalPersonal: rewardsPointsAPI?.response?.data?.totalPersonalVp,
      userTeam: weakLegStatsAPI?.response?.data?.teamVp,
      userPersonal: weakLegStatsAPI?.response?.data?.personalVp,
    },
    [unitsList.CP.value]: {
      totalTeam: rewardsPointsAPI?.response?.data?.totalTeamCp,
      totalPersonal: rewardsPointsAPI?.response?.data?.totalPersonalCp,
      userTeam: weakLegStatsAPI?.response?.data?.teamCp,
      userPersonal: weakLegStatsAPI?.response?.data?.personalCp,
    },
    [unitsList.IP.value]: {
      totalTeam: rewardsPointsAPI?.response?.data?.totalTeamIp,
      totalPersonal: rewardsPointsAPI?.response?.data?.totalPersonalIp,
      userTeam: weakLegStatsAPI?.response?.data?.teamIp,
      userPersonal: weakLegStatsAPI?.response?.data?.personalIp,
    },
  };

  return (
    <Card minHeight={"500px"} apiHook={rewardsPointsAPI}>
      <div className={Style.header}>
        <h3 className={Style.titleCard}>Weak Legs Stats</h3>
        <PointsButtonGroup
          initialSelected={selectedValue}
          onSelectedChange={(value) => setSelectedValue(value)}
        />
      </div>
      <Info
        title="Total Turnover:"
        value={totalData?.[selectedValue]?.totalTeam}
        label={getLabelByValue(selectedValue)}
        privacy={privacy}
      />
      <div className={Style.separator} />
      <Info
        title={<>{getOrdinal(1)} Line Turnover:</>}
        value={totalData?.[selectedValue]?.totalPersonal}
        label={getLabelByValue(selectedValue)}
        privacy={privacy}
      />
      {newData.length > 0 ? (
        <>
          <div className={Style.sliderWrapper}>
            <Swiper {...swiperConfig}>
              {newData.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    {({ isActive }) => {
                      return (
                        <SingleCardActive
                          {...item}
                          index={index}
                          range={range}
                          isActive={isActive}
                          privacy={privacy}
                        />
                      );
                    }}
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className={Style.pagination}>
              <div
                className={Style.prev}
                onClick={decrementRange}
                style={{
                  pointerEvents: range <= 4 || length <= 1 ? "none" : "auto",
                  opacity: range <= 4 || length <= 1 ? 0.3 : 1,
                }}
              >
                <PiArrowUp />
              </div>

              <div
                className={Style.next}
                onClick={incrementRange}
                style={{
                  pointerEvents:
                    range === length + 3 || length <= 1 ? "none" : "auto",
                  opacity: range === length + 3 || length <= 1 ? 0.3 : 1,
                }}
              >
                <PiArrowUp />
              </div>
            </div>
          </div>

          <Card
            minHeight={"233px"}
            className={Style.bottomCard}
            apiHook={weakLegStatsAPI}
          >
            <Info
              title={`@${renderUsername()} 's Personal Volume :`}
              value={totalData?.[selectedValue]?.userPersonal}
              label={getLabelByValue(selectedValue)}
              privacy={privacy}
            />
            <div className={Style.separator} />
            <Info
              privacy={privacy}
              title={`@${renderUsername()} 's Total Turnover:`}
              value={totalData?.[selectedValue]?.userTeam}
              label={getLabelByValue(selectedValue)}
            />
            <div className={Style.separator} />
            <Info
              privacy={privacy}
              title={`@${renderUsername()} 's Size:`}
              value={weakLegStatsAPI?.response?.data?.teamCount}
              label={
                weakLegStatsAPI?.response?.data?.teamCount !== 1
                  ? `Users`
                  : "User"
              }
            />
            <div className={Style.separator} />
            <Info
              privacy={privacy}
              title={`@${renderUsername()} 's Longest Chain`}
              value={weakLegStatsAPI?.response?.data?.maxDepth}
              label={"Level"}
            />
          </Card>
        </>
      ) : (
        <div className={Style.sliderWrapper}>
          <h5>No Weak Leg Users Yet</h5>
        </div>
      )}
    </Card>
  );
};

const SingleCardActive = ({
  isActive,
  profilePicture,
  username,
  index,
  range,
  id,
  privacy,
}) => {
  const { paths } = useContext(RoutesContext);

  const swiper = useSwiper();
  const idx = swiper.realIndex;

  function makeOpacity(index) {
    if (index > swiper.slides.length / 2 - 1) return 0;
    if (isActive || index === idx + 1) return 1;
    if (index < idx) return 0;
    return 0.2;
  }

  return (
    <div
      style={{ opacity: makeOpacity(index) }}
      className={isActive ? Style.cardActive : Style.card}
    >
      <div className={Style.imgWrapper}>
        <img
          src={privacy ? bluredPicture : profilePicture ?? userInfoAvatar}
          alt="profile"
        />
      </div>
      <a
        target="_blank"
        rel="noreferrer"
        href={paths.main.builderReport.replace(":id", id)}
      >
        <span className={Style.username}>
          @{privacy ? "***********" : username}
        </span>
        <ExportIcon />
      </a>
    </div>
  );
};

const Info = ({ title, value, label, privacy }) => {
  return (
    <>
      <div className={Style.info}>
        <div className={Style.infoTitle}>{title}</div>
        <div className={Style.value}>
          <strong>{privacy ? "***" : value}</strong>&nbsp;
          <span>{label}</span>
        </div>
      </div>
    </>
  );
};

export default WeakLegStats;
