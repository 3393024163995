import React from "react";

const LinkArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M2.5 6H9.5"
        stroke="white"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
      <path
        d="M6 2.5L9.5 6L6 9.5"
        stroke="white"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default LinkArrowIcon;
