import { useEffect, useState, useRef } from "react";
import { useReactFlow } from "reactflow";
import { timer } from "d3-timer";

function useAnimatedNodes(nodes, animationDuration) {
  const [tmpNodes, setTmpNodes] = useState(nodes);
  const { getNode, zoomTo } = useReactFlow();
  const initial = useRef(true);

  useEffect(() => {
    const transitions = nodes.map((node) => ({
      id: node?.id,
      from: getNode(node.id)?.position ?? node.position,
      to: node.position,
      node,
    }));

    const t = timer(function (elapsed) {
      const s = elapsed / animationDuration;
      const currNodes = transitions.map(({ node, from, to }) => {
        return {
          ...node,
          position: {
            x: from.x + (to.x - from.x) * s,
            y: from.y + (to.y - from.y) * s,
          },
        };
      });

      setTmpNodes(currNodes);

      if (elapsed > animationDuration) {
        // it's important to set the final nodes here to avoid glitches
        setTmpNodes(nodes);
        t.stop();
      }
    });

    return function () {
      t.stop();
      if (!initial.current) {
        zoomTo(0.5, { duration: 300 });
      }
      initial.current = false;
    };
  }, [nodes, getNode, animationDuration, zoomTo]);

  return { nodes: tmpNodes };
}

export default useAnimatedNodes;
