import React, { useState } from "react";

// Libraries
import { Button, InputField } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";
import useForm from "@hybris-software/use-ful-form";
import { Select } from "@hybris-software/ui-kit";
import { useOutletContext } from "react-router-dom";
import { PhoneInput } from "react-international-phone";

// Components
import SelectComponent from "../../../../../components/advanced/SelectComponent/SelectComponent";
import Card from "../../../../../components/advanced/card/Card";
import SimpleStyledCard from "../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";
import StatusResponse from "../../../../../components/advanced/statusResponse/StatusResponse";

// Data
import countryList from "../../../../../utils/countryList";
import endpoints from "../../../../../data/endpoints";

// Icons
import SendIcon from "../../../../../assets/svgComponents/SendIcon";
import verified from "../../../../../assets/svg/greenCheck.svg";
import BellIcon from "../../../../../assets/icons/response-Icons/bell.png";

// Styles
import Style from "./SupportForm.module.css";

const SupportForm = () => {
  // Hooks
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  // Contexts
  const { generalModalRef } = useOutletContext();

  // Form
  const supportForm = useForm({
    inputs: {
      name: {
        value: "",
        required: true,
      },
      surname: {
        value: "",
        required: true,
      },
      country: {
        value: "",
        required: true,
      },
      language: {
        value: "",
        required: true,
      },
      telephoneNumber: {
        value: "",
        required: true,
      },
      telegramId: {
        value: "",
        required: true,
      },
    },
  });

  // Languages
  const items = [
    { value: "EN", label: "English" },
    { value: "FR", label: "French" },
    { value: "ES", label: "Spanish" },
    { value: "DE", label: "German" },
    { value: "IT", label: "Italian" },
    { value: "JA", label: "Japanese" },
    { value: "AR", label: "Arabic" },
    { value: "BN", label: "Bengali" },
    { value: "HI", label: "Hindi" },
    { value: "VI", label: "Vietnamese" },
    { value: "RO", label: "Romanian" },
    { value: "PT", label: "Portuguese" },
    { value: "EL", label: "Greek" },
    { value: "ET", label: "Estonian" },
  ];

  // Queries
  const supportFormPostApi = useQuery({
    url: endpoints.vipSupport.SUBMISSIONS,
    method: "POST",
    onSuccess: () => {},
    onError: (error) => {
      if (error?.response?.status === 422) {
        if (error?.response?.data?.user) {
          generalModalRef.current.updateBody(
            <StatusResponse
              icon={BellIcon}
              title="Something went wrong!"
              description="You have already submitted the form"
            >
              <Button onClick={() => generalModalRef.current.destroy()}>
                Close
              </Button>
            </StatusResponse>
          );
        } else {
          supportForm.fetchQueryErrors(error?.response?.data);
        }
      } else {
        generalModalRef.current.updateBody(
          <StatusResponse
            icon={BellIcon}
            title="Something went wrong!"
            description="An error occurred while submitting the form, Please try again later."
          >
            <Button onClick={() => generalModalRef.current.destroy()}>
              Close
            </Button>
          </StatusResponse>
        );
      }
    },
    clientOptions: {
      timeout: 120000,
    },
  });

  const submisionsAPI = useQuery({
    url: endpoints.vipSupport.ELIGIBILITY,
    method: "GET",
    executeImmediately: true,
    clientOptions: {
      timeout: 120000,
    },
  });

  return (
    <>
      {submisionsAPI?.response?.data?.isEligible ? (
        <>
          {supportFormPostApi.isSuccess ? (
            <SimpleStyledCard>
              <div className={Style.formSubmited}>
                <img src={verified} alt="Green check" />
                <div className={Style.textWrapper}>
                  <h5>
                    Your Form has been submitted, our VIP Support will reach out
                    to you soon.
                  </h5>
                  <p>
                    Thank you for filling out the form. Your information has
                    been received.
                  </p>
                </div>
              </div>
            </SimpleStyledCard>
          ) : (
            <Card minHeight={"600px"} apiHook={submisionsAPI}>
              <div className={Style.wrapper}>
                <div className={Style.card}>
                  <div className={Style.textWrapper}>
                    <h5>VIP Telegram Support</h5>
                    <p>
                      Please fill out the form below to join our VIP Telegram
                      Support. Our Team will reach out to you shortly after you
                      fill out the form
                    </p>
                  </div>

                  <div className={Style.formInputs}>
                    <InputField
                      placeholder="Enter your full name"
                      label="Name"
                      {...supportForm.getInputProps("name")}
                    />
                    <InputField
                      type="surname"
                      placeholder="Enter your surname"
                      label="Surname"
                      {...supportForm.getInputProps("surname")}
                    />

                    <div className={Style.country}>
                      <div className={Style.label}>Country</div>
                      <SelectComponent
                        className={Style.select}
                        maxHeightOpened={235}
                        items={countryList}
                        searchKey="country"
                        classNameSearchInput={Style.search}
                        classNamePlaceholder={Style.placeholder}
                        placeholder="Select a country"
                        renderOption={(item, fullItem) => (
                          <div className={Style.option}>
                            <span className={Style.flag}>
                              <img
                                alt=""
                                style={{
                                  height: "20px",
                                  width: "20px",
                                }}
                                src={`data:image/svg+xml;utf8,${encodeURIComponent(
                                  countryList?.find(
                                    (item) => item.code === fullItem.code
                                  )?.flag
                                )}`}
                              />
                            </span>

                            {fullItem.country}
                          </div>
                        )}
                        value={countryList?.find(
                          (item) => item.code === supportForm.values["country"]
                        )}
                        setValue={(value) => {
                          supportForm
                            .getInputProps("country")
                            .setValue(value?.code);
                        }}
                      />
                    </div>

                    <div className={Style.language}>
                      <div className={Style.label}>Preferred Language</div>
                      <Select
                        items={items}
                        value={selectedLanguage}
                        placeholder="Select a language"
                        setValue={(value) => {
                          setSelectedLanguage(value);
                          supportForm
                            .getInputProps("language")
                            .setValue(value.value);
                        }}
                        className={Style.languageSelect}
                        classNamePlaceholder={Style.placeholder}
                      />
                    </div>

                    <div>
                      <div className={Style.label}>Phone number</div>
                      <PhoneInput
                        placeholder="Enter your full number"
                        value={supportForm.values["telephoneNumber"]}
                        onChange={(value) => {
                          supportForm
                            .getInputProps("telephoneNumber")
                            .setValue(value);
                        }}
                      />
                    </div>

                    <div className={Style.telegramInput}>
                      <div className={Style.icon}>
                        <SendIcon />
                      </div>
                      <InputField
                        placeholder="Enter your ID"
                        label="Telegram ID"
                        {...supportForm.getInputProps("telegramId")}
                      />
                    </div>
                  </div>

                  <Button
                    disabled={!supportForm.isValid()}
                    className={Style.submitButton}
                    isLoading={supportFormPostApi.isLoading}
                    onClick={() => {
                      supportFormPostApi.executeQuery(supportForm.getApiBody());
                    }}
                  >
                    Submit Request
                  </Button>
                </div>
                <div className={Style.imgWrapper} />
              </div>
            </Card>
          )}
        </>
      ) : null}
    </>
  );
};

export default SupportForm;
