const legendsData = [
  {
    percentage: 25,
    colorTag: "#1BE837",
  },
  {
    percentage: 24,
    colorTag: "#734324",
  },
  {
    percentage: 23,
    colorTag: "#DD579E",
  },
  {
    percentage: 22,
    colorTag: "#8E7809",
  },
  {
    percentage: 21,
    colorTag: "#F2EDD5",
  },
  {
    percentage: 20,
    colorTag: "#C1B57A",
  },
  {
    percentage: 19,
    colorTag: "#789879",
  },
  {
    percentage: 18,
    colorTag: "#89A98A",
  },
  {
    percentage: 17,
    colorTag: "#1AAB9B",
  },
  {
    percentage: 16,
    colorTag: "#577CAC",
  },
  {
    percentage: 15,
    colorTag: "#B7CDBD",
  },
  {
    percentage: 14,
    colorTag: "#2DDDCE",
  },
  {
    percentage: 13,
    colorTag: "#67EE9F",
  },
  {
    percentage: 12,
    colorTag: "#6F4C44",
  },
  {
    percentage: 11,
    colorTag: "#6F4C44",
  },
  {
    percentage: 10,
    colorTag: "#DAFF43",
  },
  {
    percentage: 9,
    colorTag: "#01765B",
  },
  {
    percentage: 8,
    colorTag: "#93ACDF",
  },
  {
    percentage: 7,
    colorTag: "#44761C",
  },
  {
    percentage: 6,
    colorTag: "#905273",
  },
  {
    percentage: 5,
    colorTag: "#6E8CBF",
  },
  {
    percentage: 4,
    colorTag: "#F7FF00",
  },
  {
    percentage: 3,
    colorTag: "#818429",
  },
  {
    percentage: 2,
    colorTag: "#93DEBF",
  },
  {
    percentage: 1,
    colorTag: "#6B94B5",
    rewards: 7000,
  },
];
const getColorTagByPercentage = (percentage) => {
  if (percentage > 0 && percentage < 25) {
    const foundLegend = legendsData?.find(
      (legend) => legend.percentage === parseInt(percentage)
    );
    return foundLegend.colorTag;
  } else return "#515151";
};

export { getColorTagByPercentage, legendsData };
