import React, { useContext, useState, Fragment } from "react";

// Components
import Card from "../../../../../components/advanced/card/Card";
import HalfDoughnutChart from "../../../../../components/advanced/halfDoughnutChart/HalfDoughnutChart";
import SimpleStyledCard from "../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";
import PointsButtonGroup from "../../../../../components/advanced/pointsButtonGroup/PointsButtonGroup";

// Libraries
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";
import { Link } from "react-router-dom";

// Utils
import formatLargeNumber from "../../../../../utils/formatLargeNumber";
import getOrdinal from "../../../../../utils/getOrdinal.js";

// Context
import GeneralInfoContext from "../../../../../contexts/GeneralInfoContext";
import { RoutesContext } from "../../../../../contexts/RoutesContext.jsx";

// Data
import { unitsList } from "../../../../../data/constants.js";

// Styles
import Style from "./Reports.module.css";
import endpoints from "../../../../../data/endpoints.js";
import DateFormatter from "../../../../../components/advanced/dateFormatter/DateFormatter.jsx";
import arrowRight from "../../../../../assets/svg/arrow-right.svg";

const Reports = ({ id }) => {
  // States
  const [selectedValue, setSelectedValue] = useState(unitsList.VP.value);

  // Contexts
  const [generalInfo] = useContext(GeneralInfoContext);
  const { paths } = useContext(RoutesContext);

  // Queries
  const builderReportAPI = useQuery({
    url: `${generalInfo.endpoints.builderReport.USERS}${id}/report/`,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {},
    onError: (error) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  // Quires
  const rewardsPointsAPI = useQuery({
    url: endpoints.dashboard.REWARDS_POINTS(id, selectedValue),
    method: "GET",
    onSuccess: (response) => {
      console.log(response);
    },
    executeImmediately: true,
    clientOptions: {
      timeout: 120000,
    },
  });

  // Functions
  function getLabelByValue(value) {
    for (const key in unitsList) {
      if (unitsList[key].value === value) {
        return unitsList[key].label;
      }
    }
    // If the value is not found, you can return a default value or handle it as needed.
    return "Label not found";
  }

  // Data
  const builderReportData = builderReportAPI?.response?.data;

  const cardsData = [
    {
      title: "Personal Volume",
      value: formatLargeNumber(rewardsPointsAPI?.response?.data?.personalTotal),
      unit: getLabelByValue(selectedValue),
    },
    {
      title: "Team",
      value: formatLargeNumber(rewardsPointsAPI?.response?.data?.teamTotal),
      unit: getLabelByValue(selectedValue),
    },
    {
      title: <>{getOrdinal(1)} Line</>,
      value: formatLargeNumber(
        rewardsPointsAPI?.response?.data?.firstLineTotal
      ),
      fullNumber: rewardsPointsAPI?.response?.data?.firstLineTotal,
      unit: getLabelByValue(selectedValue),
    },

    {
      title: "Strong Leg Volume",
      value: formatLargeNumber(rewardsPointsAPI?.response?.data?.strongLeg),
      unit: getLabelByValue(selectedValue),
    },
    {
      title: "Weak Legs Volume",
      value: formatLargeNumber(rewardsPointsAPI?.response?.data?.weakLeg),
      unit: getLabelByValue(selectedValue),
    },
  ];

  const currentRank = generalInfo?.rankInfo?.find(
    (item) => item.id === builderReportData?.currentRank
  );
  const monthlyCommissionRank = generalInfo?.rankInfo?.find(
    (item) => item.id === builderReportData?.commissionableRank
  );
  return (
    <Card apiHook={builderReportAPI} minHeight={"475px"}>
      <h5 className={Style.titleReport}>Reports</h5>
      <div className={Style.sectionGrid}>
        <div className={Style.currentRank}>
          <SimpleStyledCard className={Style.currentRankCard}>
            <div className={Style.rankInfo}>
              <p className={Style.superSmallTitle}>Current Rank</p>
              <h5>{currentRank?.name}</h5>
              <div className={Style.rankDate}>
                Since <DateFormatter date={builderReportData?.rankUpdatedAt} />
              </div>
            </div>
            <div className={Style.rankImg}>
              <img
                src={currentRank?.iconUrl}
                className={Style.rankImage}
                alt=""
              />
            </div>
          </SimpleStyledCard>
        </div>
        <div className={Style.monthlyRank}>
          <SimpleStyledCard className={Style.monthlyRankCard}>
            <div className={Style.rankInfo}>
              <p className={Style.superSmallTitle}>
                Monthly Commissionable Rank
              </p>
              <h5>{monthlyCommissionRank?.name}</h5>
              <div className={Style.rankDate}>
                Since{" "}
                <DateFormatter
                  date={builderReportData?.commissionableRankSince}
                />
              </div>
            </div>
            <div className={Style.rankImg}>
              <img
                src={currentRank?.iconUrl}
                className={Style.rankImage}
                alt=""
              />
            </div>
          </SimpleStyledCard>
        </div>
        {generalInfo?.rankInfo?.find(
          (item) => item.id === builderReportData?.currentRank + 1
        ) && (
          <SimpleStyledCard
            style={{
              backgroundColor: "var(--bg-select)",
            }}
          >
            <div className={Style.nextRankCard}>
              <div className={Style.rankInfo}>
                <p className={Style.superSmallTitle}> Your Next Rank:</p>
                <h5>
                  {
                    generalInfo?.rankInfo?.find(
                      (item) => item.id === builderReportData?.currentRank + 1
                    )?.name
                  }
                </h5>
                <div className={Style.rankRuleContainer}>
                  <Link className={Style.rankRule} to={paths.main.wiki}>
                    View Rank Rules
                  </Link>
                  <img src={arrowRight} alt="arrow-right" />
                </div>
              </div>
              <div className={Style.memberRankChart}>
                <HalfDoughnutChart
                  showPercentage
                  percentageStart={builderReportData?.nextRankProgress}
                  size={150}
                  showStartAndEnd={false}
                />
              </div>
            </div>
          </SimpleStyledCard>
        )}
      </div>
      <div className={Style.pointsUsers}>
        <Card
          apiHook={rewardsPointsAPI}
          minHeight="350px"
          className={Style.points}
        >
          <div className={Style.pointsHeading}>
            <h5>Your Points</h5>
            <PointsButtonGroup
              initialSelected={selectedValue}
              onSelectedChange={(value) => setSelectedValue(value)}
            />
          </div>
          {cardsData?.map((item, index) => (
            <Fragment key={`point-${index}`}>
              <div className={Style.pointLine}>
                <div>{item.title}:</div>
                <div>
                  <strong>{item.value}</strong> {item.unit}
                </div>
              </div>
              {cardsData?.length - 1 !== index && (
                <div className={Style.itemBorder} />
              )}
            </Fragment>
          ))}
        </Card>

        <Card apiHook={builderReportAPI} className={Style.users}>
          <h5 className={Style.titleUser}>Number of User in the team</h5>
          <div className={Style.userInfo}>
            <div className={Style.userLine}>
              <div className={Style.valueWrapper}>
                <p>New Registration in the Team</p>
                <p className={Style.vValue}>
                  {builderReportData?.newUsers}{" "}
                  <span className={Style.extension}> Users</span>
                </p>
              </div>
              <div className={Style.valueWrapper}>
                <p>Registration with KYC</p>
                <p className={Style.vValue}>
                  {builderReportData?.newKycRegisteredUsers}{" "}
                  <span className={Style.extension}> Users</span>
                </p>
              </div>
            </div>
            <div className={Style.userLine}>
              <div className={Style.valueWrapper}>
                <parent>New Paid User</parent>
                <p className={Style.vValue}>
                  {builderReportData?.newLicensePurchasedUsers}{" "}
                  <span className={Style.extension}> Users</span>
                </p>
              </div>
              <div className={Style.valueWrapper}>
                <p>Package Bought</p>
                <p className={Style.vValue}>
                  {builderReportData?.newPackagesBought}{" "}
                  <span className={Style.extension}> Users</span>
                </p>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </Card>
  );
};

export default Reports;
