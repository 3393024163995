import React, { useContext } from "react";

// Libraries
import { Link } from "react-router-dom";
import { Handle, Position, useReactFlow } from "reactflow";
import useQuery from "@hybris-software/use-query";

// Images
import placeholder from "../../../../../assets/images/placeholder.png";
import bluredPicture from "../../../../../assets/images/bluredImage.png";

// Context
import { RoutesContext } from "../../../../../contexts/RoutesContext";
import { usePrivacyContext } from "../../../../../contexts/PrivacyContext";

// Utils
import generateCardData from "../../../../../utils/generateCardData";

// Data
import endpoints from "../../../../../data/endpoints";

// Styles
import Style from "./MemberCard.module.css";

const MemberCard = ({
  data,
  id,
  triggerNodeExpansion,
  setDownlineFetched,
  deleteNode,
}) => {
  const page = data.page || 1;

  // Hooks
  const { paths } = useContext(RoutesContext);
  const { addNodes, addEdges } = useReactFlow();

  // Contexts
  const { privacy } = usePrivacyContext();

  //Queries
  const queryId = data.isLoadMore ? id.split("-")[1] : id;
  const firstLineAPI = useQuery({
    url: endpoints.community.FIRST_lINE(queryId, page),
    method: "GET",
    executeImmediately: data.isRoot === true,
    onSuccess: (response) => {
      const maxPage = response.data.totalPages;

      if (!data.isLoadMore) {
        setDownlineFetched(id, data);

        response.data.results.forEach((el) => {
          const newNodeId = el.id;

          addNodes(generateCardData(el));

          addEdges({
            id: `ed${newNodeId}->${id}`,
            source: id,
            target: newNodeId,
            type: "step",
            style: { stroke: "#515151" },
            animated: true, //dashed
          });
        });

        if (page < maxPage) {
          const loadMoreId = `loadmore-${id}-${page}`;
          addNodes(
            generateCardData(
              { id: loadMoreId, isLoadMore: true, page: page + 1 },
              false
            )
          );
          addEdges({
            id: `ed${loadMoreId}->${id}`,
            source: id,
            target: loadMoreId,
            type: "step",
            style: { stroke: "#515151" },
            animated: true, //dashed
          });
        }
      } else {
        deleteNode(id, data);

        const parentId = id.split("-")[1];

        response.data.results.forEach((el) => {
          const newNodeId = el.id;

          addNodes(generateCardData(el));

          addEdges({
            id: `ed${newNodeId}->${parentId}`,
            source: parentId,
            target: newNodeId,
            type: "step",
            style: { stroke: "#515151" },
            animated: true, //dashed
          });
        });

        if (page < maxPage) {
          const newLoadMoreId = `loadmore-${parentId}-${page}`;
          addNodes(
            generateCardData(
              { id: newLoadMoreId, isLoadMore: true, page: page + 1 },
              false
            )
          );
          addEdges({
            id: `ed${newLoadMoreId}->${parentId}`,
            source: parentId,
            target: newLoadMoreId,
            type: "step",
            style: { stroke: "#515151" },
            animated: true, //dashed
          });
        }
      }
    },
    clientOptions: {
      timeout: 120000,
    },
  });

  // Functions
  const loadMore = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    firstLineAPI.executeQuery();
  };

  const handleClick = () => {
    if (firstLineAPI.isLoading) {
      return;
    }

    if (!data.isRoot && !firstLineAPI.isSuccess && !data.downlineFetched) {
      firstLineAPI.executeQuery();
    } else {
      triggerNodeExpansion(id, data);
    }
  };

  if (!data.isLoadMore) {
    return (
      <div className={Style.cardWrapper}>
        <Handle type="target" position={Position.Top} />
        {data.isRoot && <div className={Style.cardFrame}></div>}
        <div className={Style.cardBody}>
          <div
            className={Style.avatar}
            style={{
              backgroundImage: !privacy
                ? `url(${
                    data.profilePicture ? data.profilePicture : placeholder
                  })`
                : `url(${bluredPicture})`,
            }}
          ></div>
          <div className={Style.cardInfo}>
            <span className={Style.usernameStyle}>@{data.username}</span>
            <span className={Style.rankStyle}>
              {privacy ? "***" : parseInt(data.personalVp)} VP
            </span>
            {data.detailsAvailable && (
              <Link
                to={paths.main.builderReport.replace(":id", data.id)}
                className={Style.userPerformanceLink}
              >
                View Builder Report
              </Link>
            )}
          </div>
        </div>

        <div
          className={Style.handleButton}
          onClick={handleClick}
          style={{
            visibility:
              data.expandable || data.downlineUsersCount > 0
                ? "visible"
                : "hidden",
          }}
        >
          <Handle
            type="source"
            isConnectable={false}
            position={Position.Bottom}
            className={
              firstLineAPI.isLoading
                ? Style.rotating
                : !data.expanded
                ? Style.customHandleExpand
                : Style.customHandleCollapsed
            }
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className={Style.cardWrapper}>
        <Handle
          type="target"
          position={Position.Top}
          className={Style.emptyHandle}
        />
        <div className={Style.cardBodyLoadMore} onClick={loadMore}>
          <span className={Style.usernameStyle}>
            {firstLineAPI.isLoading ? "Loading ..." : "Load More "}
          </span>
        </div>

        <Handle
          type="source"
          isConnectable={false}
          position={Position.Bottom}
          className={Style.emptyHandle}
        />
      </div>
    );
  }
};

export default MemberCard;
