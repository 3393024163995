import React from "react";

const DashIconsWhatsapp = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="dashicons:whatsapp">
        <path
          id="Vector"
          d="M28.6149 11.2929C24.8815 5.52129 17.2591 3.80542 11.3479 7.39316C5.59221 10.9809 3.72551 18.7803 7.45892 24.5519L7.77004 25.0199L6.52557 29.6995L11.1923 28.4516L11.659 28.7636C13.6813 29.8555 15.8591 30.4795 18.0369 30.4795C20.3703 30.4795 22.7037 29.8555 24.7259 28.6076C30.4816 24.8639 32.1927 17.2204 28.6149 11.2929ZM25.3482 23.304C24.7259 24.2399 23.9481 24.8639 22.8592 25.0199C22.237 25.0199 21.4592 25.3318 18.348 24.0839C15.7035 22.836 13.5257 20.8082 11.9701 18.4683C11.0368 17.3764 10.5701 15.9725 10.4145 14.5686C10.4145 13.3207 10.8812 12.2288 11.659 11.4489C11.9701 11.1369 12.2812 10.9809 12.5924 10.9809H13.3701C13.6813 10.9809 13.9924 10.9809 14.1479 11.6048C14.4591 12.3848 15.2369 14.2566 15.2369 14.4126C15.3924 14.5686 15.3924 14.8806 15.2369 15.0366C15.3924 15.3486 15.2369 15.6605 15.0813 15.8165C14.9257 15.9725 14.7702 16.2845 14.6146 16.4405C14.3035 16.5965 14.1479 16.9085 14.3035 17.2204C14.9257 18.1564 15.7035 19.0923 16.4813 19.8722C17.4147 20.6522 18.348 21.2761 19.4369 21.7441C19.7481 21.9001 20.0592 21.9001 20.2147 21.5881C20.3703 21.2761 21.1481 20.4962 21.4592 20.1842C21.7703 19.8722 21.9259 19.8722 22.237 20.0282L24.7259 21.2761C25.037 21.4321 25.3482 21.5881 25.5037 21.7441C25.6593 22.2121 25.6593 22.836 25.3482 23.304Z"
          fill="url(#paint0_linear_183_598)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_183_598"
          x1="18.0024"
          y1="5.52148"
          x2="18.0024"
          y2="30.4795"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4FC2AB" />
          <stop offset="1" stopColor="#00FFCC" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DashIconsWhatsapp;
