import React, { useRef } from "react";

// Components
import SimpleStyledCard from "../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";
import DateFormatter from "../../../../../components/advanced/dateFormatter/DateFormatter";

// Libraries
import Table from "@hybris-software/use-table";

// Data
import endpoints from "../../../../../data/endpoints";
import { customTableStyles } from "../../../../../data/constants";


// Styles
import Style from "./DeptWalletsTable.module.css";
import { formatNumberEuropean } from "../../../../../utils/utilityFunctions";

const DeptWalletsTable = () => {
  // Variables
  const ref = useRef(null);
  const endPoint = endpoints.wallets.DEBT_WALLETS;

  const columns = [
    {
      Header: "#",
      field: "id",
      orderField: "id",
      noAction: true,
      sortable: false,
      accessor: (row) => {
        return row.id;
      },
    },
    {
      Header: "Total Loan",
      field: "amount",
      orderField: "amount",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return formatNumberEuropean(row.amount, 2);
      },
    },
    {
      Header: "Remaining Loan",
      field: "balance",
      orderField: "balance",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return formatNumberEuropean(row.balance, 2);
      },
    },
    {
      Header: "Date",
      field: "createdAt",
      orderField: "createdAt",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return <DateFormatter date={row.createdAt} />;
      },
    },
  ];

  return (
    <>
      <SimpleStyledCard className={Style.DeptWalletsTable}>
        <Table
          ref={ref}
          height="410"
          enableAllowedActions={false}
          columns={columns}
          endPoint={endPoint}
          enableSelectableRows={false}
          enableSearch={false}
          enableSearchFieldSelect={false}
          enablePageSizeSelect={true}
          defaultPageSize={50}
          Styles={customTableStyles}
          searchBadgesClassName={Style.searchBadges}
          paginationButtonClassName={Style.paginationButton}
          paginationRecordsClassName={Style.paginationRecords}
          toPageInputBaseClassName={Style.toPageInput}
          enableSettings={false}
          enableRefreshBtn={false}
          enableHiddenColumnBadge={false}
        />
      </SimpleStyledCard>
    </>
  );
};

export default DeptWalletsTable;
