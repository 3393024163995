import React from "react";

// Images
import maintenance from "../../assets/images/maintenance.png";

// Libraries
import useQuery from "@hybris-software/use-query";
import { useNavigate } from "react-router";

// Data
import { paths } from "../../data/routes";
import endpoints from "../../data/endpoints";

// Styles
import Style from "./MaintenanceView.module.css";

const MaintenanceView = () => {
  // Consts
  const navigate = useNavigate();

  // Queries
  useQuery({
    url: endpoints.PLATFORM_CONFIG,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      if (response?.data?.maintainanceMode === false) {
        navigate(paths.main.dashboard);
      }
    },
    clientOptions: {
      timeout: 120000,
    },
  });

  return (
    <div className={Style.center}>
      <div className={Style.bg} />
      <img src={maintenance} alt="" />

      <div className={Style.info}>
        We are under <span> “maintenance mode”.</span> Thank You for cooperating
        with us!
      </div>
    </div>
  );
};

export default MaintenanceView;
