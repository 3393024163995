import React from "react";

// Data
import config from "../../../data/config";

// Styles
import Style from "./Loader.module.css";

const Loader = ({ children, loading = true, fullscreen = false }) => {
  return (
    <div className={fullscreen ? Style.loaderFullscreen : Style.loader}>
      <div className={Style.defaultLoaderImage}>
        {loading ? (
          <div className={Style.loaderBox}>
            <img
              src={config.LOGO_SMALL}
              className={Style.rotate}
              alt="loader"
              draggable="false"
            />
            <img
              src={config.LOGO_WORDS}
              className={Style.fixed}
              alt="loader"
              draggable="false"
            />
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default Loader;
