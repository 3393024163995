import React from "react";

// Components
import Card from "../../../../components/advanced/card/Card";
import ProgressBar from "../../../../components/advanced/progressBar/ProgressBar";

// Libraries
import useQuery from "@hybris-software/use-query";

// Data
import endpoints from "../../../../data/endpoints";

// Utilities
import formatLargeNumber from "../../../../utils/formatLargeNumber";

// Images
import HouseBonusVilla from "../../../../assets/images/houseBonusVilla.png";
import CP from "../../../../assets/svg/CP.svg";

// Styles
import Style from "./BonusDetails.module.css";

const BonusDetails = () => {

  // Variables
  const summaryData = {
    teamCp: {
      title: "Total Turnover with 50% Rule",
      unit: "CP",
      tooltipDesc: null,
    },
    targetCp: {
      title: "Target",
      tooltipDesc: null,
      unit: "CP",
    },
    TeamTurnover: {
      title: "Team Turnover with 50% Rule",
      tooltipDesc: null,
      unit: null,
    },
  };
  // Queries
  const houseBonusAPI = useQuery({
    url: endpoints.houseBonus.REPORT,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {},
    onError: (error) => {},
    clientOptions: {
      timeout: 120000,
    },
  });
  return (
    <Card
      apiHook={houseBonusAPI}
      minHeight={'315px'}
    >
     <div className={Style.generalRankCard}>
      <div className={Style.currentRankCard}>
        <div className={Style.rankImg}>
          <img src={HouseBonusVilla}  alt="" />
        </div>
      </div>
      <div>
        <div className={Style.title}>House Bonus</div>
        <div className={Style.subTitle}>
        Unlock the door to opulence with a lavish house in the vibrant cityscape of Dubai, UAE
        </div>
        <div className={Style.infoBoxes}>
          {Object.keys(summaryData).map((key) => (
            <SummaryCard
              data={summaryData[key]}
              cardKey={key}
              APIdata={houseBonusAPI?.response?.data}
              key={key}
            />
          ))}
      </div>
      </div>
      </div>
    </Card>
  );
};
const SummaryCard = ({ data, cardKey, APIdata }) => {
  const progressPercentage = APIdata?.teamCp >= APIdata?.targetCp
  ? 100
  : ((APIdata?.teamCp / APIdata?.targetCp) * 100).toFixed(2);
  return (
    <div className={Style.infoBoxCard}>
      <div className={Style.cardTitle}>
        {data?.title}
      </div>
      <div className={Style.content}>
        {!data?.unit ? (
          <>
            <span className={Style.value}>
              {((APIdata?.teamCp/ APIdata?.targetCp) * 100).toFixed(2)}%
            </span>
            <ProgressBar
              done={progressPercentage}
              startText={0}
              image={CP}
              endText={formatLargeNumber(APIdata?.targetCp)}
            />
          </>
        ) : (
          <>
            <img src={CP} alt="" />
            <div className={Style.value}>
              {APIdata[cardKey] || APIdata[cardKey] === 0
                ? (data?.specialChar ? data?.specialChar : "") +
                  formatLargeNumber(APIdata[cardKey])
                : "-"}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default BonusDetails;
