import React from "react";

// Libraries
import { Button } from "@hybris-software/ui-kit";
import { useNavigate } from "react-router-dom";

// Image
import gradientHeaderV2AboveBG from "../../../assets/images/gradientHeaderV2AboveBG.png";
import gradientHeaderV2AboveRightBG from "../../../assets/images/gradientHeaderV2AboveRightBG.png";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Styles
import Style from "./GradientHeaderV2.module.css";

const GradientHeaderV2 = ({
  title = "Want to Know How To Unlock Levels In the Unilevel Bonus?",
  subtitle = "Discover the mechanics of unilevel Bonus to get rewards throughout multiple levels",
  linkText = "Visit our wiki for more details",
  link = "",
}) => {
  const { paths } = React.useContext(RoutesContext);

  return (
    <div className={Style.gradientHeader}>
      <div
        className={Style.above}
        style={{
          background: `url(${gradientHeaderV2AboveRightBG}) bottom right/ contain no-repeat, url(${gradientHeaderV2AboveBG}) center/ cover no-repeat`,
        }}
      >
        <GradientHeaderV2Content
          title={title}
          subtitle={subtitle}
          linkText={linkText}
          link={paths.main.wiki}
        />
      </div>
    </div>
  );
};

const GradientHeaderV2Content = ({ title, subtitle, linkText, link }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={Style.title}>{title}</div>
      <div className={Style.subtitle}>{subtitle}</div>
      <div>
        <Button
          buttonClassName={Style.action}
          onClick={() => {
            navigate(link);
          }}
        >
          {linkText}
        </Button>
      </div>
    </>
  );
};

export default GradientHeaderV2;
