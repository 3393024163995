import React from "react";

// Components
import BonusCommission from "./components/bonusCommission/BonusCommission";
import InfinityBonusCommission from "./components/infinityBonusCommission/InfinityBonusCommission";
import XgiWallet from "./components/xgiWallet/XgiWallet";
import DebtWallet from "./components/debtWallet/DebtWallet";

// Libraries
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";

// Data
import endpoints from "../../../../../data/endpoints";

// Styles
import Style from "./Wallets.module.css";

const Wallets = () => {

    // Queries
    const walletReportAPI = useQuery({
      url: endpoints.wallets.COMMISSION_REPORT,
      method: "GET",
      executeImmediately: true,
      clientOptions: {
        timeout: 120000,
      },
    });
  
  return (
    <>
      <div className={Style.walletsContainer}>
        <div className={Style.walletItem}>
          <BonusCommission walletReportAPI={walletReportAPI}  />
        </div>
        <div className={Style.walletItem}>
          <InfinityBonusCommission walletReportAPI={walletReportAPI} />
        </div>
        <div className={Style.walletItem}>
          <XgiWallet walletReportAPI={walletReportAPI} />
        </div>
        <div className={Style.walletItem}>
          <DebtWallet walletReportAPI={walletReportAPI} />
        </div>
      </div>
    </>
  );
};

export default Wallets;
