import React, { useRef, useContext, useState, useEffect } from "react";

// Components
import SimpleStyledCard from "../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";

import DateFormatter from "../../../../../components/advanced/dateFormatter/DateFormatter";

// Libraries
import { TableClient } from "@hybris-software/use-table";
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";

// Context
import GeneralInfoContext from "../../../../../contexts/GeneralInfoContext";

// Data
import endpoints from "../../../../../data/endpoints";
import { customTableStyles } from "../../../../../data/constants";

// Styles
import Style from "./RankAnalytics.module.css";
import Card from "../../../../../components/advanced/card/Card";


const rankType = {
  commissionableRank: {
    label: "Commissionable Ranks",
    value: "commissionable_rank ",
  },
  rank: {
    label: "Career Ranks",
    value: "rank",
  },
};

const RankAnalytics = () => {
  const [generalInfo] = useContext(GeneralInfoContext);

  const [rankFilter, setRankFilter] = useState(rankType?.rank?.value);

  const columns = [

    {
      Header: "Rank",
      field: "name",
      orderField: "name",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return generalInfo?.rankInfo?.find((item) => item.id === row.id)?.name;
      },
    },
    {
      Header: "Rank #",
      field: "name",
      orderField: "name",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return row.id;
      },
    },
    {
      Header: "Date",
      field: "date",
      orderField: "date",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return <DateFormatter date={row.date} />;
      },
    },
  ];

  const ref = useRef(null);

  // Queries
  const getRankListAPI = useQuery({
    url: endpoints.rankOverView.RANK_ANALYTICS + "?type=" + rankFilter,
    method: "GET",
    onSuccess: (response) => {},
    onError: (error) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  useEffect(() => {
    getRankListAPI?.executeQuery();
    // eslint-disable-next-line
  }, [rankFilter]);

  return (
    <SimpleStyledCard style={{ overflow: "unset" }}>
      <div className={Style.titleContainer}>
        <h5 className={Style.analyticsTitle}>Rank Analytics</h5>
        <div className={Style.actions}>
          <div
            className={
              rankFilter === rankType?.rank?.value
                ? Style.buttonSelected
                : Style.button
            }
            onClick={() => setRankFilter(rankType?.rank?.value)}
          >
            {rankType?.rank?.label}
          </div>

          <div
            className={
              rankFilter === rankType?.commissionableRank?.value
                ? Style.buttonSelected
                : Style.button
            }
            onClick={() => setRankFilter(rankType?.commissionableRank?.value)}
          >
            {rankType?.commissionableRank?.label}
          </div>
        </div>
      </div>
      <Card
        className={Style.tableCard}
        apiHook={getRankListAPI}
        minHeight={"635px"}
        style={{ overflow: "unset" }}
      >
        {getRankListAPI.isSuccess && (
          <TableClient
            height={610}
            defaultPageSize={10}
            pageSizes={[1, 2, 3, 5, 10, 100]}
            enableAllowedActions={false}
            ref={ref}
            columns={[...columns]}
            rawData={[...getRankListAPI?.response?.data]}
            Styles={customTableStyles}
            searchBadgesClassName={Style.searchBadges}
            paginationClassName={Style.pagination}
            enablePageSizeSelect={false}
            enableSearch={false}
            enableSearchFieldSelect={false}
            enableSelectableRows={false}
            enableSettings={false}
            enableRowsSelectedBadge={false}
            enableSearchBadges={false}
          />
        )}
      </Card>
    </SimpleStyledCard>
  );
};

export default RankAnalytics;
