import { useMemo } from "react";
import { stratify, tree } from "d3-hierarchy";

function isHierarchyPointNode(pointNode) {
  return typeof pointNode.x === "number" && typeof pointNode.y === "number";
}

function useExpandCollapse(
  nodes,
  edges,
  { layoutNodes = true, treeWidth = 220, treeHeight = 100 } = {}
  // #TODO : add dynamic size for first line
) {
  return useMemo(() => {
    const hierarchy = stratify()
      .id((d) => d.id)
      .parentId((d) => edges.find((e) => e.target === d.id)?.source)(nodes);

    hierarchy.descendants().forEach((d) => {
      d.data.data.expandable = !!d.children?.length;
      d.children = d.data.data.expanded ? d.children : undefined;
    });

    const layout = tree()
      .nodeSize([treeWidth, treeHeight])
      .separation(() => 1);

    const root = layoutNodes ? layout(hierarchy) : hierarchy;

    return {
      nodes: root.descendants().map((node, index, nodesArray) => {
        const depth = node.depth;

        let isFirstInLayer = false;
        if (index > 0) {
          const previousNode = nodesArray[index - 1];
          isFirstInLayer = previousNode.depth !== depth;
        }

        return {
          ...node.data,
          data: { ...node.data.data, isFirstInLayer, depth },
          type: "custom",
          position: isHierarchyPointNode(node)
            ? { x: node.x, y: node.y }
            : node.data.position,
        };
      }),
      edges: edges.filter(
        (edge) =>
          root.find((h) => h.id === edge.source) &&
          root.find((h) => h.id === edge.target)
      ),
    };
  }, [nodes, edges, layoutNodes, treeWidth, treeHeight]);
}

export default useExpandCollapse;
