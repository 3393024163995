import React, { useContext } from "react";

// Components
import Loader from "../../../components/ui/loader/Loader";

// Libraries
import useQuery from "@hybris-software/use-query";
import { Button } from "@hybris-software/ui-kit";
import { AuthRoute } from "@hybris-software/use-auth";
import { useNavigate } from "react-router-dom";

// Data
import config from "../../../data/config";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Data
import endpoints from "../../../data/endpoints";

// Utils
import { addSlashIfNeeded } from "../../../utils/utilityFunctions";

// Styles
import Style from "./LoginView.module.css";

const LoginView = () => {
  // Hooks
  const navigate = useNavigate();

  // Contexts
  const { paths } = useContext(RoutesContext);

  // Functions
  function navigateWithRedirect() {
    const params = new URLSearchParams({
      redirect_uri: config.REDIRECT_URI,
      scope: config.SCOPE,
      response_type: config.RESPONSE_TYPE,
      client_id: config.CLIENT_ID,
    });
    const websiteUrl = `${addSlashIfNeeded(
      config.BLOCKCHAINID_BASE_URL
    )}sso?${params.toString()}`;
    window.location.href = websiteUrl;
  }
  const platformConfigAPI = useQuery({
    url: endpoints.PLATFORM_CONFIG,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      if (response?.data?.maintainanceMode) {
        navigate(paths.generic.maintenance);
      }
    },
    clientOptions: {
      timeout: 120000,
    },
  });

  return (
    <AuthRoute
      loader={<Loader fullscreen={true} />}
      minimumLoadingTime={0}
      firstApiLoading={platformConfigAPI?.isLoading}
      forLoggedUser={false}
      action={() => {
        navigate(paths.main.firstStep);
      }}
    >
      <div className={Style.center}>
        <div className={Style.content}>
          <h6>Welcome to</h6>
          <img rel="preload" as="image" src={config.LOGO_WHITE} alt=""  />
          <h4>Sign in with SSO/SAML</h4>
          <p>
            This workspace allows you to sign in with your {config.WEBSITE_2ACCESS} account
          </p>
          <Button
            className={Style.button}
            isLoading={false}
            disable={false}
            onClick={() => {
              navigateWithRedirect();
            }}
          >
            <div className={Style.box}>
              <img rel="preload" as="image" src={config.LOGO_BID_WHITE} alt="" />
            </div>
            <div className={Style.label}>Sign in with {config.WEBSITE_2ACCESS}</div>
          </Button>
          <div
            className={Style.sign}
            onClick={() => {
              window.open(
                `${addSlashIfNeeded(
                  config.BLOCKCHAINID_BASE_URL
                )}auth/register `,
                "_blank"
              );
            }}
          >
            Don't have an account yet?
            <span> Sign up here</span>
          </div>
          <div
            className={Style.sign}
            onClick={() => {
              window.open(
                config?.LEGAL_TERMS_URL,
                "_blank",
                "noopener noreferrer"
              );
            }}
          >
            <span>Legal Terms</span>
          </div>
        </div>
      </div>
    </AuthRoute>
  );
};

export default LoginView;
