import React, { useContext } from "react";

// Libraries
import { Link } from "react-router-dom";

// Context
import { RoutesContext } from "../../../../../../../contexts/RoutesContext";

// Image
import avatarBestPerformant from "../../../../../../../assets/images/placeholder.png";

// Styles
import Style from "./BestPerformanceItem.module.css";

const BestPerformanceItem = ({ item }) => {
  const { paths } = useContext(RoutesContext);

  return (
    <div className={Style.bestPerformersItem}>
      <div className={Style.userInfo}>
        <img
          src={item?.image ?? avatarBestPerformant}
          alt="user"
          width={48}
          height={48}
        />
        <div>
          <div className={Style.userName}>@{item?.username}</div>
          <div className={Style.userId}>{item?.id}</div>
        </div>
      </div>
      <div className={Style.userPerformanceValue}>{item?.value}</div>
      {item.detailsAvailable && (
        <div className={Style.userPerformanceAction}>
          <Link
            to={paths.main.builderReport.replace(":id", item.id)}
            className={Style.userPerformanceLink}
          >
            View Builder Report
          </Link>
        </div>
      )}
    </div>
  );
};

export default BestPerformanceItem;
