import React, { useTransition, useContext } from "react";


// Components
import CopyReferralCodeBtn from "../../../../../components/advanced/copyReferralCodeBtn/CopyReferralCodeBtn";

// Libraries
import { Link } from "react-router-dom";
import { Button } from "@hybris-software/ui-kit";

// Contexts
import { usePrivacyContext } from "../../../../../contexts/PrivacyContext";
import { RoutesContext } from "../../../../../contexts/RoutesContext";

// Icons
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { BiLoaderCircle } from "react-icons/bi";

// Styles
import Style from "./Header.module.css";

const Header = () => {
  // Hooks
  const [isPending, startTransition] = useTransition();

  // Contexts
  const { privacy, setPrivacy } = usePrivacyContext();
  const { paths } = useContext(RoutesContext);


  function handlePrivacyChange(state) {
    startTransition(() => {
      setPrivacy(state);
    });
  }

  return (
    <div className={Style.header}>
      <div className={Style.title}>
        <h4>Community</h4>
        <span>
          Privacy
          {!privacy ? (
            <FaRegEye onClick={() => handlePrivacyChange(true)} />
          ) : (
            <FaRegEyeSlash onClick={() => handlePrivacyChange(false)} />
          )}
          {isPending && (
            <div className={Style.rotating}>
              <BiLoaderCircle />
            </div>
          )}
        </span>
      </div>
      <div className={Style.copyBtn}>
        <Link to={paths?.main.growthAnalytics}>
         <Button className={Style.leftButton}>Growth Analytics</Button>
        </Link>
        <CopyReferralCodeBtn/>
      </div>
    </div>
  );
};

export default Header;
