const getOrdinal = (pos) => {
  if (pos) {
    var numberStr = pos.toString();

    var specialCases = {
      1: "st",
      2: "nd",
      3: "rd",
    };

    var suffix;

    if (numberStr.length > 1 && numberStr[numberStr.length - 2] === "1") {
      suffix = "th";
    } else {
      var lastDigit = numberStr[numberStr.length - 1];
      suffix = specialCases[lastDigit] || "th";
    }
  }

  return (
    <>
      {pos}
      <sup>{suffix}</sup>
    </>
  );
};
export default getOrdinal;