import React, { useContext, useRef, useState } from "react";

// Components
import Card from "../../../../../components/advanced/card/Card";
import SimpleStyledCard from "./../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";

// Libraries
import useQuery from "@hybris-software/use-query";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

// Data
import endpoints from "./../../../../../data/endpoints";

// Icons
import { PiArrowLeft } from "react-icons/pi";

// Contexts
import GeneralInfoContext from "../../../../../contexts/GeneralInfoContext";

// Styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Style from "./PercentageRank.module.css";

const PercentageRank = () => {
  // Contexts
  const [generalInfo] = useContext(GeneralInfoContext);

  // Refs
  const swiperRef = useRef();

  // States
  const [slideNavigation, setSlideNavigation] = useState({
    hasPrevSlide: false,
    hasNextSlide: true,
  });

  // Queries
  const communityAPI = useQuery({
    url: endpoints.activeQualifiedInfinityBonus.RANK,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  return (
    <Card apiHook={communityAPI} minHeight="300px" className={Style.treeCard}>
      <div className={Style.header}>
        <h5>% Rankings</h5>
        <p>Percentage reward received for each rank</p>
      </div>

      <div className={Style.titleWrapper}>
        <div />
        <div className={Style.pagination}>
          <div
            style={{
              opacity: slideNavigation.hasPrevSlide ? 1 : 0.5,
              cursor: !slideNavigation.hasPrevSlide && "not-allowed",
            }}
            className={Style.prev}
            onClick={() => {
              swiperRef.current?.slidePrev();
              setSlideNavigation(() => ({
                hasPrevSlide: swiperRef.current?.isBeginning !== true,
                hasNextSlide: true,
              }));
            }}
          >
            <PiArrowLeft />
          </div>

          <div
            style={{
              opacity: slideNavigation.hasNextSlide ? 1 : 0.5,
              cursor: !slideNavigation.hasNextSlide && "not-allowed",
            }}
            className={Style.next}
            onClick={() => {
              swiperRef.current?.slideNext();
              setSlideNavigation(() => ({
                hasPrevSlide: true,
                hasNextSlide: swiperRef.current?.isEnd !== true,
              }));
            }}
          >
            <PiArrowLeft />
          </div>
        </div>
      </div>
      <Swiper
        slidesPerView={4}
        breakpoints={{
          1200: { slidesPerView: 4 },
          767: { slidesPerView: 3 },
          575: { slidesPerView: 2 },
          0: { slidesPerView: 1 },
        }}
        spaceBetween={20}
        modules={[Pagination]}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {communityAPI?.response?.data?.map((item, index) => (
          <SwiperSlide key={index}>
            <SliderItem item={item} generalInfo={generalInfo} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Card>
  );
};

const SliderItem = ({ item, generalInfo }) => {
  const currentRank = generalInfo?.rankInfo?.filter(
    (rank) => rank?.id === item?.rank
  )?.[0];

  return (
    <SimpleStyledCard className={Style.legendCard}>
      <h6 style={{ marginBottom: 6 }}>{currentRank?.name}</h6>
      <p className={Style.legendText}> Percentage: {item.bonusPercentage}%</p>
      <img src={currentRank?.iconUrl} alt="" />
    </SimpleStyledCard>
  );
};

export default PercentageRank;
