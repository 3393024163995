import React from "react";

// Styles
import Style from "./LoaderElement.module.css";

const LoaderElement = () => {
  return <div className={Style.spinner}></div>;
};

export default LoaderElement;
