import React from "react";

// Libraries
import { Button } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";
import { useOutletContext } from "react-router-dom";

// Data
import endpoints from "../../../../../data/endpoints";

// Style
import Style from "./WatchVideo.module.css";

const WatchVideo = ({
  title,
  firstSubtitle,
  secondSubtitle,
  firstStepAPI,
  type,
  step,
  onPrevious,
}) => {
  // Context
  const { generalErrorsResolver } = useOutletContext();

  const watchVideoAPI = useQuery({
    url: endpoints.FIRST_STEP_UPDATE,
    method: "PATCH",
    executeImmediately: false,
    onSuccess: (response) => {
      firstStepAPI.executeQuery();
    },
    onError: (error) => {
      generalErrorsResolver(error);
    },
  });

  return (
    <div className={Style.animation}>
      <div className={Style.childContainer}>
        <div className={Style.grid}>
          <div>
            <h5 className={Style.title}>{title}</h5>
            <div className={Style.subTitle}>
              <p>{firstSubtitle}</p>
              <p>{secondSubtitle}</p>
            </div>
          </div>
          <iframe
            title="YouTube video player"
            src="https://www.youtube.com/embed/TtuuCWF6_sY?start=1"
            width="100%"
            height="240"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
        </div>

        <div className={Style.buttonStyle}>
          {onPrevious && (
            <Button className={Style.leftButton} onClick={onPrevious}>
              Previous Step
            </Button>
          )}
          {!firstStepAPI?.response?.data[step] && (
            <Button
              className={Style.rightButton}
              onClick={() => watchVideoAPI.executeQuery({ type: type })}
              isLoading={watchVideoAPI.isLoading}
            >
              Done
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default WatchVideo;
