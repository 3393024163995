import React from "react";

// Components
import SimpleStyledCard from "../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";
import CountdownCircle from "../../../../../components/advanced/countdownCircle/CountdownCircle";

// Libraries
import moment from "moment";

// Icons
import VP from "../../../../../assets/svg/VP.svg";
import CP from "../../../../../assets/svg/CP.svg";

// Styles
import Style from "./StatisticsUnilevel.module.css";

const StatisticsUnilevel = ({ response }) => {
  return (
    <div className={Style.grid}>
      <ActivationWindow />
      <div className={Style.cards}>
        <StatisticsCard response={response} />
      </div>
    </div>
  );
};

const ActivationWindow = () => {
  const firstDay = moment().add(1, "months").startOf("month");
  const deadline = moment(firstDay).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  const isDecember2023 = moment().month() === 11 && moment().year() === 2023;
  const addedDays = moment().startOf("month").add(3, "days");
  const deadlineDecember2023 = moment(addedDays).format(
    "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
  );

  return (
    <SimpleStyledCard className={Style.activation}>
      <div className={Style.shape} />
      <div className={Style.head}>Time till next cycle</div>

      <div className={Style.daysLeft}>
        <CountdownCircle
          marginInline="auto"
          deadline={isDecember2023 ? deadlineDecember2023 : deadline}
        />
      </div>
    </SimpleStyledCard>
  );
};

const StatisticsCard = ({ response }) => {
  // Functions
  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", {
      notation: "compact",
      compactDisplay: "short",
    }).format(number);
  };

  // Data
  const data = [
    {
      label: "VP First Line (Current Month)",
      amount: formatNumber(response?.firstLineVolume),
      fullNumber: response?.firstLineVolume,
      unit: "VP",
    },
    {
      label: "VP Structure (Current Month 50% Rule)",
      amount: formatNumber(response?.totalVolume),
      fullNumber: response?.totalVolume,
      unit: "VP",
    },
    {
      label: "% Infinity Bonus",
      amount: formatNumber(response?.bonusPercentage),
      fullNumber: response?.bonusPercentage,
      unit: "%",
    },
    {
      label: "Status",
      amount: response?.active ? "Active" : "Not Active",
      fullNumber: response?.active ? "Active" : "Not Active",
      unit: "",
    },
  ];

  return data.map((item, index) => {
    return (
      <SimpleStyledCard className={Style.addBackground} key={index}>
        <div className={Style.label}>{item.label}</div>
        <div className={Style.bodyWrapper}>
          {item.unit === "VP" ? (
            <img src={VP} alt="" />
          ) : item.unit === "CP" ? (
            <img src={CP} alt="" />
          ) : (
            ""
          )}
          <div
            className={Style.amount}
            onTouchStart={(e) => {
              e.currentTarget.classList.toggle(Style.showFullNumber);
            }}
          >
            {item.amount}
            <span>{item.unit}</span>
            <span className={Style.toolTipFullNumber}>
              {item.fullNumber} {item.unit}
            </span>
          </div>
        </div>
      </SimpleStyledCard>
    );
  });
};

export default StatisticsUnilevel;
