import React, { useRef, useState, useEffect, useContext } from "react";

// Components
import SimpleStyledCard from "../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";
import RangeDatePicker from "../../../../../components/advanced/rangeDatePicker/RangeDatePicker";
import StatusResponse from "../../../../../components/advanced/statusResponse/StatusResponse";
import ExportModal from "../../../../../components/advanced/exportModal/ExportModal";

// Libraries
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";
import { Button } from "@hybris-software/ui-kit";
import { useOutletContext } from "react-router";
import Table from "@hybris-software/use-table";

// Utilities
import { formatNumberEuropean } from "../../../../../utils/utilityFunctions";

// Data
import endpoints from "../../../../../data/endpoints";
import GeneralInfoContext from "../../../../../contexts/GeneralInfoContext";
import { customTableStyles } from "../../../../../data/constants";

// Icon
import successIcon from "../../../../../assets/icons/response-Icons/success.png";
import DownloadIcon from "../../../../../assets/svgComponents/DownloadIcon";
import IP from "../../../../../assets/svg/IP.svg";

// Styles
import Style from "./BonusHistory.module.css";
import Theme from "../../../../../assets/css/theme.module.css";

const BonusHistory = () => {
  // Variables
  const initialFilters = {
    date: {
      gteDatePickerValue: null,
      label: null,
      lteDatePickerValue: null,
    },
  };

  const columns = [
    {
      Header: "Purchaser's Monthly Commissionable Rank",
      field: "purchasedUserCommissionable",
      orderField: "purchased_User_Commissionable",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        const rankInfo = generalInfo?.rankInfo.find(
          (rank) => rank.id === row.purchasedUserCommissionable
        );
        return (
          <div className={Style.rankColContainer}>
            <img className={Style.rankInfoImg} src={rankInfo?.iconUrl} alt="" />
            <span>&nbsp;{rankInfo.name}</span>
          </div>
        );
      },
    },
    {
      Header: "Member's Monthly Comissionable Rank",
      field: "currentUserCommissionable",
      orderField: "current_User_Commissionable",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        const rankInfo = generalInfo?.rankInfo.find(
          (rank) => rank.id === row.currentUserCommissionable
        );
        return (
          <div className={Style.rankColContainer}>
            <img className={Style.rankInfoImg} src={rankInfo?.iconUrl} alt="" />
            <span>&nbsp;{rankInfo.name}</span>
          </div>
        );
      },
    },
    {
      Header: "Purchase IP",
      field: "purchaseIp",
      orderField: "purchase_ip",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return (
          <div className={Style.rankColContainer}>
            <img className={Style.ipImg} src={IP} alt="" />
            <span>
              &nbsp;{formatNumberEuropean(row.purchaseIp)}&nbsp;
              <span style={{ color: "#9A9A9A" }}>IP</span>
            </span>
          </div>
        );
      },
    },
    {
      Header: "Bonus Received",
      field: "Bonus Received",
      orderField: "Bonus_Received",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              {formatNumberEuropean(row.eurficAmount)}{" "}
              <span style={{ color: "#9A9A9A" }}>EurK-C</span>
            </span>
            <span>
              {formatNumberEuropean(row.eurfiwAmount)}{" "}
              <span style={{ color: "#9A9A9A" }}>EurK-W</span>
            </span>
          </div>
        );
      },
    },
    {
      Header: "%",
      field: "percentage",
      orderField: "percentage",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return <span>{row?.bonusPercentage}%</span>;
      },
    },
    {
      Header: "User Depth",
      field: "user_depth",
      orderField: "user_depth",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return row?.bonusLine;
      },
    },
  ];

  const ref = useRef(null);
  const endPoint = endpoints.infinityBonus.BONUS_HISTORY;

  // Status
  const [extraFilters, setExtraFilters] = useState({});
  const [currentFilter, setCurrentFilter] = useState(initialFilters);

  // Context
  const { generalModalRef, generalErrorsResolver } = useOutletContext();
  const [generalInfo] = useContext(GeneralInfoContext);

  // Queries
  const exportBonusHistoryAPI = useQuery({
    url: endpoints.exports.INFINITY_BONUS_HISTORY,
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      generalModalRef.current.updateBody(
        <StatusResponse
          title="Success"
          description={<ExportModal generalModalRef={generalModalRef} />}
          icon={successIcon}
        >
          <Button onClick={() => generalModalRef.current.destroy()}>
            Close
          </Button>
        </StatusResponse>,
        { modalStyle: { maxWidth: "452px" } }
      );
    },
    onError: (error) => {
      generalErrorsResolver(error);
    },
    clientOptions: {
      timeout: 120000,
    },
  });

  useEffect(() => {
    setExtraFilters({
      date__gte: currentFilter?.date?.gteDatePickerValue
        ? currentFilter?.date?.gteDatePickerValue.toISOString()
        : null,
      date__lte: currentFilter?.date?.lteDatePickerValue
        ? currentFilter?.date?.lteDatePickerValue.toISOString()
        : null,
    });

    // eslint-disable-next-line
  }, [currentFilter]);

  return (
    <SimpleStyledCard className={Style.bonusHistory}>
      <div className={Style.header}>
        <h5>Infinity Bonus History</h5>

        <div className={Style.action}>
          <div className={Style.datePickerContainer}>
            <RangeDatePicker
              className={Style.datePicker}
              setRangeDate={(value) => {
                setCurrentFilter({
                  date: {
                    gteDatePickerValue: value ? value[0] : null,
                    lteDatePickerValue: value ? value[1] : null,
                    label: value
                      ? `From ${new Date(
                          value[0]
                        ).toLocaleDateString()} to ${new Date(
                          value[1]
                        ).toLocaleDateString()}`
                      : "",
                  },
                });
              }}
              rangeDate={[
                currentFilter.date.gteDatePickerValue,
                currentFilter.date.lteDatePickerValue,
              ]}
            />
          </div>
          <Button
            onClick={() => exportBonusHistoryAPI.executeQuery()}
            className={Theme.downloadBtn}
            isLoading={exportBonusHistoryAPI.isLoading}
          >
            <div>Download XLSX Report</div>
            <DownloadIcon />
          </Button>
        </div>
      </div>
      <Table
        ref={ref}
        height="410"
        enableAllowedActions={false}
        columns={columns}
        endPoint={endPoint}
        enableSelectableRows={false}
        enableSearch={false}
        enableSearchFieldSelect={false}
        enablePageSizeSelect={true}
        defaultPageSize={50}
        extraFilters={extraFilters}
        Styles={customTableStyles}
        searchBadgesClassName={Style.searchBadges}
        paginationButtonClassName={Style.paginationButton}
        paginationRecordsClassName={Style.paginationRecords}
        toPageInputBaseClassName={Style.toPageInput}
        enableSettings={false}
        enableRefreshBtn={false}
        enableHiddenColumnBadge={false}
      />
    </SimpleStyledCard>
  );
};

export default BonusHistory;
