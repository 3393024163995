import React, { useEffect, useContext } from "react";

// Components
import Loader from "../../../components/ui/loader/Loader";
import GradientHeaderV2 from "../../../components/advanced/gradientHeaderV2/GradientHeaderV2";
import PercentageRank from "./components/percentageRank/PercentageRank";
import StatisticsUnilevel from "./components/statisticsUnilevel/StatisticsUnilevel";
import RankDifference from "./components/rankDifference/RankDifference";

// Libraries
import { Container } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";
import { useNavigate } from "react-router-dom";
import useQuery from "@hybris-software/use-query";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Data
import config from "../../../data/config";
import endpoints from "../../../data/endpoints";

// Styles
import Style from "./ActiveQualifiedInfinityBonusView.module.css";

const ActiveQualifiedInfinityBonusView = () => {
  // Hooks
  const navigate = useNavigate();

  // Contexts
  const { paths } = useContext(RoutesContext);

  const infinityApi = useQuery({
    url: endpoints.activeQualifiedInfinityBonus.REPORT,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  useEffect(() => {
    document.title = `${config.WEBSITE_NAME} | Active Qualified Infinity Bonus`;
    //eslint-disable-next-line
  }, []);

  return (
    <PermissionRoute
      loader={<Loader />}
      forLoggedUser={true}
      firstApiLoading={infinityApi?.isLoading}
      unAuthorizedAction={() => {
        // navigate
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        // dispatcher
        return true;
      }}
    >
      <Container>
        <section className={Style.headerSection}>
          <GradientHeaderV2
            title={"Curious About How the Infinity Bonus Works?"}
            subtitle={
              `The Infinity Bonus is a game-changing incentive that amplifies your earnings and influence within ${config.WEBSITE_NAME}. Unlocked at the rank of Chief Director, this bonus is calculated at the end of every calendar month. To qualify, you must maintain active and qualified status. Want to know more?`
            }
            linkText={"See Our Wiki"}
          />
        </section>
        <section className={Style.section}>
          <RankDifference response={infinityApi?.response?.data} />
        </section>
        <section className={Style.section}>
          <StatisticsUnilevel response={infinityApi?.response?.data} />
        </section>
        <section className={Style.section}>
          <PercentageRank />
        </section>
      </Container>
    </PermissionRoute>
  );
};

export default ActiveQualifiedInfinityBonusView;
