import React, { useEffect, useContext } from "react";

// Components
import Loader from "../../../components/ui/loader/Loader";
import Header from "./components/header/Header";
import PointsGrowthGraph from "./components/pointsGrowthGraph/PointsGrowthGraph";
import CountryGrowth from "./components/countryGrowth/CountryGrowth";
import RankWiseGrowth from "./components/rankWiseGrowth/RankWiseGrowth";

// Libraries
import { Container } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";
import { useNavigate } from "react-router-dom";

// Data
import config from "../../../data/config";

// Contexts
import { PrivacyProvider } from "../../../contexts/PrivacyContext";
import { RoutesContext } from "../../../contexts/RoutesContext";

// Styles
import Style from "./CommunityGrowthAnalyticsView.module.css";

const CommunityGrowthAnalyticsView = () => {
  // Hooks
  const navigate = useNavigate();

  // Contexts
  const { paths } = useContext(RoutesContext);

  useEffect(() => {
    document.title = `${config.WEBSITE_NAME} | Growth Analytics`;
    //eslint-disable-next-line
  }, []);

  return (
    <PermissionRoute
      loader={<Loader />}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        // navigate
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        // dispatcher
        return true;
      }}
    >
      <PrivacyProvider>
        <Container>
          <Header />
          <section className={Style.section}>
            <PointsGrowthGraph />
          </section>
          <section className={Style.section}>
            <CountryGrowth/>
          </section>
          <section className={Style.section}>
            <RankWiseGrowth/>
          </section>
        </Container>
      </PrivacyProvider>
    </PermissionRoute>
  );
};

export default CommunityGrowthAnalyticsView;
