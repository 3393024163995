import React, { useRef } from "react";

// Components
import Card from "../../../../../components/advanced/card/Card";

// Libraries
import { TableClient } from "@hybris-software/use-table";
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";

// Data
import endpoints from "../../../../../data/endpoints";
import { customTableStyles } from "../../../../../data/constants";

// Icon
import LineCloseIcon from "../../../../../assets/svgComponents/LineCloseIcon";
import LineCheckIcon from "../../../../../assets/svgComponents/LineCheckIcon";
import locked from "../../../../../assets/svg/locked.svg";
import unLocked from "../../../../../assets/svg/unlocked.svg";

// Styles
import Style from "./UniLevelDepth.module.css";

const UniLevelDepth = () => {
  const columns = [
    {
      Header: "#",
      field: "id",
      orderField: "id",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return row?.id;
      },
    },    
    {
      Header: "Status",
      field: "s",
      orderField: "first_lineCp",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return (
          <div className={Style.StatusWrapper}>
            <div>
              {row?.isQualified ? (
                <div className={Style.status}>
                  <LineCheckIcon />
                  <span>Qualified</span>
                </div>
              ) : (
                <div className={Style.status}>
                  <LineCloseIcon />
                  <span>Not Qualified</span>
                </div>
              )}
            </div>
            <div>
              {row?.isUnlocked ? (
                <div className={Style.status}>
                  <img src={unLocked} alt="unLocked" />
                </div>
              ) : (
                <div className={Style.status}>
                  <img src={locked} alt="locked" />
                </div>
              )}
            </div>
          </div>
        );
      },
    },
  ];

  const ref = useRef(null);

  // Queries
  const unileveleBomusLinesAPI = useQuery({
    url: endpoints.activeQualified.UNILEVEL_BONUS_LINES,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  return (
    <Card
      minHeight={"1100px"}
      apiHook={unileveleBomusLinesAPI}
      className={Style.depthLevels}
    >
      <div className={Style.header}>
        <h5>Unlock Unilevel Depth Level</h5>
      </div>
      <TableClient
        height={610}
        defaultPageSize={20}
        pageSizes={[1, 2, 3, 5, 10, 100]}
        enableAllowedActions={false}
        ref={ref}
        columns={[...columns]}
        rawData={unileveleBomusLinesAPI?.response?.data}
        Styles={customTableStyles}
        searchBadgesClassName={Style.searchBadges}
        paginationClassName={Style.pagination}
        enablePageSizeSelect={false}
        enableSearch={false}
        enableSearchFieldSelect={false}
        enableSelectableRows={false}
        enableSettings={false}
        enableRowsSelectedBadge={false}
        enableSearchBadges={false}
      />
    </Card>
  );
};

export default UniLevelDepth;
