import React from "react";

// Utils
import classNames from "../../../utils/classNames";

// Styles
import Style from "./Card.module.css";
import config from "../../../data/config";

const Card = ({
  children,
  className,
  minHeight,
  apiHook,
  errorMessage = "An error occurred",
  style,
}) => {
  return (
    <div
      className={classNames(Style.card, className)}
      style={{
        minHeight: minHeight,
        ...style
      }}
    >
      {apiHook?.isLoading ? (
        <div
          className={Style.loader}
          style={{
            minHeight: minHeight,
          }}
        >
          <img
            src={config.LOGO_SMALL}
            className={Style.rotate}
            alt="Loading..."
            draggable="false"
          />
        </div>
      ) : (
        <>
          {apiHook?.isSuccess ? (
            children
          ) : (
            <div
              className={Style.error}
              style={{
                minHeight: minHeight,
              }}
            >
              {errorMessage}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Card;
