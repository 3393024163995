import React from "react";

const ExportIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" id="export"  width="23" height="23">
      <path
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 5h5v5M25 5 15 15M14 5H7.5A2.5 2.5 0 0 0 5 7.5v15A2.5 2.5 0 0 0 7.5 25h15a2.5 2.5 0 0 0 2.5-2.5V16"
      ></path>
    </svg>
  );
};

export default ExportIcon;
