import React from "react";

const LineCheckIcon = () => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="10.8936" cy="10" rx="9.89361" ry="9" fill="white" />
      <mask
        id="mask0_612_2446"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="22"
        height="20"
      >
        <path
          d="M10.8936 19C12.1931 19.0015 13.4801 18.7694 14.6807 18.317C15.8813 17.8646 16.9717 17.2009 17.8894 16.3639C18.8095 15.5291 19.5391 14.5372 20.0364 13.445C20.5338 12.3529 20.7889 11.1821 20.7872 10C20.7889 8.81788 20.5337 7.64712 20.0364 6.555C19.5391 5.46287 18.8094 4.4709 17.8894 3.63611C16.9717 2.79912 15.8813 2.13536 14.6807 1.68297C13.4801 1.23057 12.1931 0.998476 10.8936 1.00001C9.59412 0.998501 8.30712 1.23061 7.10655 1.683C5.90599 2.13539 4.81553 2.79914 3.89784 3.63611C2.97777 4.4709 2.24812 5.46287 1.75082 6.555C1.25351 7.64712 0.998352 8.81788 1.00001 10C0.998325 11.1821 1.25347 12.3529 1.75078 13.445C2.24809 14.5372 2.97775 15.5291 3.89784 16.3639C4.81553 17.2009 5.90599 17.8646 7.10655 18.317C8.30712 18.7694 9.59412 19.0015 10.8936 19Z"
          fill="white"
          stroke="white"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M6.9375 9.9998L9.90558 12.6998L15.8417 7.2998"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </mask>
      <g mask="url(#mask0_612_2446)">
        <path
          d="M-0.976562 -0.799805H22.7681V20.8002H-0.976562V-0.799805Z"
          fill="#4ECB71"
        />
      </g>
    </svg>
  );
};

export default LineCheckIcon;
