import React from "react";

//libraries
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
//styles
import Style from "./HalfDoughnutChart.module.css";

const HalfDoughnutChart = ({
  showPercentage,
  percentageStart,
  percentageTotal = 100,
  size,
  label,
  showStartAndEnd = true,
}) => {
  if (percentageStart > 100) {
    percentageStart = 100;
  }
  // Calculate the font size based on the size prop and the default size (244px)
  const defaultSize = 244;
  const minFontSize = 10;
  const maxFontSize = 30;
  const scaleFactor = Math.min(size / defaultSize, 1);
  const percentageFontSize =
    minFontSize + scaleFactor * (maxFontSize - minFontSize);

  const cappedPercentageStart =
    percentageStart >= percentageTotal ? percentageTotal : percentageStart;

  const firstDoughnutData = {
    datasets: [
      {
        data: [cappedPercentageStart, percentageTotal - cappedPercentageStart],
        borderWidth: 0,
        borderRadius: 20,
        cutout: "85%",

        backgroundColor: ["#07F9C8", "transparent"],
      },
    ],
  };
  const backgroundDoughnut = {
    datasets: [
      {
        data: [100, 0],
        borderWidth: 0,
        borderRadius: 20,
        cutout: "85%",

        backgroundColor: ["#6D6D6D", "transparent"],
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    rotation: -90,
    circumference: 180,
    maintainAspectRatio: true,
    responsive: true,
  };

  return (
    <div className={Style.rewardChart}>
      <div
        className={Style.backgroundDoughnut}
        style={{ width: size, height: size }}
      >
        <Doughnut data={backgroundDoughnut} options={options} />
        <div
          className={Style.firstDoughnut}
          style={{ width: size, height: size }}
        >
          <Doughnut data={firstDoughnutData} options={options} />
        </div>

        {showPercentage && (
          <span style={{ fontSize: `${percentageFontSize}px` }}>
            {percentageStart}%
          </span>
        )}
        {label && <div className={Style.labelStyle}>{label}</div>}
        {showStartAndEnd && (
          <>
            <p className={Style.startFrom}>0%</p>
            <p className={Style.endWith}>100%</p>
          </>
        )}
      </div>
    </div>
  );
};
//
export default HalfDoughnutChart;
