// import English from '../assets/flags/english.png';
// import Francais from '../assets/flags/francais.png';
// import Spain from '../assets/flags/spain.png';
// import German from '../assets/flags/german.png';

const languages = {
    EN: {
        name: 'English',
        shortName: 'EN',
        // flag: English,
    },
    ES: {
        name: 'Spanish',
        shortName: 'ES',
        // flag: Spain,
    },
}

export default languages;