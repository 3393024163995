import React from "react";

// icons
import { RiCloseCircleLine } from "react-icons/ri";

//Utilities
import { removeAfterDoubleUnderscore } from "../../../utils/utilityFunctions";

// Styles
import Style from "./FilterBadges.module.css";

const FilterBadges = ({ currentFilters, handleResetFilter = () => {}, withRemove = true }) => {
  return (
    <div className={Style.filterBadges}>
      {Object.entries(currentFilters).filter(([key, value]) => value?.label && value.showInBadge !==false ).map(([key, value]) => (
          <div key={key} className={Style.filterBadge}>
          <span className={Style.filterBadgeName}>
            {removeAfterDoubleUnderscore(key)}:
          </span>
          <span className={Style.filterBadgeContent}>{value.label}</span>
          {withRemove && value.value !== null && value.value !== "" && <RiCloseCircleLine onClick={() => handleResetFilter(key)} />}
        </div>
      ))}
    </div>
  );
};

export default FilterBadges;
