import React, { useRef, useState, useMemo } from "react";

// Components
import SimpleStyledCard from "../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";
import DateFormatter from "../../../../../components/advanced/dateFormatter/DateFormatter";
import RangeDatePicker from "../../../../../components/advanced/rangeDatePicker/RangeDatePicker";
import FilterBadges from "../../../../../components/advanced/filterBadges/FilterBadges";
import { Button, Select } from "@hybris-software/ui-kit";
import Card from "../../../../../components/advanced/card/Card";
import StatusResponse from "../../../../../components/advanced/statusResponse/StatusResponse";
import ExportModal from "../../../../../components/advanced/exportModal/ExportModal";

// Libraries
import Table from "@hybris-software/use-table";
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";
import { useOutletContext } from "react-router-dom";

// Utilities
import {
  formatNumberEuropean,
  handleResetFilter,
} from "../../../../../utils/utilityFunctions";

// Data
import endpoints from "../../../../../data/endpoints";
import config from "../../../../../data/config";
import { customTableStyles } from "../../../../../data/constants";

// Icon
import successIcon from "../../../../../assets/icons/response-Icons/success.png";
import DownloadIcon from "../../../../../assets/svgComponents/DownloadIcon";

// Styles
import Style from "./HistoryRewardsTable.module.css";
import Theme from "../../../../../assets/css/theme.module.css";

const HistoryRewardsTable = () => {
  // Variables
  const initialFilters = {
    date: {
      gteDatePickerValue: null,
      label: null,
      lteDatePickerValue: null,
    },
    bonus_type: null,
  };
  const ref = useRef(null);
  const endPoint = endpoints.rewardsHistory.REWARDS_HISTORY;

  // Status
  const [extraFilters, setExtraFilters] = useState({});
  const [currentFilter, setCurrentFilter] = useState(initialFilters);

  // Context
  const { generalModalRef, generalErrorsResolver } = useOutletContext();

  const badges = useMemo(() => {
    return currentFilter;
    // eslint-disable-next-line
  }, [extraFilters]);

  // Queries
  const historyRewardsFiltersAPI = useQuery({
    url: endpoints.rewardsHistory.FILTERS,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {},
    onUnauthorized: (response) => {},
    onError: () => {},
    clientOptions: {
      timeout: 120000,
    },
  });
  const exportBonusHistoryAPI = useQuery({
    url: endpoints.exports.REWARDS_HISTORY,
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      generalModalRef.current.updateBody(
        <StatusResponse
          title="Success"
          description={<ExportModal generalModalRef={generalModalRef} />}
          icon={successIcon}
        >
          <Button onClick={() => generalModalRef.current.destroy()}>
            Close
          </Button>
        </StatusResponse>,
        { modalStyle: { maxWidth: "452px" } }
      );
    },
    onError: (error) => {
      generalErrorsResolver(error);
    },
    clientOptions: {
      timeout: 120000,
    },
  });

  const columns = [
    {
      Header: "#",
      field: "id",
      orderField: "id",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return row.id;
      },
    },
    {
      Header: "Bonus Type",
      field: "bonusType",
      orderField: "bonus_type",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return historyRewardsFiltersAPI?.response?.data?.filters?.bonusType?.exact.find(
          (item) => item.value === row.bonusType
        ).label;
      },
    },
    {
      Header: `${config.EURK_C_UNIT} Amount`,
      field: "eurficAmount",
      orderField: "eurfic_amount",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return formatNumberEuropean(row.eurficAmount, 2);
      },
    },
    {
      Header: `${config.EURK_W_UNIT} Amount`,
      field: "eurfiwAmount",
      orderField: "eurfiw_amount",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return formatNumberEuropean(row.eurfiwAmount, 2);
      },
    },
    {
      Header: "Date",
      field: "date",
      orderField: "date",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return (
          <div className={Style.dateContainer}>
            <DateFormatter date={row.date} />{" "}
            {new Date(row.date).toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            })}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Card apiHook={historyRewardsFiltersAPI} className={Style.extraFilters}>
        <h5 className={Style.heading}>Filter</h5>
        <div className={Style.extraFiltersBody}>
          <div className={Style.datePickerContainer}>
            <h5 className={Style.selectLabel}>Date</h5>
            <RangeDatePicker
              className={Style.datePicker}
              setRangeDate={(value) => {
                setCurrentFilter({
                  ...currentFilter,
                  date: {
                    gteDatePickerValue: value ? value[0] : null,
                    lteDatePickerValue: value ? value[1] : null,
                    label: value
                      ? `From ${new Date(
                          value[0]
                        ).toLocaleDateString()} to ${new Date(
                          value[1]
                        ).toLocaleDateString()}`
                      : "",
                  },
                });
              }}
              rangeDate={[
                currentFilter.date?.gteDatePickerValue,
                currentFilter.date?.lteDatePickerValue,
              ]}
              minDate={
                new Date(
                  historyRewardsFiltersAPI?.response?.data?.filters?.date.min
                )
              }
              maxDate={
                new Date(
                  historyRewardsFiltersAPI?.response?.data?.filters?.date.max
                )
              }
            />
          </div>

          <div>
            <h5 className={Style.selectLabel}>Bonus Type</h5>
            <Select
              placeholder="Please select a Bonus Type"
              value={currentFilter.bonus_type}
              items={[
                {
                  label: "All",
                  value: null,
                },
                ...(historyRewardsFiltersAPI?.response?.data?.filters?.bonusType
                  ?.exact || []),
              ]}
              setValue={(value) => {
                setCurrentFilter({
                  ...currentFilter,
                  bonus_type: value,
                });
              }}
            />
          </div>
          <div className={Style.spacer} />
          <Button
            className={Style.applyFilterBtn}
            onClick={() => {
              let tempExtraFilter = {};
              Object.entries(currentFilter).forEach((entry) => {
                const [key, item] = entry;
                if (item) tempExtraFilter[key] = item.value;
              });
              setExtraFilters({
                ...tempExtraFilter,
                date__gte: currentFilter?.date?.gteDatePickerValue
                  ? currentFilter?.date?.gteDatePickerValue.toISOString()
                  : null,
                date__lte: currentFilter?.date?.lteDatePickerValue
                  ? currentFilter?.date?.lteDatePickerValue.toISOString()
                  : null,
                date: null,
              });
            }}
          >
            Apply Filters
          </Button>
        </div>
        <div className={Style.extraFiltersBadges}>
          <FilterBadges
            currentFilters={badges}
            handleResetFilter={(filterKey) =>
              handleResetFilter(
                filterKey,
                initialFilters,
                currentFilter,
                setCurrentFilter,
                extraFilters,
                setExtraFilters
              )
            }
          />
        </div>
      </Card>
      <SimpleStyledCard className={Style.historyRewardsTable}>
        <div className={Style.action}>
          <Button
            onClick={() => exportBonusHistoryAPI.executeQuery()}
            buttonClassName={Theme.downloadBtn}
            isLoading={exportBonusHistoryAPI.isLoading}
          >
            <div>Download XLSX Report</div>
            <DownloadIcon />
          </Button>
        </div>

        <Table
          ref={ref}
          height="410"
          enableAllowedActions={false}
          columns={columns}
          endPoint={endPoint}
          enableSelectableRows={false}
          enableSearch={false}
          enableSearchFieldSelect={false}
          enablePageSizeSelect={true}
          defaultPageSize={50}
          extraFilters={extraFilters}
          Styles={customTableStyles}
          searchBadgesClassName={Style.searchBadges}
          paginationButtonClassName={Style.paginationButton}
          paginationRecordsClassName={Style.paginationRecords}
          toPageInputBaseClassName={Style.toPageInput}
          enableSettings={false}
          enableRefreshBtn={false}
          enableHiddenColumnBadge={false}
        />
      </SimpleStyledCard>
    </>
  );
};

export default HistoryRewardsTable;
