import React, { useEffect, useContext } from "react";

// Components
import Loader from "../../../components/ui/loader/Loader";
import GradientHeaderV2 from "../../../components/advanced/gradientHeaderV2/GradientHeaderV2";
import UniLevelDepth from "./components/uniLevelDepth/UniLevelDepth";
import UnilevelStatistics from "./components/unilevelStatistics/UnilevelStatistics";
import UnilevelOverTime from "./components/unilevelOverTime/UnilevelOverTime";

// Libraries
import { Container } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";
import { useNavigate } from "react-router-dom";
import useQuery from "@hybris-software/use-query";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Data
import config from "../../../data/config";
import endpoints from "../../../data/endpoints";

// Styles
import Style from "./ActiveQualifiedUnilevelView.module.css";

const ActiveQualifiedUnilevelView = () => {

  // Hooks
  const navigate = useNavigate();

  // Contexts
  const { paths } = useContext(RoutesContext);

  const unilevelApi = useQuery({
    url: endpoints.activeQualifiedUniLevelBonus.ANALYTICS,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  useEffect(() => {
    document.title = `${config.WEBSITE_NAME} | Active Qualified Uni Level`;
    //eslint-disable-next-line
  }, []);

  return (
    <PermissionRoute
      loader={<Loader />}
      firstApiLoading={unilevelApi?.isLoading}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        navigate(paths.auth.login);
        // navigate
      }}
      permissionController={(response) => {
        // dispatcher
        return true;
      }}
    >
      <Container>
        <section className={Style.gradientHeader}>
          <GradientHeaderV2
            title={"Want to Know How to Unlock Levels in the Unilevel Bonus?"}
            subtitle={
              "Discover the mechanics behind unlocking various levels to maximize your earnings in the Unilevel Bonus system."
            }
            linkText={"See Our Wiki"}
          />
        </section>
        <section className={Style.section}>
          <UnilevelStatistics />
        </section>
        <section className={Style.section}>
          <UnilevelOverTime />
        </section>
        <section className={Style.section}>
          <UniLevelDepth />
        </section>
      </Container>
    </PermissionRoute>
  );
};

export default ActiveQualifiedUnilevelView;
