import ActivitiesEvent from "../assets/images/activities-events.png";
import ActivitiesRankUpgrade from "../assets/images/activities-rank-upgrade.png";
import ActivitiesNew from "../assets/images/activities-became.png";
import ActivitiesGifr from "../assets/images/activities-gift.png";

const activities = {
  RANK_UPGRADE: {
    private: (data, rankInfo) => {
      return `Congratulations! You've become rank:${rankInfo?.find((item) => item.id === data?.rankId)?.name
        }`;
    },
    public: (data, rankInfo) => {
      return `A member of your community has become rank:  ${rankInfo?.find((item) => item.id === data?.rankId)?.name
        }`;
    },
  },
  EVENT_REGISTERED: {
    private: (data) => {
      return `You registered to the event: ${data.eventName} `;
    },
    public: (data) => {
      return `The user registered to the event: ${data.eventName} (${data.eventId})`;
    },
  },

  // Activation
  NEW_ACTIVATION: {
    private: (data) => {
      return ` Congratulations! You became active on the platform`;
    },
    public: (data) => {
      return `The user became active on the platform`;
    },
  },
  NEW_UNILEVEL_ACTIVATION: {
    private: (data) => {
      return `Congrats! you became active and qualified for Unilevel Bonus`;
    },
    public: (data) => {
      return `The user became active and qualified for Unilevel Bonus`;
    },
  },
  NEW_ACTIVE_FIRSTLINE_USER: {
    private: (data) => {
      return `Your first line ${data.username} became active`;
    },
    public: (data) => {
      return `The user's first line ${data.username} became active`;
    },
  },

  // Bonus
  DIRECT_BONUS_RECEIVED: {
    private: (data) => {
      return `Congratulations! You've received (${data.amount}) as a direct bonus`;
    },
    public: (data) => {
      return `The user received a direct bonus`;
    },
  },

  UNILEVEL_BONUS_RECEIVED: {
    private: (data) => {
      return `Congratulations! You've received (${data.amount}) as a Unilevel bonus`;
    },
    public: (data) => {
      return `The user received an Unilevel  bonus`;
    },
  },
  INFINITY_BONUS_RECEIVED: {
    private: (data) => {
      return `Congratulations! You've received (${data.amount}) as a infinity bonus`;
    },
    public: (data) => {
      return `The user received an infinity bonus`;
    },
  },
  TURNOVER_BONUS_RECEIVED: {
    private: (data) => {
      return `Congratulations! You've received (${data.amount}) as a Turnover Rank bonus`;
    },
    public: (data) => {
      return `The user received a Turnover Rank bonus`;
    },
  },
};

const activityCategories = {
  RANK_UPGRADE: 'RANK_UPGRADE',
  EVENT_REGISTERED: 'EVENT_REGISTERED',
  NEW_ACTIVATION: 'NEW_ACTIVATION',
  NEW_UNILEVEL_ACTIVATION: 'NEW_UNILEVEL_ACTIVATION',
  NEW_ACTIVE_FIRSTLINE_USER: 'NEW_ACTIVE_FIRSTLINE_USER',
  DIRECT_BONUS_RECEIVED: 'DIRECT_BONUS_RECEIVED',
  UNILEVEL_BONUS_RECEIVED: 'UNILEVEL_BONUS_RECEIVED',
  INFINITY_BONUS_RECEIVED: 'INFINITY_BONUS_RECEIVED',
  TURNOVER_BONUS_RECEIVED: 'TURNOVER_BONUS_RECEIVED',
}

const activityBackgrounds = {
  [activityCategories.RANK_UPGRADE]: ActivitiesRankUpgrade,
  [activityCategories.EVENT_REGISTERED]: ActivitiesEvent,
  [activityCategories.NEW_ACTIVATION]: ActivitiesNew,
  [activityCategories.NEW_UNILEVEL_ACTIVATION]: ActivitiesNew,
  [activityCategories.NEW_ACTIVE_FIRSTLINE_USER]: ActivitiesNew,
  [activityCategories.DIRECT_BONUS_RECEIVED]: ActivitiesGifr,
  [activityCategories.UNILEVEL_BONUS_RECEIVED]: ActivitiesGifr,
  [activityCategories.INFINITY_BONUS_RECEIVED]: ActivitiesGifr,
  [activityCategories.TURNOVER_BONUS_RECEIVED]: ActivitiesGifr,
}

function activityManager(activity, activityType, data, rankInfo) {
  return activities[activity][activityType](data, rankInfo);
}

function activityBackground(category) {
  const theType = activityBackgrounds[category];
  if (theType) return theType;
  else return ActivitiesNew;
}
export { activityManager, activityBackground };
