import React, { useContext } from "react";

// Components
import CopyReferralCodeBtn from "../copyReferralCodeBtn/CopyReferralCodeBtn";

// Context
import GeneralInfoContext from "../../../contexts/GeneralInfoContext";

// Styles
import Style from "./UserInfoHeader.module.css";
import DateFormatter from "../dateFormatter/DateFormatter";

const UserInfoHeader = () => {
  // Context
  const [generalInfo] = useContext(GeneralInfoContext);

  // Variables
  const currentRank = generalInfo?.rankInfo?.find(
    (item) => item.id === generalInfo?.userInfo?.currentRank
  );

  // Functions

  return (
    <div className={Style.userInfo}>
      <div className={Style.left}>
        <img
          src={currentRank?.iconUrl}
          alt="currentRank"
          width={60}
          height={60}
        />
        <div>
          <div className={Style.username}>
            @{generalInfo?.userInfo?.username}
          </div>
          <div>
            Joined on:{" "}
            <DateFormatter
              date={generalInfo?.userInfo?.dateJoined}
              format="YYYY MMM DD"
            />
          </div>
        </div>
      </div>
      <div className={Style.copyBtn}>
        <CopyReferralCodeBtn />
      </div>
    </div>
  );
};

export default UserInfoHeader;
