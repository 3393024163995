import React, { useContext } from "react";

// Libraries
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";
import { Button } from "@hybris-software/ui-kit";
import { Link } from "react-router-dom";

// Contexts
import GeneralInfoContext from "../../../../../contexts/GeneralInfoContext";

// Data
import config from "../../../../../data/config";
import endpoints from "../../../../../data/endpoints";

// Styles
import Style from "./Header.module.css";

const Header = () => {
  // User Info
  const [generalInfo] = useContext(GeneralInfoContext);

  // Queries
  const activationAPI = useQuery({
    url: endpoints.activeQualified.ACTIVATION(generalInfo?.userInfo?.id),
    method: "GET",
    executeImmediately: true,
    clientOptions: {
      timeout: 120000,
    },
  });

  return (
    <div className={Style.headerWrapper}>
      <div className={Style.titleWrapper}>
        <h4>Active Qualified Overview</h4>
        {activationAPI.isSuccess && (
          <div className={Style.status}>
            Status:{" "}
            <span
              className={
                !activationAPI?.response?.data?.active
                  ? Style.error
                  : Style.success
              }
            >
              {!activationAPI?.response?.data?.active ? "Not Active" : "Active"}
            </span>
          </div>
        )}
      </div>

      <div className={Style.wiki}>
        <Link to={config?.WIKI_PAGE_URL}>
          <Button className={Style.wikiBtn}>Visit Wiki</Button>
        </Link>
      </div>
    </div>
  );
};

export default Header;
