import React from "react";

const DashIconsTwitter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M16.0026 3.3335C9.00765 3.3335 3.33594 9.00521 3.33594 16.0001C3.33594 22.9951 9.00765 28.6668 16.0026 28.6668C22.9975 28.6668 28.6692 22.9951 28.6692 16.0001C28.6692 9.00521 22.9975 3.3335 16.0026 3.3335ZM22.0899 12.8815C22.0984 13.0144 22.0984 13.153 22.0984 13.2887C22.0984 17.4393 18.9374 22.2204 13.1611 22.2204C11.3798 22.2204 9.72863 21.703 8.33756 20.8123C8.59203 20.8406 8.83518 20.8519 9.0953 20.8519C10.5655 20.8519 11.917 20.3543 12.9943 19.5117C11.6145 19.4835 10.4553 18.5787 10.0594 17.3347C10.5429 17.4053 10.9783 17.4053 11.476 17.2781C10.7655 17.1338 10.1269 16.7479 9.66874 16.1861C9.21054 15.6243 8.96096 14.9212 8.96241 14.1963V14.1567C9.37804 14.3914 9.86718 14.5356 10.3789 14.5553C9.94872 14.2686 9.59591 13.8802 9.35178 13.4245C9.10764 12.9688 8.97973 12.4598 8.97938 11.9429C8.97938 11.3576 9.13206 10.8232 9.40631 10.3595C10.1949 11.3303 11.1789 12.1242 12.2944 12.6898C13.4099 13.2553 14.6319 13.5798 15.881 13.6421C15.4371 11.5074 17.0317 9.77991 18.9487 9.77991C19.8535 9.77991 20.6677 10.1588 21.2417 10.7695C21.9514 10.6366 22.6299 10.3708 23.235 10.0146C23.0003 10.7412 22.5084 11.3548 21.8552 11.7421C22.4886 11.6743 23.0993 11.499 23.6648 11.253C23.2378 11.8807 22.7035 12.4376 22.0899 12.8815Z"
        fill="url(#paint0_linear_183_577)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_183_577"
          x1="16.0026"
          y1="3.3335"
          x2="16.0026"
          y2="28.6668"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4FC2AB" />
          <stop offset="1" stopColor="#00FFCC" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DashIconsTwitter;
