import React from "react";

const WalletIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 108 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M106.113 64.5769H84.0156C75.9021 64.5719 69.3258 58.0005 69.3208 49.887C69.3208 41.7735 75.9021 35.2021 84.0156 35.1971H106.113"
        strokeWidth="6"
        stroke="#DBDBDB"
      />
      <path d="M86.5174 49.5514H84.8145" strokeWidth="6" stroke="#DBDBDB" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.2895 2.375H77.4682C93.2873 2.375 106.112 15.1994 106.112 31.0185V70.1931C106.112 86.0122 93.2873 98.8366 77.4682 98.8366H30.2895C14.4704 98.8366 1.646 86.0122 1.646 70.1931V31.0185C1.646 15.1994 14.4704 2.375 30.2895 2.375Z"
        strokeWidth="6"
        stroke="#DBDBDB"
      />
      <path d="M26.4048 27.1449H55.8743" strokeWidth="6" stroke="#DBDBDB" />
    </svg>
  );
};

export default WalletIcon;
