import React from "react";

const DashIconsTelegram = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M28.6692 16.0001C28.6692 19.3595 27.3347 22.5814 24.9592 24.9568C22.5838 27.3323 19.362 28.6668 16.0026 28.6668C12.6432 28.6668 9.42137 27.3323 7.04591 24.9568C4.67046 22.5814 3.33594 19.3595 3.33594 16.0001C3.33594 12.6407 4.67046 9.41893 7.04591 7.04347C9.42137 4.66801 12.6432 3.3335 16.0026 3.3335C19.362 3.3335 22.5838 4.66801 24.9592 7.04347C27.3347 9.41893 28.6692 12.6407 28.6692 16.0001ZM16.457 12.6846C15.2252 13.1976 12.7615 14.2585 9.06918 15.8671C8.47068 16.1046 8.15559 16.339 8.12709 16.567C8.0796 16.9517 8.56251 17.1037 9.21959 17.3111L9.49668 17.3982C10.1427 17.6088 11.0135 17.8542 11.4648 17.8637C11.8764 17.8732 12.334 17.7054 12.8391 17.3571C16.2892 15.028 18.0704 13.8516 18.1812 13.8262C18.2604 13.8072 18.3712 13.7851 18.4441 13.8516C18.5185 13.9165 18.5106 14.0416 18.5027 14.0748C18.4552 14.2791 16.5599 16.0397 15.5798 16.9517C15.2742 17.2367 15.0573 17.4378 15.013 17.4837C14.9154 17.5835 14.8161 17.6817 14.7153 17.7782C14.1137 18.3577 13.664 18.7916 14.7391 19.5009C15.2568 19.8429 15.6717 20.1231 16.0849 20.405C16.5346 20.7121 16.9842 21.0177 17.5669 21.4009C17.7142 21.4959 17.8567 21.5988 17.9944 21.697C18.5185 22.0706 18.9919 22.4063 19.573 22.3525C19.9118 22.3208 20.2617 22.0041 20.4391 21.0541C20.8587 18.8105 21.6836 13.9513 21.8736 11.9484C21.8852 11.7819 21.8783 11.6146 21.853 11.4496C21.8381 11.3165 21.7736 11.1939 21.6725 11.1061C21.5284 11.0066 21.3567 10.9551 21.1817 10.9588C20.7067 10.9667 19.9736 11.2216 16.457 12.6846Z"
        fill="url(#paint0_linear_183_584)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_183_584"
          x1="16.0026"
          y1="3.3335"
          x2="16.0026"
          y2="28.6668"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4FC2AB" />
          <stop offset="1" stopColor="#00FFCC" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DashIconsTelegram;
