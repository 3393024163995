import React, { useEffect } from "react";

// Components
import Loader from "../../../components/ui/loader/Loader";
import PoolsTable from "../../../components/advanced/poolsTable/PoolsTable";

// Libraries
import { Container } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";
import useQuery from "@hybris-software/use-query";

// Data
import config from "../../../data/config";
import endpoints from "../../../data/endpoints";
import SimpleTitle from "../../../components/advanced/simpleTitle/SimpleTitle";

const Pools = () => {
  useEffect(() => {
    document.title = `${config.WEBSITE_NAME} | Pools`;
    //eslint-disable-next-line
  }, []);

  const poolApi = useQuery({
    url: endpoints.pools.POOLS,
    method: "GET",
    executeImmediately: true,
  });

  return (
    <PermissionRoute
      loader={<Loader />}
      firstApiLoading={poolApi.isLoading}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        // navigate
      }}
      permissionController={(response) => {
        // dispatcher
        return true;
      }}
    >
      <Container>
        <SimpleTitle title="Pools" />
        <PoolsTable />
      </Container>
    </PermissionRoute>
  );
};

export default Pools;
