import React, { useEffect, useContext } from "react";

// Components
import Loader from "../../../components/ui/loader/Loader";
import Card from "../../../components/advanced/card/Card";
import InfinityBonusTree from "../infinityBonusView/components/infinityBonusTree/InfinityBonusTree";

// Libraries
import { PermissionRoute } from "@hybris-software/use-auth";
import useQuery from "@hybris-software/use-query";
import { useNavigate } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Data
import config from "../../../data/config";
import endpoints from "../../../data/endpoints";

// Styles
import Style from "./InfinityTreeFullScreenView.module.css";

const InfinityTreeFullScreenView = () => {
  // Hooks
  const navigate = useNavigate();

  // Contexts
  const { paths } = useContext(RoutesContext);

  //Queries
  const getUserInfoAPI = useQuery({
    url: endpoints.auth.USER,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  //Queries
  const communityAPI = useQuery({
    url: endpoints.community.FIRST_lINE(getUserInfoAPI?.response?.data.id, 1),
    method: "GET",
    executeImmediately: false,
    onSuccess: (response) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  useEffect(() => {
    if (getUserInfoAPI.isSuccess) {
      communityAPI.executeQuery();
    }
    //eslint-disable-next-line
  }, [getUserInfoAPI.isSuccess]);

  useEffect(() => {
    document.title = `${config.WEBSITE_NAME} | Infinity Tree Full Screen`;
    //eslint-disable-next-line
  }, []);

  return (
    <PermissionRoute
      loader={<Loader fullscreen />}
      forLoggedUser={true}
      firstApiLoading={communityAPI.isLoading}
      unAuthorizedAction={() => {
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        // dispatcher
        return true;
      }}
    >
      <div className={Style.bg} />
      <div className={Style.layout}>
        <Card apiHook={communityAPI} minHeight="100vh">
          <ReactFlowProvider>
            <InfinityBonusTree
              fullScreen
              userInfo={getUserInfoAPI?.response?.data}
            />
          </ReactFlowProvider>
        </Card>
      </div>
    </PermissionRoute>
  );
};

export default InfinityTreeFullScreenView;
