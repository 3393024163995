import React from "react";
// Libraries
import { Button } from "@hybris-software/ui-kit";

// Components
import SimpleStyledCard from "../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";

// Icons
import SendIcon from "../../../../../assets/svgComponents/SendIcon";

// Styles
import Style from "./JoinTelegram.module.css";

const JoinTelegram = () => {
  return (
    <>
      <div className={Style.header}>
        <p>Welcome to</p>
        <h4>Leadership Support Area</h4>
      </div>
      <SimpleStyledCard className={Style.card}>
        <div className={Style.wrapper}>
          <div className={Style.textWrapper}>
            <h5>
              Enhance Your Business: Click to Join Our Exclusive Telegram
              Channel!
            </h5>
            <p>
              Tap the button below to join our Telegram channel and gain access
              to unique insights and leadership resources. Don't miss out on
              this opportunity to connect with fellow leaders and enhance your
              leadership journey.
            </p>
            <Button
              onClick={() =>
                window.open(
                  "https://t.me/addlist/dhyZi3N2b_gzZDhk",
                  "_blank",
                  "noopener noreferrer"
                )
              }
              className={Style.sendButton}
            >
              <SendIcon />
              <span>Join Telegram</span>
            </Button>
          </div>
        </div>
      </SimpleStyledCard>
    </>
  );
};

export default JoinTelegram;
