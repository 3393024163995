import React, { useState, useContext } from "react";

// Components
import Card from "../../../../../components/advanced/card/Card";
import CommunityPointsModal from "../communityPoints/CommunityPointsModal";

// Libraries
import { Button } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";
import { Link } from "react-router-dom";

// Images
import AvatarMember from "../../../../../assets/images/placeholder.png";
import bluredPicture from "../../../../../assets/images/bluredImage.png";

// Icons
import { HiMinusSm, HiPlusSm } from "react-icons/hi";
import navigateToIcon from "../../../../../assets/svg/navigateToIcon.svg";

// Data
import endpoints from "../../../../../data/endpoints";

// Utils
import classNames from "../../../../../utils/classNames";

// Context
import GeneralInfoContext from "../../../../../contexts/GeneralInfoContext";
import { RoutesContext } from "../../../../../contexts/RoutesContext";

// Styles
import Style from "./ListView.module.css";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function showMember(randomId, randomIdSvg, randomIdSvgTwo, width, setWidth) {
  const el = document.getElementById(randomId);
  const elSvg = document.getElementById(randomIdSvg);
  const elSvgTwo = document.getElementById(randomIdSvgTwo);

  if (el.classList.contains("row_none")) {
    el.classList.remove("row_none");
    elSvgTwo.style.display = "block";
    elSvg.style.display = "none";
    setWidth(width + 30);
  } else {
    el.classList.add("row_none");
    elSvg.style.display = "block";
    elSvgTwo.style.display = "none";
    setWidth(width - 30);
  }
}

const InfoRow = (props) => {
  const randomId = props.POL_ID + "_" + props.level + "_" + makeid(7);

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "max-content",
          marginLeft: 13,
          borderRadius: "20px 20px 0 0",
        }}
      >
        <div style={{ minWidth: props.width, display: "flex" }}></div>
        <div
          className={Style.personal_poll}
          style={{ fontWeight: 600, fontSize: 12, color: "#CECECE" }}
        >
          Points
        </div>
        <div
          className={Style.ratio}
          style={{ fontWeight: 600, fontSize: 12, color: "#CECECE" }}
        >
          <span>Rank</span>
        </div>
        <div
          className={Style.status}
          style={{ fontWeight: 600, fontSize: 12, color: "#CECECE" }}
        >
          Status
        </div>
        <div
          className={Style.member}
          style={{ fontWeight: 600, fontSize: 12, color: "#CECECE" }}
        >
          Community Size
        </div>
        <div
          className={Style.member_since}
          style={{ fontWeight: 600, fontSize: 12, color: "#CECECE" }}
        >
          <span>Member Since</span>
        </div>
      </div>

      <div
        id={randomId}
        className="row_none"
        style={{ width: "max-content" }}
      />
    </>
  );
};

Object.byString = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

const ClubRow = (props) => {
  const randomId = props.id;
  const randomIdSvg = props.id + "plus";
  const randomIdSvgTwo = props.id + "minus";

  // Hooks
  const { paths } = useContext(RoutesContext);
  const [generalInfo] = useContext(GeneralInfoContext);
  // const { generalModalRef } = useOutletContext();

  const generalModalRef = props?.generalModalRef;

  const rankName = props?.rankApi
    ? props.rankApi?.find((item) => item.id === props?.rank)?.iconUrl
    : generalInfo?.rankInfo?.find((item) => item.id === props?.rank)?.iconUrl;

  const [pages, setPages] = useState({
    totalPages: null,
    currentPage: null,
  });

  const communityAPI = useQuery({
    url: `${endpoints.community.FIRST_lINE(props?.id, 1)}`,
    method: "GET",
    executeImmediately: false,
    onSuccess: (response) => {
      props.setTreeData((prevState) => {
        const newData = { ...prevState };
        newData[props.id]["listTree"] = response.data.results.reduce(
          (acc, curr) => {
            return {
              ...acc,
              [curr.id]: {
                ...curr,
                level: props.level ? props.level + 1 : 2,
              },
            };
          },
          {}
        );

        return newData;
      });

      setPages({
        totalPages: response.data.totalPages,
        currentPage: response.data.page,
      });

      showMember(
        randomId,
        randomIdSvg,
        randomIdSvgTwo,
        props.width,
        props.setWidth
      );
    },
    clientOptions: {
      timeout: 120000,
    },
  });

  const loadMorePageApi = useQuery({
    url: `${endpoints.community.FIRST_lINE(props?.id, pages.currentPage + 1)}`,
    method: "GET",
    executeImmediately: false,
    onSuccess: (response) => {
      props.setTreeData((prevState) => {
        const newData = { ...prevState };
        newData[props.id]["listTree"] = {
          ...newData[props.id]["listTree"],
          ...response.data.results.reduce((acc, curr) => {
            return {
              ...acc,
              [curr.id]: {
                ...curr,
                level: props.level ? props.level + 1 : 2,
              },
            };
          }, {}),
        };

        return newData;
      });
      setPages({
        ...pages,
        currentPage: response.data.page,
      });
    },
    clientOptions: {
      timeout: 120000,
    },
  });

  return (
    <>
      <div style={{ borderRadius: 0, paddingBottom: 4 }}>
        <div className={Style.container_max}>
          {props.index === 0 && (
            <InfoRow width={props.width} setWidth={props.setWidth} {...props} />
          )}

          <div className={Style.containerFrame}>
            <div className={Style.cardFrame} />

            <div className={Style.backgroundRowOne}>
              <div style={{ minWidth: props.width, display: "flex" }}>
                <div className={Style.allFlex}>
                  {props.downlineUsersCount > 0 ? (
                    <div className={Style.plus_border_first}>
                      {props.level === 1 ? (
                        <div className={Style.vertical_left_initial}></div>
                      ) : (
                        <div className={Style.vertical_left}></div>
                      )}
                      <div
                        id={randomIdSvg}
                        className={
                          communityAPI.isLoading
                            ? Style.textIconPlusLoading
                            : Style.text_icon_plus
                        }
                        onClick={() => {
                          if (props?.listTree === undefined)
                            communityAPI.executeQuery({});
                          else
                            showMember(
                              randomId,
                              randomIdSvg,
                              randomIdSvgTwo,
                              props.width,
                              props.setWidth
                            );
                        }}
                      >
                        <HiPlusSm />
                      </div>
                      <div
                        style={{ display: "none" }}
                        id={randomIdSvgTwo}
                        className={Style.text_icon_plus}
                        onClick={() => {
                          showMember(
                            randomId,
                            randomIdSvg,
                            randomIdSvgTwo,
                            props.width,
                            props.setWidth
                          );
                        }}
                      >
                        <HiMinusSm />
                      </div>
                    </div>
                  ) : (
                    <div
                      className={Style.plus_border_first}
                      style={{ width: 20 }}
                    ></div>
                  )}

                  <div style={{ position: "relative" }}>
                    <div
                      className={classNames(Style.avatar)}
                      style={
                        props.privateMode
                          ? { backgroundImage: `url(${bluredPicture})` }
                          : props.profilePicture !== null
                          ? {
                              backgroundImage: `url(${props.profilePicture})`,
                            }
                          : { backgroundImage: `url(${AvatarMember})` }
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      marginLeft: 13,
                    }}
                  >
                    {/* Name */}
                    <div className={Style.title_member}>
                      <div className={Style.username}>
                        {props.privateMode === true
                          ? "@*****"
                          : "@" + props.username}
                        <Link
                          to={paths.main.builderReport.replace(":id", props.id)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={navigateToIcon}
                            alt=""
                            className={Style.builderReportButton}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={Style.view_points}>
                <span
                  className={Style.pointDetails}
                  style={{ textDecoration: "underline" }}
                  onClick={() =>
                    generalModalRef?.current?.updateBody(
                      <CommunityPointsModal
                        generalModalRef={generalModalRef}
                        userId={props?.id}
                        //add your props here
                      />,
                      {
                        modalStyle: {
                          maxWidth: "490px",
                          background: ` no-repeat top left, no-repeat bottom right, var(--bg-like-blur)`,
                        },
                      }
                    )
                  }
                >
                  View Points
                </span>
                {/* {props.privateMode
                  ? "*** VP"
                  : (props.personalVp ? props.personalVp : "0") + " VP"} */}
              </div>

              <div className={Style.ratio}>
                <img src={rankName} alt="" width={70} />
              </div>

              <div className={Style.status}>
                <span>
                  {props.privateMode ? (
                    "***"
                  ) : props.active ? (
                    <span className={Style.activeStatus}>Active</span>
                  ) : (
                    <span className={Style.inactive}>Inactive</span>
                  )}
                </span>
              </div>

              <div className={Style.member}>
                {props.privateMode === true ? "*** " : props.downlineUsersCount}{" "}
              </div>

              <div className={Style.member_since}>
                {props.privateMode
                  ? "***"
                  : new Date(props.dateJoined)
                      .toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })
                      .split("/")
                      .reverse()
                      .join("-")}
              </div>
            </div>
          </div>

          <div
            id={randomId}
            className={classNames(Style.rowSection, "row_none")}
            style={{ width: "max-content" }}
          >
            {props &&
              props.listTree &&
              Object.keys(props.listTree).map((key, index) => (
                <div key={index} className={Style.test}>
                  <ClubRowSmall
                    width={props.width}
                    setWidth={props.setWidth}
                    privateMode={props.privateMode}
                    {...props.listTree[key]}
                    passData={props?.listTree}
                    parentId={props.id}
                    upline={[props.id]}
                    setTreeData={props.setTreeData}
                    rankApi={props.rankApi}
                    generalModalRef={generalModalRef}
                  />
                </div>
              ))}

            {pages.totalPages > pages.currentPage && (
              <Button
                style={{ marginLeft: 40 * props.level ? props.level + 1 : 2 }}
                className={Style.loadmore}
                isLoading={loadMorePageApi.isLoading}
                onClick={() => {
                  loadMorePageApi.executeQuery({});
                }}
              >
                Load More
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const ClubRowSmall = (props) => {
  const randomId = props.id;
  const randomIdSvg = props.id + "plus";
  const randomIdSvgTwo = props.id + "minus";

  // Hooks
  const { paths } = useContext(RoutesContext);
  const [generalInfo] = useContext(GeneralInfoContext);
  const generalModalRef = props?.generalModalRef;

  const rankName = props.rankApi
    ? props.rankApi?.find((item) => item.id === props?.rank)?.iconUrl
    : generalInfo?.rankInfo?.find((item) => item.id === props?.rank)?.iconUrl;

  const [pages, setPages] = useState({
    totalPages: null,
    currentPage: null,
  });

  const communityAPI = useQuery({
    url: `${endpoints.community.FIRST_lINE(props?.id, 1)}`,
    method: "GET",
    executeImmediately: false,
    onSuccess: (response) => {
      props.setTreeData((prevState) => {
        const newData = { ...prevState };

        const pathString = props.upline.reduce((acc, curr) => {
          return acc + "[" + curr + "][listTree]";
        }, "");

        const thisEl = Object.byString(newData, pathString)[props.id];

        thisEl.listTree = response.data.results.reduce((acc, curr) => {
          return {
            ...acc,
            [curr.id]: {
              ...curr,
              level: props.level ? props.level + 1 : 2,
            },
          };
        }, {});

        return newData;
      });

      setPages({
        totalPages: response.data.totalPages,
        currentPage: response.data.page,
      });

      showMember(
        randomId,
        randomIdSvg,
        randomIdSvgTwo,
        props.width,
        props.setWidth
      );
    },
    clientOptions: {
      timeout: 120000,
    },
  });

  const loadMorePageApi = useQuery({
    url: `${endpoints.community.FIRST_lINE(props?.id, pages.currentPage + 1)}`,
    method: "GET",
    executeImmediately: false,
    onSuccess: (response) => {
      props.setTreeData((prevState) => {
        const newData = { ...prevState };

        const pathString = props.upline.reduce((acc, curr) => {
          return acc + "[" + curr + "][listTree]";
        }, "");

        const thisEl = Object.byString(newData, pathString)[props.id];

        thisEl.listTree = {
          ...thisEl.listTree,
          ...response.data.results.reduce((acc, curr) => {
            return {
              ...acc,
              [curr.id]: {
                ...curr,
                level: props.level,
              },
            };
          }, {}),
        };

        return newData;
      });

      setPages({
        ...pages,
        currentPage: response.data.page,
      });
    },
    clientOptions: {
      timeout: 120000,
    },
  });

  return (
    <>
      <div
        style={{ marginLeft: 14 }}
        className={classNames(Style.secondChild, "row_small")}
      >
        <div style={{ minWidth: props.width, display: "flex" }}>
          <div className={Style.containerFrame}>
            <div
              style={props.level ? { marginLeft: 40 * props.level } : null}
              className={Style.allFlex}
            >
              {props?.downlineUsersCount > 0 ? (
                <div className={Style.plus_border}>
                  {props.level === 1 ? (
                    <div className={Style.vertical_left_initial}></div>
                  ) : (
                    <div className={Style.vertical_left}></div>
                  )}
                  <div
                    className={Style.pre_line}
                    style={{ left: props.level * 40 * -1 - 43 }}
                  ></div>
                  <div className={Style.orizontal}></div>

                  {(() => {
                    let elements = [];
                    for (let i = 0; i < props.level; i++) {
                      elements.push(
                        <div
                          className={Style.orizontal}
                          style={{ transform: `translateX(${-40 * i}px)` }}
                          key={i}
                        ></div>
                      );
                    }
                    return elements;
                  })()}

                  <div
                    id={randomIdSvg}
                    className={
                      communityAPI.isLoading
                        ? Style.textIconPlusLoading
                        : Style.text_icon_plus
                    }
                    onClick={() => {
                      if (props.listTree === undefined)
                        communityAPI.executeQuery({});
                      else
                        showMember(
                          randomId,
                          randomIdSvg,
                          randomIdSvgTwo,
                          props.width,
                          props.setWidth
                        );
                    }}
                  >
                    <HiPlusSm />
                  </div>
                  <div
                    style={{ display: "none" }}
                    id={randomIdSvgTwo}
                    className={Style.text_icon_plus}
                    onClick={() => {
                      showMember(
                        randomId,
                        randomIdSvg,
                        randomIdSvgTwo,
                        props.width,
                        props.setWidth
                      );
                    }}
                  >
                    <HiMinusSm />
                  </div>
                </div>
              ) : (
                <div style={{ position: "relative" }}>
                  <div className={Style.plus_border_first}>
                    {props.level === 1 ? (
                      <div className={Style.vertical_left_initial}></div>
                    ) : (
                      <div className={Style.vertical_left}></div>
                    )}
                    <div className={Style.icon_circle}>
                      <div className={Style.circle_final}></div>
                    </div>
                    {(() => {
                      let elements = [];
                      for (let i = 0; i < props.level; i++) {
                        elements.push(
                          <div
                            className={Style.orizontal_circle}
                            style={{ transform: `translateX(${-40 * i}px)` }}
                            key={i}
                          ></div>
                        );
                      }
                      return elements;
                    })()}
                  </div>

                  <div className={Style.vertical_left_last}></div>
                  <div
                    className={Style.pre_line_last}
                    style={{ left: props.level * 40 * -1 - 23 }}
                  ></div>
                </div>
              )}

              <div style={{ position: "relative" }}>
                <div
                  className={classNames(Style.avatar)}
                  style={
                    props.privateMode
                      ? { backgroundImage: `url(${bluredPicture})` }
                      : props.profilePicture !== null
                      ? {
                          backgroundImage: `url(${props.profilePicture})`,
                        }
                      : { backgroundImage: `url(${AvatarMember})` }
                  }
                />
              </div>

              <div className={Style.flexColumn}>
                {/* Name */}
                <div className={Style.title_member}>
                  <div className={Style.username}>
                    {props.privateMode === true
                      ? "@*****"
                      : "@" + props.username}
                    <Link
                      to={paths.main.builderReport.replace(":id", props.id)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={navigateToIcon}
                        alt=""
                        className={Style.builderReportButton}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={Style.containerFrame}>
          <div className={Style.rightSecondChild}>
            <div className={Style.view_points}>
              <span
                style={{ textDecoration: "underline" }}
                onClick={() =>
                  generalModalRef?.current?.updateBody(
                    <CommunityPointsModal
                      generalModalRef={generalModalRef}
                      userId={props?.id}
                      //add your props here
                    />,
                    {
                      modalStyle: {
                        maxWidth: "490px",
                        background: ` no-repeat top left, no-repeat bottom right, var(--bg-like-blur)`,
                      },
                    }
                  )
                }
              >
                View Points
              </span>
              {/* {props.privateMode
                ? "*** VP"
                : (props.personalVp ? props.personalVp : "0") + " VP"} */}
            </div>

            <div className={Style.ratio}>
              <img src={rankName} alt="" width={70} />
            </div>

            <div className={Style.status}>
              <span>
                {props.privateMode ? (
                  "***"
                ) : props.active ? (
                  <span className={Style.activeStatus}>Active</span>
                ) : (
                  <span className={Style.inactive}>Inactive</span>
                )}
              </span>
            </div>

            <div className={Style.member}>
              {props.privateMode === true ? "*** " : props?.downlineUsersCount}{" "}
            </div>

            <div className={Style.member_since}>
              {props.privateMode
                ? "***"
                : new Date(props.dateJoined)
                    .toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })
                    .replace(/\//g, "-")}
            </div>
          </div>
        </div>
      </div>

      <div id={randomId} className="row_none">
        {props &&
          props.listTree &&
          Object.keys(props.listTree).map((key, index) => (
            <div key={index}>
              <ClubRowSmallTwo
                width={props.width}
                setWidth={props.setWidth}
                privateMode={props.privateMode}
                {...props.listTree[key]}
                setTreeData={props.setTreeData}
                upline={[...props.upline, props.id]}
                rankApi={props.rankApi}
                generalModalRef={generalModalRef}
              />
            </div>
          ))}

        {pages.totalPages > pages.currentPage && (
          <Button
            style={props.level ? { marginLeft: 40 * props.level } : null}
            className={Style.loadmore}
            isLoading={loadMorePageApi.isLoading}
            onClick={() => {
              loadMorePageApi.executeQuery({});
            }}
          >
            Load More
            <div className={Style.dotBefore} />
            <div className={Style.dotAfter} />
          </Button>
        )}
      </div>
    </>
  );
};

// 3 ROW
const ClubRowSmallTwo = (props) => {
  const randomId = props.id;
  const randomIdSvg = props.id + "plus";
  const randomIdSvgTwo = props.id + "minus";

  // Hooks
  const [generalInfo] = useContext(GeneralInfoContext);
  const generalModalRef = props?.generalModalRef;

  const rankName = props.rankApi
    ? props.rankApi?.find((item) => item.id === props?.rank)?.iconUrl
    : generalInfo?.rankInfo?.find((item) => item.id === props?.rank)?.iconUrl;

  const [pages, setPages] = useState({
    totalPages: null,
    currentPage: null,
  });

  const communityAPI = useQuery({
    url: `${endpoints.community.FIRST_lINE(props?.id, 1)}`,
    method: "GET",
    executeImmediately: false,
    onSuccess: (response) => {
      props.setTreeData((prevState) => {
        const newData = { ...prevState };

        // Convert the array of strings in the upline prop to "[upline[0]]["listTree"][upline[1]]["listTree"]...[upline[n]]"
        const pathString = props.upline.reduce((acc, curr) => {
          return acc + "[" + curr + "][listTree]";
        }, "");

        const thisEl = Object.byString(newData, pathString)[props.id];

        thisEl.listTree = response.data.results.reduce((acc, curr) => {
          return {
            ...acc,
            [curr.id]: {
              ...curr,
              level: props.level ? props.level + 1 : 2,
            },
          };
        }, {});

        return newData;
      });

      setPages({
        totalPages: response.data.totalPages,
        currentPage: response.data.page,
      });

      showMember(
        randomId,
        randomIdSvg,
        randomIdSvgTwo,
        props.width,
        props.setWidth
      );
    },
    clientOptions: {
      timeout: 120000,
    },
  });

  const loadMorePageApi = useQuery({
    url: `${endpoints.community.FIRST_lINE(props?.id, pages.currentPage + 1)}`,
    method: "GET",
    executeImmediately: false,
    onSuccess: (response) => {
      props.setTreeData((prevState) => {
        const newData = { ...prevState };

        const pathString = props.upline.reduce((acc, curr) => {
          return acc + "[" + curr + "][listTree]";
        }, "");

        const thisEl = Object.byString(newData, pathString)[props.id];

        thisEl.listTree = {
          ...thisEl.listTree,
          ...response.data.results.reduce((acc, curr) => {
            return {
              ...acc,
              [curr.id]: {
                ...curr,
                level: props.level,
              },
            };
          }, {}),
        };

        return newData;
      });

      setPages({
        ...pages,
        currentPage: response.data.page,
      });
    },
    clientOptions: {
      timeout: 120000,
    },
  });

  return (
    <>
      <div
        style={{ marginLeft: 14 }}
        className={classNames(Style.secondChild, "row_small")}
      >
        <div style={{ minWidth: props.width, display: "flex" }}>
          <div className={Style.containerFrame}>
            <div
              style={props.level ? { marginLeft: 40 * props.level } : null}
              className={Style.allFlex}
            >
              {props.downlineUsersCount > 0 ? (
                <div className={Style.plus_border}>
                  {props.level === 1 ? (
                    <div className={Style.vertical_left_initial}></div>
                  ) : (
                    <div className={Style.vertical_left}></div>
                  )}
                  <div
                    className={Style.pre_line}
                    style={{ left: props.level * 40 * -1 - 43 }}
                  ></div>
                  <div className={Style.orizontal}></div>
                  {(() => {
                    let elements = [];
                    for (let i = 0; i < props.level; i++) {
                      elements.push(
                        <div
                          className={Style.orizontal}
                          style={{ transform: `translateX(${-40 * i}px)` }}
                          key={i}
                        ></div>
                      );
                    }
                    return elements;
                  })()}
                  <div
                    id={randomIdSvg}
                    className={
                      communityAPI.isLoading
                        ? Style.textIconPlusLoading
                        : Style.text_icon_plus
                    }
                    onClick={() => {
                      if (props?.listTree === undefined)
                        communityAPI.executeQuery({});
                      else
                        showMember(
                          randomId,
                          randomIdSvg,
                          randomIdSvgTwo,
                          props.width,
                          props.setWidth
                        );
                    }}
                  >
                    <HiPlusSm />
                  </div>
                  <div
                    style={{ display: "none" }}
                    id={randomIdSvgTwo}
                    className={Style.text_icon_plus}
                    onClick={() =>
                      showMember(
                        randomId,
                        randomIdSvg,
                        randomIdSvgTwo,
                        props.width,
                        props.setWidth
                      )
                    }
                  >
                    <HiMinusSm />
                  </div>
                </div>
              ) : (
                <div style={{ position: "relative" }}>
                  <div className={Style.plus_border_first}>
                    {props.level === 1 ? (
                      <div className={Style.vertical_left_initial}></div>
                    ) : (
                      <div className={Style.vertical_left}></div>
                    )}

                    <div className={Style.icon_circle}>
                      <div className={Style.circle_final}></div>
                    </div>

                    {(() => {
                      let elements = [];
                      for (let i = 0; i < props.level; i++) {
                        elements.push(
                          <div
                            className={Style.orizontal_circle}
                            style={{ transform: `translateX(${-40 * i}px)` }}
                            key={i}
                          ></div>
                        );
                      }
                      return elements;
                    })()}
                  </div>

                  <div
                    className={Style.pre_line_last}
                    style={{ left: props.level * 40 * -1 - 23 }}
                  ></div>
                </div>
              )}

              <div style={{ position: "relative" }}>
                <div
                  className={classNames(Style.avatar)}
                  style={
                    props.privateMode
                      ? { backgroundImage: `url(${bluredPicture})` }
                      : props.profilePicture !== null
                      ? {
                          backgroundImage: `url(${props.profilePicture})`,
                        }
                      : { backgroundImage: `url(${AvatarMember})` }
                  }
                />
              </div>

              <div className={Style.flexColumn}>
                {/* Name */}
                <div className={Style.title_member}>
                  <div className={Style.username}>
                    {props.privateMode === true
                      ? "@*****"
                      : "@" + props.username}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={Style.containerFrame}>
          <div className={Style.rightSecondChild}>
            <div className={Style.view_points}>
              <span
                style={{ textDecoration: "underline" }}
                onClick={() =>
                  generalModalRef?.current?.updateBody(
                    <CommunityPointsModal
                      generalModalRef={generalModalRef}
                      userId={props?.id}
                      //add your props here
                    />,
                    {
                      modalStyle: {
                        maxWidth: "490px",
                        background: ` no-repeat top left, no-repeat bottom right, var(--bg-like-blur)`,
                      },
                    }
                  )
                }
              >
                View Points
              </span>
              {/* {props.privateMode
                ? "*** VP"
                : (props.personalVp ? props.personalVp : "0") + " VP"} */}
            </div>

            <div className={Style.ratio}>
              <img src={rankName} alt="" width={70} />
            </div>

            <div className={Style.status}>
              <span>
                {props.privateMode ? (
                  "***"
                ) : props.active ? (
                  <span className={Style.activeStatus}>Active</span>
                ) : (
                  <span className={Style.inactive}>Inactive</span>
                )}
              </span>
            </div>

            <div className={Style.member}>
              {props.privateMode === true ? "*** " : props?.downlineUsersCount}{" "}
            </div>

            <div className={Style.member_since}>
              {props.privateMode
                ? "***"
                : new Date(props.dateJoined)
                    .toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })
                    .replace(/\//g, "-")}
            </div>
          </div>
        </div>
      </div>

      <div id={randomId} className="row_none">
        {props &&
          props.listTree &&
          Object.keys(props.listTree).map((key, index) => {
            return (
              <div id={randomId} key={index}>
                <ClubRowSmall
                  width={props.width}
                  setWidth={props.setWidth}
                  privateMode={props.privateMode}
                  {...props.listTree[key]}
                  setTreeData={props.setTreeData}
                  upline={[...props.upline, props.id]}
                  rankApi={props.rankApi}
                  generalModalRef={generalModalRef}
                />
              </div>
            );
          })}

        {pages.totalPages > pages.currentPage && (
          <Button
            style={props.level ? { marginLeft: 40 * props.level } : null}
            className={Style.loadmore}
            isLoading={loadMorePageApi.isLoading}
            onClick={() => {
              loadMorePageApi.executeQuery({});
            }}
          >
            Load More
            <div
              className={Style.dotBefore}
              style={
                props.level ? { left: -63 + -(props.level - 2) * 40 } : null
              }
            />
            <div
              className={Style.dotAfter}
              style={
                props.level ? { left: -16 + -(props.level - 2) * 40 } : null
              }
            />
          </Button>
        )}
      </div>
    </>
  );
};

const ListView = ({
  rankApi,
  userId,
  privacyMode,
  generalModalRef,
  searchValue = "",
}) => {
  const [width, setWidth] = useState(400);
  const [pages, setPages] = useState({
    totalPages: null,
    currentPage: null,
  });
  const [treeData, setTreeData] = useState([]);

  const treeDataApi = useQuery({
    url: endpoints.community.FIRST_lINE(userId, 1, searchValue),
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      setTreeData(
        response.data.results.reduce((acc, item) => {
          return {
            ...acc,
            [item.id]: {
              ...item,
              level: 1,
            },
          };
        }, {})
      );
      setPages({
        totalPages: response.data.totalPages,
        currentPage: response.data.page,
      });
    },
    clientOptions: {
      timeout: 120000,
    },
  });

  const updateTreeDataApi = useQuery({
    url: endpoints.community.FIRST_lINE(userId, pages.currentPage + 1),
    method: "GET",
    executeImmediately: false,
    onSuccess: (response) => {
      setTreeData((prevState) => {
        const newData = { ...prevState };
        response.data.results.forEach((item) => {
          newData[item.id] = {
            ...item,
            level: 1,
          };
        });
        return newData;
      });
      setPages({
        ...pages,
        currentPage: response.data.page,
      });
    },
    clientOptions: {
      timeout: 120000,
    },
  });

  return (
    <Card
      apiHook={treeDataApi}
      minHeight="80px"
      className={Style.treeCard}
      style={{ borderColor: "transparent", padding: 0 }}
    >
      {Object.keys(treeData).length === 0 ? (
        <div className={Style.noLine}>No line data</div>
      ) : (
        <div className={Style.list_member}>
          {Object.keys(treeData).map((key, index) => (
            <div key={index} style={{ borderRadius: 20 }}>
              <ClubRow
                index={index}
                width={width}
                setWidth={setWidth}
                {...treeData[key]}
                privateMode={privacyMode}
                setTreeData={setTreeData}
                rankApi={rankApi}
                generalModalRef={generalModalRef}
              />
            </div>
          ))}

          {pages?.totalPages > pages?.currentPage && (
            <Button
              className={Style.loadmoreCore}
              isLoading={updateTreeDataApi.isLoading}
              onClick={() => {
                updateTreeDataApi.executeQuery({});
              }}
            >
              Load More
            </Button>
          )}
        </div>
      )}
    </Card>
  );
};

export default ListView;
