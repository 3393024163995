import React, { useState } from "react";

// Components
import Card from "../../../../../components/advanced/card/Card";
import BestPerformanceItem from "./components/bestPerformanceItem/BestPerformanceItem";

// Libraries
import { Select } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";

// Data
import endpoints from "../../../../../data/endpoints";

// Styles
import Style from "./Statistics.module.css";

// Constants

const REFERRAL = "referral";
const TEAM_VP = "team_vp";
const PERSONAL_VP = "personal_vp";

const FilterList = [
  {
    name: "Most Referrals",
    value: REFERRAL,
  },
  {
    name: "Most Team Volume",
    value: TEAM_VP,
  },
  {
    name: "Most Personal Volume",
    value: PERSONAL_VP,
  },
];

const Statistics = () => {
  // States
  const [filter, setFilter] = useState(FilterList[0]);

  // Queries
  const statisticsAPI = useQuery({
    url: endpoints.dashboard.STATISTICS(filter?.value),
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  return (
    <Card
      apiHook={statisticsAPI}
      minHeight={"520px"}
      className={Style.teamGrowthContainer}
    >
      <div className={Style.heading}>
        <h5>Statistics</h5>
        <div className={Style.selectContainer}>
          <Select
            style={{ minWidth: "180px" }}
            items={FilterList}
            value={filter}
            labelKey={"name"}
            setValue={(value) => {
              setFilter(value);
            }}
          />
        </div>
      </div>
      <div>
        {filter?.value &&
          statisticsAPI?.response?.data?.slice(0, 5)?.map((item, index) => (
            <div key={`rank${index}`} className={Style.bodyData}>
              <BestPerformanceItem item={item} />
              {index !== statisticsAPI?.response?.data?.length - 1 && (
                <div className={Style.itemBorder} />
              )}
            </div>
          ))}

        {statisticsAPI?.response?.data?.length < 1 && (
          <div className={Style.noDataAvailableBestPerformance}>
            No data available
          </div>
        )}
      </div>
    </Card>
  );
};

export default Statistics;
