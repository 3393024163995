import React, { useEffect, useContext } from "react";

// Components
import Loader from "../../../components/ui/loader/Loader";
import Summary from "./components/summary/Summary";
import BonusAnalytics from "./components/bonusAnalytics/BonusAnalytics";
import BonusHistory from "./components/bonusHistory/BonusHistory";
import GradientHeaderV2 from "../../../components/advanced/gradientHeaderV2/GradientHeaderV2";

// Libraries
import { Container } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";
import { useNavigate } from "react-router-dom";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Data
import config from "../../../data/config";

// Styles
import Style from "./InfinityBonusView.module.css";

const InfinityBonusView = () => {
  // Hooks
  const navigate = useNavigate();

  // Contexts
  const { paths } = useContext(RoutesContext);

  useEffect(() => {
    document.title = `${config.WEBSITE_NAME} | Infinity Bonus`;
    //eslint-disable-next-line
  }, []);

  return (
    <PermissionRoute
      loader={<Loader />}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        // navigate
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        // dispatcher
        return true;
      }}
    >
      <Container>
        <section className={Style.headerSection}>
          <GradientHeaderV2
            subtitle={"Discover the mechanics behind Infinity Bonus to maximize your earning in Xera."}
            title={"Want to know how to Unlock Levels In The Infinity Bonus?"}
            linkText={"Vist Our Wiki for More Details"}
          />
        </section>
        <section className={Style.section}>
          <Summary />
        </section>
        <section className={Style.section}>
          <BonusAnalytics />
        </section>
        {/* <section className={Style.section}>
          <InfinityBonusStats />
        </section> */}
        <section className={Style.section}>
          <BonusHistory />
        </section>
      </Container>
    </PermissionRoute>
  );
};

export default InfinityBonusView;
