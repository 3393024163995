import React from "react";

// Libraries

// Images
import firstStepDone from "../../../../../assets/svg/firstStepDone.svg";

// Styles
import Style from "./Steps.module.css";

const Steps = ({ setActiveState, firstStepAPI, firstStepsArray }) => {
  const incompleteList = firstStepsArray
    ?.filter((item) => !item.done)
    ?.sort((a, b) => a.order - b.order);

  return (
    <div className={Style.stepsContainer}>
      <div className={Style.stepsLine}/>
      {firstStepAPI?.isSuccess &&
        firstStepsArray
          .sort((a, b) => a.order - b.order)
          .map((item, index) => (
            <div
              key={index}
              onClick={() => {
                if (item.done || item.order === incompleteList[0]?.order)
                  setActiveState(item.step);
              }}
              className={
                item.done || item.order === incompleteList[0]?.order
                  ? Style.step
                  : Style.notAllowed
              }
            >
              {item.done ? (
                <img
                  rel="preload"
                  as="image"
                  className={Style.stepDoneIcon}
                  src={firstStepDone}
                  alt="Icon"
                  width={38}
                  height={38}
                />
              ) : item.order === incompleteList[0]?.order ? (
                <div className={Style.stepOrderInProgress}>{index + 1}</div>
              ) : (
                <div className={Style.stepOrder}>{index + 1}</div>
              )}
              <div>
                <h6 className={item.done ? Style.title : Style?.titleLocked}>{item.title}</h6>
                {item.done && (
                  <span className={Style.stepCompletedLabel}>Completed</span>
                )}
              </div>
            </div>
          ))}
    </div>
  );
};

export default Steps;
