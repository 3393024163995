import React, {useEffect} from "react";

// Components
import Loader from "../../../components/ui/loader/Loader";
import SimpleTitle from "../../../components/advanced/simpleTitle/SimpleTitle";

// Libraries
import { Container } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";

// Data
import config from "../../../data/config";


// Styles
import Style from "./ExportView.module.css";
import Exports from "./components/exports/Exports";

const ExportView = () => {
  
  useEffect(() => {
    document.title = `${config.WEBSITE_NAME} | Exports`;
    //eslint-disable-next-line
  }, []);

  return (
    <PermissionRoute
      loader={<Loader />}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        // navigate
      }}
      permissionController={(response) => {
        // dispatcher
        return true;
      }}
    >
      <Container>
        <SimpleTitle title="Exports" />

        <section className={Style.section}>
          <Exports/>
        </section>

      </Container>
    </PermissionRoute>
  );
};

export default ExportView;
