import React from 'react'

const FisrtStepIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <g clipPath="url(#clip0_311_2649)">
                <path d="M0.916626 8.24996C0.916626 5.68305 0.916626 4.3996 1.41618 3.41917C1.8556 2.55676 2.55676 1.8556 3.41917 1.41618C4.3996 0.916626 5.68305 0.916626 8.24996 0.916626H13.75C16.3169 0.916626 17.6003 0.916626 18.5808 1.41618C19.4432 1.8556 20.1443 2.55676 20.5837 3.41917C21.0833 4.3996 21.0833 5.68305 21.0833 8.24996V13.75C21.0833 16.3169 21.0833 17.6003 20.5837 18.5808C20.1443 19.4432 19.4432 20.1443 18.5808 20.5837C17.6003 21.0833 16.3169 21.0833 13.75 21.0833H8.24996C5.68305 21.0833 4.3996 21.0833 3.41917 20.5837C2.55676 20.1443 1.8556 19.4432 1.41618 18.5808C0.916626 17.6003 0.916626 16.3169 0.916626 13.75V8.24996Z" stroke="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.5833 17.4166L16.8072 11.9089C17.1417 10.4039 16.1738 8.91806 14.662 8.61571L12.8333 8.24996V6.41663C12.8333 5.4041 12.0125 4.58329 11 4.58329C9.98744 4.58329 9.16663 5.4041 9.16663 6.41663V11L8.22828 10.3744C7.26631 9.73308 5.95994 10.08 5.44289 11.1141C4.96815 12.0636 5.353 13.2181 6.30249 13.6929L7.02282 14.0531C7.80854 14.4459 8.40602 15.1348 8.68382 15.9682L9.16663 17.4166L15.5833 17.4166Z" stroke="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_311_2649">
                    <rect width="22" height="22" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default FisrtStepIcon