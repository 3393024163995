import React, { useContext, useEffect } from "react";

// Components
import Loader from "../../../components/ui/loader/Loader";

// Libraries
import useQuery from "@hybris-software/use-query";
import { AuthRoute } from "@hybris-software/use-auth";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "@hybris-software/ui-kit";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Data
import endpoints from "../../../data/endpoints";
import config from "../../../data/config";

// Styles
import Style from "./SpyLoginView.module.css";

const SpyLoginView = () => {
  // Hooks
  const navigate = useNavigate();

  // Contexts
  const { paths } = useContext(RoutesContext);
  const { errorRef } = useOutletContext();

  const urlParams = new URLSearchParams(window.location.search);
  const paramToken = urlParams.get("token");

  const getTokenSpyApi = useQuery({
    url: endpoints.auth.GET_TOKEN_SPY,
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      sessionStorage.setItem("token", response?.data?.token);
      sessionStorage.setItem("inspectorTool", "true");
      navigate(paths.main.firstStep);
    },
    onError: (error) => {
      if (
        error?.response?.status === 400 &&
        error?.response?.data?.code === "USER_BLOCKED"
      )
        errorRef?.current?.updateBody(
          <ErrorSpy
            errorRef={errorRef}
            title="User Inactive"
            description="This user is inactive. You can't login with this user"
          />
        );
      else errorRef?.current?.updateBody(<ErrorSpy errorRef={errorRef} />);
    },
    onUnauthorized: () => {
      errorRef?.current?.updateBody(<ErrorSpy errorRef={errorRef} />);
    },
  });

  const platformConfigAPI = useQuery({
    url: endpoints.PLATFORM_CONFIG,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      if (response?.data?.maintainanceMode) {
        navigate(paths.generic.maintenance);
      }
    },
    clientOptions: {
      timeout: 120000,
    },
  });

  useEffect(() => {
    if (paramToken) {
      localStorage.clear();
      getTokenSpyApi.executeQuery({
        token: paramToken,
      });
    } else navigate(paths.main.firstStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthRoute
      loader={<Loader fullscreen={true} />}
      minimumLoadingTime={0}
      firstApiLoading={platformConfigAPI?.isLoading}
      forLoggedUser={false}
      action={() => {
        navigate(paths.main.firstStep);
      }}
    >
      <div className={Style.center}>
        <div className={Style.content}>
          <div className={Style.loaderBox}>
            <img
              src={config.LOGO_SMALL}
              className={Style.rotate}
              alt="loader"
              draggable="false"
            />
            <img
              src={config.LOGO_WORDS}
              className={Style.fixed}
              alt="loader"
              draggable="false"
            />
          </div>
        </div>
      </div>
    </AuthRoute>
  );
};

const ErrorSpy = ({ errorRef, title, description }) => {
  const navigate = useNavigate();

  const { paths } = useContext(RoutesContext);
  return (
    <div className={Style.errorPopup}>
      <h3>{title ?? "Session expired"}</h3>
      <p>
        {description ??
          "The token has expired. Please try again. If the problem persists, please contact the administrator."}
      </p>
      <Button
        className={Style.button}
        onClick={() => {
          window.close() ?? navigate(paths.main.firstStep);
          errorRef?.current?.destroy();
        }}
      >
        Close
      </Button>
    </div>
  );
};

export default SpyLoginView;
