import { getCountryListMap } from 'country-flags-dial-code';

const allowedCountryList = [
	'AD', // Andorra
	'AG', // Antigua and Barbuda
	'AU', // Australia
	'AT', // Austria
	'BE', // Belgium
	'CA', // Canada
	'DK', // Denmark
	'FI', // Finland
	'FR', // France
	'DE', // Germany
	'IS', // Iceland
	'IE', // Ireland
	'JP', // Japan
	'LI', // Liechtenstein
	'LU', // Luxembourg
	'NL', // Netherlands
	'NZ', // New Zealand
	'NO', // Norway
	'SG', // Singapore
	'ES', // Spain
	'SE', // Sweden
	'CH', // Switzerland
	'GB', // United Kingdom
	'UY', // Uruguay
	'AM', // Armenia
	'BH', // Bahrain
	'BJ', // Benin
	'BT', // Bhutan
	'BW', // Botswana
	'BN', // Brunei/ Brunei Darussalam
	'BG', // Bulgaria
	'CV', // Cabo Verde/ Republic of Cabo Verde
	'CL', // Chile
	'CZ', // Czechia (Czech Republic)
	'DM', // Dominica/ Commonwealth of Dominica
	'EG', // Egypt
	'EE', // Estonia
	'GE', // Georgia
	'GR', // Greece
	'GD', // Grenada
	'GY', // Guyana
	'HU', // Hungary
	'IN', // India
	'IL', // Israel
	'IT', // Italy
	'KI', // Kiribati/Republic of Kiribati
	'KW', // Kuwait
	'LV', // Latvia
	'LT', // Lithuania
	'MV', // Maldives
	'MT', // Malta
	'ME', // Montenegro
	'NA', // Namibia
	'MK', // North Macedonia/ Republic of North Macedonia
	'OM', // Oman
	'PL', // Poland
	'PT', // Portugal
	'QA', // Qatar
	'RO', // Romania
	'RW', // Rwanda
	'KN', // Saint Kitts and Nevis
	'LC', // Saint Lucia
	'VC', // Saint Vincent and the Grenadines
	'SM', // San Marino
	'SK', // Slovakia
	'SI', // Slovenia
	'SB', // Solomon Islands
	'KR', // South Korea
	'TL', // Timor-Leste
	'TO', // Tonga
	'TV', // Tuvalu
	'AL', // Albania
	'DZ', // Algeria
	'AO', // Angola
	'AI', // Anguilla
	'AR', // Argentina
	'AZ', // Azerbaijan
	'BS', // Bahamas
	'BD', // Bangladesh
	'BB', // Barbados
	'BZ', // Belize
	'BM', // Bermuda
	'BO', // Bolivia
	'BA', // Bosnia and Herzegovina
	'BR', // Brazil
	'VG', // British Virgin Islands
	'BF', // Burkina Faso
	'BI', // Burundi
	'KH', // Cambodia
	'CM', // Cameroon
	'KY', // Cayman Islands
	'CF', // Central African Republic
	'TD', // Chad
	'CX', // Christmas Island
	'CC', // Cocos (Keeling) Islands
	'CO', // Colombia
	'KM', // Comoros
	'CG', // Congo
	'CK', // Cook Islands
	'CR', // Costa Rica
	'CI', // Cote d'Ivoire/Ivory Coast
	'HR', // Croatia
	'CY', // Cyprus
	'DJ', // Djibouti
	'DO', // Dominican Republic
	'EC', // Ecuador
	'SV', // El Salvador
	'GQ', // Equatorial Guinea
	'ER', // Eritrea
	'SZ', // Eswatini
	'ET', // Ethiopia
	'FK', // Falkland Islands
	'FJ', // Fiji
	'PF', // French Polynesia
	'GA', // Gabon
	'GM', // Gambia
	'GH', // Ghana
	'GI', // Gibraltar
	'GU', // Guam
	'GT', // Guatemala
	'GN', // Guinea
	'GW', // Guinea-Bissau
	'HM', // Heard Island and McDonald Islands
	'HN', // Honduras
	'ID', // Indonesia
	'IQ', // Iraq
	'JM', // Jamaica
	'JO', // Jordan
	'KZ', // Kazakhstan
	'KE', // Kenya
	'KG', // Kyrgyzstan
	'LA', // Laos
	'LB', // Lebanon
	'LS', // Lesotho
	'LR', // Liberia
	'MG', // Madagascar
	'MW', // Malawi
	'MY', // Malaysia
	'ML', // Mali
	'MH', // Marshall Islands
	'MQ', // Martinique
	'MR', // Mauritania
	'MU', // Mauritius
	'MX', // Mexico
	'MD', // Moldova
	'MN', // Mongolia
	'MS', // Montserrat
	'MA', // Morocco
	'MZ', // Mozambique
	'NP', // Nepal
	'NC', // New Caledonia
	'NI', // Nicaragua
	'NE', // Niger
	'NU', // Niue
	'NF', // Norfolk Island
	'PK', // Pakistan
	'PW', // Palau
	'PG', // Papua New Guinea
	'PY', // Paraguay
	'PE', // Peru
	'PH', // Philippines
	'PM', // Saint Pierre and Miquelon
	'BL', // Saint-Barthélemy
	'MF', // Saint-Martin
	'WS', // Samoa
	'ST', // Sao Tome and Principe
	'SA', // Saudi Arabia
	'SN', // Senegal
	'RS', // Serbia
	'SC', // Seychelles
	'SL', // Sierra Leone
	'SO', // Somalia
	'ZA', // South Africa
	'LK', // Sri Lanka
	'SH', // St Helena
	'SR', // Suriname
	'TJ', // Tajikistan
	'TZ', // Tanzania
	'TH', // Thailand
	'GP', // The Islands of Guadeloupe
	'TG', // Togo
	'TK', // Tokelau
	'TT', // Trinidad and Tobago
	'TN', // Tunisia
	'TR', // Turkey
	'TM', // Turkmenistan
	'TC', // Turks and Caicos Islands
	'UG', // Uganda
	'AE', // United Arab Emirates
	'VI', // US Virgin Islands
	'UZ', // Uzbekistan
	'VU', // Vanuatu
	'VE', // Venezuela
	'VN', // Vietnam
	'WF', // Wallis and Futuna
	'YE', // Yemen
	'ZM', // Zambia
	'ZW', // Zimbabwe
	'CN', // China
	'NG', // Nigeria
	'PA', // Panama
];

let countryList = Object.values(getCountryListMap());

countryList.push({
	code: 'HM',
	country: 'Heard Island and McDonald Islands',
	dialCode: '+672',
	flag: `<svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-hm" viewBox="0 0 640 480">
            <path fill="#00008B" d="M0 0h640v480H0z"/>
            <path fill="#fff" d="m37.5 0 122 90.5L281 0h39v31l-120 89.5 120 89V240h-40l-120-89.5L40.5 240H0v-30l119.5-89L0 32V0z"/>
            <path fill="red" d="M212 140.5 320 220v20l-135.5-99.5zm-92 10 3 17.5-96 72H0zM320 0v1.5l-124.5 94 1-22L295 0zM0 0l119.5 88h-30L0 21z"/>
            <path fill="#fff" d="M120.5 0v240h80V0h-80ZM0 80v80h320V80H0Z"/>
            <path fill="red" d="M0 96.5v48h320v-48zM136.5 0v240h48V0z"/>
            <path fill="#fff" d="m527 396.7-20.5 2.6 2.2 20.5-14.8-14.4-14.7 14.5 2-20.5-20.5-2.4 17.3-11.2-10.9-17.5 19.6 6.5 6.9-19.5 7.1 19.4 19.5-6.7-10.7 17.6 17.4 11.1Zm-3.7-117.2 2.7-13-9.8-9 13.2-1.5 5.5-12.1 5.5 12.1 13.2 1.5-9.8 9 2.7 13-11.6-6.6-11.6 6.6Zm-104.1-60-20.3 2.2 1.8 20.3-14.4-14.5-14.8 14.1 2.4-20.3-20.2-2.7 17.3-10.8-10.5-17.5 19.3 6.8L387 178l6.7 19.3 19.4-6.3-10.9 17.3 17.1 11.2ZM623 186.7l-20.9 2.7 2.3 20.9-15.1-14.7-15 14.8 2.1-21-20.9-2.4 17.7-11.5-11.1-17.9 20 6.7 7-19.8 7.2 19.8 19.9-6.9-11 18 17.8 11.3Zm-96.1-83.5-20.7 2.3 1.9 20.8-14.7-14.8-15.1 14.4 2.4-20.7-20.7-2.8 17.7-11L467 73.5l19.7 6.9 7.3-19.5 6.8 19.7 19.8-6.5-11.1 17.6 17.4 11.5ZM234 385.7l-45.8 5.4 4.6 45.9-32.8-32.4-33 32.2 4.9-45.9-45.8-5.8 38.9-24.8-24-39.4 43.6 15 15.8-43.4 15.5 43.5 43.7-14.7-24.3 39.2 38.8 25.1Z"/>
          </svg>`,
});

countryList = countryList
	.filter((item) => allowedCountryList.includes(item.code))
	.sort((a, b) => {
		if (a.name < b.name) return -1;
		else if (a.name > b.name) return 1;
		else return 0;
	});

export default countryList;
