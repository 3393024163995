import React from "react";

const DashboardIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M4.62637 2.29208C3.46813 2.56377 2.56377 3.46813 2.29208 4.62637C2.08658 5.50246 2.08658 6.41421 2.29208 7.2903C2.56377 8.44854 3.46813 9.3529 4.62637 9.62459C5.50246 9.83009 6.41421 9.83009 7.29029 9.62459C8.44854 9.3529 9.3529 8.44854 9.62459 7.2903C9.83009 6.41421 9.83009 5.50246 9.62459 4.62637C9.3529 3.46813 8.44854 2.56377 7.2903 2.29208C6.41421 2.08658 5.50246 2.08658 4.62637 2.29208Z"
        stroke="none"
        strokeWidth="1.5"
      />
      <path
        d="M4.62637 12.3755C3.46813 12.6471 2.56377 13.5515 2.29208 14.7097C2.08658 15.5858 2.08658 16.4976 2.29208 17.3737C2.56377 18.5319 3.46813 19.4363 4.62637 19.708C5.50246 19.9135 6.41421 19.9135 7.29029 19.708C8.44854 19.4363 9.3529 18.5319 9.62459 17.3737C9.83009 16.4976 9.83009 15.5858 9.62459 14.7097C9.3529 13.5515 8.44854 12.6471 7.2903 12.3755C6.41421 12.17 5.50246 12.17 4.62637 12.3755Z"
        stroke="none"
        strokeWidth="1.5"
      />
      <path
        d="M14.7097 2.29208C13.5515 2.56377 12.6471 3.46813 12.3755 4.62637C12.17 5.50246 12.17 6.41421 12.3755 7.2903C12.6471 8.44854 13.5515 9.3529 14.7097 9.62459C15.5858 9.83009 16.4976 9.83009 17.3737 9.62459C18.5319 9.3529 19.4363 8.44854 19.708 7.2903C19.9135 6.41421 19.9135 5.50246 19.708 4.62637C19.4363 3.46813 18.5319 2.56377 17.3737 2.29208C16.4976 2.08658 15.5858 2.08658 14.7097 2.29208Z"
        stroke="none"
        strokeWidth="1.5"
      />
      <path
        d="M14.7097 12.3755C13.5515 12.6471 12.6471 13.5515 12.3755 14.7097C12.17 15.5858 12.17 16.4976 12.3755 17.3737C12.6471 18.5319 13.5515 19.4363 14.7097 19.708C15.5858 19.9135 16.4976 19.9135 17.3737 19.708C18.5319 19.4363 19.4363 18.5319 19.708 17.3737C19.9135 16.4976 19.9135 15.5858 19.708 14.7097C19.4363 13.5515 18.5319 12.6471 17.3737 12.3755C16.4976 12.17 15.5858 12.17 14.7097 12.3755Z"
        stroke="none"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default DashboardIcon;
