import React, { Fragment, useContext, useState } from "react";

//Components
import Card from "../../../../../components/advanced/card/Card";
import HalfDoughnutChart from "../../../../../components/advanced/halfDoughnutChart/HalfDoughnutChart";
import SimpleStyledCard from "./../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";
import PointsButtonGroup from "../../../../../components/advanced/pointsButtonGroup/PointsButtonGroup";

// Images
import maxRankLimit from "../../../../../assets/svg/maxRankLimit.svg";

// Libraries
import useQuery from "@hybris-software/use-query";
import { useWindowSize } from "@hybris-software/ui-kit";

// Utils
import formatLargeNumber from "../../../../../utils/formatLargeNumber";
import getOrdinal from "../../../../../utils/getOrdinal";

// Data
import endpoints from "../../../../../data/endpoints";
import { unitsList } from "../../../../../data/constants";

// Contexts
import GeneralInfoContext from "../../../../../contexts/GeneralInfoContext";

// Styles
import Style from "./YourRank.module.css";
import CustomToolTip from "../../../../../components/advanced/customToolTip/CustomToolTip";
import DateFormatter from "../../../../../components/advanced/dateFormatter/DateFormatter";

const YourRank = () => {
  // States
  const [selectedValue, setSelectedValue] = useState(unitsList.VP.value);

  // Contexts
  const [generalInfo] = useContext(GeneralInfoContext);

  // Quires
  const youRankAPI = useQuery({
    url: endpoints.dashboard.RANK_REPORT,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  const rewardsPointsAPI = useQuery({
    url: endpoints.dashboard.REWARDS_POINTS(
      generalInfo?.userInfo?.id,
      selectedValue
    ),
    method: "GET",
    executeImmediately: true,
    clientOptions: {
      timeout: 120000,
    },
  });
  const teamDepthAPI = useQuery({
    url: endpoints.dashboard.TEAM_DEPTH(generalInfo?.userInfo?.id),
    method: "GET",
    executeImmediately: true,
    clientOptions: {
      timeout: 120000,
    },
  });

  // Consts
  const rankData = youRankAPI?.response?.data;
  const { width } = useWindowSize();
  const isMobile = width >= 575 ? true : false;

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", {
      notation: "compact",
      compactDisplay: "short",
    }).format(number);
  };

  // Functions
  function getLabelByValue(value) {
    for (const key in unitsList) {
      if (unitsList[key].value === value) {
        return unitsList[key].label;
      }
    }
    return "Label not found";
  }

  // Data
  const cardsData = [
    {
      title: "Total Volume",
      info: "Total Volume refers to the volume points generated by your entire team",
      value: formatLargeNumber(rewardsPointsAPI?.response?.data?.teamTotal),
      fullNumber: rewardsPointsAPI?.response?.data?.teamTotal,
      unit: getLabelByValue(selectedValue),
      backgroundStyle: Style.infoCardBackground,
    },
    {
      title: "Strong Leg Volume",
      info: "Your Strong leg is the part of your community that generates the most rewards.",
      value: formatLargeNumber(rewardsPointsAPI?.response?.data?.strongLeg),
      fullNumber: rewardsPointsAPI?.response?.data?.strongLeg,
      unit: getLabelByValue(selectedValue),
      backgroundStyle: Style.infoCardBackground,
    },
    {
      title: <>{getOrdinal(1)} Line Turnover</>,
      info: <>{getOrdinal(1)} Line Turnover</>,
      value: formatLargeNumber(
        rewardsPointsAPI?.response?.data?.firstLineTotal
      ),
      fullNumber: rewardsPointsAPI?.response?.data?.firstLineTotal,
      unit: getLabelByValue(selectedValue),
      backgroundStyle: Style.infoCardBackground,
    },
    {
      title: "Weak Legs Volume",
      info: "Weak legs includes the sum total of volume generated on all legs other than the strong leg.",
      value: formatLargeNumber(rewardsPointsAPI?.response?.data?.weakLeg),
      fullNumber: rewardsPointsAPI?.response?.data?.weakLeg,
      unit: getLabelByValue(selectedValue),
      backgroundStyle: Style.infoCardBackgroundSecond,
    },
    {
      title: "Personal Volume",
      info: "Personal Volume refers to the amount of volume points you have generated from your purchases.",
      value: formatLargeNumber(rewardsPointsAPI?.response?.data?.personalTotal),
      fullNumber: rewardsPointsAPI?.response?.data?.personalTotal,
      unit: getLabelByValue(selectedValue),
      backgroundStyle: Style.infoCardBackground,
    },
  ];

  const smallCardsData = [
    {
      title: "Team Depth",
      info: "Team depth refers to the number of members in your longest leg.",
      value: getOrdinal(teamDepthAPI?.response?.data?.maxDepth),
      fullNumber: teamDepthAPI?.response?.data?.maxDepth,
      unit: "LEVEL",
      backgroundStyle: Style.infoCardBackgroundThird,
    },
    {
      title: "Team Size",
      info: "Team Size refers to the number of members who have used your referral code and are, hence, part of your community.",
      value: teamDepthAPI?.response?.data?.userCount,
      fullNumber: teamDepthAPI?.response?.data?.userCount,
      unit: "USERS",
      backgroundStyle: Style.infoCardBackgroundSecond,
    },
  ];

  const nextRankList = generalInfo?.rankInfo?.find(
    (item) => item.id === rankData?.currentRank + 1
  );
  const currenRankList = generalInfo?.rankInfo?.find(
    (item) => item.id === rankData?.currentRank
  );

  return (
    <SimpleStyledCard className={Style.section}>
      <div className={Style.sectionFlex}>
        <Card minHeight="400px" apiHook={youRankAPI}>
          <h5>Your Rank</h5>
          <div className={Style.memberRankWrapper}>
            <div className={Style.memberRankInfo}>
              <h5>{currenRankList?.name}</h5>
              <span>
                Since <DateFormatter date={rankData?.rankUpdatedAt} />
              </span>
              <div className={Style.nextRankInfo}>
                {generalInfo?.rankInfo?.find(
                  (item) => item.id === rankData?.currentRank + 1
                ) && (
                  <p className={Style.nextRankParagraph}>
                    Next Rank :{" "}
                    <span>
                      {
                        generalInfo?.rankInfo?.find(
                          (item) => item.id === rankData?.currentRank + 1
                        )?.name
                      }
                    </span>
                  </p>
                )}
              </div>
            </div>
            <div className={Style.memberRankChart}>
              <HalfDoughnutChart
                showPercentage
                percentageStart={rankData?.nextRankProgress}
                size={isMobile ? 260 : 200}
                label={"Next rank progress"}
              />
            </div>
          </div>
          {nextRankList ? (
            <>
              <div className={Style.separator} />
              <div className={Style.rankRulesTitle}>
                <h6>Rules to achieve next rank:</h6>
              </div>
              <ul className={Style.unOrdered}>
                <li>
                  Users required in the first line:{" "}
                  {nextRankList?.firstLineCount} users{" "}
                  {nextRankList?.firstLineCount === 0
                    ? nextRankList?.firstLineCount > 1
                      ? "Users"
                      : "User"
                    : ""}
                </li>
                <li>
                  Total volume required:{" "}
                  {formatNumber(nextRankList?.totalVolume)} VP
                </li>
                <li>
                  Volume required in the first line:{" "}
                  {formatNumber(nextRankList?.firstLevelVolume, 2)} VP
                </li>
              </ul>
            </>
          ) : (
            <>
              <div className={Style.separator} />
              <div className={Style.maxRankLimit}>
                <img src={maxRankLimit} alt="" />
                <h6>Congratulations, You Have</h6>
                <h6>Reached Maximum Rank Limit.</h6>
              </div>
            </>
          )}
        </Card>
        <div>
          <Card apiHook={rewardsPointsAPI} minHeight="370px">
            <div className={Style.pointsHeading}>
              <h5>Your Points</h5>
              <div className={Style.selector}>
                <PointsButtonGroup
                  initialSelected={selectedValue}
                  onSelectedChange={(value) => setSelectedValue(value)}
                />
              </div>
            </div>
            {cardsData?.map((item, index) => (
              <Fragment key={`point-${index}`}>
                <div className={Style.pointLine}>
                  <div>{item.title}:</div>
                  <div>
                    <strong>{item.value} </strong>
                    {item.unit}
                  </div>
                </div>
                {cardsData?.length - 1 !== index && (
                  <div className={Style.separatorWithMargin} />
                )}
              </Fragment>
            ))}
          </Card>
          <Card
            className={Style.extraInfo}
            apiHook={youRankAPI}
            minHeight="100px"
          >
            {smallCardsData?.map((item, index) => (
              <SimpleStyledCard className={Style.smallCard} key={index}>
                <div className={Style.pointLine}>
                  <div className={Style.pointInfoLabel}>
                    {item.title}
                    <CustomToolTip toolTipText={item.info} />
                  </div>
                  <span>
                    <strong>{item.value} </strong>
                    {item.unit}
                  </span>
                </div>
              </SimpleStyledCard>
            ))}
          </Card>
        </div>
      </div>
    </SimpleStyledCard>
  );
};

export default YourRank;
