import React, { useRef } from "react";

// Components
import SimpleStyledCard from "../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";
import DateFormatter from "../../../../../components/advanced/dateFormatter/DateFormatter";

// Libraries
import Table from "@hybris-software/use-table";

// Utilities
import { formatNumberEuropean } from "../../../../../utils/utilityFunctions";

// Data
import endpoints from "../../../../../data/endpoints";
import { customTableStyles } from "../../../../../data/constants";

// Styles
import Style from "./TransactionHistory.module.css";
import { withdrawToken } from "../../../../../data/constants";

const TransactionHistory = () => {

  const columns = [
    {
      Header: "Transaction",
      field: "amount",
      orderField: "amount",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return formatNumberEuropean(row.amount);
      },
    },
    {
      Header: "Type",
      field: "token",
      orderField: "token",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return withdrawToken?.[row.token]?.label || "-";
      },
    },
    {
      Header: "Date",
      field: "createdAt",
      orderField: "created_at",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return <DateFormatter date={row.createdAt} />;
      },
    },
  ];

  const ref = useRef(null);
  const endPoint = endpoints.wallets.TRANSACTIONS;

  return (
    <SimpleStyledCard style={{ overflow: "unset" }}>
      <div className={Style.header}>
        <h5>Transaction History</h5>
      </div>
      <Table
        ref={ref}
        height="410"
        enableAllowedActions={false}
        columns={columns}
        endPoint={endPoint}
        enableSelectableRows={false}
        enableSearch={false}
        enableSearchFieldSelect={false}
        enablePageSizeSelect={true}
        defaultPageSize={50}
        extraFilters={{}}
        Styles={customTableStyles}
        searchBadgesClassName={Style.searchBadges}
        paginationButtonClassName={Style.paginationButton}
        paginationRecordsClassName={Style.paginationRecords}
        toPageInputBaseClassName={Style.toPageInput}
        enableSettings={false}
        enableRefreshBtn={false}
        enableHiddenColumnBadge={false}
      />
    </SimpleStyledCard>
  );
};

export default TransactionHistory;
