import React, { useContext } from "react";

// Components
import OverLappingChart from "./../../../../../components/advanced/overLappingChart/OverLappingChart";
import Card from "./../../../../../components/advanced/card/Card";
import CustomToolTip from "../../../../../components/advanced/customToolTip/CustomToolTip";

// Libraries
import { useNavigate } from "react-router-dom";
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";

//Icons
import VP from "../../../../../assets/svg/VP.svg";

// utilities
import {
  calculatePercentageBetweenCurrentAndMaxValue,
  formatNumberEuropean,
} from "../../../../../utils/utilityFunctions";

// Contexts
import { RoutesContext } from "../../../../../contexts/RoutesContext";
import GeneralInfoContext from "../../../../../contexts/GeneralInfoContext";

// Data
import endpoints from "../../../../../data/endpoints";

// Styles
import Style from "./ActiveQualifiedCharts.module.css";

const ActiveQualifiedCharts = () => {
  // Hooks
  const navigate = useNavigate();

  // Contexts
  const { paths } = useContext(RoutesContext);
  const [generalInfo] = useContext(GeneralInfoContext);

  // Queries
  const activationAPI = useQuery({
    url: endpoints.activeQualified.ACTIVATION(generalInfo?.userInfo?.id),
    method: "GET",
    executeImmediately: true,
    clientOptions: {
      timeout: 120000,
    },
    onSuccess: (res) => {},
  });


  // Data
  const currentRank = generalInfo?.rankInfo?.find(
    (item) =>
      item.id ===
      activationAPI?.response?.data?.infinity.currentMonthCommissionRank
  );


  function daysLeftUntilEndOfMonth() {
    let today = new Date();
    let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    let differenceInDays = Math.ceil(
      (lastDayOfMonth - today) / (1000 * 60 * 60 * 24)
    );

    return differenceInDays;
  }

  return (
    <>
      <div className={Style.grid}>
        <Card apiHook={activationAPI} minHeight={"200px"}>
          <div className={Style.unilevel}>
            <div className={Style.titleWrapper}>
              <h5 className={Style.title}>Active Qualified Unilevel</h5>
              <div
                className={Style.button}
                onClick={() => {
                  navigate(paths.main.qualifiedUnilevel);
                }}
              >
                See More
              </div>
            </div>
              <div className={Style.linesGrid}>
                <div>
                  <div>
                    Lines Qualified{" "}
                    <CustomToolTip toolTipText="The number of lines you are qualified for unilevel bonus" />
                  </div>
                  <div className={Style.linesValue}>
                    {activationAPI?.response?.data?.unilevel?.activeLines}/
                    {activationAPI?.response?.data?.unilevel?.totalLines}
                  </div>
                </div>
                <div>
                  <div>
                    Lines Unlocked
                    <CustomToolTip toolTipText="The number of lines unlocked for your unilevel bonus" />
                  </div>
                  <div className={Style.linesValue}>
                    {" "}
                    {activationAPI?.response?.data?.unilevel?.unlockedLines}/
                    {activationAPI?.response?.data?.unilevel?.totalLines}
                  </div>
                </div>
              </div>
          </div>
        </Card>

        <Card apiHook={activationAPI} minHeight={"200px"}>
          <div
            style={{
              marginBottom: "40px",
            }}
            className={Style.titleWrapper}
          >
            <h5 className={Style.title}>Active Qualified Infinity</h5>
          </div>
            <div className={Style.chartContainer}>
              <div className={Style.labelsFlex}>
                <div className={Style.labelsFlexInner}>
                  <div className={Style.labelWrapper}>
                    <div
                      className={Style.labelCircle}
                      style={{ backgroundColor: "#3899D6" }}
                    />
                    <div className={Style.label}>
                      Total Turnover
                      <div className={Style.bodyWrapper}>
                        <img src={VP} alt="" />
                        <div className={Style.amount}>
                          {formatNumberEuropean(
                            activationAPI?.response?.data?.infinity
                              .activationCurrentPoints,
                            2
                          )}{" "}
                          /{" "}
                          {formatNumberEuropean(
                            activationAPI?.response?.data?.infinity
                              .activationRequiredPoints,
                            2
                          )}
                          <span>VP</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={Style.labelWrapper}>
                    <div
                      className={Style.labelCircle}
                      style={{ backgroundColor: "#08F57F" }}
                    />
                    <div className={Style.label}>
                      Weak Legs
                      <div className={Style.bodyWrapper}>
                        <div className={Style.amount}>
                          {formatNumberEuropean(
                            activationAPI?.response?.data?.infinity
                              .activationWeakLegPoints,
                            2
                          )}
                          <span>VP</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Style.commisionableRank}>
                  <p>Current Month Commissionable Rank:</p>
                  <div className={Style.rankWrapper}>
                    <img src={currentRank?.iconUrl} alt="Rank Icon" />
                    <h5>{currentRank?.name}</h5>
                  </div>
                </div>
              </div>
              <div className={Style.chart}>
                <OverLappingChart
                  innerText={daysLeftUntilEndOfMonth()}
                  textCenterFontSize="14"
                  Title="Days Left!"
                  showInner={false}
                  middlePercentage={calculatePercentageBetweenCurrentAndMaxValue(
                    activationAPI?.response?.data?.infinity
                      .activationWeakLegPoints,
                    activationAPI?.response?.data?.infinity
                      .activationRequiredPoints / 2
                  )}
                  WrapperPercentage={calculatePercentageBetweenCurrentAndMaxValue(
                    activationAPI?.response?.data?.infinity
                      .activationCurrentPoints,
                    activationAPI?.response?.data?.infinity
                      .activationRequiredPoints
                  )}
                  MiddleColor="#08F57F"
                  WrapperColor="#3899D6"
                />
              </div>
            </div>
        </Card>
      </div>
    </>
  );
};

export default ActiveQualifiedCharts;
