import React, { useContext } from "react";

// Components
import CopyInput from "../../../../../components/advanced/copyInput/CopyInput";
import SimpleStyledCard from "../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";
import ReferFriendModal from "./referFriendModal/ReferFriendModal";

// Libraries
import { useOutletContext } from "react-router";

// Context
import GeneralInfoContext from "../../../../../contexts/GeneralInfoContext";

// Data
import config from "../../../../../data/config";

// Images
import modalTopLeftBg from "../../../../../assets/images/modalTopLeftBg.png.png";
import modalBottomRightBg from "../../../../../assets/images/modalBottomRightBg.png.png";

// Icons
import shareInvitationTitleIcon from "../../../../../assets/svg/shareInvitationTitleIcon.svg";
import ShareIcon from "../../../../../assets/svgComponents/ShareIcon";

// Styles
import Style from "./ShareInvitation.module.css";

const ShareInvitation = () => {
  // States
  // Contexts
  const { generalModalRef } = useOutletContext();
  const [generalInfo] = useContext(GeneralInfoContext);

  return (
    <SimpleStyledCard className={Style.shareInvitation}>
      <div className={Style.up}>
        <div className={Style.shareInvitationTitleContainer}>
          <h2 className={Style.shareInvitationTitle}>
            Share the Vision, Fuel the Growth
          </h2>
          <img src={shareInvitationTitleIcon} width={28} height={28} alt="" />
        </div>
        <div className={Style.shareInvitationSubTitle}>
          Empower Your Community by Introducing Them to {config.WEBSITE_NAME}{" "}
          Through Your Unique Referral ID or Direct Link. Together, We Unlock
          New Opportunities and Shape a More Equitable Future.
        </div>
      </div>
      <div className={Style.down}>
        <div className={Style.referralID}>
          <CopyInput
            label={"Referral ID"}
            value={generalInfo?.userInfo?.referralCode}
          />
        </div>
        <div className={Style.referralLink}>
          <CopyInput
            label={"Referral Link"}
            value={`${config.REFERRAL_URL}${generalInfo?.userInfo?.referralCode}`}
          />
        </div>

        <div className={Style.shareActions}>
          <div
            className={Style.shareBtn}
            onClick={() =>
              generalModalRef.current.updateBody(
                <ReferFriendModal
                  generalModalRef={generalModalRef}
                  referralCode={generalInfo?.userInfo?.referralCode}
                />,
                {
                  modalStyle: {
                    maxWidth: "490px",
                    background: `url("${modalTopLeftBg}")  no-repeat top left,
                            url("${modalBottomRightBg}") no-repeat bottom right,
                            var(--bg-modal)`,
                  },
                }
              )
            }
          >
            <ShareIcon />
            <div>Share Invite</div>
          </div>
        </div>
      </div>
    </SimpleStyledCard>
  );
};

export default ShareInvitation;
