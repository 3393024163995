import React, { useEffect, useContext } from "react";

// Libraries
import useQuery from "@hybris-software/use-query";

// Contexts
import { RoutesContext } from "../../../../../contexts/RoutesContext";
import { usePrivacyContext } from "../../../../../contexts/PrivacyContext";

// Utils
import getOrdinal from "../../../../../utils/getOrdinal";

// Components
import Card from "../../../../../components/advanced/card/Card";
import PointsButtonGroup from "../../../../../components/advanced/pointsButtonGroup/PointsButtonGroup";

// Images
import userInfoAvatar from "../../../../../assets/images/placeholder.png";
import blurredImage from "../../../../../assets/images/bluredImage.png";

// Icons
import ExportIcon from "../../../../../assets/svgComponents/ExportIcon";

// Data
import endpoints from "../../../../../data/endpoints";
import { unitsList } from "../../../../../data/constants";

// Style
import Style from "./StrongLeg.module.css";

const StrongLeg = () => {
  //state
  const [selectedValue, setSelectedValue] = React.useState(unitsList.VP.value);

  // Contexts
  const { paths } = useContext(RoutesContext);
  const { privacy } = usePrivacyContext();

  // Quires
  const communityStrongLegAPI = useQuery({
    url: endpoints.community.COMMUNITY_STRONG_LEG,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  const rewardsPointsAPI = useQuery({
    url: endpoints.dashboard.REWARDS_POINTS(
      communityStrongLegAPI?.response?.data?.id,
      selectedValue
    ),
    method: "GET",
    executeImmediately: false,
    clientOptions: {
      timeout: 120000,
    },
  });

  useEffect(() => {
    if (communityStrongLegAPI?.response?.data?.id) {
      rewardsPointsAPI?.executeQuery();
    }
    // eslint-disable-next-line
  }, [communityStrongLegAPI?.isSuccess, selectedValue]);
  
  function getLabelByValue(value) {
    for (let key in unitsList) {
      if (unitsList[key].value === value) {
        return unitsList[key].label;
      }
    }
    return null; // Return null if value is not found
  }

  return (
    <Card
      className={Style.card}
      minHeight={"450px"}
      apiHook={communityStrongLegAPI}
    >
      <div className={Style.wrapper}>
        <div className={Style.header}>
          <h3 className={Style.titleCard}>Strong Leg</h3>

          <PointsButtonGroup
            initialSelected={selectedValue}
            onSelectedChange={(value) => setSelectedValue(value)}
          />
        </div>
        <div className={Style.imgCardWrapper}>
          <img
            src={
              privacy
                ? blurredImage
                : communityStrongLegAPI?.response?.data?.profileLogo ??
                  userInfoAvatar
            }
            alt=""
            className={Style.personalImg}
          />
          <a
            href={paths.main.builderReport.replace(
              ":id",
              communityStrongLegAPI?.response?.data?.id
            )}
            target="_blank"
            rel="noreferrer"
            className={Style.username}
          >
            <div className={Style.personal}>
              @
              {privacy
                ? `*****`
                : communityStrongLegAPI?.response?.data?.username}
              <ExportIcon />
            </div>
          </a>
        </div>
        <Card
          className={Style.valueWrapper}
          minHeight={"230px"}
          apiHook={rewardsPointsAPI}
        >
          <div className={Style.infoWrapper}>
            <div className={Style.left}>
              <Value
                privacy={privacy}
                title="Personal Volume"
                value={rewardsPointsAPI?.response?.data?.personalTotal}
                unit={getLabelByValue(selectedValue)}
                // value={communityStrongLegAPI?.response?.data?.personalVp}
                // unit="VP"
              />
              <Value
                title="Size"
                value={communityStrongLegAPI?.response?.data?.size}
                unit="Users"
                privacy={privacy}
              />
            </div>
            <div className={Style.right}>
              <Value
                title="Total Turnover"
                value={rewardsPointsAPI?.response?.data?.teamTotal}
                unit={getLabelByValue(selectedValue)}
                // value={communityStrongLegAPI?.response?.data?.teamVp}
                // unit="VP"
                privacy={privacy}
              />
              <Value
                privacy={privacy}
                title="Longest Chain"
                value={+communityStrongLegAPI?.response?.data?.longestChain}
                unit="Level"
              />
            </div>
          </div>
        </Card>
      </div>
    </Card>
  );
};

const Value = ({ title, value, unit, privacy }) => {
  return (
    <div className={Style.valueWrapper}>
      <h4 className={Style.vTitle}>{title}</h4>
      <p className={unit === "Level" ? Style.levelValue : Style.vValue}>
        {privacy ? "***" : unit === "Level" ? <>{getOrdinal(value)}</> : value}
        <span className={Style.extention}> {unit}</span>
      </p>
    </div>
  );
};

export default StrongLeg;
