import React, { useState, useEffect } from "react";

// Components
import Card from "../../../../../components/advanced/card/Card";

// Libraries
import { Select } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";

// Data
import endpoints from "../../../../../data/endpoints";

// Styles
import Style from "./ReferralAnalytics.module.css";

const FilterList = [
  {
    name: "Last Week",
    value: "week",
  },
  {
    name: "Last Month",
    value: "month",
  },
  {
    name: "Last Year",
    value: "year",
  },
];

const ReferralAnalytics = () => {
  // States
  const [timeFilter, setTimeFilter] = useState(FilterList[0]);

  // Queries
  const builderReportAPI = useQuery({
    url: endpoints.referrals.REAL_TIME_USERS + "?filter_by=" + timeFilter.value,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {},
    onError: (error) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  const items = [
    {
      name: "Active Referred Users %",
      value: `${builderReportAPI?.response?.data.activeReferredUsersPercentage} %`,
    },
    {
      name: "KYC Approved Count",
      value: builderReportAPI?.response?.data.kycApprovedCount,
    },
    {
      name: "Referrals Count",
      value: builderReportAPI?.response?.data.referralsCount,
    },
    {
      name: "License Purchased Count",
      value: builderReportAPI?.response?.data.licensePurchasedCount,
    },
  ];

  useEffect(() => {
    builderReportAPI.executeQuery();
    //eslint-disable-next-line
  }, [timeFilter]);

  return (
    <Card
      apiHook={builderReportAPI}
      minHeight={"215px"}
      className={Style.generalCard}
    >
      <div className={Style.cardTitle}>
        <h5>Referral Analytics</h5>
        <Select
          items={FilterList}
          value={timeFilter}
          labelKey={"name"}
          setValue={(value) => {
            setTimeFilter(value);
          }}
        />
      </div>
      <div className={Style.gridWrapper}>
        {items.map((item, index) => (
          <div className={Style.card} key={index}>
            <div className={Style.normalBar}></div>
            <div className={Style.infoWrapper}>
              <div className={Style.infoDetails}>
                <p> {item.name}</p>
                {item.value}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default ReferralAnalytics;
