import React, { useRef, useState, useEffect } from "react";

// Icons
import infoIcon from "../../../assets/svg/info.svg";

// Styles
import Style from "./CustomToolTip.module.css";
import { Link } from "react-router-dom";

const CustomToolTip = ({ toolTipText, links = [], icon = infoIcon }) => {
  const tooltipTextRef = useRef(null);
  const [rect, setRect] = useState(null);

  useEffect(() => {
    const updateRect = () => {
      if (tooltipTextRef.current) {
        setRect(tooltipTextRef.current.getBoundingClientRect());
      }
    };
    updateRect();
    window.addEventListener("resize", updateRect);

    return () => {
      window.removeEventListener("resize", updateRect);
    };
  }, [toolTipText]);

  const isOverflowingRight = rect?.right > window.innerWidth;
  const isOverflowingLeft = rect?.left < 0;

  const renderToolTipText = () => {
    if (!toolTipText) return null;

    let words = toolTipText.split(" ");

    return words.map((word, index) => {
      const link = links.find((item) => item.index === index);

      if (link) {
        return (
          <Link to={link.link} key={index} className={Style.link}>
            {word}{" "}
          </Link>
        );
      } else {
        return <span key={index}>{word} </span>;
      }
    });
  };

  return (
    <div
      className={Style.tooltip}
      style={{
        backgroundImage: `url(${icon})`,
      }}
    >
      {toolTipText && (
        <span
          style={{
            left: isOverflowingLeft ? "30px" : "",
            transform: isOverflowingRight ? "translateX(-220px)" : "",
          }}
          className={Style.tooltipText}
          ref={tooltipTextRef}
        >
          {renderToolTipText()}
        </span>
      )}
    </div>
  );
};

export default CustomToolTip;
