import React, { useState, useEffect, useContext } from "react";

// Components
import UserInfo from "./components/userInfo/UserInfo";
import Activities from "./components/activities/Activities";
import Loader from "../../../components/ui/loader/Loader";
import Reports from "./components/reports/Reports";

// Libraries
import { Container } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";
import { useNavigate, useParams } from "react-router-dom";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Data
import config from "../../../data/config";

// Styles
import Style from "./BuilderReportView.module.css";
import ActiveQualifiedCharts from "./components/activeQualifiedCharts/ActiveQualifiedCharts";

const BuilderReportView = () => {
  // Status
  const [userInfo, setUserInfo] = useState({});

  // Consts
  const { id } = useParams();

  // Hooks
  const navigate = useNavigate();

  // Contexts
  const { paths } = useContext(RoutesContext);

  useEffect(() => {
    document.title = `${config.WEBSITE_NAME} | Builder Report`;
    //eslint-disable-next-line
  }, []);

  return (
    <PermissionRoute
      loader={<Loader />}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        // dispatcher
        return true;
      }}
    >
      <Container>
        <section className={Style.UserInfoSection}>
          <UserInfo setUserInfo={setUserInfo} id={id} />
        </section>
        <section className={Style.section}>
          <Reports id={id} />
        </section>
        <section className={Style.section}>
          <ActiveQualifiedCharts id={id} />
        </section>
        <section className={Style.section}>
          <Activities userInfo={userInfo} id={id} />
        </section>
      </Container>
    </PermissionRoute>
  );
};

export default BuilderReportView;
