import { createContext } from "react";

// Data
import endpoints from "../data/endpoints";
import config from "../data/config";

const GeneralInfoContext = createContext({
    rankInfo: [],
    userInfo: {},
    endpoints: endpoints,
    config: config,
  });

export default GeneralInfoContext;
