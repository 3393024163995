import React, { useRef, useState, useMemo, useEffect } from "react";

// Components
import DateFormatter from "../../../../../components/advanced/dateFormatter/DateFormatter";
import RangeDatePicker from "../../../../../components/advanced/rangeDatePicker/RangeDatePicker";
import FilterBadges from "../../../../../components/advanced/filterBadges/FilterBadges";
import { Button, Select } from "@hybris-software/ui-kit";
import Card from "../../../../../components/advanced/card/Card";

// Libraries
import Table from "@hybris-software/use-table";
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";

// Utilities
import { handleResetFilter } from "../../../../../utils/utilityFunctions";

// Data
import endpoints from "../../../../../data/endpoints";
import config from "../../../../../data/config";
import { customTableStyles } from "../../../../../data/constants";

//Icon
import { BsDownload } from "react-icons/bs";

// Styles
import Style from "./Exports.module.css";

const Exports = () => {
  // Variables
  const initialFilters = {
    created_at: {
      gteDatePickerValue: null,
      label: null,
      lteDatePickerValue: null,
    },
    status: null,
    type: null,
  };
  const ref = useRef(null);
  const endPoint = endpoints.exports.EXPORT_LIST;

  // Status
  const [extraFilters, setExtraFilters] = useState({});
  const [currentFilter, setCurrentFilter] = useState(initialFilters);

  const badges = useMemo(() => {
    return currentFilter;
    // eslint-disable-next-line
  }, [extraFilters]);

  // Queries
  const exportFiltersAPI = useQuery({
    url: endpoints.exports.EXPORT_FILTERS,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {},
    onUnauthorized: (response) => {},
    onError: () => {},
  });

  const columns = [
    {
      Header: "#",
      field: "id",
      orderField: "id",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return row.id;
      },
    },
    {
      Header: "Type",
      field: "type",
      orderField: "type",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return exportFiltersAPI?.response?.data?.filters?.type?.exact.find(
          (item) => item.value === row.type
        )?.label;
      },
    },
    {
      Header: "Status",
      field: "status",
      orderField: "status",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return exportFiltersAPI?.response?.data?.filters?.status?.exact.find(
          (item) => item.value === row.status
        ).label;
      },
    },
    {
      Header: "File",
      field: "file",
      orderField: "file",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return (
          <>
            {row.file && (
              <a href={row.file} target="blank" download={"export-file"}>
                <BsDownload color="#757575" />
              </a>
            )}
          </>
        );
      },
    },
    {
      Header: "Date",
      field: "createdAt",
      orderField: "created_at",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return <DateFormatter date={row.createdAt} />;
      },
    },
  ];

  useEffect(() => {
    document.title = `${config.WEBSITE_NAME} | Exports`;
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Card
        minHeight={"210px"}
        apiHook={exportFiltersAPI}
        className={Style.extraFilters}
      >
        <h5 className={Style.heading}>Filter</h5>
        <div className={Style.extraFiltersBody}>
          <div className={Style.datePickerContainer}>
            <h5 className={Style.selectLabel}>Date</h5>
            <RangeDatePicker
              className={Style.datePicker}
              setRangeDate={(value) => {
                setCurrentFilter({
                  ...currentFilter,
                  created_at: {
                    gteDatePickerValue: value ? value[0] : null,
                    lteDatePickerValue: value ? value[1] : null,
                    label: value
                      ? `From ${new Date(
                          value[0]
                        ).toLocaleDateString()} to ${new Date(
                          value[1]
                        ).toLocaleDateString()}`
                      : "",
                  },
                });
              }}
              rangeDate={[
                currentFilter.created_at?.gteDatePickerValue,
                currentFilter.created_at?.lteDatePickerValue,
              ]}
              minDate={
                new Date(
                  exportFiltersAPI?.response?.data?.filters?.createdAt.min
                )
              }
              maxDate={
                new Date(
                  exportFiltersAPI?.response?.data?.filters?.createdAt.max
                )
              }
            />
          </div>

          <div>
            <h5 className={Style.selectLabel}>Status</h5>
            <Select
              placeholder="Please select a status"
              value={currentFilter.status}
              items={[
                {
                  label: "All",
                  value: null,
                },
                ...(exportFiltersAPI?.response?.data?.filters?.status?.exact ||
                  []),
              ]}
              setValue={(value) => {
                setCurrentFilter({
                  ...currentFilter,
                  status: value,
                });
              }}
            />
          </div>
          <div>
            <h5 className={Style.selectLabel}>Type</h5>
            <Select
              placeholder="Please select a type"
              value={currentFilter.type}
              items={[
                {
                  label: "All",
                  value: null,
                },
                ...(exportFiltersAPI?.response?.data?.filters?.type?.exact ||
                  []),
              ]}
              setValue={(value) => {
                setCurrentFilter({
                  ...currentFilter,
                  type: value,
                });
              }}
            />
          </div>
          <Button
            className={Style.applyFilterBtn}
            onClick={() => {
              let tempExtraFilter = {};
              Object.entries(currentFilter).forEach((entry) => {
                const [key, item] = entry;
                if (item) tempExtraFilter[key] = item.value;
              });
              setExtraFilters({
                ...tempExtraFilter,
                created_at__gte: currentFilter?.created_at?.gteDatePickerValue
                  ? currentFilter?.created_at?.gteDatePickerValue.toISOString()
                  : null,
                created_at__lte: currentFilter?.created_at?.lteDatePickerValue
                  ? currentFilter?.created_at?.lteDatePickerValue.toISOString()
                  : null,
                created_at: null,
              });
            }}
          >
            Apply Filters
          </Button>
        </div>
        <div className={Style.extraFiltersBadges}>
          <FilterBadges
            currentFilters={badges}
            handleResetFilter={(filterKey) =>
              handleResetFilter(
                filterKey,
                initialFilters,
                currentFilter,
                setCurrentFilter,
                extraFilters,
                setExtraFilters
              )
            }
          />
        </div>
      </Card>
      <Card
        minHeight={"585px"}
        apiHook={exportFiltersAPI}
        className={Style.exports}
      >
        <Table
          ref={ref}
          height="410"
          enableAllowedActions={false}
          columns={columns}
          endPoint={endPoint}
          enableSelectableRows={false}
          enableSearch={false}
          enableSearchFieldSelect={false}
          enablePageSizeSelect={true}
          defaultPageSize={20}
          extraFilters={extraFilters}
          Styles={customTableStyles}
          searchBadgesClassName={Style.searchBadges}
          paginationButtonClassName={Style.paginationButton}
          paginationRecordsClassName={Style.paginationRecords}
          toPageInputBaseClassName={Style.toPageInput}
          enableSettings={false}
          enableRefreshBtn={false}
          enableHiddenColumnBadge={false}
        />
      </Card>
    </>
  );
};

export default Exports;
