import config from "./config";
import styled from "styled-components";

// Global Variables
const steps = {
  COMPLETE_DATA: "personalInfo",
  COMPLETE_KYC: "kyc",
  WATCH_ECOSYSTEM_VIDEO: "ecosystemVideoWatched",
  WATCH_PRODUCT_VIDEO: "productVideoWatched",
  BUY_LICENSEE: "licenseBought",
  DEPOSIT_COLLATERAL: "readDepositCollateral",
};

const stepsStatus = {
  COMPLETED: "Completed",
  IN_PROGRESS: "InProgress",
  INCOMPLETE: "Incomplete",
};

const activityCategory = {
  RANK: "RANK_UPGRADE",
  COMPLETE_STEP: "completeStep",
  NEW_LOGIN: "LOGIN",
};

const withdrawToken = {
  EURFIC: {
    value: "EURFIC",
    label: config.EURK_C_UNIT,
  },
  EURFIW: {
    value: "EURFIW",
    label: config.EURK_W_UNIT,
  },
  INFINITY_EURFIC: {
    value: "INFINITY_EURFIC",
    label: `${config.EURK_C_UNIT} Infinity`,
  },
  INFINITY_EURFIW: {
    value: "INFINITY_EURFIW",
    label: `${config.EURK_W_UNIT} Infinity`,
  },
};

const otpMethods = {
  EMAIL: "EMAIL",
  TOTP: "TOTP",
};
const accountType = {
  PERSONAL: "PERSONAL",
  BUSINESS: "BUSINESS",
};

const unitsList = {
  VP: {
    label: "VP",
    value: "vp",
  },
  CP: {
    label: "CP",
    value: "cp",
  },
  IP: {
    label: "IP",
    value: "ip",
  },
};

const rewardList = {
  clfi_w: 'cLFi-W',
  clfi_r: 'cLFi-R',
}

const customTableStyles = styled.div`
  padding: 0;
  color: var(--light);

  table {
    position: relative;
    width: 100%;
    border-spacing: 0;
    margin-bottom: 23px;
    thead {
      tr {
        font-weight: 500;
        font-size: 16px;
        th {
          min-width: 270px;
          max-width: 800px;
          text-align: center;
          padding: 0 30px;
        }
      }
    }
    tbody {
      tr {
        height: 65px;
        font-weight: 500;
        font-size: 15px;
        color: #ccc;
        border: 1px solid red;

        td {
          min-width: 270px;
          max-width: 800px;
          text-align: center;
          border-bottom: 1px solid rgba(131, 129, 129, 0.25);
          :first-child,
          :last-child {
            border-width: 1px;
            border-style: solid;
            border-right: none;
            border-left: none;
            border-top: none;
            border-image: linear-gradient(
                to left,
                rgba(131, 129, 129, 0.25) 0%,
                rgba(131, 129, 129, 0.25) 43.75%,
                rgba(108, 108, 108, 0) 100%
              )
              1 10%;
          }
          :last-child {
            border-image: linear-gradient(
                to left,
                rgba(108, 108, 108, 0) 0%,
                rgba(131, 129, 129, 0.25) 43.75%,
                rgba(131, 129, 129, 0.3) 100%
              )
              1 10%;
          }
          padding: 0 30px;
        }
      }
    }
  }
`;


const AutoDebitStatus = {
  PENDING: {
    label: "Pending",
    value: "PENDING",
  },
  REQUESTED: {
    label: "Requested",
    value: "REQUESTED",
  },
  APPROVED: {
    label: "Approved",
    value: "APPROVED",
  },
  REJECTED: {
    label: "Rejected",
    value: "REJECTED",
  },
};

export {
  steps,
  stepsStatus,
  activityCategory,
  withdrawToken,
  otpMethods,
  accountType,
  unitsList,
  customTableStyles,
  rewardList,
  AutoDebitStatus
};
