import React from "react";

// Libraries
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";

// Components
import SimpleStyledCard from "../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";
import Card from "../../../../../components/advanced/card/Card";

// Data
import endpoints from "../../../../../data/endpoints";
import config from "../../../../../data/config";

// Utilities
import { formatNumberEuropean } from "../../../../../utils/utilityFunctions";
import getOrdinal from "../../../../../utils/getOrdinal";

// Images
import directBonusesSummeryCardRightBG from "../../../../../assets/images/directBonusesSummeryCardRightBG.png";
import directBonusesSummeryCardLeftBG from "../../../../../assets/images/directBonusesSummeryCardLeftBG.png";

// Styles
import Style from "./Summary.module.css";

const Summary = () => {
  // Variables
  const summaryData = {
    totalCp: {
      title: "Total Commission Earned",
      unit: config.EURK_UNIT,
      tooltipDesc: null,
      backgroundPosition: "right",
      background: directBonusesSummeryCardRightBG,
      needTruncate: true,
    },
    firstLineBiggestPurchase: {
      title: <span>Biggest Purchase in {getOrdinal(1)} Line</span>,
      unit: config.EURK_UNIT,
      tooltipDesc: null,
      backgroundPosition: "left",
      background: directBonusesSummeryCardLeftBG,
      needTruncate: true,
    },
    highestUserUsername: {
      title: "Best Customer",
      unit: null,
      tooltipDesc:
        "Your best customer is the referral who has purchased the maximum volume of products",
      backgroundPosition: "right",
      background: directBonusesSummeryCardRightBG,
      specialChar: "@",
      needTruncate: false,
    },
    firstLineTotalPurchasedCp: {
      title: "Total Purchase",
      unit: config.EURK_UNIT,
      tooltipDesc: null,
      backgroundPosition: "right",
      background: directBonusesSummeryCardRightBG,
      needTruncate: true,
    },
  };

  // Queries
  const directBonusReportAPI = useQuery({
    url: endpoints.directBonus.REPORT,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  return (
    <Card apiHook={directBonusReportAPI} minHeight={"250px"}>
      <div className={Style.title}>Direct Bonus</div>
      {/* <div className={Style.subTitle}>
        The Direct Bonus is 10 percent (if you won the Fast Start challenge in
        your first 30 days of business it will be 15 percent) direct sales
        commission on your frontline purchases. The Direct Bonus is calculated
        on the CPs (Commissionable Points) produced by your customers.
      </div> */}
      <div className={Style.infoBoxes}>
        {Object.keys(summaryData).map((key) => (
          <SummaryCard
            data={summaryData[key]}
            cardKey={key}
            APIdata={directBonusReportAPI?.response?.data}
            key={key}
          />
        ))}
      </div>
    </Card>
  );
};

const SummaryCard = ({ data, cardKey, APIdata }) => {
  return (
    <SimpleStyledCard
      className={Style.infoBoxCard}
      style={{
        background: `url(${data.background}) no-repeat ${data.backgroundPosition}, var(--bg-like-blur)`,
      }}
    >
      <div className={Style.cardTitle}>
        {data.title}
        {data.tooltipDesc && (
          <div
            className={Style.tooltip}
            onTouchStart={(e) => {
              e.currentTarget.classList.toggle(Style.active);
            }}
          >
            <span className={Style.tooltipText}>{data.tooltipDesc}</span>
          </div>
        )}
      </div>

      <div className={Style.content}>
        <div className={Style.value}>
          {APIdata[cardKey] || APIdata[cardKey] === 0
            ? (data?.specialChar ? data?.specialChar : "") +
              (data?.needTruncate
                ? formatNumberEuropean(APIdata[cardKey], 2)
                : APIdata[cardKey])
            : "-"}
        </div>
        <div className={Style.unit}>{data.unit}</div>
      </div>
    </SimpleStyledCard>
  );
};

export default Summary;
