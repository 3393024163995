import React, { useRef } from "react";

// Components

// Libraries
import QRCode from "react-qr-code";
import {
  WhatsappShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from "react-share";
import { InputField } from "@hybris-software/ui-kit";

// Utilities
import {
  copyTextToClipboard,
  downloadImage,
} from "../../../../../../utils/utilityFunctions";

// Data
import config from "../../../../../../data/config";

// Icons
import DashIconsWhatsapp from "../../../../../../assets/svgComponents/DashIconsWhatsapp";
import DashIconsFacebook from "../../../../../../assets/svgComponents/DashIconsFacebook";
import DashIconsTelegram from "../../../../../../assets/svgComponents/DashIconsTelegram";
import DashIconsTwitter from "../../../../../../assets/svgComponents/DashIconsTwitter";
import DashIconsMessenger from "../../../../../../assets/svgComponents/DashIconsMessenger";
import CopyIcon from "../../../../../../assets/svgComponents/CopyIcon";
import DownloadIcon from "../../../../../../assets/svgComponents/DownloadIcon";
import ModalCloseIcon from "../../../../../../assets/svgComponents/ModalCloseIcon";

// Styles
import Style from "./ReferFriendModal.module.css";

const ReferFriendModal = ({
  referralCode,
  onlyShare = false,
  generalModalRef,
}) => {
  const divRef = useRef(null);
  const url = `${config.REFERRAL_URL}${referralCode}`;
  const copyTooltipRef = useRef(null);

  const handleCopyClick = () => {
    copyTextToClipboard(url)
      .then(() => {
        showCopyTooltip();
      })
      .catch((err) => {});
  };

  const showCopyTooltip = () => {
    if (copyTooltipRef.current) {
      copyTooltipRef.current.classList.add(Style.show);
      setTimeout(() => {
        hideCopyTooltip();
      }, 1500);
    }
  };

  const hideCopyTooltip = () => {
    if (copyTooltipRef.current) {
      copyTooltipRef.current.classList.remove(Style.show);
    }
  };

  return (
    <div className={Style.modalContent}>
      <div
        className={Style.closeContainer}
        onClick={() => {
          generalModalRef.current.destroy();
        }}
      >
        <ModalCloseIcon />
      </div>
      <div className={Style.modalContentWithoutClose}>
        <h5 className={Style.modalTitle}>Refer your friend and Earn!</h5>
        {!onlyShare && (
          <div className={Style.qrRow}>
            <div className={Style.qrOuterContainer}>
              <div className={Style.qrInnerContainer} ref={divRef}>
                <div>
                  <QRCode className={Style.qrCode} value={url} />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={Style.social}>
          <div className={Style.socialTitle}>
            Share your Referral Link through your Social Media:{" "}
          </div>
          <div className={Style.socialIcons}>
            <WhatsappShareButton url={url}>
              <DashIconsWhatsapp />
            </WhatsappShareButton>
            <FacebookShareButton url={url}>
              <DashIconsFacebook />
            </FacebookShareButton>
            <TelegramShareButton url={url}>
              <DashIconsTelegram />
            </TelegramShareButton>
            <TwitterShareButton url={url}>
              <DashIconsTwitter />
            </TwitterShareButton>
            <FacebookMessengerShareButton url={url}>
              <DashIconsMessenger />
            </FacebookMessengerShareButton>
          </div>
        </div>

        <div className={Style.otherOptions}>
          <div className={Style.otherOptionsHeading}>
            <div className={Style.smallDivider}></div>
            <div className={Style.otherOptionsTitle}>or</div>
            <div className={Style.smallDivider}></div>
          </div>
          <div className={Style.copySection}>
            <div className={Style.left}>
              <InputField
                style={{ marginTop: "0" }}
                showError={false}
                readOnly={true}
                value={url}
              />
            </div>
            <div className={Style.right}>
              <div
                className={Style.modalActionCopy}
                onClick={() => handleCopyClick()}
              >
                <CopyIcon />
                <div>Copy Link</div>
                <span
                  ref={copyTooltipRef}
                  className={Style.copyTooltip}
                  id="copyTooltip"
                  aria-live="assertive"
                  role="tooltip"
                >
                  Copied!
                </span>
              </div>
            </div>
          </div>
          {!onlyShare && (
            <div
              onClick={() => downloadImage(divRef, "QR-invitation.png")}
              className={Style.modalAction}
            >
              <DownloadIcon />
              <div>Download</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReferFriendModal;
