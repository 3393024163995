import { useState } from "react";

// styles
import Style from "./ProgressBar.module.css";

const ProgressBar = ({ done, startText, image, endText }) => {
    const [animate, setAnimate] = useState({});
    
    setTimeout(() => {
        const newAnimation = {
            opacity: 1,
            width: `${done}%`
        }
        setAnimate(newAnimation);
    }, 200);
    
    return (
        <div className={Style.progress}>
            <div className={Style.progressDone} style={animate} />
            <span className={Style.startText}>{startText}</span>
            <span className={Style.endText}>
                <img className={Style.progressImg} src={image} alt="" />
                {endText}
            </span>
        </div>
    )
}

export default ProgressBar;
