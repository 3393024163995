import React, { useContext, useEffect } from "react";

// Components
import Loader from "../../../components/ui/loader/Loader";
import TeamGrowth from "./components/teamGrowth/TeamGrowth";
import YourRank from "./components/yourRank/YourRank";
import RealTimeUsers from "./components/realTimeUsers/RealTimeUsers";
// import AiKPITeam from "./components/aiKPITeam/AiKPITeam";
import UserInfoHeader from "../../../components/advanced/userInfoHeader/UserInfoHeader";

// Libraries
import { Container } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";
import { useNavigate } from "react-router-dom";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Data
import config from "../../../data/config";

// Styles
import Style from "./DashboardView.module.css";
import Statistics from "./components/statistics/Statistics";
import classNames from "../../../utils/classNames";

const DashboardView = () => {
  // Hooks
  const navigate = useNavigate();

  // Contexts
  const { paths } = useContext(RoutesContext);

  useEffect(() => {
    document.title = `${config.WEBSITE_NAME} | Dashboard`;
    //eslint-disable-next-line
  }, []);

  return (
    <PermissionRoute
      forLoggedUser={true}
      loader={<Loader />}
      unAuthorizedAction={() => {
        // navigate
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        // dispatcher
        return true;
      }}
    >
      <Container>
        <UserInfoHeader />
        <section className={Style.section}>
          <RealTimeUsers />
        </section>
        <section className={Style.section}>
          <YourRank />
        </section>
        <section
          className={classNames(Style.section, Style.statisticsAnalytics)}
        >
          <TeamGrowth />
          <Statistics />
        </section>
      </Container>
    </PermissionRoute>
  );
};

export default DashboardView;
