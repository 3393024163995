import React from "react";

// libraries
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";

// Style
import Style from "./OverLappingChart.module.css";

const OverLappingChart = ({
  Title,
  innerPercentage, //Weak Legs
  middlePercentage, // Strong Legs
  WrapperPercentage, //  Total Turnover
  showInner = true,
  showMiddle = true,
  showWrapper = true,
  MiddleColor,
  innerColor,
  WrapperColor,
  innerText = "%",
  textCenterFontSize = "10",
}) => {
  const percentageTotal = 100;

  //  wrapper chart  data //
  const wrapperStart =
    WrapperPercentage >= percentageTotal ? percentageTotal : WrapperPercentage;
  const wrapperEnd = percentageTotal - wrapperStart;
  const wrapperBackgroundDoughnut = {
    datasets: [
      {
        data: [100, 0],
        borderWidth: 0,
        cutout: "88%",
        backgroundColor: ["#fff", "transparent"],
      },
    ],
  };
  const WrapperDoughnutData = {
    datasets: [
      {
        label: "Total Volume",
        data: [WrapperPercentage, wrapperEnd],
        borderWidth: 0,
        borderRadius: middlePercentage === 100 ? 0 : 20,
        cutout: "88%",
        backgroundColor: [WrapperColor, "transparent"],
        borderAlign: ["top", "center"],
        rotation: -180,
      },
    ],
  };

  //  wrapper chart  data //
  const middleStart =
    middlePercentage >= percentageTotal ? percentageTotal : middlePercentage;
  const middleEnd = percentageTotal - middleStart;

  const middleBackgroundDoughnut = {
    datasets: [
      {
        data: [100, 0],
        borderWidth: 0,
        cutout: "85%",
        backgroundColor: ["#fff", "transparent"],
      },
    ],
  };
  const middleDoughnutData = {
    datasets: [
      {
        label: "Weak Legs Volume",
        data: [middlePercentage, middleEnd],
        borderWidth: 0,
        borderRadius: middlePercentage === 100 ? 0 : 20,
        cutout: "85%",
        backgroundColor: [MiddleColor, "transparent"],
        borderAlign: ["top", "center"],
        rotation: -180,
      },
    ],
  };

  //  inner chart  data //

  const innerStart =
    innerPercentage >= percentageTotal ? percentageTotal : innerPercentage;
  const innerEnd = percentageTotal - innerStart;

  const innerBackgroundDoughnut = {
    datasets: [
      {
        data: [100, 0],
        borderWidth: 0,
        cutout: "83%",
        backgroundColor: ["#fff", "transparent"],
      },
    ],
  };
  const innerDoughnutData = {
    datasets: [
      {
        label: "Weak Legs Volume",
        data: [innerPercentage, innerEnd],
        borderWidth: 0,
        borderRadius: innerPercentage === 100 ? 0 : 20,
        cutout: "83%",
        backgroundColor: [innerColor, "transparent"],
        borderAlign: ["top", "center"],
        rotation: -180,
      },
    ],
  };

  const textCenter = {
    id: "textCenter",
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx } = chart;
      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y;
      ctx.save();
      ctx.font = `500 ${textCenterFontSize}px Montserrat`;

      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      ctx.fillStyle = "white";
      ctx.fillText(innerText, xCoor, yCoor - 10);
      ctx.fillText(Title, xCoor, yCoor + 10);

      ctx.restore(); // Restore the context state
    },
  };

  //  general options //

  const options = {
    // plugins: {
    //   tooltip: {
    //     yAlign: "bottom",
    //     xAlign: "left",
    //     bodyAlign: "transparent",
    //     backgroundColor: "rgba(60, 67, 66, 0.8)",
    //     padding: 6,
    //     caretPadding: 80,
    //     displayColors: false,
    //     filter: function (tooltipItem) {
    //       const isHidden = tooltipItem.element.options.borderAlign === "top";
    //       return isHidden;
    //     },
    //   },
    // },
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <div className={Style.relativeBox}>
      <div className={Style.tooltipText}>
        <div className={Style.tooltipTitle}>
          Total Volume <span> {WrapperPercentage} %</span>
        </div>
        {showMiddle && (
          <div className={Style.tooltipTitle}>
            Weak Legs Volume <span> {middlePercentage} %</span>
          </div>
        )}
        {showInner && (
          <div className={Style.tooltipTitle}>
            Weak Legs Volume <span> {innerPercentage} %</span>
          </div>
        )}
      </div>

      {/* wrapper chart */}
      {showWrapper && (
        <div className={Style.wrapperBackground}>
          <Doughnut data={wrapperBackgroundDoughnut} options={options} />
          <div className={Style.wrapperDoughnut}>
            <Doughnut
              data={WrapperDoughnutData}
              options={options}
              plugins={[textCenter]}
            />
          </div>
        </div>
      )}

      {/* middle chart */}
      {showMiddle && (
        <div className={Style.middleBackground}>
          <Doughnut data={middleBackgroundDoughnut} options={options} />
          <div className={Style.middleDoughnut}>
            <Doughnut data={middleDoughnutData} options={options} />
          </div>
        </div>
      )}
      {/* inner chart */}
      {showInner && (
        <div className={Style.innerBackground}>
          <Doughnut data={innerBackgroundDoughnut} options={options} />
          <div className={Style.innerDoughnut}>
            <Doughnut data={innerDoughnutData} options={options} />
          </div>
        </div>
      )}
    </div>
  );
};

export default OverLappingChart;
