import React from "react";

const DashIconsMessenger = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00002 17.6089C5.00002 10.3614 10.6769 5 18.0005 5C25.3242 5 31 10.3625 31 17.6089C31 24.8554 25.3231 30.2168 17.9995 30.2168C16.6886 30.2168 15.421 30.0435 14.2401 29.7185C14.0097 29.6564 13.7648 29.6755 13.5468 29.7726L10.9575 30.9101C10.8014 30.9796 10.6306 31.0096 10.4602 30.9973C10.2898 30.9851 10.125 30.9311 9.98043 30.8401C9.83585 30.7491 9.71592 30.6238 9.63125 30.4754C9.54657 30.327 9.49976 30.1601 9.49495 29.9893L9.41911 27.671C9.41324 27.5313 9.37955 27.3942 9.32002 27.2677C9.26049 27.1412 9.17632 27.0278 9.07243 26.9343C7.7698 25.7589 6.73285 24.3192 6.03079 22.7113C5.32872 21.1034 4.97769 19.3643 5.0011 17.61L5.00002 17.6089ZM14.0137 15.2364L10.2002 21.3031C9.82105 21.8773 10.5469 22.537 11.0886 22.1156L15.1946 19.0064C15.4763 18.7898 15.8446 18.7898 16.1371 19.0064L19.1706 21.2814C20.0806 21.9639 21.3807 21.7148 21.9874 20.7614L25.8009 14.6948C26.1801 14.1206 25.4542 13.4706 24.9125 13.8823L20.8065 16.9914C20.5357 17.2081 20.1565 17.2081 19.8748 16.9914L16.8413 14.7164C16.6256 14.5535 16.3784 14.4371 16.1153 14.3748C15.8522 14.3125 15.579 14.3056 15.3131 14.3545C15.0472 14.4034 14.7944 14.5071 14.5707 14.6589C14.347 14.8107 14.1573 15.0074 14.0137 15.2364Z"
        fill="url(#paint0_linear_996_1698)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_996_1698"
          x1="18"
          y1="5"
          x2="18"
          y2="31"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4FC2AB" />
          <stop offset="1" stopColor="#00FFCC" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DashIconsMessenger;
