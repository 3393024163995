import React, { useEffect, useContext, useRef } from "react";

// Components
import Loader from "../../../components/ui/loader/Loader";
import Card from "../../../components/advanced/card/Card";
import TreeView from "../communityView/components/treeView/TreeView";
import ListView from "../communityView/components/listView/ListView";

// Libraries
import { PermissionRoute } from "@hybris-software/use-auth";
import useQuery from "@hybris-software/use-query";
import { MagicModal } from "@hybris-software/ui-kit";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";
import { PrivacyProvider } from "../../../contexts/PrivacyContext";

// Data
import config from "../../../data/config";
import endpoints from "../../../data/endpoints";

// Styles
import Style from "./TreeMemberFullScreenView.module.css";

const TreeMemberFullScreenView = () => {
  // Hooks
  const navigate = useNavigate();
  const location = useLocation();
  const generalModalRef = useRef(null);

  // Consts
  const searchParams = new URLSearchParams(location.search);
  const treeViewParam = searchParams.get("type");

  // Contexts
  const { paths } = useContext(RoutesContext);

  //Queries
  const getUserInfoAPI = useQuery({
    url: endpoints.auth.USER,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  const rankListAPI = useQuery({
    url: endpoints.RANKS_LIST,
    method: "GET",
    executeImmediately: true,
    clientOptions: {
      timeout: 120000,
    },
  });

  const communityAPI = useQuery({
    url: endpoints.community.FIRST_lINE(getUserInfoAPI?.response?.data.id, 1),
    method: "GET",
    executeImmediately: false,
    onSuccess: (response) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  useEffect(() => {
    if (getUserInfoAPI.isSuccess) {
      communityAPI.executeQuery();
    }
    //eslint-disable-next-line
  }, [getUserInfoAPI.isSuccess]);

  useEffect(() => {
    document.title = `${config.WEBSITE_NAME} | Tree Member Full Screen`;
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <MagicModal
        overlayStyle={{ background: "var(--overlay-modal)" }}
        closeIcon={null}
        ref={generalModalRef}
        contentStyle={{ padding: "0" }}
      />
      <PermissionRoute
        loader={<Loader fullscreen />}
        forLoggedUser={true}
        firstApiLoading={communityAPI.isLoading || rankListAPI.isLoading}
        unAuthorizedAction={() => {
          navigate(paths.auth.login);
        }}
        permissionController={(response) => {
          // dispatcher
          return true;
        }}
      >
        <div className={Style.bg} />
        <div className={Style.layout}>
          <Card apiHook={communityAPI || rankListAPI} className={Style.bgNone}>
            {treeViewParam === "tree" ? (
              <ReactFlowProvider>
                <PrivacyProvider>
                  <TreeView
                    fullScreen
                    userInfo={getUserInfoAPI?.response?.data}
                  />
                </PrivacyProvider>
              </ReactFlowProvider>
            ) : (
              <ListView
                userId={getUserInfoAPI?.response?.data.id}
                rankApi={rankListAPI?.response?.data}
                generalModalRef={generalModalRef}
              />
            )}
          </Card>
        </div>
      </PermissionRoute>
    </>
  );
};

export default TreeMemberFullScreenView;
