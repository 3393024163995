import React, {
  useContext,
  useTransition,
  useState,
  useEffect,
  useRef,
} from "react";

// Components
import Card from "../../../../../components/advanced/card/Card";
import ListView from "../listView/ListView";
import TreeView from "../treeView/TreeView";
import SimpleStyledCard from "../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";

// Libraries
import useQuery from "@hybris-software/use-query";
import { Link } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import { useOutletContext } from "react-router";

// Icons
import { RiFullscreenFill } from "react-icons/ri";

// Contexts
import { RoutesContext } from "../../../../../contexts/RoutesContext";
import GeneralInfoContext from "../../../../../contexts/GeneralInfoContext";
import { usePrivacyContext } from "../../../../../contexts/PrivacyContext";

// Data
import endpoints from "./../../../../../data/endpoints";
import config from "../../../../../data/config";

// Styles
import Style from "./TreeMembers.module.css";
import { InputField } from "@hybris-software/ui-kit";

const TreeMembers = () => {
  // Hooks
  const [treeView, setTreeView] = useState(false);
  const [currentSearchValue, setCurrentSearchValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [isPending, startTransition] = useTransition();

  // Contexts
  const { paths } = useContext(RoutesContext);
  const [generalInfo] = useContext(GeneralInfoContext);
  const { privacy } = usePrivacyContext();
  const { generalModalRef } = useOutletContext();

  //Queries
  const communityAPI = useQuery({
    url: endpoints.community.FIRST_lINE(generalInfo?.userInfo?.id, 1),
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  // Function
  function handleViewChange(newTreeViewValue) {
    startTransition(() => {
      setTreeView(newTreeViewValue);
    });
  }

  useEffect(() => {
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      setSearchValue(currentSearchValue);
    }, 1000);
    // eslint-disable-next-line
  }, [currentSearchValue]);

  const timeoutId = useRef(null);

  return (
    <div className={Style.treeCard}>
      <SimpleStyledCard
        style={{
          borderBottomColor: "transparent",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          paddingBottom: 0,
        }}
      >
        <div className={Style.header}>
          <div className={Style.searchContainer}>
            <h5>Tree Members</h5>
            {!treeView && (
              <InputField
                className={Style.searchInput}
                showError={false}
                type="text"
                placeholder="Search First Line"
                onChange={(e) => {
                  setCurrentSearchValue(e.target.value);
                }}
              />
            )}
          </div>

          <div className={Style.actions}>
            <div
              className={!treeView ? Style.buttonSelected : Style.button}
              onClick={() => handleViewChange(false)}
            >
              List View
            </div>
            <div
              className={treeView ? Style.buttonSelected : Style.button}
              onClick={() => handleViewChange(true)}
            >
              Tree View
            </div>
            {/* <div className={Style.buttonContainer}  onClick={() => handleViewChange(false)}>
              <div className={!treeView ? Style.buttonBackground : Style.buttonBackgroundRm}></div>
              <div className={Style.buttonContent} >List View</div>
            </div>
            <div className={Style.buttonContainer}  onClick={() => handleViewChange(true)}>
              <div className={treeView ? Style.buttonBackground : Style.buttonBackgroundRm}></div>
              <div className={Style.buttonContent} >Tree View</div>
            </div> */}
            <div className={Style.border} />

            <Link
              to={`${paths.fullScreen.memberTree}?type=${
                treeView ? "tree" : "listing"
              }`}
              target="_blank"
              className={Style.fullScreen}
            >
              <RiFullscreenFill />
              View Full
            </Link>
          </div>
        </div>
      </SimpleStyledCard>
      <Card
        apiHook={communityAPI}
        className={Style.contentWrapper}
        style={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderTopColor: "transparent",
        }}
      >
        {isPending && <CustomLoader />}
        {treeView ? (
          <ReactFlowProvider>
            <TreeView />
          </ReactFlowProvider>
        ) : (
          <ListView
            searchValue={searchValue}
            userId={generalInfo?.userInfo?.id}
            privacyMode={privacy}
            generalModalRef={generalModalRef}
          />
        )}
      </Card>
    </div>
  );
};

const CustomLoader = () => {
  return (
    <div className={Style.loader}>
      <div className={Style.defaultLoaderImage}>
        <img
          src={config.LOGO_SMALL}
          className={Style.rotate}
          alt="loader"
          draggable="false"
        />
        <img
          src={config.LOGO_WORDS}
          className={Style.fixed}
          alt="loader"
          draggable="false"
        />
      </div>
    </div>
  );
};
export default TreeMembers;
