import React, { useContext, useState } from "react";

// Libraries
import { Button } from "@hybris-software/ui-kit";

// Utils
import { copyTextToClipboard } from "../../../utils/utilityFunctions";

// Contexts
import GeneralInfoContext from "../../../contexts/GeneralInfoContext";

// Styles
import Style from "./CopyReferralCodeBtn.module.css";

const CopyReferralCodeBtn = () => {
  // Contexts
  const [generalInfo] = useContext(GeneralInfoContext);

  // Consts
  const initialState = "Copy Referral Code";

  // States
  const [buttonTextChange, setButtonTextChange] = useState(initialState);

  // Functions
  const handleCopyClick = () => {
    copyTextToClipboard(`${generalInfo?.userInfo?.referralCode}`).then(() => {
      setButtonTextChange("Copied !");
      setTimeout(() => setButtonTextChange(initialState), [1500]);
    });
  };

  return (
    <Button
      className={Style.rightButton}
      onClick={() => {
        handleCopyClick();
      }}
    >
      {buttonTextChange}
    </Button>
  );
};

export default CopyReferralCodeBtn;
