import React from "react";

// Utils
import classNames from "../../../utils/classNames";

// Styles
import Style from "./SimpleStyledCard.module.css";

const SimpleStyledCard = ({ children, className, style, onTouchStart }) => {
  return (
    <div
      className={classNames(Style.card, className)}
      style={style}
      onTouchStart={onTouchStart}
    >
      {children}
    </div>
  );
};

export default SimpleStyledCard;
