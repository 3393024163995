import React, { useRef } from "react";

// Components
import DateFormatter from "../../../../../components/advanced/dateFormatter/DateFormatter";
import SimpleStyledCard from "../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";

// Libraries
import Table from "@hybris-software/use-table";

// Utilities
import { formatNumberEuropean } from "../../../../../utils/utilityFunctions";

// Data
import endpoints from "../../../../../data/endpoints";
import { customTableStyles } from "../../../../../data/constants";

// Icons
import userAvatar from "../../../../../assets/images/strongLegPic.png";

// Styles
import Style from "./FirstLineUsers.module.css";

const FirstLineUsers = () => {
  const columns = [
    {
      Header: "Username",
      noAction: true,
      field: "username",
      orderField: "username",
      sortable: false,
      accessor: (row) => {
        return (
          <div className={Style.userName}>
          <img
            alt="user"
            width={48}
            height={48}
            src={row.profilePicture ?? userAvatar}
          />
          <div>@{row.username}</div>
        </div>

        )
      },
    },
    {
      Header: "Bonus Commission",
      field: "bonusPercentage",
      orderField: "bonus_percentage",
      noAction: true,
      sortable: false,
      accessor: (row) => {
        return row.bonusPercentage + "%";
      },
    },
    {
      Header: "CP",
      field: "purchasedCp",
      orderField: "purchased_cp",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return formatNumberEuropean(row.purchaseCp);
      },
    },

    {
      Header: `Total Rewards Received`,
      field: "eurficAmount",
      orderField: "eurfic_amount",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return (
          <div className={Style.totalRewardsColumn}>
            <div><strong>{formatNumberEuropean(row.totalEurficAmount)}</strong> <span style={{opacity:".8"}}> EurK - C </span></div>
            <div><strong>{formatNumberEuropean(row.totalEurfiwAmount)}</strong> <span style={{opacity:".8"}}> EurK - W </span></div>
          </div>
        ) ;
      },
    },
        {
      Header: "Date",
      noAction: true,
      field: "date",
      orderField: "date",
      sortable: false,
      accessor: (row) => {
        return <DateFormatter date={row.date} />;
      },
    },
    
  ];

  const ref = useRef(null);
  const endPoint = endpoints.directBonus.FIRST_LINE_USERS;

  return (
    <SimpleStyledCard className={Style.firstLineUsers}>
      <h5>First Line Users</h5>
      <Table
        ref={ref}
        height="410"
        enableAllowedActions={false}
        columns={columns}
        endPoint={endPoint}
        enableSelectableRows={false}
        enableSearch={false}
        enableSearchFieldSelect={false}
        enablePageSizeSelect={true}
        defaultPageSize={50}
        extraFilters={{}}
        Styles={customTableStyles}
        searchBadgesClassName={Style.searchBadges}
        paginationButtonClassName={Style.paginationButton}
        paginationRecordsClassName={Style.paginationRecords}
        toPageInputBaseClassName={Style.toPageInput}
        enableSettings={false}
        enableRefreshBtn={false}
        enableHiddenColumnBadge={false}
      />
    </SimpleStyledCard>
  );
};

export default FirstLineUsers;
