import React from "react";

// Style
import Style from "./PercentageSelector.module.css"

const PercentageSelector = ({percentage, setPercentage}) => {
  return (
    <div className={Style.percentageWrapper}>
      <div
        className={
          percentage === 0.25 ? Style.percentageActive : Style.percentage
        }
        onClick={() =>
          percentage === 0.25 ? setPercentage(0) : setPercentage(0.25)
        }
      >
        25%
      </div>
      <div
        className={
          percentage === 0.5 ? Style.percentageActive : Style.percentage
        }
        onClick={() =>
          percentage === 0.5 ? setPercentage(0) : setPercentage(0.5)
        }
      >
        50%
      </div>
      <div
        className={
          percentage === 0.75 ? Style.percentageActive : Style.percentage
        }
        onClick={() =>
          percentage === 0.75 ? setPercentage(0) : setPercentage(0.75)
        }
      >
        75%
      </div>
      <div
        className={percentage === 1 ? Style.percentageActive : Style.percentage}
        onClick={() => (percentage === 1 ? setPercentage(0) : setPercentage(1))}
      >
        100%
      </div>
    </div>
  );
};

export default PercentageSelector;
