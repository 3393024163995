import React, { useState, useEffect, useContext } from "react";

// Components
import Loader from "../../../components/ui/loader/Loader";
import SimpleTitle from "../../../components/advanced/simpleTitle/SimpleTitle";
import BonusesHistory from "./components/bonusesHistory/BonusesHistory";

// Libraries
import { Button, Container } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";
import RankBonus from "./components/rankBonus/RankBonus";
import { useNavigate } from "react-router-dom";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Data
import config from "../../../data/config";

// Utils
import classNames from "../../../utils/classNames";

// Styles
import Style from "./RankTurnoverView.module.css";

const RankTurnoverView = () => {
  // Hooks
  const navigate = useNavigate();
  const [, setPercentageRule] = useState({
    eurficPercentage: 0,
    eurfiwPercentage: 0,
  });

  // Contexts
  const { paths } = useContext(RoutesContext);

  useEffect(() => {
    document.title = `${config.WEBSITE_NAME} | Turnover Bonus`;
    //eslint-disable-next-line
  }, []);

  return (
    <PermissionRoute
      loader={<Loader />}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        // navigate
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        setPercentageRule({
          eurficPercentage: response.data.eurficPercentage,
          eurfiwPercentage: response.data.eurfiwPercentage,
        });
        return true;
      }}
    >
      <Container>
        <section className={classNames(Style.section, Style.headers)}>
          <SimpleTitle
            title="Turnover Bonus"
            subtitle="Bonuses are cumulative, paid once for each bonus, with the payment being the difference from the previous bonus. Half of the bonus is paid in EURK-W and EURK-P, and the other half in XGI Token. "
          />
          <Button
            className={Style.wikiBtn}
            onClick={() => {
              navigate(paths.main.wiki);
            }}
          >
            Visit Wiki
          </Button>
        </section>
        <section className={Style.section}>
          <RankBonus />
        </section>
        <section className={Style.section}>
          <BonusesHistory />
        </section>
      </Container>
    </PermissionRoute>
  );
};

export default RankTurnoverView;
