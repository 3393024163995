import React, { useContext, useEffect, useState } from "react";

// Components
import Card from "../../../../../components/advanced/card/Card";
import HalfDoughnutChart from "../../../../../components/advanced/halfDoughnutChart/HalfDoughnutChart";
import SimpleStyledCard from "./../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";
import CustomToolTip from "../../../../../components/advanced/customToolTip/CustomToolTip";
import DateFormatter from "../../../../../components/advanced/dateFormatter/DateFormatter";

// Libraries
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";
import { Select } from "@hybris-software/ui-kit";

// Utils
import formatLargeNumber from "../../../../../utils/formatLargeNumber";
import { formatNumberEuropean } from "../../../../../utils/utilityFunctions";

// Data
import endpoints from "../../../../../data/endpoints";

// Icons
import VP from "../../../../../assets/svg/VP.svg";
import NoRank from "../../../../../assets/icons/no-rank.png";

// Contexts
import GeneralInfoContext from "../../../../../contexts/GeneralInfoContext";
import { RoutesContext } from "../../../../../contexts/RoutesContext";

// Style
import Style from "./RankStats.module.css";

const DEFAULT_RANK = 1000;
const DEFAULT_YEAR = new Date().getFullYear().toString();
const CURRENT_MONTH_SHORT = new Date().toLocaleString("en-US", {
  month: "short",
});

const RankStats = () => {
  // Contexts
  const [generalInfo] = useContext(GeneralInfoContext);

  // Queries
  const rankReportAPI = useQuery({
    url: endpoints.rankOverView.RANK_REPORT,
    method: "GET",
    executeImmediately: true,
    clientOptions: {
      timeout: 120000,
    },
  });

  // Data
  const rankReportData = rankReportAPI?.response?.data;
  const currentRank = generalInfo?.rankInfo?.find(
    (item) => item.id === rankReportData?.currentRank
  );
  const nextRankList = generalInfo?.rankInfo?.find(
    (item) => item.id === rankReportData?.currentRank + 1
  );

  // Hooks
  return (
    <Card
      className={Style.card}
      style={{
        overflow: "visible",
      }}
      apiHook={rankReportAPI}
      minHeight={"543px"}
    >
      <div className={Style.sectionGrid}>
        <div className={Style.leftPart}>
          <SimpleStyledCard
            style={{
              overflow: "visible",
            }}
          >
            <CurrentRankCard
              rankName={currentRank?.name}
              rankSince={rankReportData?.rankUpdatedAt}
              rankIcon={currentRank?.iconUrl}
              nextRankList={nextRankList}
              nextRankName={nextRankList?.name}
            />
            <div className={Style.horizontalSeparator}></div>
            <MonthlyCommissionRank generalInfo={generalInfo} />
          </SimpleStyledCard>
        </div>
        <div className={Style.rightPart}>
          <UsersCardGrid
            // Personal VP
            personalVp={rankReportData?.personalVp}
            targetPersonalVp={rankReportData?.targetPersonalVp}
            personalVpIncreased={rankReportData?.personalVpIncreased}
            // Eligible Directs Users
            eligibleDirects={rankReportData?.eligibleDirects}
            eligibleDirectsIncreased={rankReportData?.eligibleDirectsIncreased}
            targetEligibleDirects={rankReportData?.targetEligibleDirects}
            // Direct VP
            directVp={rankReportData?.directVp}
            directVpIncreased={rankReportData?.directVpIncreased}
            targetDirectVp={rankReportData?.targetDirectVp}
            // Total VP
            totalVp={rankReportData?.totalVp}
            totalVpIncreased={rankReportData?.totalVpIncreased}
            targetTotalVp={rankReportData?.targetTotalVp}
          />
          <LegsVolume
            strongLegVp={rankReportData?.strongLegVp}
            weakLegsVp={rankReportData?.weakLegsVp}
          />
        </div>
      </div>
    </Card>
  );
};

const CurrentRankCard = ({
  nextRankName = "No Rank Found",
  rankSince = "01-01-2021",
  rankIcon = null,
  nextRankList = {},
  rankName = "No Rank Found",
}) => {
  // Contexts
  const { paths } = useContext(RoutesContext);

  return (
    <div className={Style.currentRankCard}>
      <div className={Style.rankInfo}>
        <div className={Style.titleWrapper}>
          <h6>Career Rank </h6>
          <CustomToolTip
            links={[
              {
                index: 4,
                link: paths.main.wiki,
              },
            ]}
            toolTipText="Please refer to the wiki to see the rules to achieve the ranks."
          />
        </div>
        <h5>{rankName}</h5>
        <div className={Style.rankDate}>
          Since <DateFormatter date={rankSince} />
        </div>
        {nextRankList && (
          <p className={Style.nextRankParagraph}>
            Next Rank : <span>{nextRankName}</span>
          </p>
        )}
      </div>
      <div className={Style.rankImg}>
        <div
          className={Style.rankImgFade}
          style={{ backgroundImage: `url(${rankIcon})` }}
        ></div>
        <img src={rankIcon} alt="Career Rank" />
      </div>
      {nextRankList && (
        <p className={Style.nextRankParagraphMobile}>
          Next Rank : <span>{nextRankName}</span>
        </p>
      )}
    </div>
  );
};

const MonthlyCommissionRank = ({ generalInfo }) => {
  // Contexts
  const { paths } = useContext(RoutesContext);

  // Functions
  function generateFilterList() {
    const currentYear = new Date().getFullYear();
    const filterList = [];

    for (let year = 2023; year <= currentYear; year++) {
      filterList.push({
        year: year.toString(),
        value: year.toString(),
      });
    }

    return filterList;
  }
  const filterList = generateFilterList();

  // States
  const [currentMonthlyRank, setCurrentMonthlyRank] = useState(DEFAULT_RANK);

  const [selectedYear, setSelectedYear] = useState(
    filterList.find((item) => item.value === DEFAULT_YEAR)
  );

  // Queries
  const monthlyCommissionableRankAPI = useQuery({
    url:
      endpoints.rankOverView.MONTHLY_COMMISSIONABLE_RANK +
      "?year=" +
      selectedYear?.value,
    method: "GET",
    onSuccess: (response) => {
      if (currentMonthlyRank === DEFAULT_RANK) {
        const currentMonthShort = CURRENT_MONTH_SHORT;
        setCurrentMonthlyRank(response.data?.data?.[currentMonthShort]);
      }
    },
  });

  // Hooks

  const timeFilter = {
    matchedData: monthlyCommissionableRankAPI?.response?.data,
    selectedYear: selectedYear?.value,
  };

  useEffect(() => {
    monthlyCommissionableRankAPI.executeQuery();
    // eslint-disable-next-line
  }, [selectedYear]);

  const handleChange = (selectedItem) => {
    setSelectedYear(selectedItem);
  };

  return (
    <>
      <div className={Style.monthlyCommissionRank}>
        <div className={Style.toolTipTittle}>
          <h6>
            Monthly Commissionable Rank:
            <CustomToolTip
              links={[
                {
                  index: 4,
                  link: paths.main.wiki,
                },
              ]}
              toolTipText="Please refer to the wiki to see the rules to achieve the ranks."
            />
          </h6>
        </div>
        <Select
          className={Style.select}
          items={filterList}
          value={selectedYear}
          labelKey={"year"}
          setValue={handleChange}
        />
      </div>

      <div className={Style.monthlyCommissionRankCard}>
        <div className={Style.monthlyCommissionRankImg}>
          <img
            src={
              currentMonthlyRank || currentMonthlyRank === 0
                ? generalInfo?.rankInfo?.find(
                    (item) => item?.id === currentMonthlyRank
                  )?.iconUrl
                : NoRank
            }
            alt="Career Rank"
          />
          <p>Current Rank</p>
        </div>
        {timeFilter && timeFilter?.matchedData && (
          <div className={Style.monthlyCommissionRankGrid}>
            {Object.entries(timeFilter?.matchedData?.data).map(
              ([month, rank]) => (
                <div key={month} className={Style.monthlyCommissionRankItem}>
                  <img
                    className={
                      month.toLowerCase() ===
                        CURRENT_MONTH_SHORT.toLowerCase() &&
                      timeFilter.selectedYear === DEFAULT_YEAR
                        ? Style.currentMonth
                        : ""
                    }
                    src={
                      rank || rank === 0
                        ? generalInfo?.rankInfo?.find(
                            (item) => item?.id === rank
                          )?.iconUrl
                        : NoRank
                    }
                    alt=""
                  />
                  <span>{month}</span>
                </div>
              )
            )}
          </div>
        )}
      </div>
    </>
  );
};

const UsersCardGrid = ({
  // Personal VP
  personalVp = 0,
  targetPersonalVp,
  personalVpIncreased = 0,
  // Eligible Directs Users
  eligibleDirects = 0,
  eligibleDirectsIncreased = 0,
  targetEligibleDirects = 0,
  // Direct VP
  directVp = 0,
  directVpIncreased = 0,
  targetDirectVp,
  // Total VP
  totalVp = 0,
  totalVpIncreased = 0,
  targetTotalVp,
}) => {
  return (
    <SimpleStyledCard className={Style.usersCardGrid}>
      <div className={Style.usersCardGridFlex}>
        <div className={Style.cardChildren}>
          <div className={Style.singleUsersCard}>
            <p>Your Turnover</p>
            <div className={Style.amountWrapper}>
              <img src={VP} alt="" />
              <div className={Style.amountTop}>
                {formatNumberEuropean(personalVp)}
                <span>VP</span>
              </div>
            </div>
            {(targetPersonalVp || targetPersonalVp === 0) && (
              <div className={Style.usersTarget}>
                Target: {formatNumberEuropean(targetPersonalVp)}
                <span>VP</span>
              </div>
            )}
          </div>
          <div className={Style.chart}>
            <HalfDoughnutChart
              percentageStart={personalVpIncreased}
              size={120}
              showStartAndEnd={false}
              showPercentage
            />
          </div>
        </div>

        <div className={Style.horizontalSeparator}></div>
        <div className={Style.cardChildren}>
          <div className={Style.singleUsersCard}>
            <p>
              1<sup className={Style.order}>ST</sup> Line Users
            </p>
            <div className={Style.amountWrapper}>
              <div className={Style.amountTop}>
                {eligibleDirects} <span>USERS</span>
              </div>
            </div>
            {(targetEligibleDirects || targetEligibleDirects === 0) && (
              <div className={Style.usersTarget}>
                Target: {targetEligibleDirects}
                <span>USERS</span>
              </div>
            )}
          </div>
          <div className={Style.chart}>
            <HalfDoughnutChart
              percentageStart={eligibleDirectsIncreased}
              size={120}
              showStartAndEnd={false}
              showPercentage
            />
          </div>
        </div>
      </div>
      <div className={Style.verticalSeparator}></div>

      <div className={Style.usersCardGridFlex}>
        <div className={Style.cardChildren}>
          <div className={Style.singleUsersCard}>
            <p>
              1<sup className={Style.order}>ST</sup> Line Turnover
            </p>
            <div className={Style.amountWrapper}>
              <img src={VP} alt="" />
              <div className={Style.amountTop}>
                {formatNumberEuropean(directVp)} <span>VP</span>
              </div>
            </div>
            {(targetDirectVp || targetDirectVp === 0) && (
              <div className={Style.usersTarget}>
                Target: {formatNumberEuropean(targetDirectVp)} <span>VP</span>
              </div>
            )}
          </div>
          <div className={Style.chart}>
            <HalfDoughnutChart
              percentageStart={directVpIncreased}
              size={120}
              showStartAndEnd={false}
              showPercentage
            />
          </div>
        </div>
        <div className={Style.horizontalSeparator}></div>
        <div className={Style.cardChildren}>
          <div className={Style.singleUsersCard}>
            <p>Total Turnover</p>
            <div className={Style.amountWrapper}>
              <img src={VP} alt="" />
              <div className={Style.amountTop}>
                {formatNumberEuropean(totalVp)} <span>VP</span>
              </div>
            </div>
            {(targetTotalVp || targetTotalVp === 0) && (
              <div className={Style.usersTarget}>
                Target: {formatNumberEuropean(targetTotalVp)}
                <span>VP</span>
              </div>
            )}
          </div>
          <div className={Style.chart}>
            <HalfDoughnutChart
              percentageStart={totalVpIncreased}
              size={120}
              showStartAndEnd={false}
              showPercentage
            />
          </div>
        </div>
      </div>
    </SimpleStyledCard>
  );
};

const LegsVolume = ({ strongLegVp = 0, weakLegsVp = 0 }) => {
  return (
    <SimpleStyledCard>
      <div className={Style.cardsInfoGrid}>
        <div className={Style.cardBody}>
          <div className={Style.titleWrapper}>
            <h6>Strong Leg Volume</h6>
          </div>

          <div className={Style.bodyWrapper}>
            <img src={VP} alt="" />
            <div className={Style.amount}>
              {formatLargeNumber(strongLegVp)}
              <span>VP</span>
            </div>
          </div>
        </div>
        <div className={Style.verticalSeparator}></div>
        <div className={Style.cardBody}>
          <div className={Style.titleWrapper}>
            <h6>Weak Legs Volume</h6>
          </div>

          <div className={Style.bodyWrapper}>
            <img src={VP} alt="" />
            <div className={Style.amount}>
              {formatLargeNumber(weakLegsVp)}
              <span>VP</span>
            </div>
          </div>
        </div>
      </div>
    </SimpleStyledCard>
  );
};

export default RankStats;
