import React, { useContext } from "react";

// Components
import WalletBalance from "../walletBalance/WalletBalance";
import Card from "../../../../../../../components/advanced/card/Card";
import SendModal from "../../../../../../../components/advanced/sendModal/SendModal";
import StatusResponse from "../../../../../../../components/advanced/statusResponse/StatusResponse";
import BalanceDetails from "../balanceDetails/BalanceDetails";

// Libraries
import { useOutletContext } from "react-router-dom";
import { Button } from "@hybris-software/ui-kit";

// Context
import GeneralInfoContext from "../../../../../../../contexts/GeneralInfoContext";

// Data
import { otpMethods, withdrawToken } from "../../../../../../../data/constants";
import config from "../../../../../../../data/config";

// Utils
import { addSlashIfNeeded } from "../../../../../../../utils/utilityFunctions";

// Icons
import E from "../../../../../../../assets/svg/E.svg";
import ESmall from "../../../../../../../assets/svg/E-small.svg";
import BillIcon from "../../../../../../../assets/icons/response-Icons/bell.png";

// Styles
import Style from "./InfinityBonusCommission.module.css";
import Theme from "../../../../../../../assets/css/theme.module.css";

const InfinityBonusCommission = ({ walletReportAPI }) => {
  // Context
  const { generalModalRef, generalErrorsResolver } = useOutletContext();
  const [generalInfo] = useContext(GeneralInfoContext);

  return (
    <Card
      minHeight={"180px"}
      apiHook={walletReportAPI}
      className={Style.walletCard}
    >
      <div className={Theme.walletCardTitle}>
        Lifetime Infinity Bonus Commission
      </div>
      <div>
        <WalletBalance
          icon={E}
          balance={walletReportAPI?.response?.data?.totalInfinityAmount}
          unit={config.EURK_UNIT}
        />
      </div>
      <div className={Theme.walletDetails}>
        <BalanceDetails
          icon={ESmall}
          balance={walletReportAPI?.response?.data?.infinityEurficBalance}
          unit={config.EURK_C_UNIT}
          action={() => {
            if (generalInfo?.userInfo?.kycApproved === false) {
              generalModalRef.current.updateBody(
                <StatusResponse
                  icon={BillIcon}
                  title={"Attention !"}
                  description={
                    <div>
                      Your KYC has to be approved to continue with this action,{" "}
                      <span
                        onClick={() => {
                          window.open(
                            addSlashIfNeeded(config.BLOCKCHAINID_BASE_URL) +
                              "kyc",
                            "_blank"
                          );
                        }}
                        className={Style.link}
                      >
                        start your KYC.
                      </span>
                    </div>
                  }
                >
                  <Button onClick={() => generalModalRef.current.destroy()}>
                    Close
                  </Button>
                </StatusResponse>
              );
            } else if (generalInfo?.userInfo?.sendInfinityEnabled === false) {
              generalModalRef.current.updateBody(
                <StatusResponse
                  icon={BillIcon}
                  title={"Attention !"}
                  description={
                    <div>
                      Temporarily, the function is disabled. We are in the
                      process of adding new features and soon all functions will
                      be available again.
                    </div>
                  }
                >
                  <Button onClick={() => generalModalRef.current.destroy()}>
                    Close
                  </Button>
                </StatusResponse>
              );
            } else {
              if (generalInfo.userInfo.otpMethod === otpMethods.TOTP) {
                generalModalRef.current.updateBody(
                  <SendModal
                    token={withdrawToken?.INFINITY_EURFIC}
                    balance={
                      walletReportAPI?.response?.data?.infinityEurficBalance
                    }
                    description={`Please confirm you want to move your ${config.EURK_C_UNIT} balance to ${config.WEBSITE_LFI}`}
                    generalModalRef={generalModalRef}
                    generalErrorsResolver={generalErrorsResolver}
                    refreshAPI={walletReportAPI}
                  />,
                  { modalStyle: { padding: "0" } }
                );
              } else {
                generalModalRef.current.updateBody(
                  <StatusResponse
                    title="OTP Required !"
                    description={`Please change your authentication method in ${config.WEBSITE_2ACCESS} to Google OTP to do this action`}
                  >
                    <Button onClick={() => generalModalRef.current.destroy()}>
                      Close
                    </Button>
                  </StatusResponse>
                );
              }
            }
          }}
          actionLabel={`Send ${config.EURK_C_UNIT} in ${config.WEBSITE_LFI}`}
        />
        <BalanceDetails
          icon={ESmall}
          balance={walletReportAPI?.response?.data?.infinityEurfiwBalance}
          unit={config.EURK_W_UNIT}
          action={() => {
            if (generalInfo?.userInfo?.kycApproved === false) {
              generalModalRef.current.updateBody(
                <StatusResponse
                  icon={BillIcon}
                  title={"Attention !"}
                  description={
                    <div>
                      Your KYC has to be approved to continue with this action,{" "}
                      <span
                        onClick={() => {
                          window.open(
                            addSlashIfNeeded(config.BLOCKCHAINID_BASE_URL) +
                              "kyc",
                            "_blank"
                          );
                        }}
                        className={Style.link}
                      >
                        start your KYC.
                      </span>
                    </div>
                  }
                >
                  <Button onClick={() => generalModalRef.current.destroy()}>
                    Close
                  </Button>
                </StatusResponse>
              );
            } else if (generalInfo?.userInfo?.sendInfinityEnabled === false) {
              generalModalRef.current.updateBody(
                <StatusResponse
                  icon={BillIcon}
                  title={"Attention !"}
                  description={
                    <div>
                      Temporarily, the function is disabled. We are in the
                      process of adding new features and soon all functions will
                      be available again.
                    </div>
                  }
                >
                  <Button onClick={() => generalModalRef.current.destroy()}>
                    Close
                  </Button>
                </StatusResponse>
              );
            } else {
              if (generalInfo.userInfo.otpMethod === otpMethods.TOTP) {
                generalModalRef.current.updateBody(
                  <SendModal
                    token={withdrawToken?.INFINITY_EURFIW}
                    balance={
                      walletReportAPI?.response?.data?.infinityEurfiwBalance
                    }
                    generalModalRef={generalModalRef}
                    generalErrorsResolver={generalErrorsResolver}
                    refreshAPI={walletReportAPI}
                  />,
                  { modalStyle: { padding: "0" } }
                );
              } else {
                generalModalRef.current.updateBody(
                  <StatusResponse
                    title="OTP Required !"
                    description={`Please change your authentication method in ${config.WEBSITE_2ACCESS} to Google OTP to do this action`}
                  >
                    <Button onClick={() => generalModalRef.current.destroy()}>
                      Close
                    </Button>
                  </StatusResponse>
                );
              }
            }
          }}
          actionLabel={`Send ${config.EURK_W_UNIT} in ${config.WEBSITE_LFI}`}
        />
      </div>
    </Card>
  );
};

export default InfinityBonusCommission;
