import React from "react";

const DashIconsFacebook = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33594 16.0709C3.33594 22.3683 7.90965 27.6049 13.8915 28.6668V19.5183H10.7248V16.0001H13.8915V13.185C13.8915 10.0183 15.9319 8.25976 18.8177 8.25976C19.7319 8.25976 20.7177 8.40015 21.6318 8.54054V11.7779H20.0137C18.4652 11.7779 18.1137 12.5516 18.1137 13.5375V16.0001H21.4915L20.9288 19.5183H18.1137V28.6668C24.0955 27.6049 28.6692 22.3693 28.6692 16.0709C28.6692 9.06515 22.9692 3.3335 16.0026 3.3335C9.03593 3.3335 3.33594 9.06515 3.33594 16.0709Z"
        fill="url(#paint0_linear_183_592)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_183_592"
          x1="16.0026"
          y1="3.3335"
          x2="16.0026"
          y2="28.6668"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4FC2AB" />
          <stop offset="1" stopColor="#00FFCC" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DashIconsFacebook;
