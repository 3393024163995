import React, { useContext } from "react";

// Components
import Checkbox from "../checkbox/Checkbox";
import StatusResponse from "../statusResponse/StatusResponse";

// Libraries
import useForm from "@hybris-software/use-ful-form";
import { Button } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";

// Context
import { RoutesContext } from "../../../contexts/RoutesContext";

// Data
import endpoints from "../../../data/endpoints";

//Images
// import Modal from "../../../assets/images/modal.png";
// Icon
import BillIcon from "../../../assets/icons/response-Icons/bell.png";

// Styles
import Style from "./VipSupportModal.module.css";

const VipSupportModal = ({ generalModalRef }) => {
  const { paths } = useContext(RoutesContext);

  const form = useForm({
    inputs: {
      showAgain: {
        nature: "checkbox",
        value: false,
      },
    },
  });

  const readVIPSupportPopupAPI = useQuery({
    url: endpoints.vipSupport?.READ_VIP_POPUP,
    method: "POST",
  });

  return (
    <StatusResponse
      icon={BillIcon}
      title={"Join the Telegram Channel"}
      description={"XERA COMMUNITY OFFICIAL: A group created to help each of you achieve maximum success with XERA."}
      cta={{
        isRight: true,
        text: "Subscribe",
        to: paths.main.vipSupport,
      }}
      onClick={() => {
        if (form?.getInputProps("showAgain")?.value) {
          readVIPSupportPopupAPI?.executeQuery();
        }
        generalModalRef?.current?.destroy();
      }}
    >
      <div className={Style.showAgain}>
        <Checkbox
          checked={form.values["showAgain"]}
          onChange={(e) =>
            form?.getInputProps("showAgain").setValue(e.target.checked)
          }
        />
        Please don't show this again
      </div>
      <Button
        onClick={() => {
          if (form?.getInputProps("showAgain")?.value) {
            readVIPSupportPopupAPI?.executeQuery();
          }
          generalModalRef?.current?.destroy();
        }}
        className={Style.closeBtn}
      >
        Close
      </Button>
    </StatusResponse>
  );
};

export { VipSupportModal };
