import React, { useEffect, useContext } from "react";

// Components
import Loader from "../../../components/ui/loader/Loader";
import CommissionStatistics from "./components/commissionStatistics/CommissionStatistics";
import TransactionHistory from "./components/transactionHistory/TransactionHistory";
import SimpleTitle from "../../../components/advanced/simpleTitle/SimpleTitle";

// Libraries
import { Container } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";
import { useNavigate } from "react-router-dom";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Data
import config from "../../../data/config";

// Styles
import Style from "./WalletView.module.css";
import Wallets from "./components/wallets/Wallets";

const WalletView = () => {
  
  // Hooks
  const navigate = useNavigate();

  // Contexts
  const { paths } = useContext(RoutesContext);

  useEffect(() => {
    document.title = `${config.WEBSITE_NAME} | Wallet`;
    //eslint-disable-next-line
  }, []);

  return (
    <PermissionRoute
      loader={<Loader />}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        // dispatcher
        return true;
      }}
    >
      <Container>
        <SimpleTitle title="Commission" />
        <section className={Style.section}>
          <Wallets />
        </section>
        <section className={Style.section}>
          <CommissionStatistics />
        </section>
        <section className={Style.section}>
          <TransactionHistory />
        </section>
      </Container>
    </PermissionRoute>
  );
};

export default WalletView;
