import React, { useEffect, useContext } from "react";

// Libraries
import { PermissionRoute } from "@hybris-software/use-auth";
import { Container } from "@hybris-software/ui-kit";
import { useNavigate } from "react-router-dom";

// Components
import SupportForm from "./components/supportForm/SupportForm";
import Loader from "../../../components/ui/loader/Loader";
import JoinTelegram from "./components/joinTelegram/JoinTelegram";

// Data
import config from "../../../data/config";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";
import GeneralInfoContext from "../../../contexts/GeneralInfoContext";

// Styles
import Style from "./VipCustomerSupportView.module.css";

const VipCustomerSupportView = () => {
  // Hooks
  const navigate = useNavigate();

  // Contexts
  const { paths } = useContext(RoutesContext);
  const [generalInfo] = useContext(GeneralInfoContext);

  useEffect(() => {
    document.title = `${config.WEBSITE_NAME} | VIP Customer Support`;
    //eslint-disable-next-line
  }, []);

  return (
    <PermissionRoute
      loader={<Loader />}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        // dispatcher
        if (!generalInfo?.userInfo?.isShowVipSupport) {
          navigate(paths.main.dashboard);
          return false;
        }
        return true;
      }}
    >
      <Container>
        <section>
          <JoinTelegram />
        </section>
        <section className={Style.form}>
          <SupportForm />
        </section>
      </Container>
    </PermissionRoute>
  );
};

export default VipCustomerSupportView;
