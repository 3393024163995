import React, { useState, useContext } from "react";

// Components
import Card from "../../../../../components/advanced/card/Card";

// Libraries
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";

// Context
import GeneralInfoContext from "../../../../../contexts/GeneralInfoContext";

// Data
import endpoints from "../../../../../data/endpoints";

// Icons
import { PiArrowLeft } from "react-icons/pi";

// Utils
import {
  activityManager,
  activityBackground,
} from "../../../../../utils/activitiesManager";

// Style
import Style from "./Activities.module.css";

const Activities = ({ id }) => {
  // Context
  const [generalInfo] = useContext(GeneralInfoContext);

  //  States
  const [url, setUrl] = useState(
    `${endpoints.builderReport.USERS}${id}/activities/?limit=5`
  );

  // Queries
  const activitiesAPI = useQuery({
    url: url,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  // Function
  function timeAgo(timestamp) {
    const currentDate = new Date();
    const lastPasswordChangeDate = new Date(timestamp);

    const timeDifference = currentDate - lastPasswordChangeDate;

    const minutesDifference = Math.floor(timeDifference / (1000 * 60));
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    let timeUnit;
    let unitLabel;

    if (minutesDifference < 60) {
      timeUnit = minutesDifference;
      unitLabel = " Minutes";
    } else if (hoursDifference < 24) {
      timeUnit = hoursDifference;
      unitLabel = " Hour";
    } else {
      timeUnit = daysDifference;
      unitLabel = " Day";
    }

    const pluralSuffix = timeUnit === 1 ? "" : "s";
    const passwordUnit = [timeUnit, `${unitLabel}${pluralSuffix} ago`];

    return passwordUnit;
  }
  const linksAPI = activitiesAPI?.response?.data?.links;
  return (
    <Card apiHook={activitiesAPI} minHeight={"684px"} className={Style.card}>
      <div className={Style.bodyWrapper}>
        <h5 className={Style.activityTitle}>Activities</h5>
        {activitiesAPI?.response?.data?.results?.length > 0 ? (
          <div className={Style.activityList}>
            {activitiesAPI.response.data.results.map((item, index) => (
              <div
                className={
                  activitiesAPI?.response?.data?.results?.length === 1
                    ? Style.oneActivity
                    : Style.activity
                }
                key={index}
              >
                <div className={Style.activityCard}>
                  <img
                    src={activityBackground(item?.category)}
                    alt=""
                    className={Style.imgCategory}
                  />
                  <div className={Style.description}>
                    {activityManager(
                      item.category,
                      "public",
                      item?.data,
                      generalInfo.rankInfo
                    )}
                  </div>
                  <div className={Style.activityDate}>
                    {timeAgo(item?.createdAt)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={Style.emptyMessage}>No activities yet</div>
        )}
      </div>

      <div className={Style.pagination}>
        <div
          style={{
            opacity: linksAPI?.previous === null ? 0.5 : 1,
            cursor: linksAPI?.previous === null ? "not-allowed" : "pointer",
          }}
          className={Style.prev}
          onClick={() => {
            if (linksAPI?.previous !== null) {
              setUrl(linksAPI?.previous);
            }
          }}
        >
          <PiArrowLeft />
        </div>

        <div
          style={{
            opacity: linksAPI?.next === null ? 0.5 : 1,
            cursor: linksAPI?.next === null ? "not-allowed" : "pointer",
          }}
          className={Style.next}
          onClick={() => {
            if (linksAPI?.next !== null) {
              setUrl(linksAPI?.next);
            }
          }}
        >
          <PiArrowLeft />
        </div>
      </div>
    </Card>
  );
};

export default Activities;
