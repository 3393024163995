import React, { useState, useContext } from "react";

// Components
import FisrtStepIcon from "../../../assets/svgComponents/FisrtStepIcon";

// Libraries
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useWindowSize } from "@hybris-software/ui-kit";

// Context
import GeneralInfoContext from "../../../contexts/GeneralInfoContext";

// Images
import Placeholder from "../../../assets/images/placeholder.png";

// Icons
import { FiChevronDown } from "react-icons/fi";
import { HiOutlineLockClosed } from "react-icons/hi";
import { MdLogout } from "react-icons/md";
import { CgWebsite } from "react-icons/cg";
import { BiSupport } from "react-icons/bi";

// Utils
import classNames from "../../../utils/classNames";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Styles
import Style from "./Sidebar.module.css";
import config from "../../../data/config";

const sidebarClass = (sidebarIsOpen, isDesktop) => {
  if (isDesktop) {
    if (sidebarIsOpen) return Style.sidebarOpened;
    else return Style.sidebarOpened;
  } else {
    if (sidebarIsOpen) return Style.sidebarOpened;
    else return Style.sidebarMobile;
  }
};

const dropdownLabelClass = (routeBasePath, location) => {
  if (location.pathname === routeBasePath) return Style.elementActive;
  else if (location.pathname.split("/")[1] === routeBasePath.split("/")[1])
    return Style.elementActive;
  else return Style.element;
};

const linkLabelClass = (isOpen) => {
  if (isOpen) return Style.mainCategoryOpenCollapsed;
  else return Style.mainCategory;
};

const Sidebar = ({
  route,
  sidebarIsOpen,
  setSidebarIsOpen,
  logoutModalRef,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { paths } = useContext(RoutesContext);
  const { width } = useWindowSize();
  const isDesktop = width >= 1200 ? true : false;

  // Context
  const [generalInfo] = useContext(GeneralInfoContext);

  const [openDropdowns, setOpenDropdowns] = useState(
    Object.keys(route).reduce((acc, key) => {
      acc[key] = route[key].routes.map(() => false);
      return acc;
    }, {})
  );

  const setDropdownOpen = (index, category, open) => {
    setOpenDropdowns((oldOpenDropdowns) => {
      const newOpenDropdowns = { ...oldOpenDropdowns };
      newOpenDropdowns[category][index] = open;
      return newOpenDropdowns;
    });
  };

  return (
    <div className={sidebarClass(sidebarIsOpen, isDesktop)}>
      {/* <MagicModal
                ref={logoutModalRef}
                body={<LogoutModalBody modalRef={logoutModalRef} />}
                destroyBodyOnClose={false}
            /> */}

      <div className={Style.background} />
      {/* Top */}
      <div className={Style.boxLogo}>
        <img src={config.LOGO_WHITE} alt="" />

        <div className={Style.avatar}>
          <div
            className={Style.picture}
            style={{
              backgroundImage: `url(${
                generalInfo?.userInfo?.profilePicture ?? Placeholder
              })`,
            }}
          />
          <div className={Style.username}>
            @{generalInfo?.userInfo?.username}
          </div>
        </div>

        {/* todo: visible only if all the steps arent completed */}
        <div
          className={
            location.pathname === paths.main.firstStep
              ? Style.firstStepActive
              : Style.firstStep
          }
          onClick={() => {
            navigate(paths.main.firstStep);
            setSidebarIsOpen(false);
          }}
        >
          <FisrtStepIcon />
          First Step
        </div>
        <div
          className={Style.firstStep}
          style={{ marginBlock: "5px 0" }}
          onClick={() => {
            window.open(config.SUPPORT_LINK, "_blank", "noopener noreferrer");
            setSidebarIsOpen(false);
          }}
        >
          <BiSupport />
          Support
        </div>
      </div>

      {/* Center */}
      <div className={Style.boxNav}>
        <nav>
          {Object.keys(route).map((category, i) => {
            return (
              <div key={i} className={Style.categoryBox}>
                {route[category].showInSideBar && (
                  <div className={Style.labelCategory}>
                    <div className={Style.category}>
                      {route[category].categoryTitle}
                    </div>
                    <div className={Style.categoryLine}></div>
                  </div>
                )}
                <ul>
                  {route[category].routes.map((el, j) => {
                    if (el?.comingSoon) {
                      return <NavSimpleNotAllowed key={j} route={el} />;
                    } else if (
                      el?.hideInNavbar
                       ||
                      (el.path === "vip-customer-support" &&
                        !generalInfo?.userInfo?.isShowVipSupport)
                    ) {
                      return null;
                    } else {
                      if (el.routes) {
                        return (
                          <NavMenu
                            key={j}
                            route={el}
                            basePath={el.path}
                            isOpen={openDropdowns[category][j]}
                            setOpen={(open) =>
                              setDropdownOpen(j, category, open)
                            }
                            sidebarIsOpen={sidebarIsOpen}
                            setSidebarIsOpen={setSidebarIsOpen}
                            isDesktop={isDesktop}
                          />
                        );
                      } else {
                        return (
                          <NavSimple
                            key={j}
                            route={el}
                            isDesktop={isDesktop}
                            setSidebarIsOpen={setSidebarIsOpen}
                          />
                        );
                      }
                    }
                  })}
                </ul>
              </div>
            );
          })}
        </nav>
      </div>

      {/* Bottom */}
      <div className={Style.containerBox}>
        <div
          className={Style.itemBottom}
          onClick={() => {
            window.open(
              `https://app.2access.io/`,
              "_blank",
              "noopener noreferrer"
            );
          }}
        >
          <CgWebsite />
          Go to {config.WEBSITE_2ACCESS}
        </div>
        <div
          className={Style.itemBottom}
          onClick={() => {
            logoutModalRef.current.open();
          }}
        >
          <MdLogout />
          Logout
        </div>
      </div>
    </div>
  );
};

const NavSimple = ({ route, isDesktop, setSidebarIsOpen }) => {
  const location = useLocation();
  const routeBasePath = `/${route.path}`;

  return (
    <li
      className={dropdownLabelClass(routeBasePath, location)}
      onClick={() => {
        !isDesktop && setSidebarIsOpen(false);
      }}
    >
      <Link
        to={route.normalLink ? route.link : routeBasePath}
        target={route.normalLink ? "_blank" : null}
        rel="noopener noreferrer"
      >
        <LabelLinkSimple route={route} />
      </Link>
    </li>
  );
};

const NavSimpleNotAllowed = ({ route }) => {
  const location = useLocation();
  const routeBasePath = `/${route.path}`;

  return (
    <li
      className={dropdownLabelClass(routeBasePath, location)}
      style={{ cursor: "not-allowed" }}
    >
      <Link>
        <LabelLinkSimpleNotAllowed
          icon={route.icon}
          title={route.title}
          style={{ cursor: "not-allowed" }}
        />
      </Link>
    </li>
  );
};

const LabelLinkSimple = ({ route }) => {
  return (
    <div className={Style.mainCategory}>
      <div className={Style.innerCategory}>
        <div className={Style.iconWrapper}>{route.icon}</div>
        <div className={Style.categoryText}>{route.title}</div>
      </div>
    </div>
  );
};

const LabelLinkSimpleNotAllowed = ({ icon, title }) => {
  return (
    <div
      className={Style.mainCategory}
      style={{ cursor: "not-allowed", position: "relative", opacity: 0.6 }}
    >
      <div className={Style.innerCategory} style={{ cursor: "not-allowed" }}>
        <div className={Style.iconWrapper}>{icon}</div>
        <div className={Style.categoryText} style={{ cursor: "not-allowed" }}>
          {title}
        </div>
      </div>
      <div className={Style.lock}>
        <HiOutlineLockClosed />
      </div>
    </div>
  );
};

const NavMenu = ({
  route,
  isOpen,
  setOpen,
  setSidebarIsOpen,
  isDesktop,
  sidebarIsOpen,
}) => {
  const location = useLocation();
  const routeBasePath = `/${route.path}`;

  return (
    <li className={dropdownLabelClass(routeBasePath, location)}>
      <LabelLink
        icon={route.icon}
        routes={route.routes}
        title={route.title}
        isOpen={isOpen}
        setOpen={setOpen}
        setSidebarIsOpen={setSidebarIsOpen}
      />
      <Dropdown
        isOpen={isOpen}
        routes={route.routes}
        basePath={routeBasePath}
        isDesktop={isDesktop}
        setSidebarIsOpen={setSidebarIsOpen}
        sidebarIsOpen={sidebarIsOpen}
      />
    </li>
  );
};

const LabelLink = ({ icon, routes, title, isOpen, setOpen }) => {
  return (
    <div
      className={linkLabelClass(isOpen)}
      onClick={() => {
        setOpen(!isOpen);
        // setSidebarIsOpen(true);
      }}
    >
      <div className={Style.innerCategory}>
        <div className={Style.iconWrapper}>{icon}</div>
        <div className={Style.categoryText}>{title}</div>
      </div>
      {routes && (
        <FiChevronDown
          className={classNames(Style.arrow, {
            class: Style.arrowActive,
            condition: isOpen,
          })}
        />
      )}
    </div>
  );
};

const Dropdown = ({
  isOpen,
  routes,
  basePath,
  isDesktop,
  setSidebarIsOpen,
  sidebarIsOpen,
}) => {
  const routesLength = Object.entries(routes).filter(
    ([val]) => !val?.comingSoon
  ).length;

  return (
    <ul
      style={{ height: isOpen ? `${routesLength * 47}px` : "0px" }}
      className={classNames(Style.closeDropdown, {
        class: Style.active,
        condition: isOpen,
      })}
    >
      {Object.entries(routes).map(([key, val]) => {
        return (
          <DropdownElement
            key={key}
            route={val}
            basePath={basePath}
            isDesktop={isDesktop}
            setSidebarIsOpen={setSidebarIsOpen}
          />
        );
      })}
    </ul>
  );
};

const DropdownElement = ({ route, basePath, isDesktop, setSidebarIsOpen }) => {
  const location = useLocation();
  const routeBasePath = `${basePath}${route.path}`;

  return (
    <li
      onClick={() => {
        !isDesktop && setSidebarIsOpen(false);
      }}
    >
      <div className={Style.subCategory}>
        <div className={Style.innerSub}>
          {route?.comingSoon === true ? (
            <div className={Style.locked}>
              <div
                className={
                  location.pathname === routeBasePath
                    ? Style.innerElementActive
                    : Style.innerElement
                }
                style={{
                  cursor: "not-allowed",
                  position: "relative",
                  opacity: 0.6,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className={Style.overflow}>{route.title}</div>
                <div className={Style.lock}>
                  <HiOutlineLockClosed />
                </div>
              </div>
            </div>
          ) : (
            <Link
              to={route.normalLink ? route.link : routeBasePath}
              target={route.normalLink ? "_blank" : null}
              rel="noopener noreferrer"
            >
              <div
                className={
                  location.pathname === routeBasePath
                    ? Style.innerElementActive
                    : Style.innerElement
                }
              >
                {route.title}
              </div>
            </Link>
          )}
        </div>
      </div>
    </li>
  );
};

export default Sidebar;
