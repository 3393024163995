import React from "react";

// Utils
import { formatNumberEuropean } from "../../../../../../../utils/utilityFunctions";

// Styles
import Style from "./WalletBalance.module.css";

const WalletBalance = ({ icon, balance, unit }) => {
  return (
    <div className={Style.wrapper}>
      <div>
        <img src={icon} alt="Icon" />
      </div>
      <div className={Style.balance}>
        {formatNumberEuropean(
          balance
        )}
        <span>{unit}</span>
      </div>
    </div>
  );
};

export default WalletBalance;
