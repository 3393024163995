import React from "react";

// Components
import OverLappingChart from "./../../../../../components/advanced/overLappingChart/OverLappingChart";
import Card from "./../../../../../components/advanced/card/Card";

// Libraries
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";


//Icons
import VP from "../../../../../assets/svg/VP.svg";
import IP from "../../../../../assets/svg/IP.svg";

// utilities
import {
  calculatePercentageBetweenCurrentAndMaxValue,
  formatNumberEuropean,
} from "../../../../../utils/utilityFunctions";

// Data
import endpoints from "../../../../../data/endpoints";

// Styles
import Style from "./ActiveQualifiedCharts.module.css";

const ActiveQualifiedCharts = ({ id }) => {
  // Queries
  const activationAPI = useQuery({
    url: endpoints.activeQualified.ACTIVATION(id),
    method: "GET",
    executeImmediately: true,
    clientOptions: {
      timeout: 120000,
    },
  });


  return (
    <>
      <div className={Style.grid}>
        <Card apiHook={activationAPI} minHeight={"290px"}>
          <div className={Style.titleWrapper}>
            <h5 className={Style.title}>Active Qualified Unilevel</h5>
          </div>

          <div className={Style.chartContainer}>
            <div className={Style.labelsFlex}>
              <div className={Style.labelWrapper}>
                <div className={Style.label}>
                  <span>
                    <div
                      className={Style.labelCircle}
                      style={{ backgroundColor: "#72E47E" }}
                    ></div>
                    Total Volume
                  </span>
                  <div className={Style.bodyWrapper}>
                    <img src={VP} alt="" />
                    <div className={Style.amount}>
                      {formatNumberEuropean(
                        activationAPI?.response?.data?.unilevel
                          .activationCurrentPoints,
                        2
                      )}{" "}
                      /{" "}
                      {formatNumberEuropean(
                        activationAPI?.response?.data?.unilevel
                          .activationRequiredPoints,
                        2
                      )}
                      <span>VP</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.buttomWrapper}>
                <div className={Style.separator} />
                <div className={Style.statusWrapper}>
                  <div className={Style.status}>
                    Status:{" "}
                    <span
                      className={
                        !activationAPI?.response?.data?.unilevel.active
                          ? Style.error
                          : Style.success
                      }
                    >
                      {!activationAPI?.response?.data?.unilevel.active
                        ? "Not Active"
                        : "Active"}
                    </span>
                  </div>
                  <div className={Style.lineunlocked}>
                    Unlocked line:{" "}
                    <span>
                      {activationAPI?.response?.data?.unilevel.unlockedLines} /{" "}
                      {activationAPI?.response?.data?.unilevel.totalLines}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={Style.chart}>
              <OverLappingChart
                Title="AQ Unilevel"
                showInner={false}
                showMiddle={false}
                WrapperPercentage={calculatePercentageBetweenCurrentAndMaxValue(
                  activationAPI?.response?.data?.unilevel
                    .activationCurrentPoints,
                  activationAPI?.response?.data?.unilevel
                    .activationRequiredPoints
                )}
                WrapperColor="#72E47E"
              />
            </div>
          </div>
        </Card>

        <Card apiHook={activationAPI} minHeight={"290px"}>
          <div className={Style.titleWrapper}>
            <h5 className={Style.title}>Active Qualified Infinity</h5>
          </div>
          <div className={Style.chartContainer}>
            <div className={Style.labelsFlex}>
              <div className={Style.labelsFlexInner}>
                <div className={Style.labelWrapper}>
                  <div className={Style.label}>
                    <span>
                      <div
                        className={Style.labelCircle}
                        style={{ backgroundColor: "#3899D6" }}
                      />
                      Total Volume (50%)
                    </span>
                    <div className={Style.bodyWrapper}>
                      <img src={IP} alt="" />
                      <div className={Style.amount}>
                        {formatNumberEuropean(
                          activationAPI?.response?.data?.infinity
                            .activationCurrentPoints,
                          2
                        )}{" "}
                        /{" "}
                        {formatNumberEuropean(
                          activationAPI?.response?.data?.infinity
                            .activationRequiredPoints,
                          2
                        )}
                        <span>IP</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Style.labelWrapper}>
                  <div className={Style.label}>
                    <span>
                      <div
                        className={Style.labelCircle}
                        style={{ backgroundColor: "#08F57F" }}
                      />
                      Weak Legs Volume
                    </span>
                    <div className={Style.bodyWrapper}>
                      <img src={IP} alt="" />

                      <div className={Style.amount}>
                        {formatNumberEuropean(
                          activationAPI?.response?.data?.infinity
                            .activationWeakLegPoints,
                          2
                        )}
                        <span>IP</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.separator} />
              <div className={Style.status}>
                Status:{" "}
                <span
                  className={
                    !activationAPI?.response?.data?.infinity.active
                      ? Style.error
                      : Style.success
                  }
                >
                  {!activationAPI?.response?.data?.infinity.active
                    ? "Not Active"
                    : "Active"}
                </span>
              </div>
            </div>
            <div className={Style.chart}>
              <OverLappingChart
                Title="AQ Infinity"
                showInner={false}
                middlePercentage={calculatePercentageBetweenCurrentAndMaxValue(
                  activationAPI?.response?.data?.infinity
                    .activationWeakLegPoints,
                  activationAPI?.response?.data?.infinity
                    .activationRequiredPoints / 2
                )}
                WrapperPercentage={calculatePercentageBetweenCurrentAndMaxValue(
                  activationAPI?.response?.data?.infinity
                    .activationCurrentPoints,
                  activationAPI?.response?.data?.infinity
                    .activationRequiredPoints
                )}
                MiddleColor="#2F8E84"
                WrapperColor="#3899D6"
              />
            </div>
          </div>
        </Card>
      </div>

      {/* {activationAPI?.response?.data?.unilevel.active && difference <= 0 && (
        <DataVisualization
          activationWindow={
            activationAPI?.response?.data?.unilevel.activationWindow
          }
        />
      )} */}
    </>
  );
};

export default ActiveQualifiedCharts;
