import React, { useRef } from "react";

// Components
import Card from "../../../../../components/advanced/card/Card";
import DateFormatter from "../../../../../components/advanced/dateFormatter/DateFormatter";

// Libraries
import { TableClient } from "@hybris-software/use-table";
import styled from "styled-components";
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";

// Utilities
import { formatNumberEuropean } from "../../../../../utils/utilityFunctions";

// Data
import endpoints from "../../../../../data/endpoints";

// Icon
import LineCloseIcon from "../../../../../assets/svgComponents/LineCloseIcon";
import LineCheckIcon from "../../../../../assets/svgComponents/LineCheckIcon";
// Styles
import Style from "./DepthLevels.module.css";

const customStyles = styled.div`
  padding: 0;
  color: var(--light);

  table {
    position: relative;
    width: 100%;
    border-spacing: 0;
    margin-bottom: 23px;
    thead {
      tr {
        font-weight: 500;
        font-size: 16px;
        th {
          max-width: 800px;
          text-align: center;
          padding: 0 10px;
        }
      }
    }
    tbody {
      tr {
        height: 65px;
        font-weight: 500;
        font-size: 15px;
        color: #ccc;
        border: 1px solid red;

        td {
          max-width: 800px;
          text-align: center;
          border-bottom: 1px solid rgba(131, 129, 129, 0.25);
          :first-child,
          :last-child {
            border-width: 1px;
            border-style: solid;
            border-right: none;
            border-left: none;
            border-top: none;
            border-image: linear-gradient(
                to left,
                rgba(131, 129, 129, 0.25) 0%,
                rgba(131, 129, 129, 0.25) 43.75%,
                rgba(108, 108, 108, 0) 100%
              )
              1 10%;
          }
          :last-child {
            border-image: linear-gradient(
                to left,
                rgba(108, 108, 108, 0) 0%,
                rgba(131, 129, 129, 0.25) 43.75%,
                rgba(131, 129, 129, 0.3) 100%
              )
              1 10%;
          }
          padding: 0 10px;
        }
      }
    }
  }
`;

const DepthLevels = () => {
  const columns = [
    {
      Header: "#",
      field: "id",
      orderField: "id",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return row.id;
      },
    },
    {
      Header: "First Line Users",
      field: "firstLineCount",
      orderField: "first_line_count",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return row.firstLineCurrentCount + "/" + row.firstLineCount;
      },
    },
    {
      Header: "Total Turnover",
      field: "isQualified",
      orderField: "is_qualified",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return (
          formatNumberEuropean(row.firstLineCurrentCp, 2) +
          "/" +
          formatNumberEuropean(row.firstLineCp, 2)
        );
      },
    },
    {
      Header: "Bonus Percentage",
      field: "bonusCpPercent",
      orderField: "bonus_cp_percent",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return row.bonusCpPercent + "%";
      },
    },
    {
      Header: "Status",
      field: "s",
      orderField: "first_lineCp",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return row.isQualified ? <LineCheckIcon /> : <LineCloseIcon />;
      },
    },
    {
      Header: "Date",
      field: "date",
      orderField: "date",
      sortable: false,
      noAction: true,
      accessor: (row) => {
        return row.unlockedAt ? <DateFormatter date={row.unlockedAt} /> : "--";
      },
    },
  ];

  const ref = useRef(null);

  // Queries
  const unileveleBomusLinesAPI = useQuery({
    url: endpoints.activeQualified.UNILEVEL_BONUS_LINES,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  return (
    <Card
      minHeight={"1100px"}
      apiHook={unileveleBomusLinesAPI}
      className={Style.depthLevels}
    >
      <div className={Style.header}>
        <h5>Unlock Unilevel Depth Level </h5>
      </div>
      <TableClient
        height={610}
        defaultPageSize={20}
        pageSizes={[1, 2, 3, 5, 10, 100]}
        enableAllowedActions={false}
        ref={ref}
        columns={[...columns]}
        rawData={unileveleBomusLinesAPI?.response?.data}
        Styles={customStyles}
        searchBadgesClassName={Style.searchBadges}
        paginationClassName={Style.pagination}
        enablePageSizeSelect={false}
        enableSearch={false}
        enableSearchFieldSelect={false}
        enableSelectableRows={false}
        enableSettings={false}
        enableRowsSelectedBadge={false}
        enableSearchBadges={false}
      />
    </Card>
  );
};

export default DepthLevels;
