import React from "react";

const PoolIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      id="pool"
      width="27"
      height="27"
    >
      <path
        stroke="#DBDBDB"
        fill="#1b1b1b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2,12.9c0.3-0.8,1-1.4,1.9-1.4H10c0.9,0,1.7,0.5,2,1.4l1,3.2c0.3,0.9,0.5,1.9,0.5,2.9l0,0    c0,0.8-0.7,1.5-1.5,1.5H2c-0.8,0-1.5-0.7-1.5-1.5l0,0c0-1,0.2-2,0.5-2.9L2,12.9z"
      ></path>
      <polyline
        stroke="#DBDBDB"
        fill="#1b1b1b"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="11.1 16.7 10.1 13.5 7.5 13.5"
      ></polyline>
      <path
        stroke="#DBDBDB"
        fill="#1b1b1b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12,20.5h10c0.8,0,1.5-0.7,1.5-1.5l0,0c0-1-0.2-2-0.5-2.9l-1-3.2c-0.3-0.8-1-1.4-1.9-1.4h-6.2    c-0.9,0-1.7,0.5-1.9,1.4V13"
      ></path>
      <polyline
        stroke="#DBDBDB"
        fill="#1b1b1b"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="21.1 16.7 20.1 13.5 17.5 13.5"
      ></polyline>
      <path
        stroke="#DBDBDB"
        fill="#1b1b1b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18.4,11.5c0.1-0.2,0.1-0.3,0.1-0.5l0,0c0-1-0.2-2-0.5-2.9l-1-3.2c-0.3-0.8-1-1.4-1.9-1.4H8.9    C8.1,3.5,7.3,4,7,4.9L6,8.1C5.7,9,5.5,10,5.5,11l0,0c0,0.2,0,0.3,0.1,0.5"
      ></path>
      <polyline
        stroke="#DBDBDB"
        fill="#1b1b1b"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="16.1 8.7 15.1 5.5 12.5 5.5"
      ></polyline>
    </svg>
  );
};

export default PoolIcon;
