import React, { useContext } from "react";

// Components
import Card from "../../../../../components/advanced/card/Card";
import CountDown from "../../../../../components/advanced/countDown/CountDown";

//libs
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";

// Styles
import Style from "./UnilevelStatistics.module.css";

// Data
import endpoints from "../../../../../data/endpoints";

// Contexts
import GeneralInfoContext from "../../../../../contexts/GeneralInfoContext";

const UnilevelStatistics = () => {
  //context
  const [generalInfo] = useContext(GeneralInfoContext);

  // Queries
  const activationAPI = useQuery({
    url: endpoints.activeQualified.ACTIVATION(generalInfo?.userInfo?.id),
    method: "GET",
    executeImmediately: true,
    clientOptions: {
      timeout: 120000,
    },
  });

  const items = [
    {
      name: "Number of unlocked levels",
      value: `${
        activationAPI?.response?.data?.unilevel?.unlockedLines ?? "-"
      }/${activationAPI?.response?.data?.unilevel?.totalLines ?? "-"}`,
    },
    {
      name: "Number of qualified levels",
      value: `${activationAPI?.response?.data?.unilevel?.activeLines ?? "-"}/${
        activationAPI?.response?.data?.unilevel?.totalLines ?? "-"
      }`,
    },
    {
      name: "Status",
      value: activationAPI?.response?.data?.unilevel?.active
        ? "Active"
        : "Not Active",
      color: activationAPI?.response?.data?.unilevel?.active
        ? "#00d27b"
        : "#efc11e",
    },
  ];

  return (
    <Card apiHook={activationAPI} minHeight="80">
      <div className={Style.gridWrapper}>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <div className={Style.card}>
              <div className={Style.infoWrapper}>
                <div className={Style.infoDetails}>
                  <p>{item.name}</p>
                  <span style={{ color: item.color }}>{item.value}</span>
                </div>
              </div>
            </div>
            {index < items.length - 1 && (
              <div className={Style.normalBar}></div>
            )}
          </React.Fragment>
        ))}
        {activationAPI?.response?.data?.unilevel?.unilevelActivationEndsAt && (
          <CountDown deadline={activationAPI?.response?.data?.unilevel?.unilevelActivationEndsAt} />
        )}
      </div>
    </Card>
  );
};

export default UnilevelStatistics;
