import React, { useRef } from "react";

// Components
// Utilities
import classNames from "../../../utils/classNames";
import { copyTextToClipboard } from "../../../utils/utilityFunctions";

//Icons
import CopyIcon from "../../../assets/svgComponents/CopyIcon";

// Style
import Style from "./CopyInput.module.css";

const CopyInput = ({ label, value, textIcon, withCopyIcon = true }) => {
  const copyTooltipRef = useRef(null);

  const handleCopyClick = () => {
    copyTextToClipboard(value)
      .then(() => {
        showCopyTooltip();
      })
      .catch((err) => {});
  };

  const showCopyTooltip = () => {
    if (copyTooltipRef.current) {
      copyTooltipRef.current.classList.add(Style.show);
      setTimeout(() => {
        hideCopyTooltip();
      }, 1500);
    }
  };

  const hideCopyTooltip = () => {
    if (copyTooltipRef.current) {
      copyTooltipRef.current.classList.remove(Style.show);
    }
  };

  return (
    <div className={Style.CopyInput}>
      {label && <div className={Style.label}>{label}</div>}
      <div className={Style.info}>
        <div className={Style.iconText}>
          {textIcon && <img src={textIcon} alt="textIcon" />}
          <span className={Style.toCopy}>{value}</span>
        </div>
        {withCopyIcon && (
          <div onClick={() => handleCopyClick()} className={Style.copyBtn}>
            <CopyIcon />
            <span
              ref={copyTooltipRef}
              className={classNames(Style.copyTooltip)}
              id="copyTooltip"
              aria-live="assertive"
              role="tooltip"
            >
              Copied!
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CopyInput;
