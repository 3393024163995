import React from "react";

// Components
import SimpleStyledCard from "../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";

// Styles
import Style from "./Summary.module.css";

const Summary = () => {
  return (
    <SimpleStyledCard>
      <div className={Style.title}>Unilevel Bonus</div>
      <p className={Style.subTitle}>
        Unilevel Bonus is rewarded for volume points from your active teams,
        across multiple eligible levels. To be eligible for the same, you have
        to be active and qualified.
      </p>
    </SimpleStyledCard>
  );
};

export default Summary;
