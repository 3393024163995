import { useState, useEffect } from "react";

// Components
import SimpleStyledCard from "./../../../../../components/advanced/simpleStyledCard/SimpleStyledCard";
import Card from "../../../../../components/advanced/card/Card";

// Icons
import Live from "../../../../../assets/svg/liveAction.svg";

// Components
import useQuery from "@hybris-software/use-query";

// Data
import endpoints from "../../../../../data/endpoints";

// Styles
import Style from "./RealTimeUsers.module.css";

const RealTimeUsers = () => {
  // Quires
  const latestUsersAPI = useQuery({
    url: endpoints.dashboard.LATEST_USERS,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  // Functions
  const fetchLatestUsersData = () => {
    latestUsersAPI.executeQuery();
  };

  useEffect(() => {
    fetchLatestUsersData();

    const interval = setInterval(fetchLatestUsersData, 120000);
    return () => {
      clearInterval(interval);
    };
    //eslint-disable-next-line
  }, []);

  // Consts
  const usersData = latestUsersAPI?.response?.data;
  return (
    <SimpleStyledCard>
      <div className={Style.cardTitle}>
        <h5>Real Time Users</h5>
        <img src={Live} className={Style.iconAnimation} alt="live" />
      </div>
      <Card className={Style.card} apiHook={latestUsersAPI}>
        <div className={Style.cardsContainerParent}>
          <div className={Style.cardsContainer}>
            <div className={Style.flexContainer}>
              {usersData?.map((user, index) => (
                <NewUsersCard
                  key={`user_${index}`}
                  username={user.username}
                  dateJoined={user.dateJoined}
                  animationDelay={index + 1}
                />
              ))}
            </div>
          </div>
          <div className={Style.cardsContainerClone}>
            <div className={Style.flexContainerClone}>
              {usersData?.map((user, index) => (
                <NewUsersCard
                  key={`user_clone_${index}`}
                  username={user.username}
                  dateJoined={user.dateJoined}
                  animationDelay={index + 1}
                />
              ))}
            </div>
          </div>
        </div>
      </Card>
    </SimpleStyledCard>
  );
};

export const NewUsersCard = ({
  username,
  dateJoined,
  animationDelay,
}) => {
  // Consts
  const [animationCompleted, setAnimationCompleted] = useState(false);
  const handleAnimationEnd = () => {
    setAnimationCompleted(true);
  };

  // Functions
  const formatDateUtc = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleString("en-US", {
      hour12: true,
      hour: "numeric",
      minute: "numeric",
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    const time = formattedDate.split(", ");
    return `${time[2]} ${time[0]} ${time[1]}`;
  }

  return (
    <div
      className={
        animationCompleted ? Style.countryCardBodyActive : Style.countryCardBody
      }
      style={{
        animation: `trigger 0s  ease-in-out ${animationDelay * 0.2}s`,
      }}
      onAnimationEnd={handleAnimationEnd}
    >
      <div className={Style.country}>
        <div style={{color: "#FFFFFF", marginBottom: 8}}>@{username}</div>
        <span>{formatDateUtc(dateJoined)}</span>
      </div>
    </div>
  );
};

export default RealTimeUsers;
