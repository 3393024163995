import React from "react";

// Components
import Checkbox from "../../../components/advanced/checkbox/Checkbox";

// Libraries
import useForm from "@hybris-software/use-ful-form";
import { Button } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";

// Data
import config from "../../../data/config";
import endpoints from "../../../data/endpoints";

//Images
// import Modal from "../../../assets/images/modal.png";

// Styles
import Style from "./TermsModal.module.css";

const TermsModal = ({ modalRef, linkPdf, generalModalRef, termsRef }) => {
  const form = useForm({
    inputs: {
      terms: {
        nature: "checkbox",
        value: false,
      },
    },
  });

  const acceptTermsApi = useQuery({
    url: endpoints.auth.TERMS,
    method: "POST",
  });

  return (
    <div className={Style.termsModal}>
      <h5 className={Style.title}>ITD Terms &amp; Conditions</h5>
      <p className={Style.info}>
        To become an Independent XERA Distributor, please accept the{" "}
        <a href={linkPdf} target="_blank" rel="noopener noreferrer">
          Terms and Conditions
        </a>
        .
      </p>

      <div className={Style.termsAccept}>
        <Checkbox
          checked={form.values["terms"]}
          onChange={(e) =>
            form?.getInputProps("terms").setValue(e.target.checked)
          }
        />
        I accept the ITD Terms &amp; Conditions
      </div>
      <Button
        className={Style.closeButton}
        disabled={!form.values["terms"]}
        onClick={() => {
          acceptTermsApi.executeQuery({
            version: config.TERMS_VERSION,
          });
          modalRef.current.destroy();
          // modalRef.current.updateBody(<InfoModalContent modalRef={modalRef} />);
        }}
      >
        Close
      </Button>
    </div>
  );
};

// const RewardsInfo = ({ modalRef }) => {
//   return (
//     <div className={Style.termsModal}>
//       <h5 className={Style.title}>Rewards in Process</h5>
//       <p className={Style.infoDisclamer}>
//         Your commission calculation is underway! To maintain your Unilevel
//         Active Status, please complete your purchase on KMALL by 12 pm UTC on
//         September 21st. Thank you for your continued partnership!
//       </p>

//       <Button
//         className={Style.closeButton}
//         onClick={() => {
//           window.open("https://app.lfi.io/", "_blank", "noopener,noreferrer");
//           modalRef.current.destroy();
//         }}
//       >
//         Purchase on KMALL
//       </Button>
//     </div>
//   );
// };

const InfoModalContent = ({ modalRef }) => {
  return (
    <div className={Style.termsModal}>
      <h5 className={Style.pop}>
        &#127881; Exciting Extension and System Update for XERA Community!
        &#x1F389;
      </h5>
      <p className={Style.infoDisclamer}>
        Hello XERA Family!
        <span>
          We come bearing fantastic news! To celebrate our collective success
          and to show our appreciation for your dedication, we're extending the
          commissionable month by an additional 3 days!&#128640;
        </span>
      </p>

      <p className={Style.infoDisclamer}>
        &#x1F4C6;<strong>Extra Days for Your Goals</strong>
        <span>
          This extension gives you more time to reach your targets and fully
          leverage our promotions.{" "}
        </span>
      </p>

      <p className={Style.infoDisclamer}>
        &#127775; <strong>Promotions Extended Until December 3rd</strong>
        <span>
          All promotions set to end on November 30th are now extended to
          December 3rd. This is your golden opportunity to advance in your
          career plan and take advantage of these offers. Please note, the
          closing time for this period is set for December 4th at 00:00 AM
          Server Time. Plan accordingly to make the most of this extended
          period.
        </span>
      </p>

      <p className={Style.infoDisclamer}>
        &#128295; <strong>System Enhancements</strong>
        <span>
          We're also using this time to fine-tune and fix minor glitches in the
          Ranking system. Our goal is to ensure a smoother, more accurate
          experience for everyone.
        </span>
      </p>

      <p className={Style.infoDisclamer}>
        &#128293; <strong>Seize the Moment!</strong>
        <span>
          Now is the perfect time to step up your efforts with XERA. Let's use
          these extra days to climb higher and achieve even more. Thank you for
          being an integral part of our vibrant XERA community. Together, let's
          make these additional days count and continue on our path to success!
        </span>
      </p>

      <Button
        className={Style.closeButton}
        onClick={() => {
          modalRef.current.destroy();
        }}
      >
        Close
      </Button>
    </div>
  );
};

export { TermsModal, InfoModalContent };
