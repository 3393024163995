import React, { useState } from 'react';

//Styles
import Style from "./PointsButtonGroup.module.css";
import { unitsList } from '../../../data/constants';

// Button component
const PointButton = ({ label, isSelected, onButtonClick }) => {
  return (
    <button
      className={isSelected ? Style.selectedButton : Style.button}
      onClick={onButtonClick}
    >
      {label}
    </button>
  );
};

// ButtonGroup component
const PointsButtonGroup = ({ initialSelected, onSelectedChange }) => {
  const [selected, setSelected] = useState(initialSelected);

  const handleButtonClick = (value) => {
    const lowerValue = value;
    setSelected(lowerValue);
    onSelectedChange(lowerValue);
  };

  const buttons = [
    { label: unitsList.VP.label, value: unitsList.VP.value },
    { label: unitsList.CP.label, value: unitsList.CP.value },
    { label: unitsList.IP.label, value: unitsList.IP.value }
  ];

  return (
    <div className={Style.buttonGroup}>
      {buttons.map((button) => (
        <PointButton
          key={button.value}
          label={button.label}
          isSelected={selected === button.value}
          onButtonClick={() => handleButtonClick(button.value)}
        />
      ))}
    </div>
  );
};

export default PointsButtonGroup;
