export default function generateCardData(data, isRoot = false) {
  return {
    id: data.id,
    position: { x: 0, y: 0 },
    data: {
      isRoot: isRoot,
      expanded: isRoot,
      personalVp: data.totalVp,
      downlineFetched: false,
      ...data,
    },
  };
}
