import React, { useState, useContext, useCallback, useMemo } from "react";

// Components
import MemberCard from "./../memberCard/MemberCard";

// Libraries
import ReactFlow, {
  Controls,
  Background,
  applyEdgeChanges,
  applyNodeChanges,
  MiniMap,
} from "reactflow";

// Contexts
import GeneralInfoContext from "../../../../../contexts/GeneralInfoContext";

// Utils
import useAnimatedNodes from "./../../../../../utils/useAnimatedNode";
import generateCardData from "../../../../../utils/generateCardData";
import useExpandCollapse from "../../../../../utils/useExpandCollapse";

// Styles
import "reactflow/dist/style.css";
import Style from "./InfinityBonusTree.module.css";

// Consts
const proOptions = {
  account: "paid-pro",
  hideAttribution: true,
};

const InfinityBonusTree = ({ fullScreen, userInfo }) => {
  // Context
  const [generalInfo] = useContext(GeneralInfoContext);

  // Consts
  const animationDuration = 300;
  const treeWidth = 420;
  const treeHeight = 400;

  const rootCardData = generateCardData(
    fullScreen ? userInfo : generalInfo.userInfo,
    true
  );

  const [nodes, setNodes] = useState([rootCardData]);
  const [edges, setEdges] = useState([]);

  // Hooks
  const { nodes: visibleNodes, edges: visibleEdges } = useExpandCollapse(
    nodes,
    edges,
    { treeWidth, treeHeight }
  );
  const { nodes: animatedNodes } = useAnimatedNodes(
    visibleNodes,
    animationDuration
  );

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  // Functions

  const triggerNodeExpansion = useCallback(
    (_, node) => {
      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === node.id) {
            return {
              ...n,
              data: { ...n.data, expanded: !n.data.expanded },
            };
          }
          return n;
        })
      );
    },
    [setNodes]
  );

  const setDownlineFetched = useCallback(
    (_, node) => {
      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === node.id) {
            return {
              ...n,
              data: { ...n.data, expanded: true, downlineFetched: true },
            };
          }
          return n;
        })
      );
    },
    [setNodes]
  );

  const deleteNode = useCallback(
    (_, node) => {
      setNodes((nds) => nds.filter((n) => n.id !== node.id));
    },
    [setNodes]
  );

  const nodeTypes = useMemo(
    () => ({
      custom: (props) => (
        <MemberCard
          triggerNodeExpansion={triggerNodeExpansion}
          setDownlineFetched={setDownlineFetched}
          deleteNode={deleteNode}
          fullScreen={fullScreen}
          {...props}
        />
      ),
    }),
    // eslint-disable-next-line
    [triggerNodeExpansion, setDownlineFetched, deleteNode]
  );

  return (
    <div
      className={Style.flowLayout}
      style={{ height: fullScreen ? "100vh" : "" }}
    >
      <ReactFlow
        nodes={animatedNodes}
        edges={visibleEdges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        fitView
        minZoom={0.1}
        zoomOnDoubleClick={false}
        nodeTypes={nodeTypes}
        proOptions={proOptions}
        onNodeClick={() => {}}
        nodesDraggable={false}
        nodesConnectable={false}
        elementsSelectable={false}
      >
        <Controls showInteractive={false} />
        <Background variant="none" color="#1e201e" gap="0" />
        <MiniMap
          className={Style.miniMapCLassName}
          nodeColor="#4d4d4d"
          maskColor="#615c5c33"
        />
      </ReactFlow>
    </div>
  );
};

export default InfinityBonusTree;
